import { NgModule } from '@angular/core';
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { faCircle, faSearch, faSquare } from '@fortawesome/free-solid-svg-icons';
import { faCircle as farCircle, faSquare as farSquare, faCalendarPlus, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faStackOverflow, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';
import { faTrashAlt, faCheckCircle, faTimesCircle, faEdit, faTrash, faClock, faFilePdf, faSchool, faChalkboardTeacher, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import timeGrid from '@fullcalendar/timegrid'; // a plugin!
import bootstrapPlugin from '@fullcalendar/bootstrap';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { ActividadComponent } from './actividad/actividad.component';
import { ActividadRolComponent } from './actividad-rol/actividad-rol.component';
import { AgendaComponent } from './agenda/agenda.component';
import { AgendaListComponent } from './agenda-list/agenda-list.component';
import { ReporteComponent } from './reporte/reporte.component';
import { BannerComponent } from './banner/banner.component';
import { BannerRolComponent } from './banner-rol/banner-rol.component';
import { CategoriaComponent } from './categoria/categoria.component';
import { DiaInhabilComponent } from './dia-inhabil/dia-inhabil.component';
import { GradoComponent } from './grado/grado.component';
import { SolicitudCampoComponent } from './solicitud-campo/solicitud-campo.component';
import { TemaComponent } from './tema/tema.component';
import { TransporteComponent } from './transporte/transporte.component';
import { UsuarioComponent } from './usuario/usuario.component';
import { SolicitudComponent } from './solicitud/solicitud.component';

import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NotificacionComponent } from './notificacion/notificacion.component';
import { SubcategoriaComponent } from './subcategoria/subcategoria.component';
import { ColegioComponent } from './colegio/colegio.component';
import { ResponsableComponent } from './responsable/responsable.component';
import { PanelComponent } from './panel/panel.component';
import { ResponsableColegioComponent } from './responsable-colegio/responsable-colegio.component';
import { config } from '../../core/services/config';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGrid,
  bootstrapPlugin
]);

/*
MICROSOFT GRAPH IMPORTS
 */
import {
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation
} from '@azure/msal-browser';
import {
  MsalModule,
  MsalService,
  MSAL_INSTANCE
} from '@azure/msal-angular';

let msalInstance: IPublicClientApplication | undefined = undefined;

export function MSALInstanceFactory(): IPublicClientApplication {
  msalInstance = msalInstance ?? new PublicClientApplication({
    auth: {
      clientId: config.appId,      
      redirectUri: config.redirectUri,
      postLogoutRedirectUri: config.redirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    }
  });

  return msalInstance;
}

@NgModule({
  declarations: [ActividadComponent, ActividadRolComponent, AgendaComponent, AgendaListComponent, ReporteComponent, BannerComponent, BannerRolComponent, CategoriaComponent, DiaInhabilComponent, GradoComponent, SolicitudCampoComponent, TemaComponent, TransporteComponent, UsuarioComponent, SolicitudComponent,
    UsuarioComponent,
    NotificacionComponent,
    SubcategoriaComponent,
    ColegioComponent,
    ResponsableComponent,
    PanelComponent,
    ResponsableColegioComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    FullCalendarModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgbModule,
    MsalModule
  ],
  providers: [{
    provide: NgbDateAdapter,
    useClass: NgbDateNativeAdapter
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
  },
    MsalService],

  exports: [ActividadComponent, ActividadRolComponent, BannerComponent, BannerRolComponent, CategoriaComponent, DiaInhabilComponent, GradoComponent, SolicitudCampoComponent, TemaComponent, TransporteComponent, UsuarioComponent, SolicitudComponent,
    UsuarioComponent,
    NotificacionComponent,
    SubcategoriaComponent,
    ColegioComponent]
})
export class InternoModule {

  constructor(library: FaIconLibrary) {

    // Add an icon to the library for convenient access in other components
    // library.addIconPacks(fas);
    library.addIcons(faCircle, faSquare, farCircle, farSquare
      , faStackOverflow, faGithub, faMedium, faTrashAlt, faCheckCircle, faTimesCircle, faEdit, faTrashAlt, faTrash
      , faClock, faFilePdf, faCalendarPlus, faCalendarAlt, faSearch, faSchool, faChalkboardTeacher, faPaperPlane);
    //library.addIcons(faStar, faPlus, faDownload, faFacebookF, faTwitch, faLinkedinIn, faDribbble, faChevronUp, faTwitter);
    //library.addIcons(faCircle,faSquare,farCircle,farSquare
    //      ,faStackOverflow,faGithub,faMedium);
  }

}
