<!-- About Section-->
<section class="section peffe">
    <div class="container">
      <div class="row pt-5 align-items-center">
        <div class="col-md-12 text-center my-auto">
          <h2 class="titulo-seccion text-danger">
            La Ibero
          </h2>
        </div>
      </div>
      <div class="container pt-2">
       <div class="row p-3 align-items-center ">
        <div class="col-sm-12 col-md-12 col-lg-1 about_img_01">
            <img  class="seccion-avatar-01 animate__animated animate__backInUp" src="/assets/img/sol-numero-001.svg" alt="" />
        </div>
        <div class="col-sm-12 col-md-12 col-lg-11 text-secondary text-left text-parrafo">
            La Ibero sirve a México y al mundo mediante la formación de profesionales e investigadores comprometidos con el
            servicio a los demás gracias a la calidad de sus programas académicos, que tienen como fin esencial la conformación,
            transmisión y progreso de la educación superior.
        </div>
      </div>

      <div class="row pt-3 align-items-center dos">
        <div class="col-sm-12 col-md-12 col-lg-11 dos text-secondary text-left text-parrafo ">
            Respaldada por cerca de cinco siglos de tradición educativa jesuita, la Ibero es una alternativa que va más allá de la
            simple impartición de estudios universitarios, para ofrecer una formación integral, sólida y ética, donde los avances
            científicos y tecnológicos no están reñidos con los valores humanos, en un marco de respeto a la diversidad de ideas y
            creencias.
        </div>
        <div class="col-sm-12 col-md-12 col-lg-1 mb-0 uno text-left about_img_02">
            <img class="seccion-avatar-01 animate__animated animate__backInUp" src="/assets/img/sol-numero-002.svg" alt="" />
        </div>
    </div>
    <div class="row pt-3 align-items-center">
        <div class="col-sm-12 col-md-12 col-lg-1 mb-0 text-left about_img_03 ">
            <img class="seccion-avatar-01 animate__animated animate__backInUp " src="/assets/img/sol-numero-003.svg" alt="" />
        </div>
        <div class="col-sm-12 col-md-12 col-lg-11 text-secondary text-left text-parrafo">
            En la actualidad, nuestra casa de estudios imparte programas de licenciatura en las divisiones de: Ciencia, Arte y Tecnología, Ciencias Sociales, Humanidades y Comunicación.
        </div>
    </div>

    <div class="row pt-3 align-items-center">
        <div class="col-sm-12 col-md-12 col-lg-11 text-secondary text-left text-parrafo cuatro">
            Todos los programas de licenciatura que se imparten cuentan con algún tipo de acreditación o evaluación externa de organismos nacionales o extranjeros.
        </div>
        <div class="col-sm-12 col-md-12 col-lg-1 mb-0 text-left tres about_img_04">
            <img class="seccion-avatar-01 animate__animated animate__backInUp" src="/assets/img/sol-numero-004.svg" alt="" />
        </div>
    </div>
    </div>
    </div>
    </section>
