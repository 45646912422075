<!--Contenedor-->
<div class="container align-items-center flex-column">

    <!--Encabezado-->
    <div class="text-center">
      <h4 class="title-section">Catálogo - Temas</h4>
    </div>
  
  <!--Filtros-->
  <form  [formGroup]="filtros" >
    <div class="row">

      <div class="col-lg-4">
        <label>Actividad</label>
        <select class="form-control"
                formControlName="actividadId" [(ngModel)]="mf.actividadId" (change)="selCategoria()">
          <option [ngValue]="0">--Seleccionar--</option>
          <option *ngFor="let item of ActividadLista"
                  [ngValue]="item.actividadId">
            {{ item.nombreactividad }}
          </option>
        </select>


        <!--select class="form-control"
                formControlName="actividadId" [(ngModel)]="mf.actividadId" (change)="filtrar()">
          <option value="">--Seleccionar--</option>
          <option *ngFor="let item of Actividad"
                  value="{{ item.actividadId }}">
            {{ item.nombre }}
          </option>
        </select-->
      </div>

      <div class="col-lg-4">
        <label>Categoría</label>

        <select class="form-control"
                formControlName="tema_categoriaId" [(ngModel)]="mf.tema_categoriaId" (change)="selSubCategoria()">
          <option [ngValue]="0">--Seleccionar--</option>
          <option *ngFor="let item of CategoriaLista"
                  [ngValue]="item.tema_categoriaId">
            {{ item.categoriaDescripcion }}
          </option>
        </select>

        <!--select class="form-control"
                formControlName="tema_categoriaId" [(ngModel)]="mf.tema_categoriaId" (change)="selSubcategoria()">
          <option value="">--Seleccionar--</option>
          <option *ngFor="let item of Categoria | async"
                  value="{{ item.tema_categoriaId }}">
            {{ item.descripcion }}
          </option>
        </select-->
      </div>
      <div class="col-lg-4">
        <label>Sub Categoría</label>

        <select class="form-control"
                formControlName="tema_subcategoriaId" [(ngModel)]="mf.tema_subcategoriaId" (change)="filtra()">
          <option [ngValue]="0">--Seleccionar--</option>
          <option *ngFor="let item of SubCategoriaLista"
                  [ngValue]="item.tema_subcategoriaId">
            {{ item.subCategoriaDescripcion }}
          </option>
        </select>

        <!--select class="form-control"
          formControlName="tema_subcategoriaId" [(ngModel)]="mf.tema_subcategoriaId" (change)="filtrar()">
    <option value="">--Seleccionar--</option>
    <option *ngFor="let item of Subcategoria"
            value="{{ item.tema_subcategoriaId }}">
      {{ item.descripcion }}
    </option>
  </select-->
      </div>

    </div>
    </form>


    <!--Listado-->
    <div class="row justify-content-between py-2">
      <div class="col-md-4 col-sm-12">
          <h5 class="title-subsection">Datos del tema</h5>
      </div> 
      <div class="col-md-4 col-sm-12 text-right">
          <button class="btn btn-primary btn_sac" (click)="nuevo(content)">Agregar</button>
      </div>     
    </div>
  
    <table class="table table-striped text-center">
      <thead>
        <tr>
          <th scope="col" class="encabezado">#</th>
          <th scope="col" class="encabezado">Actividad</th>
          <th scope="col" class="encabezado">Tema</th>
          <th scope="col" class="encabezado">Categoría</th>
          <th scope="col" class="encabezado">Sub categoría</th>
          <th scope="col" class="encabezado">Estatus</th>
          <th scope="col" class="encabezado">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr #item *ngFor="let item of TemasFiltrados; let i = index">
          <td scope="row">{{ i+1 }}</td>
          <td class="w-25">{{ item.nombreactividad }}</td>
          <td class="w-25">{{ item.descripcion }}</td>
          <td class="w-25">{{ item.categoriaDescripcion }}</td>
          <td class="w-25">{{ item.subCategoriaDescripcion }}</td>

          <td>{{ item.activo=='S'?"Activo":"Inactivo" }}</td>
          <td>
            <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar">
              <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot class="tfoot-ibero text-center">
        <tr>
          <td colspan="7">{{ (TemasFiltrados)?.length }} Registros</td>
        </tr>
      </tfoot>
    </table>
  </div>
  
  <!--Formulario-->
  <ng-template #content let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Datos del Tema</div>
        <div class="card-body text-dark">
  
          <form [formGroup]="forma">
            <div class="row text-dark">
              <input class="form-control" type="hidden" formControlName="temaId"
                     [(ngModel)]="model.temaId">
            </div>  
            <div class="row text-dark">

              <div class="form-group col-lg-4">
                <label for="tema_categoriaId">Categoría:</label>
                <select class="form-control"
                        formControlName="tema_categoriaId" [(ngModel)]="model.tema_categoriaId" (change)="loadSubCategoria()">
                  <option [ngValue]="0">--Seleccionar--</option>
                  <option *ngFor="let item of Categoria"
                          [ngValue]="item.tema_categoriaId">
                    {{ item.descripcion }}
                  </option>
                </select>
                <!--select class="form-control"
            formControlName="tema_subcategoriaId"
            [(ngModel)]="model.tema_subcategoriaId">
      <option value="">--Seleccionar--</option>
      <option *ngFor="let item of Subcategoria"
              [ngValue]="item.tema_subcategoriaId">
        {{ item.descripcion }}
      </option>
    </select-->
              </div>
              <div class="form-group col-lg-4">
                <label for="tema_subcategoriaId">Sub categoría:</label>
                <select class="form-control"
                        formControlName="tema_subcategoriaId" [(ngModel)]="model.tema_subcategoriaId">
                  <option [ngValue]="0">--Seleccionar--</option>
                  <option *ngFor="let item of SubCategoria"
                          [ngValue]="item.tema_subcategoriaId">
                    {{ item.descripcion }}
                  </option>
                </select>

                <!--select class="form-control"
            formControlName="actividadId"
            [(ngModel)]="model.actividadId">
      <option value="">--Seleccionar--</option>
      <option *ngFor="let item of Actividad"
              [ngValue]="item.actividadId">
        {{ item.nombre }}
      </option>
    </select-->
              </div>

              <div class="form-group col-lg-4">

                <label for="actividadId">Actividad:</label>
                <select class="form-control"
                        formControlName="actividadId" [(ngModel)]="model.actividadId">
                  <option [ngValue]="0">--Seleccionar--</option>
                  <option *ngFor="let item of ActividadLista"
                          [ngValue]="item.actividadId">
                    {{ item.nombreactividad }}
                  </option>
                </select>

                <!--select class="form-control"
            formControlName="tema_categoriaId"
            [(ngModel)]="model.tema_categoriaId" (change)="selSubcategoria()">
      <option value="">--Seleccionar--</option>
      <option *ngFor="let item of Categoria | async"
              [ngValue]="item.tema_categoriaId">
        {{ item.descripcion }}
      </option>
    </select-->
              </div>

            </div>

            <div class="row text-dark">
                <div class="col-lg-6">
                    <label for="descripcion">Tema</label><br/>
                    <input type="text" id="descripcion" class="form-control" formControlName="descripcion" [(ngModel)]="model.descripcion">
                </div>
              <div class="col-lg-6">                            
                <label for="tema_tipoId">Tipo Tema</label>
                <select class="form-control"
                        formControlName="tema_tipoId"
                        [(ngModel)]="model.tema_tipoId">
                  <option value="">--Seleccionar--</option>
                  <option *ngFor="let item of tipoTema | async"
                          [ngValue]="item.tema_tipoId">
                    {{ item.descripcion }}
                  </option>
                </select>
              </div>                                          
            </div>


            <div class="row text-dark align-items-center">
              <div class="col-lg-6">
                  <label for="objetivo">Objetivo</label><br/>
                  <textarea id="objetivo" class="form-control" formControlName="objetivo" [(ngModel)]="model.objetivo" rows="2"></textarea>
              </div>
              <div class="col-lg-6">
                <label for="contenido">Contenido</label><br/>
                <textarea id="contenido" class="form-control" formControlName="contenido" [(ngModel)]="model.contenido" rows="2"></textarea>
              </div>                            
            </div>            

            <div class="row text-dark">
              <div class="col-lg-6">
                <label for="tema_tipo_publicoId">Tipo público</label>
                <select class="form-control"
                        formControlName="tema_tipo_publicoId"
                        [(ngModel)]="model.tema_tipo_publicoId">
                  <option value="">--Seleccionar--</option>
                  <option *ngFor="let item of TipoPublico | async"
                          [ngValue]="item.tema_tipo_publicoId">
                    {{ item.descripcion }}
                  </option>
                </select>
              </div>  

              <div class="col-lg-6">
                <label for="idiomaId">Idioma</label>
                <select class="form-control"
                        formControlName="idiomaId"
                        [(ngModel)]="model.idiomaId">
                  <option value="">--Seleccionar--</option>
                  <option *ngFor="let item of Idioma | async"
                          [ngValue]="item.idiomaId">
                    {{ item.descripcion }}
                  </option>
                </select>
              </div>                             
            </div>
            <div class="row text-dark">
                <div class="col-lg-6">
                  <label for="costo">Costo</label><br/>
                  <input type="text" id="costo" class="form-control" formControlName="costo" [ngValue]="model.costo" [(ngModel)]="model.costo">
              </div>
              <div class="col-lg-6">                            
                <label for="activo">Estatus</label>
                <select class="form-control" formControlName="activo"
                        [(ngModel)]="model.activo">
                  <option value="">--Seleccionar--</option>
                  <option value="S">Activo</option>
                  <option value="N">Inactivo</option>
                </select>
              </div>
            </div>            
            <div class="row text-dark pt-3">
                <div class="col-lg-12">
                    <div class="buttonSelect text-center">
                        <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                        <button class="btn btn-danger" (click)="guardar()">Guardar</button>
                    </div>
                </div>
            </div>
    
        </form>  
  
  
        </div>
      </div>
      
    </div>
    <!--div class="modal-footer">
      <button class="btn btn-danger" (click)="modal.close()">Regresar</button>&nbsp;
      <button class="btn btn-danger" (click)="modal.close()">Guardar</button>
    </div-->
   
   </ng-template>
