<section class=" section xplora hfull">
    <div class="container-fluid flex-column">
      <div class="row align-items-center  min-vh-100">
          <div class="ibero-texto col-sm-12 col-md-5 offset-md-5 text-right btn-xplora" >
             <button type="button" class=" text-color btn btn-danger btn-lg" (click)="ir()">Registra a tu (s) grupo (s)
               <!--a href="{{vLink}}" target='_blank'>Registra a tu (s) grupo (s)</a-->
             </button>
             <!--a class="btn btn-danger" href="{{vLink}}" role="button">Registra a tu (s) grupo (s)</a-->
          </div>
        </div>
        </div>
  </section>
