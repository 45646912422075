<!-- About Section-->
<section class="section ">
    <div class="container-fluid  d-flex align-items-center flex-column">
      <div class="row sol-titulo">
        <div class="col-md-12 text-center pt-3">
            <h2 class="titulo-seccion-actividades text-secondary ">
                Actividades
            </h2>
        </div>
    </div>

        <div class="row pt-5">
            <div class="col-sm-12 col-md-12 col-lg-4 tarjeta">
                <div class="card-carousel shadow h-100">
                    <img class="card-icon animate__animated animate__backInUp" src="/assets/img/icono_ibero02.svg" alt="" />
                    <div class="card-body">
                      <p class="text-danger text-center">Ibero</p>
                        <p class="text-secondary ">
                        Te invitamos a conocer nuestros talleres,
                        laboratorios y aulas especializadas, donde
                        tus alumnos (as) podrán realizar diversas
                        actividades que les permitirán comenzar
                        su camino a la vida universitaria. En esta
                        sección encontrarás actividades como
                        visitas guiadas, prácticas de laboratorio,
                        torneos deportivos, visitas a la biblioteca y
                        al centro astronómico Clavius, entre otras.
                      </p>
                    </div>
                    <button type="button" class="btn btn-danger" [routerLink]="['/actividad-form/1']">
                      Solicitar Actividad
                    </button>
                </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-4 tarjeta">
              <div class="card-carousel shadow h-100">
                <img class="card-icon animate__animated animate__backInUp" src="/assets/img/icono_colegio02.svg" alt="" />
                  <div class="card-body">
                    <p class="text-danger text-center">En tu colegio</p>
                      <p class="text-secondary ">
                        La Ibero también llega a tu colegio para
                        apoyar la formación de tus estudiantes y
                        enriquecer los contenidos en el aula. Si
                        alguno (a) de tus maestros (as) necesita
                        complementar sus clases, podemos
                        apoyarles con pláticas y/o talleres. Por otro
                        lado, si organizas una feria de
                        Universidades, requieres una plática de
                        información general o de alguna carrera,
                        será un placer acompañarte.
                    </p>
                  </div>
                  <button type="button" class="btn btn-danger" [routerLink]="['/actividad-form/2']">
                    Solicitar Actividad
                  </button>
              </div>
          </div>
<!--
          <div class="col-sm-12 col-md-6 col-lg-3 tarjeta">
            <div class="card-carousel shadow h-100">
              <img class="card-icon animate__animated animate__backInUp" src="/assets/img/icono_orientadores vocacionales02.svg" alt="" />
                <div class="card-body">
                  <p class="text-danger text-center">Orientación vocacional</p>
                    <p class="text-secondary ">
                      Sabemos que la elección de carrera es
                      una decisión muy importante para tus
                      alumnos (as). En esta sección puedes
                      encontrar todas las actividades de
                      orientación vocacional, como son:
                      talleres, pláticas, conferencias, entre
                      otras. Solicítalas para ser realizadas en tu
                      colegio o en nuestras instalaciones.
                  </p>
                </div>
                <button type="button" class="btn btn-danger" [routerLink]="['/actividad-form/3']">
                  Solicitar Actividad
                </button>
            </div>
        </div>
-->
        <div class="col-sm-12 col-md-12 col-lg-4 tarjeta">
          <div class="card-carousel shadow h-100">
            <img class="card-icon animate__animated animate__backInUp" src="/assets/img/icono_adistancia02.svg" alt="" />
              <div class="card-body">
                <p class="text-danger text-center">Actividades a distancia</p>
                  <p class="text-secondary">
                  Por si lo requieres, en la Ibero hemos
                  adaptado nuestras actividades en modalidad
                  a distancia. En esta sección podrás solicitar
                  las que te resulten de interés y con ello
                  brindar a tus alumnos y alumnas una
                  experiencia universitaria sin importar dónde
                  se encuentren.
                </p>
              </div>
              <button type="button" class="btn btn-danger" [routerLink]="['/actividad-form/4']">
                Solicitar Actividad
              </button>
          </div>
      </div>
   </div>
  </div>
</section>
