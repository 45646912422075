<section class="mb-0" id="about">
<!-- Encabezado -->
  <div class="container align-items-center flex-column">
      <div class="row text-center">
          <div class="col-md-4 my-auto">
              &nbsp;
          </div>
          <div class="col-md-4 my-auto mb-2">
              &nbsp;
              <h1 class="pagina-seccion-titulo titulo-seccion-2 text-dark">Temas</h1>
              <!--h2 class="pagina-seccion-titulo sol-titulo text-secondary mb-12">
                  Temas
              </h2-->
          </div>
      </div>
<!-- Filtros -->
      <div class="row justify-content-center py-2">
        <div class="card flex-column text-danger">
          <p class="pl-1">Pláticas para:</p>
          <div class="row">
            <div class="col-md-4 text-center" *ngFor="let item of (TipoPublico); let i=index"  >
              <button [class.selected]="i==selectedUser" class="btn icon-btn active" id="tema-{{ item.tema_tipo_publicoId }}" (click)="selTipoPublico(item.tema_tipo_publicoId); onSelect(i)" ngbTooltip="" data-toggle="button" >
                <img ngbTooltip="" class="img-box menu-image animate__animated animate__backInDown" src="/assets/img/tema-{{ item.tema_tipo_publicoId }}.png" alt="" />
              </button>
              <p>{{ item.tipoPublicoDescripcion }}</p>
            </div>
          </div>
        </div>
    </div>

  <form  [formGroup]="forma" >
  <div class="row pt-0">

    <div class="col-lg-4">
      <label class="text-danger">Actividad</label>
      <select class="form-control"
      formControlName="actividadId" [(ngModel)]="model.actividadId" (change)="selCategoria()">
        <option [ngValue]="0">--Seleccionar--</option>
        <option *ngFor="let item of ActividadLista"
        [ngValue]="item.actividadId" >
        {{ item.nombreactividad }}
      </option>
    </select>
    </div>    

    <div class="col-lg-4">
      <label class="text-danger">Categoría</label>
      <select class="form-control"
      formControlName="tema_categoriaId" [(ngModel)]="model.tema_categoriaId" (change)="selSubCategoria()">
        <option [ngValue]="0">--Seleccionar--</option>
        <option *ngFor="let item of CategoriaLista"
        [ngValue]="item.tema_categoriaId" >
        {{ item.categoriaDescripcion }}
    </option>
      </select>
    </div>
    <div class="col-lg-4">
      <label class="text-danger">Sub Categoría</label>
      <select class="form-control"
      formControlName="tema_subcategoriaId" [(ngModel)]="model.tema_subcategoriaId" (change)="filtra()">
        <option [ngValue]="0">--Seleccionar--</option>
        <option *ngFor="let item of SubCategoriaLista"
        [ngValue]="item.tema_subcategoriaId" >
          {{ item.subCategoriaDescripcion }}
          </option>
      </select>
    </div>
    <!--
      <div class="col-lg-4">
        <label class="text-danger">Tipo Tema</label>
        <select class="form-control"
        formControlName="tema_tipoId" [(ngModel)]="model.tema_tipoId" (change)="filtra()">
          <option value="">--Seleccionar--</option>
          <option *ngFor="let item of TemaTipo"
          value="{{ item.tema_tipoId }}" >
          {{ item.descripcion }}
        </option>
      </select>
      </div>
    -->
  </div>

</form>

<!-- Listado y Tarjeta -->
<div class="row pt-2">
  <div class="col-lg-4 cust-acc">

    <ul>
      <li class="grupo-temas text-left" *ngFor="let item of ActividadesAgrupadas; index as i">
        <a data-toggle="collapse">{{item.nombreactividad}}</a>
        <ul class="tema-scroll">
          <li class="lista-temas" *ngFor="let value of item.groupItem">
            <a (click)="mostrarTema(value)">{{ value.descripcion }}</a>
          </li>
        </ul>
      </li>
    </ul>

  </div>
  <div class="col-lg-8">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Detalles</div>
      <div class="card-body text-dark">
        <!--h5 class="card-title">Dark card title</h5>
        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p-->
          <p class="card-text"><strong class="text-danger">Categoría:</strong> <br/>{{vCategoria}}</p>
          <p class="card-text"><strong class="text-danger">SubCategoría:</strong> <br/>{{vSubCategoria}}</p>
          <p class="card-text"><strong class="text-danger">Tema:</strong> <br/>{{vTema}}</p>
          <p class="card-text"><strong class="text-danger">Objetivo:</strong> <br/>{{vObjetivo}}</p>
          <p class="card-text"><strong class="text-danger">Idioma:</strong> <br/>{{vIdioma}}</p>
          <!--p class="card-text"><strong class="text-danger">Costo:</strong> <br/>{{vCosto}}</p-->
          <div [hidden]="vCategoria===''" class="buttonSelect text-right">
            <button id="acceptConsentButton" class="btn btn-danger" (click)="addToCart(contenido, vIdTema)">Solicitar</button>
          </div>

      </div>
    </div>
  </div>
</div>
</div>

</section>

<!-- Carrito -->
<ng-template #contenido let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Solicitudes a reservar</div>
        <div class="card-body text-dark">

          <ng-container *ngFor="let item of items">
            <div class="row" *ngIf="item.temaId !== undefined">
                <div class="col-md-10">
                    <p> {{ item.nombreactividad }} [{{ item.descripcion }}]</p>
                </div>
                <div class="col-md-2">
                    <button class="btn" (click)="removeFromCart(item.actividadId, item.temaId)" ngbTooltip="Borrar ">
                    <fa-icon [icon]="['fas','trash-alt']" class="text-danger"></fa-icon>
                    </button>
                </div>
            </div>

            <div class="row" *ngIf="item.nombre !== undefined">
                <div class="col-md-10">
                    <p>{{ item.nombre }}</p>
                </div>
                <div class="col-md-2">
                    <button class="btn" (click)="removeFromCart(item.actividadId, 0)" ngbTooltip="Borrar">
                    <fa-icon [icon]="['fas','trash-alt']" class="text-danger"></fa-icon>
                    </button>
                </div>
            </div>

        </ng-container>

          <!--div class="row" *ngFor="let item of items">
            <div class="col-md-10">
              <p>{{ item.descripcion }}</p>
            </div>
            <div class="col-md-2">
              <button class="btn" (click)="removeFromCart(item.temaId)" ngbTooltip="Borrar ">
                <fa-icon [icon]="['fas','trash-alt']" class="text-danger"></fa-icon>
              </button>
            </div>
          </div-->

        </div>
      </div>
  </div>

  <div class="modal-footer text-center">
    <!--button class="btn btn-danger" (click)="modal.close()">Cerrar</button-->
    <button class="btn btn-danger" (click)="modal.close();gotoCart()">Reservar</button>&nbsp;
    <button class="btn btn-danger" (click)="emptyCart()">Limpiar Lista</button>
  </div>

 </ng-template>
