<!--


  <h1>Apoyo para colegios</h1>
  <div class="row">
    <div>
      <img src="/assets/img/estudiantes.svg" />
    </div>
   <div class="col-sm-12 col-lg-6 offset-lg-6 pt-3">
    <h2 class="text-center"> Inicio de sesión</h2>
    <div class="card ">
         <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="loginForm">
                <div class="form-group">
                    <label for="email">Usuario</label>
                    <input type="text" formControlName="email" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Ingresa tu correo electrónico"/>
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback" >
                        <div *ngIf="f.email.errors.required ">Email es requerido</div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="password">Contraseña</label>
                    <input type="password" formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Ingresa tu contraseña" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password es requerido</div>
                    </div>
                </div>

                <re-captcha (resolved)="resolved($event)" siteKey="6LcViLsZAAAAAMeBjvn6NHAI6tE2b1Kgn9KloLEN"></re-captcha>
                <div class="form-group text-center">
                    <button [disabled]="loading && enabled" class="btn btn-primary " (click)="loginAction()">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Entrar
                    </button>
                    <button class="btn btn-danger" [routerLink]="['/portal']">Entrar</button>
                </div>
            </form>
        </div>
    </div>
    <div class="text-center">
      <a routerLink="/recupera" class="text-dark">¿Olvidó su contraseña?</a>
    </div>
    </div>
  </div>
-->

<!-- Login Section-->
<div class="section nube_fondo">
  <header>
    <nav class="nav navbar navbar-expand-lg bg-dark " >
      <div class="container">
        <a href="#page-top">
          <img class="logo-size" src="/assets/img/logo-ibero.svg" alt="">
        </a>
        <div>
          <fa-icon [icon]="['fas','user-alt']" class="text-white mr-2"></fa-icon>
          <a routerLink="/registro" class="text-white">Crear nuevo usuario</a>
        </div>

      </div>
    </nav>
  </header>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-sm-12 col-md-7  pt-2 contiene_img_login">
        <h1 class="titulo-seccion  titulo_login text-danger text-center">
          Apoyo para<br> Colegios
      </h1>
      <img class="student_login seccion-avatar-01 animate__animated animate__backInLeft" src="/assets/img/students_login.svg"  alt="" />
      <img class="book_login seccion-avatar-01 animate__animated animate__backInRight" src="/assets/img/libro02.svg" alt="" />
      </div>
      <div class="col-sm-12 col-md-5 ">
        <h2 class="titulo-seccion titulo_sesion text-danger text-center pb-5">
          Inicio de sesión
      </h2>

      <form [formGroup]="loginForm" >
          <div class="form-group">
              <label for="correo">Usuario</label>
              <input type="text"  class="form-control" id="correo" formControlName="correo"  placeholder="Ingresa tu correo electrónico" />

          </div>
          <div class="form-group">
              <label for="contrasenia">Contraseña</label>
              <input type="password" class="form-control" id="contrasenia" formControlName="contrasenia"   placeholder="Ingresa tu contraseña"/>

          </div>

          <div class="form-group text-center">
              <button [disabled]="loading" class="btn btn-danger" (click)="entrar1();">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  Entrar
              </button>
          </div>
          <div class="text-center">
            <a [routerLink]="['./recuperacion']" class="text-dark">¿Olvidó su contraseña?</a>
          </div>
          <div class="text-center">
            <span class="text-danger">
              {{ Mensaje }}
            </span>
          </div>
      </form>
      </div>
    </div>
  </div> <!--End container-fluid-->
  </div>
