<div *ngIf="us.validar(); then thenTemplateName else elseTemplateName">
  Here is never showing
</div>

<ng-template #thenTemplateName>
      <app-nav-bar></app-nav-bar>
      <router-outlet></router-outlet>
</ng-template>

<ng-template #elseTemplateName>
  <router-outlet></router-outlet>
  <!--
  <app-login></app-login>
  -->
</ng-template>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> cargando... </p>
</ngx-spinner>  

<app-footer class = "mt-auto"></app-footer>
  
<!--
  <app-nav-bar></app-nav-bar>
  <router-outlet></router-outlet>
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" [fullScreen] = "true"><p style="color: white" > cargando... </p>
  </ngx-spinner>
  <app-footer class = "mt-auto"></app-footer>
-->