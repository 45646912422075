<div class="container">
  <div class="row justify-content-center align-items-center">
    <div class="col-md-10 pt-5">
      <h2 class=" titulo-seccion text-danger text-center py-5 titulo-seccion-cursos">Recuperación de contraseña</h2>
        <div class="row justify-content-center content-registro peffe">
          <p class="pt-5 text-center font-weight-bold">Introduzce el correo electrónico usado en tu registro para recibir las instrucciones de recuperación de contraseña.</p>
          <div class="col-sm-12 col-md-8 pt-5">
            <form [formGroup]="forma">
              <!--div class="form-group">
                <label class="font-weight-bold">Ingresa tu correo electronico</label>
                <input class="form-group" type="email"  class="form-control" />
              </div-->

              <div class="form-group">
               <label for="correo"><span class="text-danger">*</span>Ingresa tu correo electronico</label>
               <input class="form-control" type="text" formControlName="correo" >
               <div class="text-danger" *ngIf="forma.controls['correo'].touched && forma.controls['correo'].errors?.required">El correo es requerido</div>
               <div class="text-danger" *ngIf="forma.controls['correo'].touched && forma.controls['correo'].errors?.email">Ingrese un correo válido</div>
              </div>

              <div class="form-group text-center pt-5">
                <button class="btn btn-danger mr-3" [routerLink]="['/']">Cancelar</button>
                <button type="submit" class="btn btn-danger" (click)="guardar()" [disabled]="forma.invalid">Enviar</button>
              </div>
            </form>
          </div>
        </div>
    </div>
  </div>
</div>
