import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000,
    didOpen: (toast: any) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  constructor() { }

  showSuccess(message: string) {
    this.Toast.fire({
      icon: 'success',
      title: message,
    });
  }

  showError(message: string) {
    this.Toast.fire({
      icon: 'error',
      title: message,
    });
  }

  showWarning(message: string) {
    this.Toast.fire({
      icon: 'warning',
      title: message,
    });
  }

  showInfo(message: string) {
    this.Toast.fire({
      icon: 'info',
      title: message,
    });
  }
}
