<!--Contenedor-->
<div class="container align-items-center flex-column">

    <!--Encabezado-->
    <div class="text-center">
        <h4 class="title-section">Reportes - Actividades</h4>
    </div>

    <form [formGroup]="forma">
        <div class="row">
            <div class="col-md-3">
                <label>Actividad:</label>
                <select class="form-control" formControlName="agenda_actividadId">
                    <option value="0">--Seleccionar--</option>
                    <option *ngFor="let item of Actividades" [ngValue]="item.agenda_actividadId">{{ item.nombre }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label>Tipo de Actividad:</label>
                <select class="form-control" formControlName="tipo_actividad">
                    <option value="">--Seleccionar--</option>
                    <option value="Interna">Interna</option>
                    <option value="Externa">Externa</option>
                </select>
            </div>
            <div class="col-md-3">
                <label>Fecha de Inicio:</label>
                <div class="input-group">
                    <input class="form-control" placeholder="yyyy-mm-dd" [readonly]="true" formControlName="fecha_inicio" ngbDatepicker #d="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <label>Fecha Fin:</label>
                <div class="input-group">
                    <input class="form-control" placeholder="yyyy-mm-dd" [readonly]="true" formControlName="fecha_fin" ngbDatepicker #e="ngbDatepicker">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="e.toggle()" type="button"></button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <!--Listado-->
    <div class="row justify-content-between py-2">
        <div class="col-md-4 col-sm-12">
            <h5 class="title-subsection">Reporte</h5>
        </div>
        <div class="col-md-4 col-sm-12 text-right">
            <button class="btn btn-primary btn_sac" (click)="loadMainData()">Ver Reporte</button>
        </div>
    </div>

    <table class="table table-striped text-center table-fixed">
        <thead>
            <tr>
                <th scope="col" class="encabezado">#</th>
                <th scope="col" class="encabezado">Nombre Usuario</th>
                <th scope="col" class="encabezado">Horas Asignadas</th>
            </tr>
        </thead>
        <tbody>
            <tr #item *ngFor="let item of Reporte; let i = index">
                <td scope="row">{{ i+1 }}</td>
                <td>
                    {{ item.nombreCompleto }}
                </td>
                <td>
                    {{ item.horasAsignadas }}
                </td>
            </tr>
        </tbody>
        <tfoot class="tfoot-ibero text-center">
            <tr>
                <td colspan="3">{{ (Reporte)?.length }} Registros</td>
            </tr>
        </tfoot>
    </table>
</div>
