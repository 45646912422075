import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from '../../../core/services/usuario.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-responsable',
  templateUrl: './responsable.component.html',
  styles: [
  ]
})
export class ResponsableComponent implements OnInit {

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal    
  ) {
    if(!this.usuarioService.int()) this.router.navigate(['/portal']);
    this.iniciarForm();
   }

  ngOnInit(): void {
    this.addUsuarios();
    this.addLista();
    this.sort();
  }

  forma;
  model;
  iniciarForm() {
    this.forma = this.fb.group({
      colegio_responsableId: 0,
      nombre: ['', [Validators.required, Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)]],
      colegioId: 0,
      apaterno: ['', [Validators.required, Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)]],
      amaterno: ['', [Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)]],
      correo: ['', [Validators.required, Validators.email]],
      telefono: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
      extension: ['', [Validators.pattern("^[0-9]*$"), Validators.minLength(1), Validators.maxLength(5)]],
      activo:'S'
    });  
    this.model = this.forma;
  }

  Usuario;
  UsuarioLista;
  ColegioLista;
  addUsuarios(){
    this.spinner.show();
    this.usuarioService.getUsuarioAll().toPromise()
    .then(data => {
      this.Usuario = data;
      //Asignamos el universo de items al listado, mediante una entidad de filtro
      //filtramos solo los usuarios de colegio, para poner la lista de colegios (rolId=2)
      this.UsuarioLista = this.Usuario.filter(x => x.rolId === 2);

      this.ColegioLista = Array.from(new Set(this.UsuarioLista
        .map((item: any) => item.colegioId)))
        .map( colegioId=> { return this.UsuarioLista.find(obj => obj.colegioId === colegioId) } )  

      this.ColegioLista = this.ColegioLista.sort((a, b) => a.colegioNombre > b.colegioNombre ? 1 : -1);

      //this.UsuarioLista = this.Usuario;
      //console.log(this.ColegioLista);
      this.spinner.hide();
    })
  }

  Responsable;
  ResponsableLista;
  addLista(){
    this.spinner.show();
    this.usuarioService.getResponsableColegioAll().toPromise()
    .then(data => {
      this.Responsable = data;
      //Asignamos el universo de items al listado, mediante una entidad de filtro
      this.ResponsableLista = this.Responsable;
      //console.log(this.ResponsableLista);
      this.spinner.hide();
    })
  }

  nuevo(content) {
    this.iniciarForm();
    this.model.activo = 'S';
    this.modal.open(content,{size:'xl'});
  }
  
  editar(content, modelo) {

    this.model = modelo;
    this.modal.open(content, { size: 'xl' });
    /*
    var objColegio = this.model.colegioId;
    //Si seleciona uno de la lista
    if(typeof objColegio === 'object'){
      //El control de búsqueda asigna un objeto al colegioId
      //Extraemos el id del Colegio y reemplazamos el valor
      //var objColegio = this.forma.controls["colegioId"].value;
      //this.forma.controls["colegioId"].setValue(Number(objColegio.colegioId));
      this.model.colegioId = Number(objColegio.colegioId)
    }
    //Si captura uno nuevo
    if(typeof objColegio === 'string'){
      this.model.colegioId = 0;
    }


    if(this.model.colegioId!==0){
      this.usuarioService.getColegio(this.model.colegioId).toPromise()
      .then(data => {
        this.oColegio = data;
        this.model.colegioId = data;
        this.modal.open(content, { size: 'xl' });
      });
    }
    else{
        this.modal.open(content, { size: 'xl' });
    }
    */
  }

  guardar() {
    /*
    if (this.forma.controls["usuario_tipoId"].value == 1) {
      this.forma.controls["colegioId"].setValue(0);
    }
    var objColegio = this.forma.controls["colegioId"].value;
    //Si seleciona uno de la lista
    if(typeof objColegio === 'object'){
      //El control de búsqueda asigna un objeto al colegioId
      //Extraemos el id del Colegio y reemplazamos el valor
      //var objColegio = this.forma.controls["colegioId"].value;
      this.forma.controls["colegioId"].setValue(Number(objColegio.colegioId));
    }
    //Si captura uno nuevo
    if(typeof objColegio === 'string'){
      this.forma.controls["colegioId"].setValue(0);
      //this.forma.controls["otro_colegio_nombre"].setValue(objColegio);
    }
    */
    
    //return false;
    this.spinner.show();
    //this.forma.controls["colegioId"].value = 1;
    //console.log(this.forma.value);
    if (this.forma.controls["colegio_responsableId"].value == undefined) {
      this.usuarioService.addResponsableColegio(this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {
          this.spinner.hide();
          console.log(error);
          /*
          if(error.error.result === 3)
          {
            this.spinner.hide();
            this.forma.controls["colegioId"].setValue(null);
            this.toastr.error(error.error.message);
          }
          console.log(error);
          */
        });
    }
    else {
      this.usuarioService.updResponsableColegio(this.forma.controls["colegio_responsableId"].value, this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {
          console.log(error);
          /*
          if(error.error.result === 4)
          {
            this.toastr.error(error.error.message);

            if(this.model.colegioId!==0){
              this.usuarioService.getColegio(this.model.colegioId).toPromise()
              .then(data => {
                this.oColegio = data;
                this.model.colegioId = data;
              });
            }
            else{
            }
          }
          */
        });
    }
  }
  
  recargar() {
    this.modal.dismissAll();
    this.addLista();
  }
  
  asc;
  sort(){
    const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;

    const comparer = (idx, asc) => (a, b) => ((v1, v2) =>
        v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
        )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

    // do the work...
    document.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
      const table = th.closest('table');
      const tbody = table.querySelector('tbody');
      Array.from(tbody.querySelectorAll('tr'))
        .sort(
          comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc)
          )
        .forEach(tr => tbody.appendChild(tr) );
    })));

  }  
  

}
