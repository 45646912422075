import { Component, OnInit } from '@angular/core';
import { BannerService } from '../../../core/services/banner.service';
import { UsuarioService } from '../../../core/services/usuario.service';
import { CarritoService } from '../../../core/services/carrito.service';

@Component({
  selector: 'app-especiales',
  templateUrl: './especiales.component.html',
  styles: [
  ]
})
export class EspecialesComponent implements OnInit {

  constructor(
    private bannerservice:BannerService,
    private carritoService: CarritoService,
    private usuarioService: UsuarioService
  ) { 
    this.addBanners();
  }

  ngOnInit(): void {
  }

  User;
  Banner;
  BannerList;
  addBanners(){
    //this.spinner.show();
    this.bannerservice.bannerList().toPromise()
    .then(data => {
      //this.spinner.hide();
      this.Banner = data;
      //Consultamos los banners especiales
      this.Banner = this.Banner.filter(x => x.banner_grupoId === Number(7));

      //Traemos los permisos de banners del usuario
      this.User = this.usuarioService.getUser();
      let arr = this.User.banner_seleccionado.replace(/\s/g, '').split(",");

      //Filtramos los banners que tienen permiso
      this.Banner = this.Banner.filter(function(item){
        return arr.indexOf(String(item.bannerId)) >= 0;
      });

      //Determinamos el permiso del banner en pantalla
      /*
      for (var i=0;i<this.Banner.length;i++){
        this.Banner["bPermiso"] = true;
      }
      */

      //Asignamos el universo de items al listado, mediante una entidad de filtro
      this.BannerList = this.Banner;
      //console.log(this.BannerList);
    })

  }
  
  ir(item){
    //this.Banner = this.carritoService.getBanners('espacio')[0];
    window.open(item.referencia, "_blank");        
  }

}
