import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../../core/services/usuario.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-restaurar',
  templateUrl: './restaurar.component.html',
  styles: [
  ]
})
export class RestaurarComponent implements OnInit {
  forma: FormGroup;
  password1;
  password2;
  constructor(
    private formBuilder: FormBuilder,
    private usuarioservice: UsuarioService,
    private router: Router
  ) { }
  Mensaje;
  User;
  ngOnInit(): void {
    this.iniciarForm();
  }

  iniciarForm() {
    this.forma = this.formBuilder.group({
      password1: [''],
      password2:[''],
      contrasenia:[''],
      usuarioId:0
    });
  }  
  //Restaura
  entrar() { 
    //this.forma.controls["password1"].value
    this.password1 = this.forma.controls["password1"].value; 
    this.password2 = this.forma.controls["password2"].value; 
    //console.log(this.password1);
    //alert(this.password1);
    //password2 = form.password2.value; 
    //this.forma.value

    // If password not entered 
    if (this.password1 == '') 
        this.Mensaje = "Ingrese una contraseña.";
        //alert ("Please enter Password"); 
          
    // If confirm password not entered 
    else if (this.password2 == '') 
      this.Mensaje = "Confirme la contraseña.";
      //alert ("Please enter confirm password"); 
          
    // If Not same return False.     
    else if (this.password1 != this.password2) { 
      this.Mensaje = "Las contraseñas no coinciden.";
        //alert ("\nPassword did not match: Please try again...") 
        return false; 
    } 

    // If same return True. 
    else{ 
      this.User = this.usuarioservice.getUser();
      this.forma.controls["usuarioId"].setValue(this.User.usuarioId);
      this.forma.controls["contrasenia"].setValue(this.forma.controls["password1"].value);
      //console.log(this.forma.value);
      this.usuarioservice.restaura(this.User.usuarioId, this.forma.value).toPromise()
      .then(data => {
        this.User = data;
        //this.usuarioservice.addUser(this.User);
        //console.log(this.User);
        alert("La contraseña ha sido reestablecida con éxito. Favor de continuar.");
        this.router.navigate(['/']);
        return false;
      });
        //this.Mensaje = "Las contraseñas no coinciden.";
       // alert("Password Match: Welcome to GeeksforGeeks!") 
        //return true; 
    } 
}   

}
