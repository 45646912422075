<!--Contenedor-->
<div class="container align-items-center flex-column">

  <!--Encabezado-->
  <div class="text-center">
    <h4 class="title-section">Solicitudes</h4>
  </div>

  <!--Filtros-->
  <form  [formGroup]="filtros" >
  <div class="row">
    <div class="col-lg-6">
      <label>Actividad</label>
      <select id="" name="" class="form-control"
      formControlName="actividadId" [(ngModel)]="mf.actividadId" (change)="filtrar()">
        <option value="">--Seleccionar--</option>
        <option *ngFor="let item of NombreActividad"
          value="{{ item.actividadId }}" >
          {{ item.nombreaportal }}
          </option>
      </select>
    </div>    
    <div class="col-lg-3">
      <label>Estado</label>
      <select class="form-control"
        formControlName="estatus_actividadId" [(ngModel)]="mf.estatus_actividadId" (change)="filtrar()">
        <option value="">--Seleccionar--</option>
        <option *ngFor="let item of EstadoActividad"
        value="{{ item.estatus_actividadId }}" >
        {{ item.descripcion }}
        </option>
      </select>
    </div>
    <div class="col-lg-3">
      <label>Responsable</label>
      <select class="form-control" formControlName="usuarioInternoResponsable" (change)="filtrar()"
              [(ngModel)]="mf.usuarioInternoResponsable">
        <option value="">--Seleccionar--</option>
        <option value="S">Con Responsable</option>
        <option value="N">Sin Responsable</option>
      </select>
    </div>    
      <!--div class="col-lg-4">
        <label>Carrera</label>
        <select id="" name="" class="form-control">
          <option value="">--Seleccionar--</option>
          <option *ngFor="let item of NombreActividad | async"
          value="{{ item.actividadId }}" >
          {{ item.cve_carrera }}</option>
      </select>
      </div-->
  </div>
  </form>

  <!--Listado-->
  <div class="row justify-content-between py-2">
    <div class="col-md-4 col-sm-12">
        <h5 class="title-subsection">Datos de la solicitud</h5>
    </div>
    <div class="col-md-4 col-sm-12 text-right">
        <button class="btn btn-primary btn_sac" (click)="nuevo(contenido)">Agregar</button>
    </div>
  </div>

  <!-- <div>
    <input type="text" [(ngModel)]="searchText" (input)="onSearchChange()" placeholder="Buscar por actividad..." />
  </div> -->

  <table class="table table-striped text-center">
    <thead>
      <tr>
        <th scope="col" class="encabezado orden" width="5%">#</th>
        <th scope="col" class="encabezado orden" width="15%">Fechas</th>       
        <!--th scope="col" class="encabezado orden" width="10%">Fecha Solicitud</th>
        <th scope="col" class="encabezado orden" width="10%">Fecha Programada</th-->
        <th scope="col" class="encabezado orden" width="30%">Actividad</th>
        <!--th scope="col" class="encabezado orden">Tema</th-->
        <th scope="col" class="encabezado orden" width="20%">Colegio</th>
        <th scope="col" class="encabezado orden" width="10%">Responsable</th>
        <!--th scope="col" class="encabezado orden">Estado</th-->
        <th scope="col" class="encabezado orden"width="20%">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of Solicitudes; let i = index">
        <!-- <tr *ngFor="let item of paginatedItems; let i = index"> -->
        <td scope="row">{{ i+1 }}</td>
          <!-- <td scope="row">{{ (currentPage - 1) * itemsPerPage + i + 1 }}</td>  -->
        <td>
          <fa-icon [icon]="['fas','paper-plane']" ngbTooltip="Solicitada" class="text-dark"></fa-icon>: {{ item.fecha_registro | date:'yyyy-MM-dd' }}<br/>
          <fa-icon [icon]="['fas','chalkboard-teacher']" ngbTooltip="Programada" class="text-dark"></fa-icon>: {{ item.fecha_actividad | date:'yyyy-MM-dd' }}
        </td>
          <!--td>{{ item.fecha_registro | date:'yyyy-MM-dd' }}</td>
          <td>{{ item.fecha_actividad | date:'yyyy-MM-dd' }}</td-->
        <td>{{ item.nombreAPortalActividad }}<br/>
          {{ (item.descripcionTema)?"[ "+ item.descripcionTema +"]":"" }}
        </td>
        <td>{{ item.nombreColegio }}</td> 
        <td>{{ item.usuarioInternoResponsable }}</td>        
        <!--td>{{ item.descripcionTema }}</td-->
        <!--td>{{ item.descripcionEstatus }}</td-->
        <td>
          <button class="btn icon-btn" ngbTooltip="Nueva"  *ngIf="item.estatus_actividadId == 1">
            <fa-icon [icon]="['fas','paper-plane']" class="text-primary"></fa-icon>
          </button>
          <button class="btn icon-btn" ngbTooltip="Confirmada"  *ngIf="item.estatus_actividadId == 2">
            <fa-icon [icon]="['fas','chalkboard-teacher']" class="text-dark"></fa-icon>
          </button>
          <button class="btn icon-btn" ngbTooltip="Cancelada"  *ngIf="item.estatus_actividadId == 3">
            <fa-icon [icon]="['fas','times-circle']" class="text-danger"></fa-icon>
          </button>
          <button class="btn icon-btn" ngbTooltip="Reprogramada"  *ngIf="item.estatus_actividadId == 4">
            <fa-icon [icon]="['fas','clock']" class="text-secondary"></fa-icon>
          </button>                           
          <button class="btn icon-btn" ngbTooltip="Realizada"  *ngIf="item.estatus_actividadId == 6">
            <fa-icon [icon]="['fas','check-circle']" class="text-success"></fa-icon>
          </button>          
          <button class="btn icon-btn" (click)="editar(contenido, item)" ngbTooltip="Editar">
            <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
          </button>
          <button class="btn icon-btn" (click)="borrar(item)" ngbTooltip="Borrar" *ngIf="bBorrar">
            <fa-icon [icon]="['fas','trash']" class="text-danger"></fa-icon>
          </button>
        </td>
      </tr>
    </tbody>
    <!--tfoot class="tfoot-ibero text-center">
      <tr>
        <td colspan="7">{{ (Solicitudes)?.length }} Registros</td>
      </tr>
    </tfoot-->
  </table>

  <!-- <div class="pagination">
    <button (click)="changePage(currentPage - 1)" [disabled]="currentPage === 1">Anterior</button>
    <button (click)="changePage(currentPage + 1)" [disabled]="currentPage === totalPages">Siguiente</button>
    <span>Página {{ currentPage }} de {{ totalPages }}</span>
  </div> -->
</div>


  <!--Formulario-->
  <ng-template #contenido let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <div class="card border-white mb-0">
        <div class="card-header text-white bg-dark">DETALLE DE LA ACTIVIDAD</div>
            <div class="card-body text-dark pef6f">

                    <form [formGroup]="forma">
                      <input class="form-control" type="hidden" formControlName="solicitud_actividadId"
                      [(ngModel)]="model.solicitud_actividadId">
                      <input class="form-control" type="hidden" formControlName="solicitud_servicioId"
                      [(ngModel)]="model.solicitud_servicioId">
                      <input class="form-control" type="hidden" formControlName="colegioId"
                      [(ngModel)]="model.colegioId">
                      <input class="form-control" type="hidden" formControlName="microGraphCalendarId" [(ngModel)]="model.microGraphCalendarId">
                      <input class="form-control" type="hidden" formControlName="microGraphEventId" [(ngModel)]="model.microGraphEventId">
                      <div class="row text-dark">
                        <div class="col-lg-6">
                          <h5 class="title-subsection">{{aTitulo}} de Solicitud</h5>
                        </div>
                      </div>
                      <div class="row text-dark">
                        <div class="col-lg-6">
                          <label for="actividadId">Actividad</label>
                          <select class="form-control" formControlName="actividadId" 
                                  [attr.disabled]="bEditar? '':null"
                                  [(ngModel)]="model.actividadId" (change)="selCategoria()"
                                  >
                            <option [ngValue]="">--Seleccionar--</option>
                            <option *ngFor="let item of NombreActividad"
                                    [ngValue]="item.actividadId">
                              {{ item.nombreaportal }}
                            </option>
                          </select>
                        </div>
                        <div class="col-lg-3" *ngIf="bTemas">
                          <label>Categoría</label>
                          <select class="form-control"
                          formControlName="tema_categoriaId" [(ngModel)]="model.tema_categoriaId" (change)="selSubCategoria()">
                            <option [ngValue]="0">--Seleccionar--</option>
                            <option *ngFor="let item of CategoriaLista"
                            [ngValue]="item.tema_categoriaId" >
                            {{ item.categoriaDescripcion }}
                        </option>
                          </select>
                        </div>
                        <div class="col-lg-3" *ngIf="bTemas">
                          <label>Sub Categoría</label>
                          <select class="form-control"
                          formControlName="tema_subcategoriaId" [(ngModel)]="model.tema_subcategoriaId" (change)="filtra()">
                            <option [ngValue]="0">--Seleccionar--</option>
                            <option *ngFor="let item of SubCategoriaLista"
                            [ngValue]="item.tema_subcategoriaId" >
                              {{ item.subCategoriaDescripcion }}
                              </option>
                          </select>
                        </div>                       

                        <div class="col-lg-12" *ngIf="bTemas">
                          <label for="temaId">Tema</label>
                          <select class="form-control"
                                  formControlName="temaId"
                                  [(ngModel)]="model.temaId">
                            <option [ngValue]="0">--Seleccionar--</option>
                            <option *ngFor="let item of TemasFiltrados"
                                    [ngValue]="item.temaId">
                              {{ item.descripcion }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="row text-dark">
                        <div class="col-lg-6">
                          <h5 class="title-subsection">Datos de la Actividad</h5>
                        </div>
                      </div>
                      <div class="row text-dark">
                        <div class="form-group col-lg-4" *ngIf="visible(item,1)">
                          <label for="num_alumnos_actividad">No. Participantes</label>
                          <input class="form-control" type="number" formControlName="num_alumnos_actividad"
                                 [(ngModel)]="model.num_alumnos_actividad">
                        </div>


                        <div class="form-group col-lg-4" *ngIf="visible(item,3)">
                          <label for="otro_tema">Otro Tema</label>
                          <input class="form-control" type="text" formControlName="otro_tema"
                                 [(ngModel)]="model.otro_tema">
                        </div>

                        <div class="form-group col-lg-4" *ngIf="true">
                          <label for="enlace_plataforma">Plataforma o liga de acceso (en su caso)</label>
                          <input class="form-control" type="text" formControlName="enlace_plataforma"
                                 [(ngModel)]="model.enlace_plataforma">
                        </div>  

                        <div class="form-group col-lg-4" *ngIf="visible(item,4)">
                          <label for="gradoId">Grado</label>
                          <select class="form-control"
                                  formControlName="gradoId"
                                  [(ngModel)]="model.gradoId">
                            <option value="">--Seleccionar--</option>
                            <option *ngFor="let item of Grados"
                                    [ngValue]="item.gradoId">
                              {{ item.descripcion }}
                            </option>
                          </select>
                        </div>

                        <div class="form-group col-lg-4" *ngIf="visible(item,5)">
                          <label for="ind_computadora">Cuenta con Computadora</label>
                          <select class="form-control"
                                  formControlName="ind_computadora"
                                  [(ngModel)]="model.ind_computadora">
                            <option value="">--Seleccionar--</option>
                            <option value="S">SI</option>
                            <option value="N">NO</option>
                          </select>
                        </div>
                        <div class="form-group col-lg-4" *ngIf="visible(item,6)">
                          <label for="ind_internet">Cuenta con servicios de internet</label>
                          <select class="form-control"
                                  formControlName="ind_internet"
                                  [(ngModel)]="model.ind_internet">
                            <option value="">--Seleccionar--</option>
                            <option value="S">SI</option>
                            <option value="N">NO</option>
                          </select>
                        </div>
                        <div class="form-group col-lg-4" *ngIf="visible(item,7)">
                          <label for="ind_estacionamiento">Cuenta con estacionamiento</label>
                          <select class="form-control"
                                  formControlName="ind_estacionamiento"
                                  [(ngModel)]="model.ind_estacionamiento">
                            <option value="">--Seleccionar--</option>
                            <option value="S">SI</option>
                            <option value="N">NO</option>
                          </select>
                        </div>
                      </div>



                      <div class="row text-dark">
                        <div class="col-lg-12">
                          <h5 class="title-subsection">Datos del solicitante</h5>
                        </div>
                      </div>

                      <div class="row">
                        <input class="form-control" type="hidden" formControlName="usuario_tipoId"
                        [(ngModel)]="model.usuario_tipoId">
                        <input class="form-control" type="hidden" formControlName="perfilId"
                        [(ngModel)]="model.perfilId">                                                
                        <!--
                        <div class="col-lg-3">
                          <label>Tipo de usuario</label>
                          <select class="form-control"
                          formControlName="usuario_tipoId" [(ngModel)]="model.usuario_tipoId" (change)="fPerfil()">
                            <option value="0">--Seleccionar--</option>
                            <option *ngFor="let item of TipoUsuarioLista"
                              value="{{ item.usuario_tipoId }}" >
                              {{ item.descripcion }}
                              </option>
                          </select>
                        </div>
                        <div class="col-lg-3">
                          <label>Perfil</label>
                          <select class="form-control"
                          formControlName="perfilId" [(ngModel)]="model.perfilId" (change)="fRol()">
                            <option value="0">--Seleccionar--</option>
                            <option *ngFor="let item of PerfilLista"
                              value="{{ item.perfilId }}" >
                              {{ item.descripcion }}
                              </option>
                          </select>
                        </div>
                        -->                  
                        <div class="col-lg-3">
                          <label>Rol</label>
                          <select class="form-control"
                          formControlName="rolId" [(ngModel)]="model.rolId" (change)="fUsuario()">
                            <option [ngValue]="0">--Seleccionar--</option>
                            <option *ngFor="let item of RolLista"
                              [ngValue]="item.rolId" >
                              {{ item.descripcion }}
                              </option>
                          </select>
                        </div>
                  
                        <div class="col-lg-9">
                          <label>Colegio - Usuario</label>
                          <select class="form-control"
                          formControlName="usuario_solId" [(ngModel)]="model.usuario_solId" (change)="setDatosUsuario(1)">
                            <option [ngValue]="0">--Seleccionar--</option>
                            <option *ngFor="let item of UsuarioLista"
                            [ngValue]="item.usuarioId" > 
                              {{ item.colegioNombre }} - {{ item.nombreCompleto }}
                              </option>
                          </select>
                        </div>
                  
                      </div>

                      <div class="row text-dark">
                        <div class="form-group col-lg-3">
                          <label for="nombreUsuario">Nombre</label>
                          <input class="form-control" type="text" formControlName="nombreUsuario" [attr.disabled]="true"
                                 [(ngModel)]="model.nombreUsuario">
                        </div>
                        <div class="form-group col-lg-3">
                          <label for="apaternoUsuario">Apellido Paterno</label>
                          <input class="form-control" type="text" formControlName="apaternoUsuario" [attr.disabled]="true"
                                 [(ngModel)]="model.apaternoUsuario">
                        </div>
                        <div class="form-group col-lg-3">
                          <label for="amaternoUsuario">Apellido Materno</label>
                          <input class="form-control" type="text" formControlName="amaternoUsuario" [attr.disabled]="true"
                                 [(ngModel)]="model.amaternoUsuario">
                        </div>
                        <div class="form-group col-lg-3">
                          <label for="correoSolicita">Correo electrónico</label>
                          <input class="form-control" type="text" formControlName="correoSolicita" [attr.disabled]="true"
                                 [(ngModel)]="model.correoSolicita">
                        </div>
                        <div class="form-group col-lg-3">
                          <label for="telefonoSolicita">Teléfono</label>
                          <input class="form-control" type="text" formControlName="telefonoSolicita" [attr.disabled]="true"
                                 [(ngModel)]="model.telefonoSolicita">
                        </div>
                      </div>

                      <div class="row text-dark">
                        <div class="col-lg-12">
                          <h5 class="title-subsection">Datos del responsable</h5>
                        </div>
                      </div>

                      <div class="row text-dark">
                        <div class="col-lg-12">
                          <label>Colegio - Responsable</label>
                          <select class="form-control"
                          formControlName="colegio_responsableId" [(ngModel)]="model.colegio_responsableId" (change)="setDatosResponsable()">
                            <option [ngValue]="0">--Seleccionar--</option>
                            <option *ngFor="let item of ResponsableLista"
                            [ngValue]="item.colegio_responsableId" > 
                              {{ item.nombreColegio }} - {{ item.nombre }} {{ item.apaterno }} {{ item.amaterno }}
                              </option>
                          </select>
                        </div>
                      </div>

                      <div class="row text-dark">
                        <div class="form-group col-lg-3" *ngIf="visible(item,12)">
                          <label for="nombreColegioResponsable">Nombre</label>
                          <input class="form-control" type="text" formControlName="nombreColegioResponsable" [attr.disabled]="true"
                                 [(ngModel)]="model.nombreColegioResponsable">
                        </div>
                        <div class="form-group col-lg-3" *ngIf="visible(item,14)">
                          <label for="apaternoColegioResponsable">Apellido Paterno</label>
                          <input class="form-control" type="text" formControlName="apaternoColegioResponsable" [attr.disabled]="true"
                                 [(ngModel)]="model.apaternoColegioResponsable">
                        </div>
                        <div class="form-group col-lg-3" *ngIf="visible(item,13)">
                          <label for="amaternoColegioResponsable">Apellido Materno</label>
                          <input class="form-control" type="text" formControlName="amaternoColegioResponsable" [attr.disabled]="true"
                                 [(ngModel)]="model.amaternoColegioResponsable">
                        </div>
                        <div class="form-group col-lg-3" *ngIf="visible(item,15)">
                          <label for="correoResponsable">Correo electrónico</label>
                          <input class="form-control" type="text" formControlName="correoResponsable" [attr.disabled]="true"
                                 [(ngModel)]="model.correoResponsable">
                        </div>
                        <div class="form-group col-lg-3" *ngIf="visible(item,16)">
                          <label for="telefonoResponsable">Teléfono</label>
                          <input class="form-control" type="text" formControlName="telefonoResponsable" [attr.disabled]="true"
                                 [(ngModel)]="model.telefonoResponsable">
                        </div>
                      </div>


                      <div class="row text-dark">
                          <div class="col-lg-12">
                            <h5 class="title-subsection">Datos Internos</h5>
                          </div>
                      </div>
                      <div class="row text-dark">
                        <div class="form-group col-lg-4" *ngIf="visible(item,27)">
                          <label for="transporteId">Transporte</label>
                          <select class="form-control"
                                  formControlName="transporteId"
                                  [(ngModel)]="model.transporteId">
                            <option value="">--Seleccionar--</option>
                            <option *ngFor="let item of Transporte"
                                    [ngValue]="item.transporteId">
                              {{ item.descripcion }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-lg-4" *ngIf="visible(item,29)">
                          <label for="hora_sal_colegio">Hora de Salida Colegio</label>
                          <input class="form-control" type="time" value="08:00:00" formControlName="hora_sal_colegio"
                                 [(ngModel)]="model.hora_sal_colegio">
                        </div>
                        <div class="form-group col-lg-4" *ngIf="visible(item,30)">
                          <label for="hora_sal_ibero">Hora de Salida Ibero</label>
                          <input class="form-control" type="time" value="08:00:00" formControlName="hora_sal_ibero"
                                 [(ngModel)]="model.hora_sal_ibero">
                        </div>                                              
                        <div class="form-group col-lg-4" *ngIf="visible(item,20)">
                          <label for="notas">Notas Actividad</label><br/>
                          <textarea id="notas" class="form-control" formControlName="notas" rows="2"
                          [(ngModel)]="model.notas"></textarea>
                        </div>
                        <div class="form-group col-lg-4" *ngIf="visible(item,28)">
                            <label for="notas_transporte">Notas Transporte</label><br/>
                            <textarea id="notas_transporte" class="form-control" formControlName="notas_transporte" rows="2"
                            [(ngModel)]="model.notas_transporte"></textarea>
                        </div>
                        <div class="form-group col-lg-4" *ngIf="visible(item,25)">
                          <label for="num_alumnos_material">No. Alumnos Material</label>
                          <input class="form-control" type="number" formControlName="num_alumnos_material"
                                 [(ngModel)]="model.num_alumnos_material">
                        </div>
                        <div class="form-group col-lg-4" *ngIf="visible(item,24)">
                          <label for="notas_material">Notas Material</label><br/>
                          <textarea id="notas_material" class="form-control" formControlName="notas_material" rows="2"
                          [(ngModel)]="model.notas_material"></textarea>
                        </div>
                        <div class="form-group col-lg-4" *ngIf="visible(item,21)">
                          <label for="notas_viaticos">Notas Viáticos</label><br/>
                          <textarea id="notas_viaticos" class="form-control" formControlName="notas_viaticos" rows="2"
                          [(ngModel)]="model.notas_viaticos"></textarea>
                        </div>
                      </div>
					            <div class="row text-dark">
                        <div class="col-lg-12">
                          <h5 class="title-subsection">Estatus de la actividad</h5>
                        </div>
                      </div>
                      <div class="row text-dark">
                        <div class="form-group col-lg-3" *ngIf="visible(item,10)">
                          <label for="fecha">Fecha Actividad</label><br/>
                          <div class="input-group">
                              <input class="form-control" placeholder="yyyy-mm-dd" [readonly]="true"
                                  id="fecha_actividad" [(ngModel)]="model.fecha_actividad" formControlName="fecha_actividad" ngbDatepicker #d="ngbDatepicker">
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                              </div>
                            </div>
                        </div>
                        <div class="form-group col-lg-3" *ngIf="visible(item,8)">
                          <label for="hora_ini_actividad">Hora de Inicio</label>
                          <input class="form-control" type="time" value="08:00:00" formControlName="hora_ini_actividad"
                                 [(ngModel)]="model.hora_ini_actividad">
                        </div>
                        <div class="form-group col-lg-3" *ngIf="visible(item,9)">
                          <label for="hora_fin_actividad">Hora de Fin</label>
                          <input class="form-control" type="time" value="08:00:00" formControlName="hora_fin_actividad"
                                 [(ngModel)]="model.hora_fin_actividad">
                        </div>
                        <div class="form-group col-lg-3" [ngClass]="bEditar ? 'show':'hide'">
                          <label for="estatus_actividadId">Estatus de Actividad</label>
                          <select class="form-control"
                                  formControlName="estatus_actividadId"
                                  [(ngModel)]="model.estatus_actividadId">
                            <option value="">--Seleccionar--</option>
                            <option *ngFor="let item of EstadoActividad"
                                    [ngValue]="item.estatus_actividadId">
                              {{ item.descripcion }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="row text-dark pt-3">
                        <div class="col-lg-12">
                          <div class="buttonSelect text-center">
                            <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                            <button class="btn btn-danger" (click)="guardar()">Guardar</button>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
              </div>

            </div>


          </ng-template>
