<!-- About Section-->
<section class="section" >
    <div class="container-fluid d-flex align-items-center flex-column pt-5">
        <div class="row sol-titulo ">
            <div class="col-md-12 text-center my-auto">
                <h1 class="pagina-seccion-titulo text-secondary mb-12">
                    Cursos
                </h1>
            </div>
        </div>
        <div class="row justify-content-center pt-5">
            <div class="col-md-10 text-danger text-left texto-cursos">
                Conscientes de la importancia de una buena formación, ponemos a tu disposición cursos diseñados para fortalecer las
                habilidades, conocimientos y competencias de tu comunidad educativa.
            </div>
          </div>

          <div class="row pt-5">
            <div class=" col-sm-12 col-md-12 col-lg-4 text-secondary  my-auto text-right link_cursos">
                <p><a class="text-danger" href="http://">CURSO DE HABILIDADES PARA EXAMEN DE ADMISIÓN</a></p>
                <p><a class="text-danger" href="http://">CURSOS Y ESTANCIAS DE VERANO</a></p>
                <p><a class="text-danger" href="http://">CURSOS VERANO PARA PROFESORES</a></p>
            </div>
            <div class="col-md-8 mb-0 align-items-center text-center pt-5">
                <img class="seccion-avatar-01 animate__animated animate__backInUp img_cursos" src="/assets/img/imagen_cursos02.svg" alt="" />
            </div>
        </div>

    </div>
</section>
