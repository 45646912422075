<section>
    <div class="container align-items-center flex-column">
        <div class="row text-center">
            <div class="col-md-4">
                &nbsp;
            </div>
            <div class="col-md-4">
                &nbsp;
                <h1 class="pagina-seccion-titulo titulo-seccion-2 text-dark">Actividades</h1>
            </div>
            <div class="col-md-4">
                &nbsp;
            </div>
        </div>

        <!-- Menu -->
        <div class="row"></div>
        <div class="row">


            <div class="col-md-4 text-center">
                <button [class.selected]="2==selectedUser" class="btn icon-btn" (click)="filtra(2);onSelect(2)" ngbTooltip="">
                    <img class="img-box menu-image animate__animated animate__backInLeft" src="/assets/img/icono_colegio02.svg" alt="" />
                </button>
                <p class="text-danger">Tu colegio</p>
            </div>

            <div class="col-md-4 text-center">
                <button #myDiv [class.selected]="1==selectedUser" class="btn icon-btn" (click)="filtra(1); onSelect(1)" ngbTooltip="">
                    <img class="img-box menu-image animate__animated animate__backInLeft" src="/assets/img/icono_ibero02.svg" alt="" />
                </button>
                <p class="text-danger">Ibero</p>
            </div>            
        <!--
            <div class="col-md-3 text-center">
                <button [class.selected]="3==selectedUser" class="btn icon-btn" (click)="filtra(3);onSelect(3)" ngbTooltip="">
                    <img class="img-box menu-image animate__animated animate__backInLeft" src="/assets/img/icono_orientadores02.svg" alt="" />
                </button>
                <p class="text-danger">Orientación vocacional</p>
            </div>
        -->
            <div class="col-md-4 text-center">
                <button [class.selected]="4==selectedUser" class="btn icon-btn" (click)="filtra(4);onSelect(4)" ngbTooltip="">
                    <img class="img-box menu-image animate__animated animate__backInLeft" src="/assets/img/icono_adistancia02.svg" alt="" />
                </button>
                <p class="text-danger">A distancia</p>
            </div>
        </div>


        <!-- Actividades -->
        <div class="row" *ngFor="let rowData of actRows">

            <div class="col-md-1 text-center">
                &nbsp;
            </div>

            <div class="col-md-2 text-center" *ngFor="let item of rowData">

                <button *ngIf="item.tieneTemas===1" class="btn icon-btn" [routerLink]="['/solicitud-form/', item.actividadId]" ngbTooltip="">
                    <img class="img-box p-3 item-image bg-white rounded animate__animated animate__backInDown" src="/assets/img/actividad/{{ item.actividadId }}.svg" alt="" />
                </button>

                <button *ngIf="item.tieneTemas===0" class="btn icon-btn" (click)="addToCart(contenido, item)" ngbTooltip="">
                    <img class="img-box p-3 item-image bg-white rounded animate__animated animate__backInDown" src="/assets/img/actividad/{{ item.actividadId }}.svg" alt="" />
                </button>

                <p class="text-danger item-text" >{{ item.nombre }}</p>
            </div>
            <div class="col-md-1 text-center">
                &nbsp;
            </div>

        </div>

    </div>
</section>

<!-- Carrito -->
<ng-template #contenido let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="card border-dark mb-0">
          <div class="card-header text-white text-center bg-dark">Solicitudes a reservar</div>
          <div class="card-body text-dark">

            <ng-container *ngFor="let item of items">
                <div class="row" *ngIf="item.temaId !== undefined">
                    <div class="col-md-10">
                        <!--p>{{ item.descripcion }}</p-->
                        <p> {{ item.nombreactividad }} [{{ item.descripcion }}]</p>
                    </div>
                    <div class="col-md-2">
                        <button class="btn" (click)="removeFromCart(item.actividadId, item.temaId)" ngbTooltip="Borrar ">
                        <fa-icon [icon]="['fas','trash-alt']" class="text-danger"></fa-icon>
                        </button>
                    </div>
                </div>

                <div class="row" *ngIf="item.nombre !== undefined">
                    <div class="col-md-10">
                        <p>{{ item.nombre }}</p>
                    </div>
                    <div class="col-md-2">
                        <button class="btn" (click)="removeFromCart(item.actividadId, 0)" ngbTooltip="Borrar ">
                        <fa-icon [icon]="['fas','trash-alt']" class="text-danger"></fa-icon>
                        </button>
                    </div>
                </div>

            </ng-container>
          </div>
        </div>
    </div>

    <div class="modal-footer text-center">
        <!--button class="btn btn-danger" (click)="modal.close()">Cerrar</button-->
        <button class="btn btn-danger" (click)="modal.close();gotoCart()">Reservar</button>&nbsp;
        <button class="btn btn-danger" (click)="emptyCart()">Limpiar Lista</button>
    </div>

   </ng-template>
