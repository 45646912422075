import { Component, OnInit } from '@angular/core';
import { CarritoService } from '../../../core/services/carrito.service';

@Component({
  selector: 'app-concursos',
  templateUrl: './concursos.component.html',
  styles: [
  ]
})
export class ConcursosComponent implements OnInit {
  arrBanners;
  constructor(
    private carritoService: CarritoService
  ) { }

  ngOnInit(): void {
    //this.arrBanners = this.carritoService.getBanners('concurso');
    //console.log(this.arrBanners);
  }

  cs(){
    this.arrBanners = this.carritoService.getBanners('concurso');
    return true;
  }    

}
