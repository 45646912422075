import { Component, OnInit, ViewEncapsulation } from '@angular/core';
//import { TemasListadoService } from '../../../core/services/temas-listado.service';
//import { TemaService } from '../../../core/services/tema.service';
import { ToastrService } from 'ngx-toastr';
import { CategoriaService } from '../../../core/services/categoria.service';
import { SubCategoriaService } from '../../../core/services/subCategoria.service';
import { TemaService } from '../../../core/services/tema.service';
import { CarritoService } from '../../../core/services/carrito.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsuarioService } from '../../../core/services/usuario.service';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import {Router} from '@angular/router';

@Component({
  selector: 'app-solicitud-form',
  templateUrl: './solicitud-form.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
  ],
  providers: [ ]
})
export class SolicitudFormComponent implements OnInit {
  Temas;
  TemasFiltradosTemp;
  TemasFiltrados;
  //idTipoActividad;
  existe;


  selectedUser = 0;
  isSelected = false;
  onSelect(index): void {
    this.selectedUser = index;
  }

  objTema;
  vIdTema;
  vCategoria='';
  vSubCategoria;
  vTema;
  vObjetivo;
  vIdioma;
  vCosto;
  TemaTipo;
  model;
  forma: FormGroup;
  User;
  act;
  items;

  constructor(
    private categoriaService:CategoriaService,
    private subCategoriaService:SubCategoriaService,
    private temaService:TemaService,
    private carritoService: CarritoService,
    private usuarioService: UsuarioService,
    public fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public modal: NgbModal,
    private spinner: NgxSpinnerService
  ) {
    this.iniciarForm();
  }

  actividadId;
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.actividadId = params['idta'];
      this.loadTemas(this.actividadId);
    });
    this.items = this.carritoService.obtenerActividades();
  }

  iniciarForm() {
    this.forma = this.fb.group({
      tema_categoriaId: ['0', [Validators.required]],
      tema_subcategoriaId: ['0', [Validators.required]],
      tema_tipoId: ['0', [Validators.required]],
      tema_tipo_publicoId: ['0', [Validators.required]],
      actividadId: [0]
    });
    this.model = this.forma;
  }

TemaLista;
Actividad;
ActividadLista;
Categoria;
CategoriaLista;
SubCategoria;
SubCategoriaLista;
TipoPublico;
TipoPublicoLista;
  loadTemas(actividadId){
      this.spinner.show();
      this.User = this.usuarioService.getUser();
      this.temaService.getTemasUsuario(this.User.usuarioId).toPromise()
      .then(data => {
        this.Temas = data;
        //console.log(this.Temas);
        
        this.TipoPublico = this.getTipoPublico();
        //console.log(this.TipoPublico);
        this.Actividad = this.getActividades();

        //Buscamos la actividad seleccionada para obtener el tipo de público
        let act = this.Actividad.find(x => x.actividadId === Number(actividadId));
        //console.log(act);
        this.selTipoPublico(act.tema_tipo_publicoId);
        this.onSelect(act.tema_tipo_publicoId-1)

        //Solicitud del usuario de mapear estos 2 tipos de actividades con el tipo de publico
        /*
        if(Number(idTipoActividad)==13){this.selTipoPublico(3);this.onSelect(3)}
        if(Number(idTipoActividad)==19){this.selTipoPublico(2);this.onSelect(1)}
        */
        this.model.actividadId = Number(actividadId);
        this.selCategoria();
      })
    
  }

  getTipoPublico(){
    return Array.from(new Set(this.Temas
      .map((item: any) => item.tema_tipo_publicoId)))
      .map( tema_tipo_publicoId=> { return this.Temas.find(obj => obj.tema_tipo_publicoId === tema_tipo_publicoId) } )
  }

  getActividades(){
    return Array.from(new Set(this.Temas
      .map((item: any) => item.actividadId)))
      .map( actividadId=> { return this.Temas.find(obj => obj.actividadId === actividadId) } )
  }

  selCategoria(){
    this.model.tema_categoriaId=0;
    this.model.tema_subcategoriaId=0;
    this.filtra();
    this.CategoriaLista = Array.from(new Set(this.TemasFiltrados
      .map((item: any) => item.tema_categoriaId)))
      .map( tema_categoriaId=> { return this.TemasFiltrados.find(obj => obj.tema_categoriaId === tema_categoriaId) } )

  }

  selSubCategoria(){
    this.model.tema_subcategoriaId=0;
    this.filtra();
    this.SubCategoriaLista = Array.from(new Set(this.TemasFiltrados
      .map((item: any) => item.tema_subcategoriaId)))
      .map( tema_subcategoriaId=> { return this.TemasFiltrados.find(obj => obj.tema_subcategoriaId === tema_subcategoriaId) } )
  }
  ig=0;
  selTipoPublico(tema_tipo_publicoId){
    //Indica que va a resetear el combo de actividades al cambiar el tipo de publico
    this.ig=0;
    this.model.tema_tipo_publicoId = tema_tipo_publicoId;
    //this.ActividadLista = this.Actividad.filter(x => x.tema_tipo_publicoId === Number(this.model.tema_tipo_publicoId));
    this.model.actividadId=0;
    this.model.tema_categoriaId=0;
    this.model.tema_subcategoriaId=0;
    this.filtra();
  }

  mostrarTema(item) {
    this.vIdTema=item.temaId;
    this.vCategoria=item.categoriaDescripcion;
    this.vSubCategoria=item.subCategoriaDescripcion;
    this.vTema=item.descripcion;
    this.vObjetivo=item.objetivo;
    this.vIdioma=item.descripcionIdioma;
  }

  filtra(){
    this.spinner.show();
    this.TemasFiltradosTemp = this.Temas;

    if(this.model.tema_tipo_publicoId!=0 && this.model.tema_tipo_publicoId!==undefined){
      this.TemasFiltradosTemp = this.TemasFiltradosTemp
      .filter(x => x.tema_tipo_publicoId === Number(this.model.tema_tipo_publicoId));
    }

    if(this.model.actividadId!=0 && this.model.actividadId!==undefined){
      this.TemasFiltradosTemp = this.TemasFiltradosTemp
      .filter(x => x.actividadId === Number(this.model.actividadId));
    }    


    //Para la sucategoría y subcategoría, las actividades pueden tener valor nulo
    if (this.model.tema_categoriaId != 0 && this.model.tema_categoriaId !== undefined
      && this.model.tema_categoriaId !== null) {
      this.TemasFiltradosTemp = this.TemasFiltradosTemp
        .filter(x => x.tema_categoriaId === Number(this.model.tema_categoriaId));
    }

    if (this.model.tema_subcategoriaId != 0 && this.model.tema_subcategoriaId !== undefined
      && this.model.tema_subcategoriaId !== null) {
      this.TemasFiltradosTemp = this.TemasFiltradosTemp
        .filter(x => x.tema_subcategoriaId === Number(this.model.tema_subcategoriaId));
    }

    this.TemasFiltrados = this.TemasFiltradosTemp;
    //console.log(this.TemasFiltradosTemp);
    this.filtraTipoActividad();
    this.spinner.hide();
  }

  ActividadesAgrupadas;
  filtraTipoActividad(){
    this.ActividadesAgrupadas = this.TemasFiltrados.reduce((r,{nombreactividad})=>{
      if(!r.some(o=>o.nombreactividad==nombreactividad)){
        r.push({nombreactividad,groupItem:this.TemasFiltrados.filter(v=>v.nombreactividad==nombreactividad)});
    }
    return r;
    },[]);

    //console.log(this.ActividadesAgrupadas);

    //Aplica solo si es la primera vez que arma el combo de actividades
    if(this.ig==0){
      let result = this.ActividadesAgrupadas.map(a => a.nombreactividad);
      this.ActividadLista = this.Actividad.filter(x => result.includes(x.nombreactividad));      
      this.ig=1;
    }
  }

  addToCart(contenido, temaId ) {
    //Accedemos los valores para validación
    this.items = this.carritoService.obtenerActividades();
    this.existe = this.items.find(x => x.temaId === Number(temaId));
    if(this.existe!=undefined){
      this.toastr.success('Esta actividad fue agregada previamente a tu lista.');
      return false;
    }
    this.objTema = this.Temas.find(x => x.temaId === Number(temaId));
    this.carritoService.agregarActividad(this.objTema);
    this.toastr.success('La actividad ha sido agregada a tu lista.');
    //console.log(this.objTema);
    this.items = this.carritoService.obtenerActividades();
    this.modal.open(contenido);
  }

  removeFromCart(actividadId, temaId) {
    this.items = this.carritoService.quitarActividades(actividadId, temaId);
    this.toastr.warning('La actividad ha sido eliminada de tu lista.');
  }

  emptyCart() {
    this.items = this.carritoService.vaciarActividades();
    this.toastr.error('Tu lista ahora está vacía.');
  }

  gotoCart(){
    //this.modal.dismissAll();
    this.router.navigate(['./carrito']);
  }

}
