import { Component, OnInit, Input } from '@angular/core';
import { TransportService } from '../../../core/services/transport.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { UsuarioService } from '../../../core/services/usuario.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-transporte',
  templateUrl: './transporte.component.html',
  styles: [
  ]
})
export class TransporteComponent implements OnInit {
  tipoTransporte;
  tipoTransportes;
  forma: FormGroup;
  model;
 constructor(
    public fb: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,
    private transportservice:TransportService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) {
    if(!this.usuarioService.int()) this.router.navigate(['/portal']);
   this.iniciarForm();
  }

  ngOnInit(): void {
    //this.tipoTransporte = this.transportservice.getData();
    this.addTransporte();
  }

  iniciarForm() {
    this.forma = this.fb.group({
      descripcion: ['', [Validators.required]],
      transporteId: [0],
      activo: ['S', [Validators.required]]
    })
    this.model = this.forma;
  }

  addTransporte(){
    //this.Grados = this.gradoservice.getData();
    this.tipoTransporte = this.transportservice.getData();
    this.spinner.show();
    this.transportservice.getData().toPromise()
    .then(data => {
      this.spinner.hide();
      this.tipoTransporte = data;
      //Asignamos el universo de items al listado, mediante una entidad de filtro
      this.tipoTransportes = this.tipoTransporte;
    })
  }

  nuevo(content) {
    this.iniciarForm();
    this.model.activo = 'S';
    this.modal.open(content,{size:'xl'});
  }

  editar(content, modelo) {
    this.model = modelo;
    this.modal.open(content,{size:'xl'});
  }

  guardar() {
    if(this.forma.controls["transporteId"].value == undefined) {
      this.transportservice.addData(this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {console.log(error);});
    }
    else {
      this.transportservice.updData(this.forma.controls["transporteId"].value, this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {console.log(error);});
    }
  }

  recargar(){
    this.modal.dismissAll();
    this.addTransporte();
    //this.tipoTransporte = this.transportservice.getData();
  }

}
