import { Injectable } from '@angular/core';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class GraphService {
  async updateEventToCalendar(event: MicrosoftGraph.Event, microGraphEventId: string) {
    if (!this.authService.graphClient) {
      return undefined;
    }

    await this.authService.graphClient.api('/me/events/' + microGraphEventId)
      .update(event);
  }
  async deleteEvent(activity: any): Promise<any | undefined> {
    if (!this.authService.graphClient) {
      return undefined;
    }

    let eventId = activity.microGraphEventId;

    await this.authService.graphClient
      .api('/me/events/' + eventId)
      .delete();

  }

  constructor(
    private authService: AuthService) { }

  async getCalendarView(start: string, end: string, timeZone: string): Promise<MicrosoftGraph.Event[] | undefined> {
    if (!this.authService.graphClient) {
      return undefined;
    }

    try {
      const result = await this.authService.graphClient
        .api('/me/calendarview')
        .header('Prefer', `outlook.timezone="${timeZone}"`)
        .query({
          top: 999,
          startDateTime: start,
          endDateTime: end
        })
        .select('id,subject,organizer,start,end,isCancelled,attendees')
        //.select(['id','subject','organizer','start', 'end', 'subject','isCancelled','attendees'])
        //.select(['categories', 'start', 'end', 'subject'])
        .orderby('start/dateTime')
        .get();

      return result.value;
    } catch (error) {

      return undefined;

    }

  }

  async addEventToCalendar(newEvent: MicrosoftGraph.Event, microsoftGraphCalendarId: string): Promise<any> {
    if (!this.authService.graphClient) {
      return undefined;
    }

    try {
      // POST /me/events
      let response = await this.authService.graphClient
        .api('/me/calendars/' + microsoftGraphCalendarId + '/events')
        .post(newEvent);

      return response;

    } catch (error) {
      throw Error(JSON.stringify(error, null, 2));
    }
  }

  async getAllCalendarsUserCanAccess(): Promise<MicrosoftGraph.Calendar[] | undefined> {
    if (!this.authService.graphClient) {
      console.log("Not initialized graph client.")
      return undefined;
    }

    try {

      const result = await this.authService.graphClient
        .api('/me/calendars')
        .get();

      return result.value;
    } catch (error) {
      throw console.log(JSON.stringify(error, null, 2));
    }
  }

}