import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from '../../../core/services/usuario.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-responsable-colegio',
  templateUrl: './responsable-colegio.component.html',
  styles: [
  ]
})
export class ResponsableColegioComponent implements OnInit {

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal    
  ) {
    if(!this.usuarioService.int()) this.router.navigate(['/portal']);
    this.iniciarForm();
   }

  uNombreCompleto;
  usuarioId;
  colegioIdListado;
  ngOnInit(): void {
    let userCol = JSON.parse(localStorage.getItem('userCol') || '{}') || [];
    console.log(userCol);
    this.uNombreCompleto = userCol.nombreCompleto;
    this.usuarioId = userCol.usuarioId;
    this.colegioIdListado = userCol.colegioIdListado;
    if(this.colegioIdListado!=""){
      this.colegioIdListado = this.colegioIdListado.replace(" ", "");
    }
    this.addColegio();
    this.sort();
  }

  forma;
  model;
  filtros;
  iniciarForm() {
    this.forma = this.fb.group({
      usuarioId: 0,
      nombre: [''],
      colegioId: 0,
      apaterno: [''],
      amaterno: [''],
      correo: [''],
      telefono: [''],
      extension: [''],
      activo:'S',
      colegio:''
    });  
    this.model = this.forma;

    this.filtros = this.fb.group({colegioId: 0});
  }

  Colegio;
  ColegioLista;
  ColegioUsuarioLista;
  ColegioControlLista;
  addColegio(){
    this.spinner.show();
    this.usuarioService.getColegioListado().toPromise()
    .then(data => {
      this.Colegio = data;
      this.ColegioLista = this.Colegio;
      //this.ColegioControlLista = this.ColegioLista.map(({colegioId, nombre}) => ({colegioId,nombre}));
      //this.ColegioUsuarioLista = this.ColegioLista.find(x=> x.colegioId == this.colegioId);
      //.filter(x => i.idRolListado.split(',').includes(x.idRol.toString()))
      this.ColegioUsuarioLista = this.ColegioLista.filter(x => this.colegioIdListado.split(',').includes(x.colegioId.toString()));
      //console.log(this.ColegioUsuarioLista);
      //this.spinner.hide();
      this.addColegioSinAsignar();
    })
  }

  addColegioSinAsignar(){
    //this.spinner.show();
    this.usuarioService.getColegioSinUsAtiende().toPromise()
    .then(data => {
      this.ColegioControlLista = data;
      //this.ColegioLista = this.Colegio;
      this.ColegioControlLista = this.ColegioControlLista.map(({colegioId, nombre}) => ({colegioId,nombre}));
      //this.ColegioUsuarioLista = this.ColegioLista.find(x=> x.colegioId == this.colegioId);
      //console.log(this.ColegioControlLista);
      this.spinner.hide();
    })
  }  

  oValue;
  agregarColegio(){
    //return false;
    if(this.colegioId==undefined){
      this.toastr.warning('Debe seleccionar un colegio');
      return false;
    } 
    var colegioId = this.colegioId.colegioId;
    console.log(colegioId);    
    var oValue = {colegioId:0,nombre:'', usuarioId:0, activo:'S'};
    oValue.colegioId = colegioId;
    oValue.usuarioId = this.usuarioId;
    console.log(oValue);
    this.usuarioService.updColegioAsignaUsuario(oValue).subscribe(
      (res) => {
        this.colegioIdListado += ','+colegioId;
        console.log(this.colegioIdListado);
        this.ColegioUsuarioLista = this.ColegioLista.filter(x => this.colegioIdListado.split(',').includes(x.colegioId.toString()));
        this.colegioId = undefined;
        this.toastr.success('Los datos han sido actualizados.');
        this.recargar();
      },
      (error) => {console.log(error);
        this.toastr.error(error);
        this.modal.dismissAll();
      });    

  }

  quitarColegio(item){
    var oValue = {colegioId:0,nombre:'', usuarioId:0};
    oValue.colegioId = item.colegioId;
    oValue.usuarioId = this.usuarioId;
    this.usuarioService.updColegioDesAsignaUsuario(oValue).subscribe(
      (res) => {
        var arrList = this.colegioIdListado.split(",");
        var index = arrList.indexOf(item.colegioId.toString());
        if (index !== -1) {
          arrList.splice(index, 1);
        }   
        this.colegioIdListado = arrList.join(",");
        this.ColegioUsuarioLista = this.ColegioLista.filter(x => this.colegioIdListado.split(',').includes(x.colegioId.toString()));
        this.colegioId = undefined;
        this.toastr.success('Los datos han sido actualizados.');
        this.recargar();
      },
      (error) => {console.log(error);
        this.toastr.error(error);
        this.modal.dismissAll();
      });     
  }
  
  recargar() {
    this.modal.dismissAll();
    this.addColegioSinAsignar();
  }

  colegioId;
  /* Búsqueda intuitiva*/
  @ViewChild('college') college: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  selectedItem(item) {
    this.colegioId = item.item.colegioId;
  }

  public modelo: any;

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus$),
      merge(this.click$.pipe(filter(() => this.college && !this.college.isPopupOpen()))),
      map(term => (term === '' ? this.ColegioControlLista
        : this.ColegioControlLista.filter(v => v.nombre.toLowerCase().indexOf(term.toLowerCase()) > -1))
          .slice(0, this.ColegioControlLista.length))
    );

  formatter = (x: { nombre: string }) => x.nombre;  
  
  asc;
  sort(){
    const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;

    const comparer = (idx, asc) => (a, b) => ((v1, v2) =>
        v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
        )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

    // do the work...
    document.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
      const table = th.closest('table');
      const tbody = table.querySelector('tbody');
      Array.from(tbody.querySelectorAll('tr'))
        .sort(
          comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc)
          )
        .forEach(tr => tbody.appendChild(tr) );
    })));

  }  
  

}
