import { Component, OnInit } from '@angular/core';
//import { CategoriaService } from '../../../core/services/categoria.service';
import { UsuarioService } from '../../../core/services/usuario.service';
import { SolicitudService } from '../../../core/services/solicitud.service';
import { ActividadesService } from '../../../core/services/actividades.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-solicitud-campo',
  templateUrl: './solicitud-campo.component.html',
  styles: [
  ]
})
export class SolicitudCampoComponent implements OnInit {
  solicitudCampos;
  RolListado
  TipoUsuario;
  Perfil;
  Rol;

  Actividad;
  Actividades;
  Filtro;
  ActividadGrupo
  ActividadSitio;

  //Actividad;
  ActividadCampos;
  TotCampos;
  SelCampos;
  User;

  forma: FormGroup;
  filtros: FormGroup;
  model;
  mf;

  _usuario_tipoId;
  _perfilId
  _rolId

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,
    private solicitudService:SolicitudService,
    private actividadesService: ActividadesService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) {
    if(!this.usuarioService.int()) this.router.navigate(['/portal']);
    this.iniciarForm();
  }

  ngOnInit(): void {
    this.fillFiltros();
    this.addActividadListado();
    //this.RolListado = this.usuarioService.getRolListado();
    //this.Actividad = this.actividadesService.getActividadList();
    this.addChecks();
    //this.TipoUsuario = this.usuarioService.getTipoUsuario();
    //this.Perfil = this.usuarioService.getPerfil();
    //this.Rol = this.usuarioService.getRol();
    //console.log(this.tipoUsuario);
    //this.solicitudCampos = this.usuarioService.getRol();
  }

  iniciarForm() {
    this.forma = this.fb.group({
      actividadId: [0],
      nombre: [''],
      campo_seleccionado: ['']
      //transporteId: [0],
      /*
      usuario_tipoId: [0],
      perfilId: [0],
      rolId: [0],
      activo: ['S', [Validators.required]]
      */
    })
    this.model = this.forma;

    //Filtro
    this.filtros = this.fb.group({
      actividad_grupoId: 0,
      activo: ''
    });
    this.mf = this.filtros;
  }

  addActividadListado(){
    this.User = this.usuarioService.getUser();
    this.Actividad = this.actividadesService.getActividadUsuario(this.User.usuarioId);
    this.spinner.show();
    this.actividadesService.getActividadUsuario(this.User.usuarioId).toPromise()
    .then(data => {
      this.spinner.hide();
      this.Actividad = data;
      //Asignamos el universo de items al listado, mediante una entidad de filtro
      this.Actividades = this.Actividad;
    })

  }

  fillFiltros(){
    //this.ActividadSitio = this.actividadesService.getActividadSitio();
    this.ActividadGrupo = this.actividadesService.getActividadGrupo();
  }

  filtrar(){

    //Establecemos los elementos totales de la lista
    this.Filtro = this.Actividad;

    if(this.mf.actividad_grupoId!=0 && this.mf.actividad_grupoId!==undefined){
      this.Filtro = this.Filtro
      .filter(x => x.actividad_grupoId === Number(this.mf.actividad_grupoId));
    }

    if(this.mf.activo!=0 && this.mf.activo!==undefined){
      this.Filtro = this.Filtro
      .filter(x => x.activo === this.mf.activo);
    }

    this.Actividades = this.Filtro;
  }

  nuevo(content) {
    this.iniciarForm();
    this.model.activo = 'S';
    this.modal.open(content,{size:'xl'});
  }

  editar(content, modelo) {
    //Reestablecemos valores de selección
    this.checks01=false;
    this.checks02=false;

    this.model = modelo;
    this.setChecks();
    this.modal.open(content,{size:'xl'});
  }

  guardar() {

    this.forma.controls["campo_seleccionado"].setValue(this.getChecks());
    //console.log(this.forma.value)
    if(this.forma.controls["actividadId"].value == undefined) {
      this.actividadesService.addData(this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {console.log(error);});
    }
    else {
      this.actividadesService.updCampoSel(this.forma.controls["actividadId"].value, this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {console.log(error);});
    }
  }

  recargar(){
    this.modal.dismissAll();
    this.solicitudCampos = this.usuarioService.getData();
  }

  addChecks() {
    //console.log("Todos")
    //this.actividadesService.getActividadList().toPromise()
    this.actividadesService.getActividad_campos().toPromise()
    .then(data => {
      this.TotCampos = data;
      //console.log(this.TotActividad);
    })
  }

setChecks() {
  this.actividadesService.getActividadById(this.model.actividadId).toPromise()
  //this.actividadporrolservice.getRolBanner(this.model.rolId).toPromise()
  .then(data => {
    this.ActividadCampos = data;
    //console.log(this.ActividadCampos);
    //if(this.ActividadCampos.length==0){return false;}
    //console.log(this.ActividadCampos);
    let campos = this.ActividadCampos.campo_seleccionado;
    if(campos === null){return false;}
    //console.log(campos);
    this.SelCampos = this.ActividadCampos.campo_seleccionado.replace(/\s/g, '').split(",");
    var els = document.getElementsByName('opts');
    for (var i=0;i<els.length;i++){
      if(this.SelCampos.includes(String(els[i].id))){
        els[i]['checked'] = true;
        //els[i].checked = true;
      }else{
        els[i]['checked'] = false;
        //els[i].checked = false;
      }
    }


  })
}

getChecks(){
  var choices = [];
  var els = document.getElementsByName('opts');
  for (var i=0;i<els.length;i++){
    if ( els[i]['checked'] ) {
    //if ( els[i].checked ) {
      choices.push(els[i].id);
    }
  }
  return choices.toString();
}

checks01=false;
checks02=false;
checks;
allChecks(tipo_campo){
  if(tipo_campo==1){
  this.checks01 = this.checks01? false: true;
  this.checks = this.checks01;
  }
  else{
    this.checks02 = this.checks02? false: true;
    this.checks = this.checks02;
  }

  //if(this.checks)this.checks=false;
  //alert(this.checks);
  var choices = [];
  var els = document.getElementsByName('opts');
  //console.log(tipo_campo);
  if(this.checks){
    for (var i=0;i<els.length;i++){
      if(els[i]['value']==tipo_campo){
        els[i]['checked'] = 'checked';
      }
    }
  }
  else{
    for (var i=0;i<els.length;i++){
      if(els[i]['value']==tipo_campo){
        els[i]['checked'] = '';
      }
      //els[i]['checked'] = '';
    }
  }

  //return choices.toString();
}


}
