<!--Contenedor-->
<div class="container align-items-center flex-column">

  <!--Encabezado-->
  <div class="text-center">
    <h4 class="title-section">Catálogo - Banner por rol</h4>
  </div>

  <!--Filtros-->
  <form  [formGroup]="filtros" >
    <div class="row">

      <div class="col-lg-4">
        <label>Tipo de usuario</label>
        <select class="form-control"
        formControlName="usuario_tipoId" [(ngModel)]="mf.usuario_tipoId" (change)="Perfiles()">
          <option value="">--Seleccionar--</option>
          <option *ngFor="let item of TipoUsuario | async" 
            value="{{ item.usuario_tipoId }}" >
            {{ item.descripcion }}
            </option>
        </select>
      </div>
      <div class="col-lg-4">
        <label>Perfil</label>
        <select class="form-control"
        formControlName="perfilId" [(ngModel)]="mf.perfilId" (change)="Roles()">
          <option value="">--Seleccionar--</option>
          <option *ngFor="let item of Perfil" 
            value="{{ item.perfilId }}" >
            {{ item.descripcion }}
            </option>
        </select>
      </div>

      <div class="col-lg-4">
        <label>Rol</label>
        <select class="form-control"
        formControlName="rolId" [(ngModel)]="mf.rolId" (change)="filtrar()">
          <option value="">--Seleccionar--</option>
          <option *ngFor="let item of Rol" 
            value="{{ item.rolId }}" >
            {{ item.descripcion }}
            </option>
        </select>
      </div>   

    </div>
    </form>


  <!--Listado-->
  <div class="row justify-content-between py-2">
    <div class="col-md-4 col-sm-12">
        <h5 class="title-subsection">Banner por rol</h5>
    </div> 
    <div class="col-md-4 col-sm-12 text-right">
        <!--button class="btn btn-primary btn_sac" (click)="nuevo(content)">Agregar</button-->
    </div>     
  </div>

  <table class="table table-striped text-center">
    <thead>
      <tr>
        <th scope="col" class="encabezado">#</th>
        <th scope="col" class="encabezado">Tipo de usuario</th>
        <th scope="col" class="encabezado">Perfil</th>
        <th scope="col" class="encabezado">Rol</th>
        <th scope="col" class="encabezado">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of RolListado">
        <td scope="row">{{ item.rolId }}</td>
        <td>{{ item.descriptionUsuarioTipo }}</td>
        <td>{{ item.descripcionPerfil }}</td>
        <td>{{ item.descripcionRol }}</td>
        <td>
          <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar">
            <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
          </button>
        </td>
      </tr>
    </tbody>
    <tfoot class="tfoot-ibero text-center">
      <tr>
        <td colspan="5">{{ (RolListado)?.length }} Registros</td>
      </tr>
    </tfoot>    
  </table>
</div>

<!--Formulario-->
<ng-template #content let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Banner por rol</div>
      <div class="card-body text-dark">

        <form [formGroup]="forma">
          <div class="row text-dark">
            <!--input class="form-control" type="hidden" formControlName="transporteId"
              [(ngModel)]="model.transporteId"-->
              <input class="form-control" type="hidden" formControlName="usuario_tipoId"
              [(ngModel)]="model.usuario_tipoId">
              <input class="form-control" type="hidden" formControlName="perfilId"
              [(ngModel)]="model.perfilId">
              <input class="form-control" type="hidden" formControlName="rolId"
              [(ngModel)]="model.rolId">
          </div>


          <div class="row text-dark">
            <div class="form-group col-lg-4">
              <label for="usuario_tipoId">Tipo de usuario</label>
              <p>{{model.descriptionUsuarioTipo}}</p>

            </div>
            <div class="form-group col-lg-4">
              <label for="perfil">Perfil</label>
              <p>{{model.descripcionPerfil}}</p>
            </div>
            <div class="form-group col-lg-4">
              <label for="rol">Rol</label>
              <p>{{model.descripcionRol}}</p>
            </div>               
          </div>
<!--Checkboxes-->
          <div class="row text-dark">
            <h5 class="title-subsection">Asignar banners al rol</h5>
            <div class="form-group col-lg-6">
              
              <ng-container *ngFor="let item of TotBanners">
                  <p *ngIf="item.activo==='S'">
                    <label>
                      <input type="checkbox"
                      name="opts"
                      id="{{item.bannerId}}"
                       type="checkbox" 
                      >
                      <span class="label-text text-danger pl-1">{{ item.descripcion }}</span>
                    </label>
                  </p>
              </ng-container>

              <label>
                <input type="checkbox"
                name="mast"
                id="mast"
                (click)="allChecks()"
                >
                <span class="label-text text-danger pl-1">Seleccionar Todos</span>
              </label>

            </div>          
        </div>
          <div class="row text-dark pt-3">
              <div class="col-lg-12">
                  <div class="buttonSelect text-center">
                    
                    <!--button class="btn btn-danger" (click)="allChecks()">Todos</button-->&nbsp;
                      <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                      <button class="btn btn-danger" (click)="guardar()">Guardar</button>
                  </div>
              </div>
          </div>
      </form>  
      </div>
    </div>
    
  </div>
 </ng-template>