import { Injectable } from '@angular/core';
import { from, forkJoin, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../services/config';
import { UsuarioService } from '../services/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class TemaService {
  public _temas: any[] = [];
  user = [];
  Usuario:any;
  requestOptions:any;
  requestFileOptions:any;
  constructor( private http: HttpClient,
    private usuarioService: UsuarioService ) {    
    this.requestOptions = this.getHeader();
    this.requestFileOptions = this.getFileHeader();
  }

  getHeader() {
    this.Usuario = this.usuarioService.getUser();
    let header = new HttpHeaders({ "Authorization": "Bearer " + this.Usuario.token });
    return { headers: header };
  }

  getFileHeader() {
    this.Usuario = this.usuarioService.getUser();
    let header = new HttpHeaders({ "Authorization": "Bearer " + this.Usuario.token });
    return { headers: header, responseType: 'blob' }
  } 


  //Alta
  getData() {
    return this.http.get(config.apiUrl+'/Tema', this.requestOptions);
  }

  //Alta
  addData(data): Observable<any> {
    return this.http.post(config.apiUrl+'/Tema/',data, this.requestOptions);
  }

  //Cambio
  updData(id, data): Observable<any> {
    return this.http.put(config.apiUrl+'/Tema/'+id, data, this.requestOptions);
  }   

  getTemas() {
    return this.http.get(config.apiUrl+'/Tema/gettemasusuario/1', this.requestOptions);
  }

  getTipoTema() {
    return this.http.get(config.apiUrl+'/tema_tipo', this.requestOptions);
  }

  getTemaTipo() {
    return this.http.get(config.apiUrl+'/tema_tipo'), this.requestOptions;
  }  

  getTemaTipoActivo() {
    return this.http.get(config.apiUrl+'/tema_tipo/GetActive', this.requestOptions);
  }   

  getTemaTipoPublico() {
    return this.http.get(config.apiUrl+'/tema_tipo_publico', this.requestOptions);
  }

  getTemaCategoria() {
    return this.http.get(config.apiUrl+'/Tema_categoria', this.requestOptions);
  }
  getTemaSubcategoria() {
    return this.http.get(config.apiUrl+'/Tema_subcategoria', this.requestOptions);
  }

  getIdioma() {
    return this.http.get(config.apiUrl+'/idioma', this.requestOptions);
  }  

  getTemasLista(){
    return this.http.get(config.apiUrl+'/tema/gettemaslist/0', this.requestOptions);
  }

  getTemaList(){
    return this.http.get<any[]>(config.apiUrl+'/tema/gettemaslist/0', this.requestOptions);
  }

  getTemasUsuario(usuarioId) {
    return this.http.get(config.apiUrl+'/Tema/gettemasusuario/' + usuarioId, this.requestOptions);
  }

  getTemasUsuarioInterno(usuarioId) {
    return this.http.get(config.apiUrl+'/Tema/GetTemasU_DistinctTP/' + usuarioId, this.requestOptions);
  }  

  get_TemasListado(): Observable <any[]> {
    return this.http.get<any[]>(config.apiUrl+'/Tema/gettemasusuario/1');
  }

  public getAllTemasListado(): void {
    this.get_TemasListado()
    .subscribe( (resp) => {
      resp.forEach((tema) => {
        this._temas.push(tema);
      });
    },
    (err) => {
      console.log(`metodo getAllUsuarios error ${err}`);
    });
  }

}
