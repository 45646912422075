import { Component, OnInit, Input } from '@angular/core';
import { SubCategoriaService } from '../../../core/services/subCategoria.service';
import { CategoriaService } from '../../../core/services/categoria.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CategoriaComponent } from '../categoria/categoria.component';
import { NgxSpinnerService } from "ngx-spinner";
import { UsuarioService } from '../../../core/services/usuario.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-Subcategoria',
  templateUrl: './Subcategoria.component.html',
  styles: [
  ]
})
export class SubcategoriaComponent implements OnInit {
  SubCategoria;
  SubCategorias;
  Filtro;
  Categoria;
  forma: FormGroup;
  filtros: FormGroup;
  model;
  mf;
  constructor(
    public fb: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,
    private subcategoriaservice: SubCategoriaService,
    private categoriaservice: CategoriaService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) {
    if(!this.usuarioService.int()) this.router.navigate(['/portal']);
    this.iniciarForm();
  }

  ngOnInit(): void {
//    this.Categoria = this.categoriaservice.getData();
    this.fillFiltros();
    this.addSubCategorias();

  }

  iniciarForm() {
    this.forma = this.fb.group({
      descripcion: ['', [Validators.required]],
      tema_subcategoriaId: [0],
      tema_categoriaId: [0],
      categoriaDescripcion: [''],
      activo: ['S', [Validators.required]]
    })
    this.model = this.forma;

    //Filtro
    this.filtros = this.fb.group({
      tema_categoriaId: 0,
       activo: ['']
    });
    this.mf = this.filtros;
  }

  fillFiltros(){
    //this.ActividadSitio = this.actividadesService.getActividadSitio();
    //this.ActividadGrupo = this.actividadesService.getActividadGrupo();
    this.Categoria = this.categoriaservice.getData();
  }

  addSubCategorias(){
    this.spinner.show();
    this.subcategoriaservice.getData().toPromise()
    .then(data => {
      this.spinner.hide();
      this.SubCategoria = data;
      //Asignamos el universo de items al listado, mediante una entidad de filtro
      this.SubCategorias = this.SubCategoria;
    })

  }

  filtrar(){

    //Establecemos los elementos totales de la lista
    this.Filtro = this.SubCategoria;

    //alert(this.mf.actividadId);
    if(this.mf.tema_categoriaId!=0 && this.mf.tema_categoriaId!==undefined){
      this.Filtro = this.Filtro
      .filter(x => x.tema_categoriaId === Number(this.mf.tema_categoriaId));
    }

    if(this.mf.activo!=0 && this.mf.activo!==undefined){
      this.Filtro = this.Filtro
      .filter(x => x.activo === this.mf.activo);
    }


    this.SubCategorias = this.Filtro;
  }


  nuevo(content) {
    this.iniciarForm();
    this.model.activo = 'S';
    this.modal.open(content,{size:'xl'});
  }

  editar(content, modelo) {
    this.model = modelo;
    this.modal.open(content,{size:'xl'});
  }

  guardar() {
    if (this.forma.controls["tema_subcategoriaId"].value == undefined) {
      this.subcategoriaservice.addData(this.forma.value).subscribe(
        (res) => { this.recargar(); },
        (error) => { console.log(error); });
    }
    else {
      this.subcategoriaservice.updData(this.forma.controls["tema_subcategoriaId"].value, this.forma.value).subscribe(
        (res) => { this.recargar(); },
        (error) => { console.log(error); });
    }
  }

  recargar() {
    this.modal.dismissAll();
    //this.SubCategoria = this.subcategoriaservice.getData();
    this.addSubCategorias();
  }

}
