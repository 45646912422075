import { Component, OnInit, ViewChild } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, merge} from 'rxjs/operators';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from '../../../core/services/usuario.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-actualizar',
  templateUrl: './actualizar.component.html',
})
/*export class ActualizarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}*/

export class ActualizarComponent implements OnInit {
  forma: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  enabled = false;
  Colegios;
  model;
  features;
  constructor(
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.iniciarForm();
   }


  iniciarForm() {
    /*
    this.forma = this.formBuilder.group({
      correo: ['', [Validators.required]],
      contrasenia: ['', [Validators.required]],
      nombre: ['', [Validators.required]],
      apaterno: ['', [Validators.required]],
      amaterno: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      extension: ['', [Validators.required]],
      colegioId: [0],
      usuarioId: [0],
      rolId: [0],
      perfilId: [0],
      usuario_tipoId: [0],
      activo: ['S', [Validators.required]]
    });
    */
   this.forma = this.formBuilder.group({
    nombre: ['', [Validators.required, Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)]],
    apaterno: ['', [Validators.required, Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)]],
    amaterno: ['', Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)],
    correo: ['', [Validators.required, Validators.email]],
    telefono: ['', [Validators.required, Validators.pattern("^[0-9]*$"),
    Validators.minLength(10), Validators.maxLength(10)]],
    extension: ['', [Validators.pattern("^[0-9]*$"),Validators.minLength(1), Validators.maxLength(5)]],
    contrasenia: ['', Validators.required],
    confirmacontrasenia: ['', Validators.required],
    nombreColegio: [''],
    colegioId: [0],
    usuarioId: [0],
    rolId: [0],
    perfilId: [0],
    usuario_tipoId: [0],
    activo: ['S', [Validators.required]],
    otro_colegio_nombre: ['']
  },
  { validator: this.passwordConfirming }
  );
    this.model = this.forma;
  }

  /**********Validador de contraseñas iguales ***********/
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('contrasenia').value !== c.get('confirmacontrasenia').value) {
        return {invalid: true};
    }
  }

  User;
  idPagina;
  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {
      this.idPagina = Number(params['id']);
      });


    this.User = this.usuarioService.getUser();
    //console.log(this.User);
    this.model = this.User;
    this.model.confirmacontrasenia = this.model.contrasenia;
    /*
    this.usuarioService.getUsuarioById(this.User.usuarioId)
    .toPromise()
      .then(data => {
        this.model = data;
        this.model.confirmacontrasenia = this.model.contrasenia;
        //this.SubCategoria = this.SubCategoria.filter(x => x.tema_categoriaId === Number(this.model.tema_categoriaId));
        //this.filtra();
      })
      */
    //console.log(this.Colegios);
  }

  guardar() {
    //console.log(this.forma.value);
    //El control de búsqueda asigna un objeto al colegioId
    //Extraemos el id del Colegio y reemplazamos el valor
    //var objColegio = this.forma.controls["colegioId"].value;
    //console.log(objColegio);
    //console.log(objColegio.colegioId);
    //this.forma.controls["colegioId"].setValue(Number(objColegio.colegioId));
    //console.log(this.forma.value);
    //return false;
    this.usuarioService.updData(this.model.usuarioId, this.forma.value).subscribe(
      (res) => {this.recargar();},
      (error) => {
        if(error.error.result === 3)
        {
          this.toastr.error(error.error.message);
        }
        console.log(error);
      });
  }

  cancelar(){
    if(this.idPagina===1){
      this.router.navigate(['/solicitud']);
      return false;
    }
    if(this.idPagina===2){
      this.router.navigate(['/portal']);
      return false;
    }
  }

  recargar() {
    //this.modal.dismissAll();
    //this.Usuario = this.usuarioService.getData();
    /*
    console.log(this.User);
    this.User = this.model;
    this.usuarioService.addUser(this.User);
    console.log(this.User);
    */
    this.toastr.success('El perfil se ha actualizado, favor de iniciar sesión nuevamente para ver tus cambios actualizados.');
    //alert("La solicitud ha sido enviada");
    /*
    if(this.idPagina===1){
      this.router.navigate(['/solicitud']);
      return false;
    }
    if(this.idPagina===2){
      this.router.navigate(['/portal']);
      return false;
    }
    */
    this.clear();
    this.router.navigate(['/']);
    //this.router.navigate(['./login']);
  }

  clear(){
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    localStorage.removeItem('tipoUsuario');
    localStorage.removeItem('perfil');
    localStorage.removeItem('rolId');
    localStorage.removeItem('usuario');
    localStorage.removeItem('banner');
    localStorage.removeItem('actividad');
    localStorage.removeItem('curso');
    localStorage.removeItem('concurso');
    localStorage.removeItem('torneo');
    localStorage.removeItem('beca');
    localStorage.removeItem('orientador');
    localStorage.removeItem('xplora');
    localStorage.removeItem('espacio');
    localStorage.removeItem('visita');

    //localStorage.removeItem('carrito');
  }

  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  public modelo: any;

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    merge(this.focus$),
    merge(this.click$.pipe(filter(() => !this.instance.isPopupOpen()))),
    map(term => (term === '' ? this.features
      : this.features.filter(v => v.nombre.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
  );

  formatter = (x: {nombre: string}) => x.nombre;

  /*
  searchUrl(url){
    if(url){
      window.open(url,'_blank');
    }
  }
  */

}
