import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActividadesService } from '../../../core/services/actividades.service';
import { UsuarioService } from '../../../core/services/usuario.service';
import { CarritoService } from '../../../core/services/carrito.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import {Router} from '@angular/router';

@Component({
  selector: 'app-actividad-form',
  templateUrl: './actividad-form.component.html',
  styles: [
  ]
})
export class ActividadFormComponent implements OnInit {
  Actividades;
  ActividadesFiltrados;
  ActividadesListado;
  idGrupoActividad: number;
  actRows;

  SelActividad;
  User;
  Actividad;
  items;
  existe;


  selectedUser = 0;
  isSelected = false;
  onSelect(index): void {
    this.selectedUser = index;
  }



  constructor(
    private actividadesService:ActividadesService,
    private usuarioService: UsuarioService,
    private carritoService: CarritoService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    public modal: NgbModal
  ) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {
      this.idGrupoActividad = Number(params['id']);
      this.onSelect(this.idGrupoActividad);
      this.addActividades();
      });

  }

  addActividades(){
    this.User = this.usuarioService.getUser();
    this.actividadesService.getActividadesActivas(this.User.usuarioId).toPromise()
    .then(data => {
      this.User.rolId
      this.SelActividad = data;
      this.User = this.usuarioService.getUser();
      let arr = this.User.actividad_seleccionado.replace(/\s/g, '').split(",");

      this.Actividad = this.SelActividad.filter(function(item){
        return arr.indexOf(String(item.actividadId)) >= 0;
      });
      this.filtra(this.idGrupoActividad);

    })
  }


  filtra(id_ga:number){
    this.idGrupoActividad = id_ga;
    this.ActividadesFiltrados = this.Actividad;
    //console.log(this.ActividadesFiltrados);
    //Si es 1-3, aplica: modalidadId
    //1:presencial, 2 en linea, 3 ambas
    const presencial: number = 1;
    const enlinea: number = 2;
    const ambas: number = 3;
    if(id_ga==4){
      this.ActividadesFiltrados = this.ActividadesFiltrados
      .filter(x => x.modalidadId === enlinea || x.modalidadId === ambas);
    }
    else
    {
    this.ActividadesFiltrados = this.ActividadesFiltrados
    .filter(x => Number(x.actividad_grupoId) === Number(this.idGrupoActividad));

    this.ActividadesFiltrados = this.ActividadesFiltrados
    .filter(x => x.modalidadId === presencial || x.modalidadId === ambas);

    }

    this.rows();
  }

  rows(){
    this.actRows = [];
    this.ActividadesFiltrados.forEach((item, index) => {
      if(index % 5 == 0) {
          let row = [];
          row.push(item);
          this.actRows.push(row);
      } else {
          this.actRows[this.actRows.length - 1].push(item);
      }
    });
  }

  addToCart(contenido, item ) {
    //Accedemos los valores para validación
    this.items = this.carritoService.obtenerActividades();
    this.existe = this.items.find(x => x.actividadId === Number(item.actividadId));
    if(this.existe!=undefined){
      this.toastr.success('Esta actividad fue agregada previamente a tu lista.');
      return false;
    }
    this.carritoService.agregarActividad(item);
    this.toastr.success('La actividad ha sido agregada a tu lista.');
    this.items = this.carritoService.obtenerActividades();

    this.modal.open(contenido);
  }

  removeFromCart(actividadId, temaId) {
    this.items = this.carritoService.quitarActividades(actividadId, temaId);
    this.toastr.warning('La actividad ha sido eliminada de tu lista.');
  }

  emptyCart() {
    this.items = this.carritoService.vaciarActividades();
    this.toastr.error('Tu lista ahora está vacía.');
  }

  gotoCart(){
    //this.modal.dismissAll();
    this.router.navigate(['./carrito']);
  }



}
