import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioService } from '../../../core/services/usuario.service';

@Component({
  selector: 'app-colegio',
  templateUrl: './colegio.component.html',
  styles: [
  ]
})
export class ColegioComponent implements OnInit {
  Colegio;
  forma: FormGroup;
  model;
 constructor(
    public fb: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) {
    if(!this.usuarioService.int()) this.router.navigate(['/portal']);
    this.iniciarForm();
  }

  ngOnInit(): void {
    this.addLista();
  }

addLista(){

  //this.spinner.show();
  //let x = this.http.get(config.apiUrl + '/Colegio');
  //this.spinner.hide();
  //return x;
  this.spinner.show();
  this.usuarioService.getColegioAll().toPromise()
  .then(data => {
    this.Colegio = data;
    this.spinner.hide();
  })
}

  iniciarForm() {
    this.forma = this.fb.group({
      nombre: ['', [Validators.required]],
      colegioId: [0],
      activo: ['S', [Validators.required]]
    })
    this.model = this.forma;
  }

  nuevo(content) {
    this.iniciarForm();
    this.model.activo = 'S';
    this.modal.open(content,{size:'xl'});
  }

  editar(content, modelo) {
    //console.log(modelo);
    this.model = modelo;
    this.modal.open(content,{size:'xl'});
  }

  guardar() {
    if(this.forma.controls["colegioId"].value == undefined) {
      this.usuarioService.addColegio(this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {console.log(error);});
    }
    else {
      this.usuarioService.updColegio(this.forma.controls["colegioId"].value, this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {console.log(error);});
    }
  }

  recargar(){
    this.modal.dismissAll();
    this.addLista();
  }
  //constructor() { }

  //ngOnInit(): void { }

}
