<section class="section visitaguiada hfull">
    <div class="container-fluid flex-column">
      <div class="row align-items-center  min-vh-100">
          <div class="ibero-texto col-sm-12 col-md-6 text-center">
            <p class="text-white display-3">VISITA GUIADA DE</p>
             <p class="text-danger display-1">COLEGIOS</p>
             <button type="button" class="btn btn-danger btn-lg" (click)="ir()">Solicita tu visita</button>
             <!--a class="btn btn-danger" href="carritoService.ban('visita')" role="button">Solicita tu visita</a-->
          </div>
        </div>
        </div>
  </section>
