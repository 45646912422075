import { Injectable } from '@angular/core';
import { from, forkJoin, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../services/config';
import { UsuarioService } from '../services/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class TemasListadoService {
  public _temas: any[] = [];
  user = [];
  Usuario:any;
  requestOptions:any;
  requestFileOptions:any;
  constructor( private http: HttpClient,
    private usuarioService: UsuarioService ) {    
    this.requestOptions = this.getHeader();
    this.requestFileOptions = this.getFileHeader();
  }

  getHeader() {
    this.Usuario = this.usuarioService.getUser();
    let header = new HttpHeaders({ "Authorization": "Bearer " + this.Usuario.token });
    return { headers: header };
  }

  getFileHeader() {
    this.Usuario = this.usuarioService.getUser();
    let header = new HttpHeaders({ "Authorization": "Bearer " + this.Usuario.token });
    return { headers: header, responseType: 'blob' }
  } 

  getTemasListado() {
    return this.http.get(config.apiUrl+'/Tema/GetTemasList', this.requestOptions);
  }

  
  get_TemasListado(): Observable <any[]> {
    return this.http.get<any[]>(config.apiUrl+'/Tema/GetTemasList');
  }

  public getAllTemasListado(): void {
    this.get_TemasListado()
    .subscribe( (resp) => {
      resp.forEach((tema) => {
        this._temas.push(tema);
      });
    },
    (err) => {
      console.log(`metodo getAllUsuarios error ${err}`);
    });
  }


}
