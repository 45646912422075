<footer class="footer-ibero font-small text-white mt-4 ">
    <div class="container-fluid text-center">
      <!-- Grid row -->
      <div class="row text-center text-white">
        <div class="col-md-12">
        <p>Prolongación Paseo de Reforma 880, Lomas de Santa Fe, México, C.P. 01219, Ciudad de México<br>Tel. 55 5950-4000 y 55 9177-4400 / Lada nacional sin costo: 800 627-7615</p>
        </div>
      </div>
      <!-- Grid row -->
    </div>
  </footer>
