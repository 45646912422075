import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UsuarioService } from '../../../core/services/usuario.service';

@Component({
  selector: 'app-recuperacion',
  templateUrl: './recuperacion.component.html',
  styles: [
  ]
})
export class RecuperacionComponent implements OnInit {
  forma: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  enabled = false;
  constructor(
    private usuarioService: UsuarioService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.forma = this.formBuilder.group({
      correo: ['', [Validators.required, Validators.email]],
    });
  }

  guardar() {
    //console.log(this.forma.value);
    //return false;
    this.usuarioService.recupera(this.forma.value).subscribe(
      (res) => {
        this.recargar();
      },
      (error) => {
        if(error.error.result === 3)
        {
          this.toastr.error(error.error.message);
        }
        console.log(error);
      });
  }

  recargar() {
    //this.modal.dismissAll();
    //this.Usuario = this.usuarioService.getData();
    this.toastr.success('Se han enviado las instrucciones a tu correo.');
    //alert("La solicitud ha sido enviada");
    this.router.navigate(['/']);
    //this.router.navigate(['./login']);
  }

}
