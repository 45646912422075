<!-- About Section-->
<section class="pagina-seccion">
    <div class="container d-flex align-items-center flex-column">
        <div class="row align-items-center">
            <div class="col-sm-12 col-md-5 content-img">
              <img id="sol"  class="seccion-avatar-01 animate__animated animate__backInUp" src="/assets/img/sol02.svg" alt=""/>
              <img  class="students seccion-avatar-01 mb-0 animate__animated animate__backInLeft" src="/assets/img/alumnos01.svg" alt="" />
            </div>
            <div class="col-sm-12 col-md-7">
              <h2 class="pagina-seccion-encabezado text-right">
                Actividades de <br> apoyo para estudiantes
            </h2>
            <h2 class="pagina-seccion-titulo text-right">
              de nivel preparatoria,<br> orientadores (as) <br> y docentes.
          </h2>
            </div>
        </div>
    </div>
  </section>
