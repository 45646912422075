import { GraphService } from './../../../MicrosoftGraphServices/graph.service';
import { ToastrService } from 'ngx-toastr';
import { MGUser } from './../../../MicrosoftGraphClasses/MGUser';
import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { DiaInhabilService } from '../../../core/services/dia-inhabil.service';
import { AgendaService } from '../../../core/services/agenda.service';
import { UsuarioService } from '../../../core/services/usuario.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Invitado } from 'src/app/core/HelperClases/Invitado';
import { AuthService } from 'src/app/MicrosoftGraphServices/auth.service';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';

@Component({
    selector: 'app-agenda-list',
    templateUrl: './agenda-list.component.html',
    styles: [
    ]
})
export class AgendaListComponent implements OnInit {
    DiaInhabiles;
    DiaInhabil;
    Usuarios;
    Agenda;
    forma: FormGroup;
    model;

    disabledDates: NgbDateStruct[] = [];

    dropdownList = [];

    dropdownSettings: IDropdownSettings = {
        singleSelection: false,
        selectAllText: 'Todos',
        unSelectAllText: 'Ninguno',
        idField: 'usuarioId',
        textField: 'item_text',
        allowSearchFilter: true,
        searchPlaceholderText: 'Buscar'
    };



    constructor(
        public fb: FormBuilder,
        private router: Router,
        private usuarioService: UsuarioService,
        private http: HttpClient,
        private diaInhabilService: DiaInhabilService,
        private agendaService: AgendaService,
        private spinner: NgxSpinnerService,
        public modal: NgbModal,
        public toastr: ToastrService,
        public authService: AuthService,
        public graphService: GraphService
    ) {
        if(!this.usuarioService.int()) this.router.navigate(['/portal']);
        this.iniciarForm();
    }
    calendars;
    async ngOnInit() {
        this.loadMainData();
        this.loadOneTime();

        if (this.authService.graphClient) {
            await this.graphService
                .getAllCalendarsUserCanAccess()
                .then((calendars) => {
                    this.calendars = calendars.filter(x => x.canEdit == true);
                    console.log(this.calendars);
                })
        }

    }



    MGEvent_AskUserToLogIn() {


        this.authService
            .signIn()
            .then(elements =>
                this.graphService
                    .getAllCalendarsUserCanAccess()
                    .then((calendars) => {
                        this.calendars = calendars.filter(x => x.canEdit == true);
                        console.log(this.calendars);
                    }));

    }

    iniciarForm() {
        this.forma = this.fb.group({
            agenda_actividadId: [0],
            nombre: ['', [Validators.required]],
            tipo: ['', [Validators.required]],
            fecha_actividad: [0, [Validators.required]],
            hora_actividad: [0, [Validators.required]],
            hora_fin: [0, [Validators.required]],
            duracion: [0, [Validators.required]],
            invitados: [[]],
            usuario_registroId: [0],
            usuario_modificaId: [0],
            microGraphCalendarId: ['', [Validators.required]],
            microGraphEventId: ['']
        });
        this.model = this.forma;
    }

    loadMainData() {
        this.spinner.show();
        this.agendaService.getData().toPromise().then(data => {
            this.spinner.hide();
            this.Agenda = data;
        })
    }

    loadOneTime() {
        this.usuarioService.getUsuariosByTipo(1).toPromise()
            .then(data => {
                this.Usuarios = data;
                console.log(data);
                this.Usuarios.forEach((v) => {
                    this.dropdownList.push({ usuarioId: v.usuarioId, item_text: `${v.nombrecompleto} (${v.correo})` });
                });
            });

        this.diaInhabilService.getData().toPromise()
            .then(data => {
                this.DiaInhabiles = data;
                this.DiaInhabiles.forEach(v => {
                    const dateArr = v.fecha_diainhabil.split('-');
                    this.disabledDates.push({ year: dateArr[0], month: dateArr[1], day: dateArr[2] });
                });
            });
    }

    isDisabled = (date: NgbDateStruct, current: { month: number, year: number }) => {
        return this.disabledDates.find(x => x.year == date.year && x.month == date.month && x.day == date.day) ? true : false;
    }

    onItemSelect(item: any) {
        //console.log(item);
    }

    onSelectAll(items: any) {
        //console.log(items);
    }

    nuevo(content) {

        if (this.authService.graphClient) {
            this.iniciarForm();
            this.modal.open(content, { size: 'xl' });
        } else {
            this.toastr.error("Debe loggearse para dar de alta actividad.");
        }

    }

    editar(content, modelo) {
        if (!this.authService.graphClient) {
            this.toastr.error("Debe loggearse para editar actividad.");
            return;
        }

        this.model = modelo;
        this.model.invitados = this.TransformarInvitados(modelo.invitados);

        let fechaTransformadaDeActividad: Date = this.TransformStringToDate(modelo.fecha_actividad);
        this.model.fecha_actividad = fechaTransformadaDeActividad;

        let fechaYHoraDeInicio = this.ConcatenarFechaYTiempo(fechaTransformadaDeActividad, this.model.hora_actividad);
        let durationInMinutes = this.TransformDurationToMinutes(modelo.duracion);

        let fechaYHoraFin = new Date(fechaYHoraDeInicio);
        fechaYHoraFin.setMinutes(fechaYHoraDeInicio.getMinutes() + durationInMinutes);
        this.model.hora_fin = this.GetFormatedHourMinute(fechaYHoraFin);
        this.model.microGraphCalendarId = modelo.microGraphCalendarId;
        this.model.microGraphEventId = modelo.microGraphEventId;

        this.modal.open(content, { size: 'xl' });
    }

    MostrarVentana_BorradoDeActividad(content, modelo) {

        if (!this.authService.graphClient) {
            this.toastr.error("Debe loggearse para borrar actividad.");
            return;
        }

        this.model = modelo;
        this.modal.open(content, { size: 'xl' });
    }

    BorrarActividad() {
        if (!this.authService.graphClient) {
            this.toastr.error("Debe loggearse para borrar actividad.");
            return;
        }
        this.spinner.show();
        this.agendaService
            .getById(this.model.agenda_actividadId)
            .toPromise()
            .then((activity) => {
                this.graphService.deleteEvent(activity).then(message => {
                    this.agendaService
                        .delete(this.model.agenda_actividadId)
                        .toPromise()
                        .then((successMessage) => {
                            this.toastr.success("Actividad borrada correctamente.");
                            this.recargar();
                            this.spinner.hide();
                        },
                            (errorMessage) => {
                                this.toastr.error(errorMessage);
                                this.spinner.hide();
                            });
                }, (errorMessage) => {
                    this.toastr.error(errorMessage);
                    this.spinner.hide();
                });
            }, (errorMessage) => {
                this.toastr.error(errorMessage);
                this.spinner.hide();
            });
    }



    GetFormatedHourMinute(fecha: Date): string {
        let hours = (fecha.getHours() >= 10) ? fecha.getHours() : "0" + fecha.getHours();
        let minutes = (fecha.getMinutes() >= 10) ? fecha.getMinutes() : "0" + fecha.getMinutes();
        return hours + ":" + minutes;
    }

    ConcatenarFechaYTiempo(startDate: Date, horaDeInicio: string): Date {
        let durationElements = horaDeInicio.toString().split(':');
        let hours = Number(durationElements[0]);
        let minutes = Number(durationElements[1]);

        return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), hours, minutes);
    }

    TransformDurationToMinutes(duration: string): number {
        let resultingMinutes = 0;

        let durationElements = duration.toString().split(':');
        let hours = Number(durationElements[0]);
        let minutes = Number(durationElements[1]);
        resultingMinutes = (hours * 60) + minutes;

        return resultingMinutes;
    }

    TransformStringToDate(fecha: string): Date {
        let dateElements = fecha.toString().split('-');

        let year = Number(dateElements[0]);
        let month = Number(dateElements[1]) - 1;
        let day = Number(dateElements[2]);

        return new Date(year, month, day);
    }

    TransformarInvitados(invitados: any): Invitado[] {
        let resultList: Invitado[] = [];

        for (let i = 0; i < invitados.length; i++) {
            let currentInvitado = invitados[i];
            let newInvitado = new Invitado();
            newInvitado.usuarioId = currentInvitado.usuarioId;
            newInvitado.item_text = currentInvitado.nombrecompleto + " ( " + currentInvitado.correo + " )";
            resultList.push(newInvitado);
        }

        return resultList;
    }

    guardar() {

        this.spinner.show();
        const hora_i = this.forma.controls["hora_actividad"].value;
        const hora_f = this.forma.controls["hora_fin"].value;

        const timeStart = new Date("01/01/2007 " + hora_i).getTime();
        const timeEnd = new Date("01/01/2007 " + hora_f).getTime();

        let timeDiff = timeEnd - timeStart;

        if (timeDiff < 0) {
            timeDiff += 24 * 60 * 60 * 1000;
        }

        var hours = Math.floor(timeDiff / (60000 * 60));
        var minutes = ((timeDiff % (60000 * 60)) / 60000).toFixed(0);

        this.forma.controls["duracion"].setValue((hours < 10 ? '0' : '') + hours + ":" + (parseInt(minutes) < 10 ? '0' : '') + minutes);



        if (!this.FormularioCumpleConValidacionesLogicas(this.forma.value)) {

            this.spinner.hide();
            return;
        }

        if (this.forma.controls["agenda_actividadId"].value == undefined) {
            this.forma.controls["usuario_registroId"].setValue(this.usuarioService.getUser().usuarioId);

            /*
                GUARDAR EN MICROSOFT GRAPH
            */
            let event = this.initializeEvent(this.forma.value);

            this.graphService.addEventToCalendar(event, this.forma.value.microGraphCalendarId).then(message => {
                console.log(message.id);
                this.forma.value.microGraphEventId = message.id;

                this.agendaService.addData(this.forma.value).subscribe(
                    (res) => {
                        console.log(res);
                        this.toastr.success("Actividad guardada correctamente.");
                        this.recargar();
                        this.spinner.hide();
                    },
                    (error) => {
                        this.toastr.error(error);
                        this.spinner.hide();
                    });
            });
        }
        else {

            let event = this.initializeEvent(this.forma.value);
            console.log(event);
            console.log(this.forma.value);

            this.graphService
                .updateEventToCalendar(event, this.forma.value.microGraphEventId)
                .then((successfull) => {
                    this.forma.controls["usuario_modificaId"].setValue(this.usuarioService.getUser().usuarioId);
                    this.agendaService.updData(this.forma.controls["agenda_actividadId"].value, this.forma.value).subscribe(
                        (res) => {
                            console.log(res);
                            this.toastr.success("Actividad editada correctamente.");;
                            this.recargar();
                            this.spinner.hide();
                        },
                        (error) => { this.toastr.error(error); this.spinner.hide(); });
                });
        }

    }



    initializeEvent(formulario: any): MicrosoftGraph.Event {
        console.clear();
        console.log("ingresando a initialize event");
        console.log(formulario);

        let activitySubject: string = formulario.nombre;
        let startDate: string = "";
        let endDate: string = "";
        let timeZone = "America/Mexico_City";

        let fechaTransformadaDeActividad: Date = formulario.fecha_actividad;


        let fechaYHoraDeInicio = this.ConcatenarFechaYTiempo(fechaTransformadaDeActividad, formulario.hora_actividad);
        startDate = this.TransformDateToMicrosoftGraphDate(fechaYHoraDeInicio);


        let durationInMinutes = this.TransformDurationToMinutes(formulario.duracion);
        let fechaYHoraFin = new Date(fechaYHoraDeInicio);
        fechaYHoraFin.setMinutes(fechaYHoraDeInicio.getMinutes() + durationInMinutes);
        endDate = this.TransformDateToMicrosoftGraphDate(fechaYHoraFin);


        const graphEvent: MicrosoftGraph.Event = {
            subject: activitySubject,
            start: {
                dateTime: startDate,
                timeZone: timeZone
            },
            end: {
                dateTime: endDate,
                timeZone: timeZone
            }
        };

        graphEvent.attendees = [];

        const emails = this.ExtractEmailsFromAppAttendees(formulario.invitados);// nombreDeUsuario ( correo@ibero.mx )
        console.log(emails);

        emails.forEach(email => {
            graphEvent.attendees?.push({
                type: 'required',
                emailAddress: {
                    address: email
                }
            });
        });

        graphEvent.body = {
            contentType: 'text',
            content: activitySubject
        };

        return graphEvent;
    }

    ExtractEmailsFromAppAttendees(invitados: any[]): string[] {
        let emails = new Array(invitados.length);

        for (let i = 0; i < invitados.length; i++) {
            let currentInvitado = invitados[i].item_text;
            emails.push(this.ExtractEmail(currentInvitado));//Extraer de: "nombreDeUsuario ( correo@ibero.mx )"
        }

        return emails;
    }

    ExtractEmail(attendee: string): string {
        //return "calendario.share1@ibero.mx";
        return attendee.split('(')[1].split(')')[0];
    }

    TransformDateToMicrosoftGraphDate(dateToChange: Date): string {
        //YYYY-MM-DDTHH:mm:ss
        let year = dateToChange.getFullYear();
        let month = this.ZeroFirstNumberToString(dateToChange.getMonth() + 1);//los meses estan en base 0
        let day = this.ZeroFirstNumberToString(dateToChange.getDate());
        let hour = this.ZeroFirstNumberToString(dateToChange.getHours());
        let minutes = this.ZeroFirstNumberToString(dateToChange.getMinutes());
        let seconds = this.ZeroFirstNumberToString(dateToChange.getSeconds());

        return year + "-" + month + "-" + day + "T" + hour + ":" + minutes + ":" + seconds;
    }

    ZeroFirstNumberToString(number: number): string {
        //Example: if 5 then return "05"
        //Example: if 20 then return "20"
        return ((number >= 10) ? number : "0" + number).toString();
    }

    FormularioCumpleConValidacionesLogicas(formulario: any) {
        let minutesHoraInicial = this.TransformDurationToMinutes(formulario.hora_actividad);
        let minutesHoraFinal = this.TransformDurationToMinutes(formulario.hora_fin)
        let numeroDePersonasAsignadas = formulario.invitados.length;

        if (minutesHoraInicial >= minutesHoraFinal) {
            this.toastr.error("La hora de inicio debe ser menor a la hora fin.")
            return false;
        }

        if (this.ActividadEsMenorA30Minutos(minutesHoraInicial, minutesHoraFinal)) {
            this.toastr.error("Las actividades deben tener una duración mínima de 30 minutos.")
            return false;
        }

        if (numeroDePersonasAsignadas == 0) {
            this.toastr.error("Se debe asignar almenos una persona.")
            return false;
        }

        let today = new Date();
        let activityDate = this.SetTimeToDate(formulario.fecha_actividad, formulario.hora_actividad);

        if (activityDate < today) {
            this.toastr.error("La fecha de la actividad debe estar en el futuro.")
            return false;
        }

        return true;
    }

    ActividadEsMenorA30Minutos(minutesHoraInicial: number, minutesHoraFinal: number): Boolean {
        return (minutesHoraFinal - minutesHoraInicial) < 30;
    }

    SetTimeToDate(dateToSet: Date, time: string): Date {
        let durationElements = time.toString().split(':');

        let hours = Number(durationElements[0]);
        let minutes = Number(durationElements[1]);
        dateToSet.setHours(hours);
        dateToSet.setMinutes(minutes);

        return dateToSet;
    }

    recargar() {
        this.modal.dismissAll();
        this.loadMainData();
    }
}
