import { Component, OnInit } from '@angular/core';
import { BannerService } from '../../../core/services/banner.service';
import { BannerPorRolService } from '../../../core/services/banner-por-rol.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioService } from '../../../core/services/usuario.service';

@Component({
  selector: 'app-banner-rol',
  templateUrl: './banner-rol.component.html',
  styles: [
  ]
})
export class BannerRolComponent implements OnInit {
  RolListado;
  BannerRol;
  Banner;

  RolLista;
  RolListaFiltroTemp;

  //pBanner;
  SelBanners;
  TotBanners;
  TipoUsuario;
  Perfil;
  Rol;

  _usuario_tipoId;
  _perfilId
  _rolId

  forma: FormGroup;
  filtros: FormGroup;
  model;
  mf;

  butDisabled= '';

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,
    private bannerporrolservice:BannerPorRolService,
    private bannerservice:BannerService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) {
    if(!this.usuarioService.int()) this.router.navigate(['/portal']);
    this.iniciarForm();
  }

  ngOnInit(): void {

    //Llenamos filtros y combos
    this.fillFiltros();
    /*
    this.selTipoUsuario();
    this.selPerfil();
    this.selRol();
    */
    //Llenamos lista de Roles
    //this.RolListado = this.usuarioService.getRolListado();
    this.addRolListado();

    //Agregamos objetos Checkbox
    this.addChecks();
  }

  iniciarForm() {
    this.forma = this.fb.group({
      /* descripcion: ['', [Validators.required]], */
      usuario_tipoId: [0],
      perfilId: [0],
      rolId: [0],
      banner_seleccionado: [''],
      activo: ['S', [Validators.required]]
    })
    this.model = this.forma;

    //Filtro
    this.filtros = this.fb.group({
      usuario_tipoId: 0,
      perfilId: 0,
      rolId: 0
    });
    this.mf = this.filtros;

  }

  addRolListado(){
    //this.RolListado = this.usuarioService.getRolListado();
    this.spinner.show();
    this.usuarioService.getRolListado().toPromise()
    //this.bannerservice.bannerList().toPromise()
    .then(data => {
      this.spinner.hide();
      this.RolLista = data;
      //Asignamos el universo de items al listado, mediante una entidad de filtro
      this.RolListado = this.RolLista;
    })

  }

  nuevo(content) {
    this.iniciarForm();
    this.model.activo = 'S';
    this.modal.open(content,{size:'xl'});
  }

  editar(content, modelo) {
    //Cargamos modelo
    this.model = modelo;
    //Llenamos checks
    this.setChecks();
    this.modal.open(content,{size:'xl'});
  }

  guardar() {
    //console.log(this.getChecks())
    //let chks = this.getChecks();
    this.forma.controls["banner_seleccionado"].setValue(this.getChecks());
    //console.log(this.forma.value);
    //return false;
    if(this.forma.controls["rolId"].value == undefined) {
      return false;
    }
    else {
      this.bannerporrolservice.updData(this.forma.controls["rolId"].value, this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {console.log(error);});
    }
  }

  recargar(){
    this.modal.dismissAll();
    this.RolListado = this.RolLista; //this.usuarioService.getRolListado();
    // Habilitamos selects
    this.butDisabled = '';
  }

  activo(items): any[] {
    return items.filter(i => i.activo.value === 'S');
  }

  fillFiltros(){
    this.TipoUsuario = this.usuarioService.getTipoUsuario();
    //this.Perfil = this.usuarioService.getPerfil();
    //this.Rol = this.usuarioService.getRol();
  }

  Perfiles(){
    this.mf.perfilId= 0;
    this.mf.rolId= 0;
    //console.log(this.model.usuario_tipoId);
    //this.Perfil = this.usuarioService.getPerfil();
    this.usuarioService.getPerfil()
    .toPromise()
      .then(data => {
        this.Perfil = data;
        //alert(this.mf.usuario_tipoId);
        this.Perfil = this.Perfil.filter(x => x.usuario_tipoId === Number(this.mf.usuario_tipoId));
        this.filtrar();
      })
  }

  Roles(){
     this.mf.rolId= 0;
    this.usuarioService.getRol()
    .toPromise()
      .then(data => {
        this.Rol = data;
        this.Rol = this.Rol.filter(x => x.perfilId === Number(this.mf.perfilId));
        this.filtrar();
      })
  }

  filtrar(){

    //Establecemos los elementos totales de la lista
    this.RolListaFiltroTemp = this.RolLista;

    //alert(this.mf.actividadId);
    if(this.mf.usuario_tipoId!=0 && this.mf.usuario_tipoId!==undefined){
      this.RolListaFiltroTemp = this.RolListaFiltroTemp
      .filter(x => x.usuario_tipoId === Number(this.mf.usuario_tipoId));
    }

    if(this.mf.perfilId!=0 && this.mf.perfilId!==undefined){
      this.RolListaFiltroTemp = this.RolListaFiltroTemp
      .filter(x => x.perfilId === Number(this.mf.perfilId));
    }

    if(this.mf.rolId!=0 && this.mf.rolId!==undefined){
      this.RolListaFiltroTemp = this.RolListaFiltroTemp
      .filter(x => x.rolId === Number(this.mf.rolId));
    }

    this.RolListado = this.RolListaFiltroTemp;
  }


/*
  selTipoUsuario(){
    //console.log(this.model.usuario_tipoId);
    this.TipoUsuario = this.usuarioService.getTipoUsuario();
  }

  selPerfil(){
    //console.log(this.model.usuario_tipoId);
    this.Perfil = this.usuarioService.getPerfil();
    //console.log(this.Perfil);
  }

  selRol(){
    //console.log(this.model.usuario_tipoId);
    this.Rol = this.usuarioService.getRol();
    //console.log(this.Perfil);
  }
*/
  addChecks() {
      //console.log("Todos")
      this.bannerservice.getData().toPromise()
      .then(data => {
        this.TotBanners = data;
        //console.log(this.TotBanners);
      })
    }

  setChecks() {
    this.bannerporrolservice.getRolBanner(this.model.rolId).toPromise()
    .then(data => {
      this.BannerRol = data;
      if(this.BannerRol.length==0){return false;}
      //console.log(this.BannerRol.length);
      this.SelBanners = this.BannerRol[0].banner_seleccionado.replace(/\s/g, '').split(",");
      var els = document.getElementsByName('opts');
      for (var i=0;i<els.length;i++){
        if(this.SelBanners.includes(String(els[i].id))){
          //els[i].checked = true;
          els[i]['checked'] = true;
        }else{
          //els[i].checked = false;
          els[i]['checked'] = false;
        }
      }

    })
  }

  getChecks(){
    var choices = [];
    var els = document.getElementsByName('opts');
    for (var i=0;i<els.length;i++){
      //if ( els[i].checked ) {
        if ( els[i]['checked'] ) {
        choices.push(els[i].id);
      }
    }
    return choices.toString();
  }
checks=false;
  allChecks(){
    this.checks = this.checks? false: true;
    //if(this.checks)this.checks=false;
    //alert(this.checks);
    var choices = [];
    var els = document.getElementsByName('opts');
    if(this.checks){
      for (var i=0;i<els.length;i++){
        els[i]['checked'] = 'checked';
      }
    }
    else{
      for (var i=0;i<els.length;i++){
        els[i]['checked'] = '';
      }
    }

    //return choices.toString();
  }






}
