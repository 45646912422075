import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule, FaIconLibrary  } from '@fortawesome/angular-fontawesome'
import { faCircle,faSquare } from '@fortawesome/free-solid-svg-icons';
import { faCircle as farCircle,faSquare as farSquare } from '@fortawesome/free-regular-svg-icons';
import { faStackOverflow, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';
import { faTrashAlt,faCheckCircle,faTimesCircle,faEdit, faUserAlt } from '@fortawesome/free-solid-svg-icons';

import { RegistroComponent } from './registro/registro.component';
import { LoginComponent } from './login/login.component';
import { RecuperacionComponent } from './recuperacion/recuperacion.component';
import { RestaurarComponent } from './restaurar/restaurar.component';
import { ActualizarComponent } from './actualizar/actualizar.component';




@NgModule({
  declarations: [RegistroComponent, LoginComponent, RecuperacionComponent, RestaurarComponent, ActualizarComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    //NgbTypeahead,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [RegistroComponent, LoginComponent, RecuperacionComponent, RestaurarComponent, ActualizarComponent]
})
export class UsuarioModule {

  constructor(library: FaIconLibrary) {

    // Add an icon to the library for convenient access in other components
    // library.addIconPacks(fas);
    library.addIcons(faCircle,faSquare,farCircle,farSquare
      ,faStackOverflow,faGithub,faMedium,faTrashAlt,faCheckCircle,faTimesCircle,faEdit,faTrashAlt, faUserAlt );
    //library.addIcons(faStar, faPlus, faDownload, faFacebookF, faTwitch, faLinkedinIn, faDribbble, faChevronUp, faTwitter);
    //library.addIcons(faCircle,faSquare,farCircle,farSquare
    //      ,faStackOverflow,faGithub,faMedium);
  }

 }
