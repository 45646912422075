<section class="section iberotuespacio" id="especial">
    <div class="container-fluid flex-column">
      <div class="row align-items-center  min-vh-100">
          <div class="ibero-texto col-sm-12 col-md-5 offset-md-5 text-right">
            <p class="text-white display-1">IBERO</p>
             <p class="text-danger display-1">TU</p>
             <p class="text-espacio display-1">ESPACIO</p>
             <button type="button" class="btn btn-danger btn-lg" (click)="ir()">Registra a tu (s) grupo (s)</button>
          </div>
        </div>
        </div>
  </section>
