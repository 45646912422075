import { Component, OnInit, Input } from '@angular/core';
//import { ActividadesListadoService } from '../../../core/services/actividades-listado.service';
import { ActividadesService } from '../../../core/services/actividades.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from '../../../core/services/usuario.service';
import { Router, ActivatedRoute } from '@angular/router';
//import { HttpClient, HttpHeaders } from "@angular/common/http";
//import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-actividad',
  templateUrl: './actividad.component.html',
  styles: [
  ]
})
export class ActividadComponent implements OnInit {
  Actividad;
  Actividades;
  Filtro;
  ActividadGrupo
  ActividadSitio;
  User;

  forma: FormGroup;
  filtros: FormGroup;
  model;
  mf;
  constructor(
    public fb: FormBuilder,
    //private http: HttpClient,
    //private actividadservice: ActividadesListadoService,
    private router: Router,
    private usuarioService: UsuarioService,
    private actividadesService: ActividadesService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) {
    if(!this.usuarioService.int()) this.router.navigate(['/portal']);
    this.iniciarForm();
  }

  ngOnInit(): void {
    this.fillFiltros();
    this.addActividadListado();
  }

  iniciarForm() {
    this.forma = this.fb.group({
      actividadId: [0],
      clave: [''],
      nombre: ['', [Validators.required]],
      nombreaportal: [''],
      prom_suj: [0],
      permite_codigo: [0],
      mostrar_en_web: [0],
      //vigencia_inicial: [''],
      //vigencia_final: [''],
      ind_req_pago: [''],
      tmpo_min_sol: [0],
      actividad_sitioId: [0],
      actividad_grupoId: [0],
      modalidadId: [0],
      campo_seleccionado: [''],
      activo: ['S', [Validators.required]]
    });

    this.model = this.forma;

    //Filtro
    this.filtros = this.fb.group({
      actividad_grupoId: 0,
      activo: ''
    });
    this.mf = this.filtros;
  }

  addActividadListado(){
    this.spinner.show();
    this.User = this.usuarioService.getUser();
    this.actividadesService.getActividadUsuario(this.User.usuarioId).toPromise()
    .then(data => {
      this.spinner.hide();
      this.Actividad = data;
      //Asignamos el universo de items al listado, mediante una entidad de filtro
      this.Actividades = this.Actividad;
    })

  }

  fillFiltros(){
    this.ActividadSitio = this.actividadesService.getActividadSitio();
    this.ActividadGrupo = this.actividadesService.getActividadGrupo();
  }

  filtrar(){

    //Establecemos los elementos totales de la lista
    this.Filtro = this.Actividad;

    if(this.mf.actividad_grupoId!=0 && this.mf.actividad_grupoId!==undefined){
      this.Filtro = this.Filtro
      .filter(x => x.actividad_grupoId === Number(this.mf.actividad_grupoId));
    }

    if(this.mf.activo!=0 && this.mf.activo!==undefined){
      this.Filtro = this.Filtro
      .filter(x => x.activo === this.mf.activo);
    }

    this.Actividades = this.Filtro;
  }

  nuevo(content) {
    this.iniciarForm();
    this.model.activo = 'S';
    this.modal.open(content,{size:'xl'});
  }

  editar(content, modelo) {
    this.model = modelo;
    //console.log(this.model);
    this.modal.open(content,{size:'xl'});
  }

  guardar() {

    this.forma.controls["tmpo_min_sol"].setValue(Number(this.model.tmpo_min_sol));
    //console.log(this.forma.value);
    if(this.forma.controls["actividadId"].value == undefined) {
      this.actividadesService.addData(this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {console.log(error);});
    }
    else {
      this.actividadesService.updData(this.forma.controls["actividadId"].value, this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {console.log(error);});
    }
  }

  recargar(){
    this.modal.dismissAll();
    this.addActividadListado();
    //this.Actividad = this.actividadesService.getActividadList();
  }

}
