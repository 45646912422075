import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UsuarioService } from '../../../core/services/usuario.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { config } from '../../../core/services/config';
import { NgRecaptcha3Service } from 'ng-recaptcha3';


@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styles: [
  ]
})
export class RegistroComponent implements OnInit {
  forma: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  enabled = false;
  Colegios;
  model;
  features;
  constructor(
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private activatedRoute: ActivatedRoute,
    private recaptcha3: NgRecaptcha3Service,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.iniciarForm();
    this.getColegios();

   }


  iniciarForm() {
    /*
    this.forma = this.formBuilder.group({
      correo: ['', [Validators.required]],
      contrasenia: ['', [Validators.required]],
      nombre: ['', [Validators.required]],
      apaterno: ['', [Validators.required]],
      amaterno: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      extension: ['', [Validators.required]],
      colegioId: [0],
      usuarioId: [0],
      rolId: [0],
      perfilId: [0],
      usuario_tipoId: [0],
      activo: ['S', [Validators.required]]
    });
    */
   this.forma = this.formBuilder.group({
    nombre: ['', [Validators.required, Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)]],
    apaterno: ['', [Validators.required, Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)]],
    amaterno: ['', Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)],
    correo: ['', [Validators.required, Validators.email]],
    telefono: ['', [Validators.required, Validators.pattern("^[0-9]*$"),
    Validators.minLength(10), Validators.maxLength(10)]],
    extension: ['', [Validators.pattern("^[0-9]*$"),Validators.minLength(1), Validators.maxLength(5)]],
    contrasenia: ['', Validators.required],
    confirmacontrasenia: ['', Validators.required],
    colegioId: [0, [Validators.required]],
    usuarioId: [0],
    rolId: [0],
    perfilId: [0],
    usuario_tipoId: [0],
    activo: ['N', [Validators.required]],
    otro_colegio_nombre: ['']
  },
  { validator: this.passwordConfirming }
  );
    this.model = this.forma;
  }

  /**********Validador de contraseñas iguales ***********/
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('contrasenia').value !== c.get('confirmacontrasenia').value) {
        return {invalid: true};
    }
  }

  ngOnInit(): void {

    if (config.clientKey != "") {
      //console.log(config);
      this.recaptcha3.init(config.clientKey).then(status => {
        // status: success/error
        // success - script is loaded and greaptcha is ready
        // error - script is not loaded
        //console.log(status)
        if (status == 'error') {
          this.toastr.error('No se cargó el script. Intente de nuevo más tarde.');
        }
      });
    }


    //this.spinner.show();
 
    //console.log(this.Colegios);
    /*
    this.registro_usuario = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellidoPaterno: ['', Validators.required],
      apellidoMaterno: ['', Validators.required],
      telefono: ['', Validators.required],
      extension: ['', Validators.required],
      correoelectronico: ['', Validators.required],
      contraseña: ['', Validators.required],
      confirmacontrasenia: ['', Validators.required],
      nombrecolegio: ['', Validators.required],
      calle: ['', Validators.required],
      no_ext: ['', Validators.required],
      no_int: ['', Validators.required],
      colonia: ['', Validators.required],
      estado: ['', Validators.required],
      municipio: ['', Validators.required],
      codigopostal: ['', Validators.required]

    });
    */

  }

  getColegios(){
    this.usuarioService.getColegioListado()
    .toPromise()
      .then(data => {
        this.features = data;
        //this.SubCategoria = this.SubCategoria.filter(x => x.tema_categoriaId === Number(this.model.tema_categoriaId));
        //this.filtra();
      })
  }

  guardar() {
    //console.log(this.forma.value);
    var objColegio = this.forma.controls["colegioId"].value;
    //Si seleciona uno de la lista
    if(typeof objColegio === 'object'){
      //El control de búsqueda asigna un objeto al colegioId
      //Extraemos el id del Colegio y reemplazamos el valor
      //var objColegio = this.forma.controls["colegioId"].value;
      this.forma.controls["colegioId"].setValue(Number(objColegio.colegioId));
    }
    //Si captura uno nuevo
    if(typeof objColegio === 'string'){
      this.forma.controls["colegioId"].setValue(0);
      this.forma.controls["otro_colegio_nombre"].setValue(objColegio);
    }

    //console.log(this.forma.value);

    //return false;

    //console.log(this.forma.value);
    //return false;
    this.spinner.show();
    this.usuarioService.addData(this.forma.value).subscribe(
      (res) => {
        this.spinner.hide();
        this.recargar();
      },
      (error) => {
        this.spinner.hide();
        if(error.error.result === 3)
        {
          this.toastr.error(error.error.message);
        }
        
        console.log(error);
      });
  }

  recargar() {
    //this.modal.dismissAll();
    //this.Usuario = this.usuarioService.getData();
    this.toastr.success('Se ha enviado tu solicitud de registro.');
    //alert("La solicitud ha sido enviada");
    this.router.navigate(['/']);
    //this.router.navigate(['./login']);
  }

  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  public modelo: any;

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    merge(this.focus$),
    merge(this.click$.pipe(filter(() => this.instance && !this.instance.isPopupOpen()))),
    map(term => (term === '' ? this.features
      : this.features.filter(v => v.nombre.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
  );

  formatter = (x: {nombre: string}) => x.nombre;

  /*
  searchUrl(url){
    if(url){
      window.open(url,'_blank');
    }
  }
  */

  public ngOnDestroy() {
    this.recaptcha3.destroy();
  }

}
