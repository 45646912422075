<!-- Cursos Section-->
<section class="section pef5d" *ngIf="this.cs()">
  <img src="/assets/img/sol_bg_cursos.svg" alt="" style="float: left; margin-left:-130px; width: 250px; padding-top: 80px; z-index:2; position: absolute; ">
    <div class="container d-flex align-items-center flex-column">
        <div class="row sol-titulo">
            <div class="col-sm-12 col-md-12 text-center pt-3">
                <h2 class="titulo-seccion-cursos text-secondary">
                    Cursos
                </h2>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12  text-secondary text-left texto-cursos pt-2">
                Conscientes de la importancia de una buena formación, ponemos a tu disposición cursos diseñados para fortalecer las
                habilidades, conocimientos y competencias de tu comunidad educativa.
            </div>
       </div>
        <div class="row pt-2">
            <div class="col-sm-12 col-md-5 text-secondary text-left link_cursos py-5">
                <p *ngFor="let item of arrBanners">
                    <a class="text-danger" href="{{ item.referencia }}" target="_blank">{{ item.descripcion }}</a>
                </p>
            </div>
            <div class="col-sm-12 col-md-7 ">
                <img class="seccion-avatar-01 animate__animated animate__backInUp img_cursos" src="/assets/img/imagen_cursos02.svg" alt="" />
            </div>
        </div>
    </div>
</section>
