import { NgModule } from '@angular/core';
//import { Routes, RouterModule } from '@angular/router';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { UsuarioComponent } from './modules/interno/usuario/usuario.component';
import { SolicitudComponent } from './modules/interno/solicitud/solicitud.component';
import { PortalComponent } from './modules/externo/portal/portal.component';
import { ActividadComponent } from './modules/interno/actividad/actividad.component';
import { ActividadRolComponent } from './modules/interno/actividad-rol/actividad-rol.component';
import { AgendaComponent } from './modules/interno/agenda/agenda.component';
import { AgendaListComponent } from './modules/interno/agenda-list/agenda-list.component';
import { ReporteComponent } from './modules/interno/reporte/reporte.component';
import { BannerComponent } from './modules/interno/banner/banner.component';
import { BannerRolComponent } from './modules/interno/banner-rol/banner-rol.component';
import { CategoriaComponent } from './modules/interno/categoria/categoria.component';
import { DiaInhabilComponent } from './modules/interno/dia-inhabil/dia-inhabil.component';
import { GradoComponent } from './modules/interno/grado/grado.component';
import { TemaComponent } from './modules/interno/tema/tema.component';
import { TransporteComponent } from './modules/interno/transporte/transporte.component';
import { CarritoComponent } from './modules/externo/carrito/carrito.component';
import { LoginComponent } from './modules/usuario/login/login.component';
import { ActividadFormComponent } from './modules/externo/actividad-form/actividad-form.component';
import { TemaFormComponent } from './modules/externo/tema-form/tema-form.component';
import { SolicitudFormComponent } from './modules/externo/solicitud-form/solicitud-form.component';
import { SolicitudStatusComponent } from './modules/externo/solicitud-status/solicitud-status.component';
import { NotificacionComponent } from './modules/interno/notificacion/notificacion.component';
import { SolicitudCampoComponent } from './modules/interno/solicitud-campo/solicitud-campo.component';
import { ColegioComponent } from './modules/interno/colegio/colegio.component';
import { SubcategoriaComponent } from './modules/interno/subcategoria/subcategoria.component';
import { RegistroComponent } from './modules/usuario/registro/registro.component';
import { RecuperacionComponent } from './modules/usuario/recuperacion/recuperacion.component';
import { RestaurarComponent } from './modules/usuario/restaurar/restaurar.component';
import { ActualizarComponent } from './modules/usuario/actualizar/actualizar.component';
import { ResponsableComponent } from './modules/interno/responsable/responsable.component';
import { PanelComponent } from './modules/interno/panel/panel.component';
import { ResponsableColegioComponent } from './modules/interno/responsable-colegio/responsable-colegio.component';


//const routes: Routes = [];
const routerOptions: ExtraOptions = {
  enableTracing: false,
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  //scrollOffset: [0, 64],
  relativeLinkResolution: 'legacy'
};

const routes: Routes = [
  {
    path: '', component: LoginComponent
  },
  /*{path: '404', component: NotFoundComponent},
  {path: '**', redirectTo: '/404'},*/
  {
    path: '*path', component: LoginComponent /*redirectTo: '/login'*/
  },
  /*
  {
    path: 'login', component: LoginComponent
  },
  */
  /* Usuario Externo */
  {
    path: 'registro', component: RegistroComponent
  },
  {
    path: 'recuperacion', component: RecuperacionComponent
  },
  {
    path: 'restaurar', component: RestaurarComponent
  },
  {
    path: 'portal', component: PortalComponent
  },
  {
    path: 'panel', component: PanelComponent
  },
  {
    path: 'actividad-form/:id', component: ActividadFormComponent
  },
  {
    path: 'tema-form', component: TemaFormComponent
  },
  {
    path: 'solicitud-form/:idta', component: SolicitudFormComponent
  },
  {
    path: 'solicitud-status', component: SolicitudStatusComponent
  },
  /* Usuario Interno */
  {
    path: 'actividad', component: ActividadComponent
  },
  {
    path: 'actividad-rol', component: ActividadRolComponent
  },
  {
    path: 'agenda', component: AgendaComponent
  },
  {
    path: 'agenda-list', component: AgendaListComponent
  },
  {
    path: 'reporte', component: ReporteComponent
  },
  {
    path: 'banner', component: BannerComponent
  },
  {
    path: 'banner-rol', component: BannerRolComponent
  },
  {
    path: 'categoria', component: CategoriaComponent
  },
  {
    path: 'subcategoria', component: SubcategoriaComponent
  },
  {
    path: 'dia-inhabil', component: DiaInhabilComponent
  },
  {
    path: 'grado', component: GradoComponent
  },
  {
    path: 'notificacion', component: NotificacionComponent
  },
  {
    path: 'solicitud', component: SolicitudComponent
  },
  {
    path: 'solicitud-campo', component: SolicitudCampoComponent
  },
  {
    path: 'tema', component: TemaComponent
  },
  {
    path: 'transporte', component: TransporteComponent
  },
  {
    path: 'usuario', component: UsuarioComponent
  },
  {
    path: 'responsable', component: ResponsableComponent
  },
  {
    path: 'carrito', component: CarritoComponent
  },
  {
    path: 'subcategoria', component: SubcategoriaComponent
  },
  {
    path: 'colegio', component: ColegioComponent
  },
  {
    path: 'actualizar/:id', component: ActualizarComponent
  },
  {
    path: 'responsable-colegio', component: ResponsableColegioComponent
  },
  /*
  { path: '', redirectTo: '/user/login', pathMatch: 'full' },

  {
    path: 'usuario', component: InternoModule.UserComponent,
    children: [
      //{ path: 'registration', component: RegistrationComponent },
      { path: 'login', component: LoginComponent },
      //  { path: 'list', component: ListUserComponent }
    ]
  }
  , {
    path: 'home', component: HomeComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'registro', component: RegisterUserComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'recupera', component: PasswordRecover, //canActivate: [AuthGuard]
  },
  {
    path: 'listRequest', component: ListComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'newRequest', component: CreateComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'updateRequest', component: UpdateComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'listNotifications', component: ListNotificationsComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'newNotifications', component: CreateNotificationsComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'updateNotifications', component: UpdateNotificationsComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'activities', component: ActivitiesComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'categories', component: CategoriesComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'themes', component: ThemesComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'transport', component:TransportComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'grades', component:GradesComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'activitiesbyrole', component:ActivitiesbyroleComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'bannersbyrole', component:BannersbyroleComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'banners', component:BannersComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'requestfields', component:RequestfieldsComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'userlist', component:UserListComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'activationuser', component:ActivationUserComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'updateschool', component:UpdateSchoolComponent, //canActivate: [AuthGuard]
  },
  {
    path: 'updateuser', component:UpdateUserComponent, //canActivate: [AuthGuard]
  }
*/




  //{ path: '', component: HomeComponent, canActivate: [AuthGuard] }
  //, { path: 'home', component: HomeComponent, canActivate: [AuthGuard] }
  // ,{path:'counter',component:CounterComponent,canActivate:[AuthGuard]}
  // ,{path:'fetch-data',component:FetchDataComponent,canActivate:[AuthGuard]}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
