<!--
<app-solicitud-form></app-solicitud-form>
<app-tema-form></app-tema-form>
<app-actividad-form></app-actividad-form>
-->
<div mnFullpage>
  <div class="section welcome-section fp-section fp-table">
    <div class="fp-tableCell">
      <app-inicio></app-inicio>
    </div>
  </div>
  <div class="section welcome-section fp-section fp-table">
    <div class="fp-tableCell">
      <app-ibero></app-ibero>
    </div>
  </div>
  <div class="section welcome-section fp-section fp-table">
    <div class="fp-tableCell">
      <app-actividad-grupo></app-actividad-grupo>
    </div>
  </div>
  <div id="especial"></div>
  <div class="section welcome-section fp-section fp-table">
    <div class="fp-tableCell">
      <app-cursos></app-cursos>
    </div>
  </div>
  <div class="section welcome-section fp-section fp-table">
    <div class="fp-tableCell">
      <app-concursos></app-concursos>
    </div>
  </div>
  <div class="section welcome-section fp-section fp-table">
    <div class="fp-tableCell">
      <app-torneos></app-torneos>
    </div>
  </div>
  <div class="section welcome-section fp-section fp-table">
    <div class="fp-tableCell">
      <app-becas></app-becas>
    </div>
  </div>
  <div class="section welcome-section fp-section fp-table">
    <div class="fp-tableCell">
      <app-orientadores></app-orientadores>
    </div>
  </div>
  <!--div class="section welcome-section fp-section fp-table" *ngIf="bEspacio"-->

  <div class="section welcome-section fp-section fp-table" *ngIf="bEspecial">
    <div class="fp-tableCell">
      <app-especiales></app-especiales>
    </div>
  </div>
<!--
  <div class="section welcome-section fp-section fp-table" *ngIf="bEspacio">
    <div class="fp-tableCell">
      <app-tu-espacio></app-tu-espacio>
    </div>
  </div>
  <div class="section welcome-section fp-section fp-table" *ngIf="bExplora">
    <div class="fp-tableCell">
      <app-x-plora></app-x-plora>
    </div>
  </div>
  <div class="section welcome-section fp-section fp-table" *ngIf="bVisita">
    <div class="fp-tableCell">
      <app-visita-guiada></app-visita-guiada>
    </div>
  </div>
  -->
  <div class="section welcome-section fp-section fp-table">
    <div class="fp-tableCell">
      <app-planes></app-planes>
    </div>
  </div>
</div>
<!-- <app-inicio></app-inicio>
<app-ibero></app-ibero>
<app-actividad-grupo></app-actividad-grupo>
<app-cursos></app-cursos>
<app-concursos></app-concursos>
<app-torneos></app-torneos>
<app-becas></app-becas>
<app-orientadores></app-orientadores>
<app-tu-espacio></app-tu-espacio>
<app-x-plora></app-x-plora>
<app-visita-guiada></app-visita-guiada>
<app-planes></app-planes> -->

<!--
<app-actividades></app-actividades>
-->
