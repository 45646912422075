<!--Contenedor-->
<div class="container align-items-center flex-column">

    <!--Encabezado-->
    <div class="text-center">
      <h4 class="title-section">Catálogo - Responsables</h4>
    </div>
  
    <!--Filtros
    <form  [formGroup]="filtros" >
      <div class="row">
  
        <div class="col-lg-3">
          <label>Tipo de usuario</label>
          <select class="form-control"
          formControlName="usuario_tipoId" [(ngModel)]="mf.usuario_tipoId" (change)="fPerfiles()">
            <option value="">--Seleccionar--</option>
            <option *ngFor="let item of TipoUsuario | async"
              value="{{ item.usuario_tipoId }}" >
              {{ item.descripcion }}
              </option>
          </select>
        </div>
        <div class="col-lg-3">
          <label>Perfil</label>
          <select class="form-control"
          formControlName="perfilId" [(ngModel)]="mf.perfilId" (change)="fRoles()">
            <option value="">--Seleccionar--</option>
            <option *ngFor="let item of fPerfil"
              value="{{ item.perfilId }}" >
              {{ item.descripcion }}
              </option>
          </select>
        </div>
  
        <div class="col-lg-3">
          <label>Rol</label>
          <select class="form-control"
          formControlName="rolId" [(ngModel)]="mf.rolId" (change)="filtrar()">
            <option value="">--Seleccionar--</option>
            <option *ngFor="let item of fRol"
              value="{{ item.rolId }}" >
              {{ item.descripcion }}
              </option>
          </select>
        </div>
  
        <div class="col-lg-3">
          <label>Estado</label>
          <select class="form-control"
            formControlName="activo" [(ngModel)]="mf.activo" (change)="filtrar()">
            <option value="">--Seleccionar--</option>
            <option value="S">Activo</option>
            <option value="N">Inactivo</option>
          </select>
        </div>
  
      </div>
      </form>
  -->
    <!--Listado-->
    <div class="row justify-content-between py-2">
      <div class="col-md-4 col-sm-12">
          <h5 class="title-subsection">Datos del usuario</h5>
      </div>
      <div class="col-md-4 col-sm-12 text-right">
          <button class="btn btn-primary btn_sac" (click)="nuevo(content)">Agregar</button>
      </div>
    </div>
  
    <table class="table table-striped text-center table-fixed">
      <thead>
        <tr>
          <th scope="col" class="encabezado orden" >#</th>
          <th scope="col" class="encabezado orden">Nombre</th>
          <th scope="col" class="encabezado orden">Apellido Paterno</th>
          <th scope="col" class="encabezado orden">Apellido Materno</th>
          <th scope="col" class="encabezado orden">Correo</th>
          <th scope="col" class="encabezado orden">Estatus</th>
          <th scope="col" class="encabezado orden">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr #item *ngFor="let item of ResponsableLista; let i = index">
          <td scope="row">{{ i+1 }}</td>
          <!--td class="w-25">{{ item.nombreCompleto }}</td-->
          <td class="w-25">{{ item.nombre }}</td>
          <td class="w-25">{{ item.apaterno }}</td>
          <td class="w-25">{{ item.amaterno }}</td>
          <td class="w-25">{{ item.correo }}</td>
          <td>
            {{ item.activo=='S'?"Activo":"Inactivo" }}
          </td>
          <td>
            <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar">
              <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot class="tfoot-ibero text-center">
        <tr>
          <td colspan="8">{{ (ResponsableLista)?.length }} Registros</td>
        </tr>
      </tfoot>
    </table>
    </div>
  
    <!--Formulario-->
  
    <ng-template #content let-modal>
      <div class="modal-hidden">
        <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
  
      <div class="modal-body">
        <div class="card border-dark mb-0">
          <div class="card-header text-white text-center bg-dark">Datos del responsable</div>
          <div class="card-body text-dark">
  
            <form [formGroup]="forma">
              <div class="row text-dark">
                <input class="form-control" type="hidden" formControlName="colegio_responsableId"
                       [(ngModel)]="model.colegio_responsableId">
                <input class="form-control" type="hidden" formControlName="colegioId"
                       [(ngModel)]="model.colegioId">
              </div>
  
                <!--
              <div class="row text-dark">
                
                <div class="form-group col-lg-4">
                  <label for="usuario_tipoId"><span class="text-danger">*</span>Tipo de usuario</label>
                  <select class="form-control" formControlName="usuario_tipoId" (change)="Perfiles()"
                          [(ngModel)]="model.usuario_tipoId" [attr.disabled]="butDisabled">
                    <option value="">--Seleccionar--</option>
                    <option *ngFor="let item of TipoUsuario | async"
                            [ngValue]="item.usuario_tipoId">
                      {{ item.descripcion }}
                    </option>
                  </select>
                  <div class="text-danger" *ngIf="forma.controls['usuario_tipoId'].touched && forma.controls['usuario_tipoId'].errors?.required">El tipo de usuario es requerido</div>
                </div>
                
                <div class="form-group col-lg-4">
                  <label for="perfil"><span class="text-danger">*</span>Perfil</label>
                  <select class="form-control" formControlName="perfilId" (change)="Roles()"
                          [(ngModel)]="model.perfilId" [attr.disabled]="butDisabled">
                    <option value="">--Seleccionar--</option>
                    <option *ngFor="let item of Perfil"
                            [ngValue]="item.perfilId">
                      {{ item.descripcion }}
                    </option>
                  </select>
                  <div class="text-danger" *ngIf="forma.controls['perfilId'].touched && forma.controls['perfilId'].errors?.required">El perfil es requerido</div>
                </div>
                <div class="form-group col-lg-4">
                  <label for="rol"><span class="text-danger">*</span>Rol</label>
                  <select class="form-control" formControlName="rolId"
                          [(ngModel)]="model.rolId" [attr.disabled]="butDisabled">
                    <option value="">--Seleccionar--</option>
                    <option *ngFor="let item of Rol"
                            [ngValue]="item.rolId">
                      {{ item.descripcion }}
                    </option>
                  </select>
                  <div class="text-danger" *ngIf="forma.controls['rolId'].touched && forma.controls['rolId'].errors?.required">El rol es requerido</div>
                </div>
              </div>
                -->
            <div class="row text-dark">
                <div class="form-group col-lg-12">
                    <label for="colegioId">Colegio</label>
                    <select class="form-control"
                            formControlName="colegioId"
                            [(ngModel)]="model.colegioId">
                    <option value="">--Seleccionar--</option>
                    <option *ngFor="let item of ColegioLista"
                            [ngValue]="item.colegioId">
                        {{ item.colegioNombre }}
                    </option>
                    </select>
                </div>   
            </div>             
              <div class="row text-dark">
                <div class="form-group col-lg-12">
                  <label for="correo"><span class="text-danger">*</span>Correo electrónico:</label>
                  <input class="form-control" type="text" formControlName="correo"
                         [(ngModel)]="model.correo">
                  <div class="text-danger" *ngIf="forma.controls['correo'].touched && forma.controls['correo'].errors?.required">El correo es requerido</div>
                  <div class="text-danger" *ngIf="forma.controls['correo'].touched && forma.controls['correo'].errors?.email">Ingrese un correo válido</div>
                </div>
              </div>
              <!--div class="row text-dark">
                <div class="form-group col-lg-12">
                  <label for="contrasenia"><span class="text-danger">*</span>Contraseña:</label>
                  <input class="form-control" type="password" formControlName="contrasenia"
                         [(ngModel)]="model.contrasenia">
                  <div class="text-danger" *ngIf="forma.controls['contrasenia'].touched && forma.controls['contrasenia'].errors?.required">La contraseña es requerida</div>
                </div>
              </div-->
  
               <!--div class="form-group col-lg-6" *ngIf="model.usuarioId > 0">
                 <label for="colegioNombre">Colegio:</label>
                 <input class="form-control" type="text" formControlName="colegioNombre"
                        [(ngModel)]="model.colegioNombre">
               </div-->
  
                <!--div class="form-group col-lg-6">
                  <label for="colegioId"><span class="text-danger">*</span>Colegio:</label>
                  <select class="form-control" formControlName="colegioId"
                          [(ngModel)]="model.colegioId">
                    <option value="">--Seleccionar--</option>
                    <option *ngFor="let item of Colegio"
                            [ngValue]="item.colegioId">
                      {{ item.nombre }}
                    </option>
                  </select>
                  <div class="text-danger" *ngIf="forma.controls['colegioId'].touched && forma.controls['colegioId'].errors?.required">El colegio es requerido</div>
                </div-->
                <!--div class="form-group col-lg-6" *ngIf="model.usuarioId > 0">
                  <label for="colegioNombre">Colegio:</label>
                  <input class="form-control" type="text" formControlName="colegioNombre"
                         [(ngModel)]="model.colegioNombre">
                </div-->
                <!--
                <div class="row text-dark" *ngIf="model.usuario_tipoId == 2">
                  <div class="form-group col-lg-6">
                     <label for="typeahead-basic">Colegios</label>
  
                     <ng-template #rt let-r="result" let-t="term">
                       {{ r.nombre}}
                     </ng-template>
  
                     <input
                       class="form-control"
                       type="search"
                       id="colegioId"
                       name="colegioId"
                       formControlName="colegioId"
                       [(ngModel)]="model.colegioId"
                       #instance="ngbTypeahead"
                       placeholder="Búsqueda"
                       aria-label="Search"
                       [ngbTypeahead]="search"
                       [resultTemplate]="rt"
                       [inputFormatter]="formatter"
                       (focus)="focus$.next($event.target.value)"
                       (click)="click$.next($event.target.value)"
                       >
                       <div class="text-danger" *ngIf="forma.controls['colegioId'].touched && forma.controls['colegioId'].errors?.required">El colegio es requerido</div>
                  </div>  
              </div>
            -->
              <div class="row text-dark">
                <div class="form-group col-lg-6">
                  <label for="nombre"><span class="text-danger">*</span>Nombre:</label>
                  <input class="form-control" type="text" formControlName="nombre"
                         [(ngModel)]="model.nombre">
                  <div class="text-danger" *ngIf="forma.controls['nombre'].touched && forma.controls['nombre'].errors?.required">El nombre es requerido</div>
                  <div class="text-danger" *ngIf="forma.controls['nombre'].touched && forma.controls['nombre'].errors?.pattern">Ingresa solo letras</div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="apaterno"><span class="text-danger">*</span>Apellido Paterno:</label>
                  <input class="form-control" type="text" formControlName="apaterno"
                         [(ngModel)]="model.apaterno">
                  <div class="text-danger" *ngIf="forma.controls['apaterno'].touched && forma.controls['apaterno'].errors?.required">El apellido paterno es requerido</div>
                  <div class="text-danger" *ngIf="forma.controls['apaterno'].touched && forma.controls['apaterno'].errors?.pattern">Ingresa solo letras</div>
                </div>
              </div>
              <div class="row text-dark">
                <div class="form-group col-lg-6">
                  <label for="amaterno">Apellido Materno:</label>
                  <input class="form-control" type="text" formControlName="amaterno"
                         [(ngModel)]="model.amaterno">
                  <div class="text-danger" *ngIf="forma.controls['amaterno'].touched && forma.controls['amaterno'].errors?.pattern">Ingresa solo letras</div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="telefono"><span class="text-danger">*</span>Teléfono:</label>
                  <input class="form-control" type="text" formControlName="telefono" [(ngModel)]="model.telefono" maxlength="10">
                  <div class="text-danger" *ngIf="forma.controls['telefono'].touched && forma.controls['telefono'].errors?.required">El teléfono es requerido</div>
                  <div class="text-danger" *ngIf="forma.controls['telefono'].touched && forma.controls['telefono'].errors?.pattern">Ingrese solo números</div>
                  <div class="text-danger" *ngIf="forma.controls['telefono'].touched && forma.controls['telefono'].errors?.minlength || forma.controls['telefono'].errors?.maxlength">El teléfono debe contener 10 dígitos</div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="extension">Extensión:</label>
                  <input class="form-control" type="text" formControlName="extension" [(ngModel)]="model.extension" maxlength="5">
                  <div class="text-danger" *ngIf="forma.controls['extension'].touched && forma.controls['extension'].errors?.pattern">Ingrese solo números</div>
                  <div class="text-danger" *ngIf="forma.controls['extension'].touched && forma.controls['extension'].errors?.minlength">La extensión debe contener máximo 5 dígitos</div>
                  <div class="text-danger" *ngIf="forma.controls['extension'].touched && forma.controls['extension'].errors?.maxlength">La extensión debe contener máximo 5 dígitos</div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="activo"><span class="text-danger">*</span>Estatus</label>
                  <select class="form-control" formControlName="activo"
                          [(ngModel)]="model.activo">
                    <option value="">--Seleccionar--</option>
                    <option value="S">Activo</option>
                    <option value="N">Inactivo</option>
                  </select>
                  <div class="text-danger" *ngIf="forma.controls['activo'].touched && forma.controls['activo'].errors?.required">El estatus es requerido</div>
                </div>
              </div>
              <div class="row text-dark pt-3">
                <div class="col-lg-12">
                  <div class="buttonSelect text-center">
                    <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                    <button class="btn btn-danger" (click)="guardar()" [disabled]="forma.invalid">Guardar</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ng-template>
  