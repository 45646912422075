import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MnFullpageModule } from 'ngx-fullpage';

import { FontAwesomeModule, FaIconLibrary  } from '@fortawesome/angular-fontawesome'
import { faCircle,faSquare } from '@fortawesome/free-solid-svg-icons';
import { faCircle as farCircle,faSquare as farSquare } from '@fortawesome/free-regular-svg-icons';
import { faStackOverflow, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';
import { faTrashAlt,faCheckCircle,faTimesCircle,faEdit, faFileAlt, faChalkboardTeacher, faPaperPlane, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

import { IberoComponent } from './ibero/ibero.component';
import { ActividadesComponent } from './actividades/actividades.component';
import { BecasComponent } from './becas/becas.component';
import { ConcursosComponent } from './concursos/concursos.component';
import { CursosComponent } from './cursos/cursos.component';
import { EspecialesComponent } from './especiales/especiales.component';
import { OrientadoresComponent } from './orientadores/orientadores.component';
import { PlanesComponent } from './planes/planes.component';
import { TemasComponent } from './temas/temas.component';
import { TorneosComponent } from './torneos/torneos.component';
import { InicioComponent } from './inicio/inicio.component';
import { ActividadFormComponent } from './actividad-form/actividad-form.component';
import { TemaFormComponent } from './tema-form/tema-form.component';
import { SolicitudFormComponent } from './solicitud-form/solicitud-form.component';
import { PortalComponent } from './portal/portal.component';
import { CarritoComponent } from './carrito/carrito.component';
import { ActividadGrupoComponent } from './actividad-grupo/actividad-grupo.component';
import { SolicitudStatusComponent } from './solicitud-status/solicitud-status.component';
import { TuEspacioComponent } from './tu-espacio/tu-espacio.component';
import { XPloraComponent } from './x-plora/x-plora.component';
import { VisitaGuiadaComponent } from './visita-guiada/visita-guiada.component';



@NgModule({
  declarations: [IberoComponent, ActividadesComponent, BecasComponent, ConcursosComponent, CursosComponent, EspecialesComponent, OrientadoresComponent, PlanesComponent, TemasComponent, TorneosComponent, InicioComponent, ActividadFormComponent, TemaFormComponent, SolicitudFormComponent, PortalComponent, CarritoComponent, ActividadGrupoComponent, SolicitudStatusComponent, TuEspacioComponent, XPloraComponent, VisitaGuiadaComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    MnFullpageModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule
  ],
  exports: [IberoComponent, ActividadesComponent, BecasComponent, ConcursosComponent, CursosComponent, EspecialesComponent, OrientadoresComponent, PlanesComponent, TemasComponent, TorneosComponent, InicioComponent, ActividadFormComponent, TemaFormComponent, SolicitudFormComponent, PortalComponent, CarritoComponent, ActividadGrupoComponent, SolicitudStatusComponent, TuEspacioComponent, XPloraComponent, VisitaGuiadaComponent],
  
})
export class ExternoModule { 

  constructor(library: FaIconLibrary) {

    library.addIcons(faCircle,faSquare,farCircle,farSquare
      ,faStackOverflow,faGithub,faMedium,faTrashAlt,faCheckCircle,faTimesCircle,faEdit,faTrashAlt,faFileAlt, faChalkboardTeacher, faPaperPlane);

  }   

}
