<!-- Concursos Section-->
<section class="section pee74" *ngIf="this.cs()">
  <img src="/assets/img/sol_bg_concursos.svg" alt="" style="float: left; margin-left:-100px; width: 230px; padding-top: 80px; z-index:2; position: absolute; ">
    <div class="container align-items-center d-flex flex-column">
      <div class="row sol-titulo-blanco ">
        <div class="col-sm-12 col-md-12 text-center pt-3">
            <h1 class="titulo-seccion-concursos text-secondary">
                Concursos
            </h1>
        </div>
    </div>
      <div class="container">
        <div class="row pt-5">
          <div class="col-md-12 text-secondary text-left texto-concursos">
              Con el objetivo de apoyar el desarrollo de diferentes habilidades, invitar a la reflexión e impulsar la creatividad de tus
              estudiantes, te invitamos a consultar la oferta de nuestros concursos, mismos que están relacionados con diversos
              temas del saber.
          </div>
      </div>
      </div>

      <div class="row text-center pt-3 align-items-center">
        <div class="col-sm-12 col-md-7 text-secondary text-center link_cursos ">
          <img  class="img_concursos seccion-avatar-01 animate__animated animate__backInLeft" src="/assets/img/Concursos.svg" alt="" />
        </div>
        <div class="col-sm-12 col-md-5 text-left link_concursos">
          <p *ngFor="let item of arrBanners">
              <a class="text-white" href="{{ item.referencia }}" target="_blank">{{ item.descripcion }}</a>
          </p>
        </div>
    </div>
      </div>
  </section>
