import { ToastrService } from 'ngx-toastr';
import { AuthService } from './../../../MicrosoftGraphServices/auth.service';
import { GraphService } from './../../../MicrosoftGraphServices/graph.service';
import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { DiaInhabilService } from '../../../core/services/dia-inhabil.service';
import { AgendaService } from '../../../core/services/agenda.service';
import { UsuarioService } from '../../../core/services/usuario.service';
import { Router } from '@angular/router';
import { NgbDate, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { FullCalendarComponent, CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking
import esLocale from '@fullcalendar/core/locales/es-us';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
    selector: 'app-agenda',
    templateUrl: './agenda.component.html',
    styles: [
    ]
})
export class AgendaComponent implements OnInit {
    DiaInhabiles;
    DiaInhabil;
    Agenda;
    Usuarios;
    forma: FormGroup;
    model;
    participantes: Array<string> = [];

    disabledDates: NgbDateStruct[] = [];
    participanteSeleccionado: string = "";

    dropdownList = [];

    dropdownSettings: IDropdownSettings = {
        singleSelection: false,
        selectAllText: 'Todos',
        unSelectAllText: 'Ninguno',
        idField: 'usuarioId',
        textField: 'item_text',
        allowSearchFilter: true,
        searchPlaceholderText: 'Buscar'
    };

    @ViewChild('content', { read: TemplateRef }) modalForm: TemplateRef<any>;
    @ViewChild('calendar') calendarComponent: FullCalendarComponent;

    calendarOptions: CalendarOptions = {
        locale: esLocale,
        initialView: 'timeGridWeek',
        customButtons: {
            lista: {
                icon: 'fa fa-list',
                click: this.goToList.bind(this)
            }
        },
        headerToolbar: {
            start: 'title',
            center: 'lista prev,today,next',
            end: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        slotLabelFormat: [
            {
                hour: '2-digit',
                minute: '2-digit',
                hour12:false
            }
            ],
        businessHours: 
            {
              daysOfWeek: [ 1, 2, 3, 4, 5 ], // Monday, Tuesday, Wednesday
              startTime: '07:00', // 6am
              endTime: '18:00' // 6pm
            },
        eventDisplay: 'auto',
        displayEventTime: false,
        datesSet: this.handleMonthChange.bind(this),
    };

    constructor(
        public fb: FormBuilder,
        private router: Router,
        private usuarioService: UsuarioService,
        private http: HttpClient,
        private diaInhabilService: DiaInhabilService,
        private agendaService: AgendaService,
        private spinner: NgxSpinnerService,
        public modal: NgbModal,
        public graphService: GraphService,
        public authService: AuthService,
        public toastr: ToastrService
    ) {
        if(!this.usuarioService.int()) this.router.navigate(['/portal']);
        this.iniciarForm();
    }

    //dateRef;
    async handleMonthChange(currDate) {
        this.spinner.show();
        var firstDay = currDate.startStr.slice(0, 19); //this.TransformDateToMicrosoftGraphDate(new Date(date.getFullYear(), date.getMonth()-1, 1));
        var lastDay = currDate.endStr.slice(0, 19); //this.TransformDateToMicrosoftGraphDate(new Date(date.getFullYear(), date.getMonth(), 31));
        await this.graphService.getCalendarView(firstDay, lastDay, "America/Mexico_City").then(events => {
            events = events.filter(x => !x.isCancelled);
            //console.log(events);
            const calendarApi = this.calendarComponent.getApi();
            calendarApi.removeAllEventSources();
            calendarApi.removeAllEvents();
            events.forEach((v) => {
                //console.log(v);
                var att = "";
                var len = 0;
                var alen = 0;
                var ename = "";
                v.attendees.forEach(a => {att += this.getAtts(a.emailAddress.name);});
                //console.log(att);
                calendarApi.addEvent({
                    id: v.id,
                    start: this.TransformStringToDate(v.start.dateTime),
                    end: this.TransformStringToDate(v.end.dateTime),
                    title: v.subject==null?"(Sin asunto)":v.subject + "\n" + att.slice(0, -1),
                    display: 'block',
                    backgroundColor: '#EF4044'
                });

                v.attendees.forEach((v) => {
                    let participant = v.emailAddress.name + "(" + v.emailAddress.address + ")";
                    this.participantes.push(participant);
                });


            });

            this.participantes = [...new Set(this.participantes)];//unique values


            this.spinner.hide();
        }, (error) => { console.log(error); this.spinner.hide(); });

        
    }
    
    getAtts(ename){
        var len = 0;
        var alen = 0;
        var att = "";
        alen=ename.split(" ").length;
        if(alen==1){att = ename;}
        if(alen>1){
            att = Array.from(ename.split(" ")[1])[0].toString() + Array.from(ename.split(" ")[0])[0].toString() + ",";
        }
        return att;				
    }

    ngOnInit(): void {
        //this.loadMainData(new Date());
        this.loadOneTime();
    }

    MGEvent_AskUserToLogIn() {
        this.authService
            .signIn()
            .then(elements => {
                window.location.reload();
            }
            );

    }

    async FiltrarPorParticipanteSeleccionado() {
        if (!this.authService.graphClient) {
            this.toastr.error("Debe loggearse para ver actividades.");
            return;
        }

        this.spinner.show();
        var date = new Date();
        var firstDay = this.TransformDateToMicrosoftGraphDate(new Date(date.getFullYear(), date.getMonth(), 1));
        var lastDay = this.TransformDateToMicrosoftGraphDate(new Date(date.getFullYear(), 12, 31));



        await this.graphService.getCalendarView(firstDay, lastDay, "America/Mexico_City").then(events => {
            events = events.filter(x => !x.isCancelled);
            const calendarApi = this.calendarComponent.getApi();
            calendarApi.removeAllEvents();
            console.log(calendarApi.getEvents().length);

            events.forEach((v) => {
                //console.log(v);
                console.log(this.participanteSeleccionado);
                if (this.participanteSeleccionado == "") {
                    console.log("Ver todos los eventos");
                    var att = "";
                    v.attendees.forEach(a => {att += this.getAtts(a.emailAddress.name);});
                    /*
                    v.attendees.forEach(a => {
                        att += Array.from(a.emailAddress.name.split(" ")[2])[0]  
                        + Array.from(a.emailAddress.name.split(" ")[0])[0] + ",";
                    });
                    */
                    calendarApi.addEvent({
                        id: v.id,
                        start: this.TransformStringToDate(v.start.dateTime),
                        end: this.TransformStringToDate(v.end.dateTime),
                        title: v.subject + "\n" + att.slice(0, -1),
                        display: 'block',
                        backgroundColor: '#EF4044'
                    });

                } else {
                    console.log("Evento filtrado");
                    console.log(v.attendees);
                    let participants = v.attendees.filter(x => (x.emailAddress.name + "(" + x.emailAddress.address + ")") == this.participanteSeleccionado);

                    if (participants.length > 0) {
                        var att = "";
                        v.attendees.forEach(a => {att += this.getAtts(a.emailAddress.name);});
                        /*
                        v.attendees.forEach(a => {
                            att += Array.from(a.emailAddress.name.split(" ")[2])[0]  
                            + Array.from(a.emailAddress.name.split(" ")[0])[0] + ",";
                        });
                        */
                        calendarApi.addEvent({
                            id: v.id,
                            start: this.TransformStringToDate(v.start.dateTime),
                            end: this.TransformStringToDate(v.end.dateTime),
                            title: v.subject + "\n" + att.slice(0, -1),
                            display: 'block',
                            backgroundColor: '#EF4044'
                        });
                    }


                }

            });




            this.spinner.hide();
        }, (error) => { console.log(error); this.spinner.hide(); });


    }

    iniciarForm() {
        this.forma = this.fb.group({
            agenda_actividadId: [0],
            nombre: ['', [Validators.required]],
            tipo: ['', [Validators.required]],
            fecha_actividad: [0, [Validators.required]],
            hora_actividad: [0, [Validators.required]],
            hora_fin: [0, [Validators.required]],
            duracion: [0, [Validators.required]],
            invitados: [[]],
            usuario_registroId: [0],
            usuario_modificaId: [0]
        })
        this.model = this.forma;
    }

    async loadMainData(date) {
        if (!this.authService.graphClient) {
            this.toastr.error("Debe loggearse para ver actividades.");
            return;
        }

        this.spinner.show();
        //var date = new Date();
        var firstDay = this.TransformDateToMicrosoftGraphDate(new Date(date.getFullYear(), date.getMonth()-1, 1));
        var lastDay = this.TransformDateToMicrosoftGraphDate(new Date(date.getFullYear(), date.getMonth(), 31));
        //firstDay = "2023-05-01T08:00:00-08:00";
        //lastDay = "2023-05-15T23:00:00-08:00";
        //console.log(firstDay);
        //console.log(lastDay);
        await this.graphService.getCalendarView(firstDay, lastDay, "America/Mexico_City").then(events => {
            events = events.filter(x => !x.isCancelled);
            console.log(events);
            const calendarApi = this.calendarComponent.getApi();
            calendarApi.removeAllEventSources();
            events.forEach((v) => {
                console.log(v);
                var att = "";
                v.attendees.forEach(a => {att += this.getAtts(a.emailAddress.name);});
                /*
                v.attendees.forEach(a => {
                    att += Array.from(a.emailAddress.name.split(" ")[2])[0]  
                    + Array.from(a.emailAddress.name.split(" ")[0])[0] + ",";
                });
                */
                calendarApi.addEvent({
                    id: v.id,
                    start: this.TransformStringToDate(v.start.dateTime),
                    end: this.TransformStringToDate(v.end.dateTime),
                    title: v.subject + "\n" + att.slice(0, -1),
                    display: 'block',
                    backgroundColor: '#EF4044'
                });

                v.attendees.forEach((v) => {
                    let participant = v.emailAddress.name + "(" + v.emailAddress.address + ")";
                    this.participantes.push(participant);
                });


            });

            this.participantes = [...new Set(this.participantes)];//unique values


            this.spinner.hide();
        }, (error) => { console.log(error); this.spinner.hide(); });


        /*this.agendaService.getData().toPromise().then(data => {
            this.spinner.hide();
            const calendarApi = this.calendarComponent.getApi();

            this.Agenda = data;
            this.Agenda.forEach((v) => {
                const timeStart = new Date(v.fecha_actividad + ' ' + v.hora_actividad).getTime();
                const timeEnd = new Date("1970-01-01T" + v.duracion + ':00Z').getTime();


                calendarApi.addEvent({ id: v.agenda_actividadId, start: v.fecha_actividad + ' ' + v.hora_actividad, end: new Date(timeStart + timeEnd), title: v.nombre, display: 'block', backgroundColor: '#EF4044' })
            });
        })*/
    }

    TransformStringToDate(dateString: string): Date {
        let elements = dateString.split('T');
        let dateToken = elements[0];
        let timeToken = elements[1];

        let dateElements = dateToken.split('-');
        let year = Number(dateElements[0]);
        let month = Number(dateElements[1]) - 1;
        let day = Number(dateElements[2]);

        let timeElements = timeToken.split(':');
        let hour = Number(timeElements[0]);
        let minute = Number(timeElements[1]);
        let seconds = Number(timeElements[2]);

        return new Date(year, month, day, hour, minute, seconds);
    }

    TransformDateToMicrosoftGraphDate(dateToChange: Date): string {
        //YYYY-MM-DDTHH:mm:ss
        let year = dateToChange.getFullYear();
        let month = this.ZeroFirstNumberToString(dateToChange.getMonth() + 1);//los meses estan en base 0
        let day = this.ZeroFirstNumberToString(dateToChange.getDate());
        let hour = this.ZeroFirstNumberToString(dateToChange.getHours());
        let minutes = this.ZeroFirstNumberToString(dateToChange.getMinutes());
        let seconds = this.ZeroFirstNumberToString(dateToChange.getSeconds());

        return year + "-" + month + "-" + day + "T" + hour + ":" + minutes + ":" + seconds;
    }

    ZeroFirstNumberToString(number: number): string {
        //Example: if 5 then return "05"
        //Example: if 20 then return "20"
        return ((number >= 10) ? number : "0" + number).toString();
    }

    loadOneTime() {
        this.usuarioService.getUsuariosByTipo(1).toPromise()
            .then(data => {
                this.Usuarios = data;
                this.Usuarios.forEach((v) => {
                    this.dropdownList.push({ usuarioId: v.usuarioId, item_text: `${v.nombrecompleto} (${v.correo})` });
                });
            });

        this.diaInhabilService.getData().toPromise()
            .then(data => {
                this.DiaInhabiles = data;
                const calendarApi = this.calendarComponent.getApi();
                this.DiaInhabiles.forEach(v => {
                    console.log(v);
                    const dateArr = v.fecha_diainhabil.split('-');
                    this.disabledDates.push({ year: dateArr[0], month: dateArr[1], day: dateArr[2] });
                    calendarApi.addEvent({ start: v.fecha_diainhabil, title: v.nombre, display: 'background', backgroundColor: '#F6B26B' })
                });
            });
    }

    isDisabled = (date: NgbDateStruct, current: { month: number, year: number }) => {
        return this.disabledDates.find(x => x.year == date.year && x.month == date.month && x.day == date.day) ? true : false;
    }

    onItemSelect(item: any) {
        //console.log(item);
    }

    onSelectAll(items: any) {
        //console.log(items);
    }

    goToList() {
        this.router.navigate(['/agenda-list']);
    }

    openModal() {
        this.iniciarForm();
        this.modal.open(this.modalForm, { size: 'xl' });
    }

    editar(content, modelo) {
        this.model = modelo;
        this.modal.open(content, { size: 'xl' });
    }

    guardar() {
        this.spinner.show();
        const hora_i = this.forma.controls["hora_actividad"].value;
        const hora_f = this.forma.controls["hora_fin"].value;

        const timeStart = new Date("01/01/2007 " + hora_i).getTime();
        const timeEnd = new Date("01/01/2007 " + hora_f).getTime();

        let timeDiff = timeEnd - timeStart;

        if (timeDiff < 0) {
            timeDiff += 24 * 60 * 60 * 1000;
        }

        var hours = Math.floor(timeDiff / (60000 * 60));
        var minutes = ((timeDiff % (60000 * 60)) / 60000).toFixed(0);

        this.forma.controls["duracion"].setValue((hours < 10 ? '0' : '') + hours + ":" + (parseInt(minutes) < 10 ? '0' : '') + minutes);

        if (this.forma.controls["agenda_actividadId"].value == undefined) {
            this.forma.controls["usuario_registroId"].setValue(this.usuarioService.getUser().usuarioId);
            //console.log(this.forma.value);
            this.agendaService.addData(this.forma.value).subscribe(
                (res) => { this.recargar(); },
                (error) => { console.log(error); });
        }
        else {
            this.forma.controls["usuario_modificaId"].setValue(this.usuarioService.getUser().usuarioId);
            this.agendaService.updData(this.forma.controls["agenda_actividadId"].value, this.forma.value).subscribe(
                (res) => { this.recargar(); },
                (error) => { console.log(error); });
        }
    }

    recargar() {
        this.modal.dismissAll();
        this.spinner.hide();
        this.loadMainData(new Date());
    }
}
