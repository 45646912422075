import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../services/config';
import { NgxSpinnerService } from "ngx-spinner";
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  user = [];
  Usuario:any;
  requestOptions:any;
  requestFileOptions:any;
  constructor(private http: HttpClient, private spinner: NgxSpinnerService) {
    this.requestOptions = this.getHeader();
    this.requestFileOptions = this.getFileHeader();
  }

  getHeader() {
    this.Usuario = this.getUser();
    let header = new HttpHeaders({ "Authorization": "Bearer " + this.Usuario.token });
    return { headers: header };
  }

  getFileHeader() {
    this.Usuario = this.getUser();
    let header = new HttpHeaders({ "Authorization": "Bearer " + this.Usuario.token });
    return { headers: header, responseType: 'blob' }
  } 

    getTipoUsuario() {
      this.requestOptions = this.getHeader();
      return this.http.get(config.apiUrl+'/Usuario_tipo', this.requestOptions);
    }

    getPerfil() {
      this.requestOptions = this.getHeader();
      return this.http.get(config.apiUrl+'/perfil', this.requestOptions);
    }

    getRol() {
      this.requestOptions = this.getHeader();
      return this.http.get(config.apiUrl+'/rol', this.requestOptions);
    }

    getRolListado() {
      this.requestOptions = this.getHeader();
      return this.http.get(config.apiUrl+'/usuario/getrolfull/0', this.requestOptions);
    }
    getUsuarioAll() {
      this.requestOptions = this.getHeader();
      return this.http.get(config.apiUrl + '/usuario/GetUsuarioAll/0', this.requestOptions);
    }

    getUsuarioById(id) {
      this.requestOptions = this.getHeader();
      return this.http.get(config.apiUrl + '/usuario/' + id, this.requestOptions);
    }

    getUsuariosByTipo(idTipo) {
      this.requestOptions = this.getHeader();
        return this.http.get(config.apiUrl + '/usuario/GetUsuariosPorTipo/' + idTipo, this.requestOptions);
    }

    //Consulta
    getData() {
      this.requestOptions = this.getHeader();
      return this.http.get(config.apiUrl + '/Usuario', this.requestOptions);
  }


    //Alta
    addData(data): Observable<any> {
      this.requestOptions = this.getHeader();
      return this.http.post(config.apiUrl + '/Usuario/', data, this.requestOptions);
    }

    //Cambio
    updData(id, data): Observable<any> {
      this.requestOptions = this.getHeader();
      return this.http.put(config.apiUrl + '/Usuario/' + id, data, this.requestOptions);
    }

    //Login
    login(data): Observable<any> {
      this.requestOptions = this.getHeader();
      return this.http.post(config.apiUrl + '/Usuario/UserAccount/', data);
    }

    //Login
    loginSHA(data): Observable<any> {
      this.requestOptions = this.getHeader();
      return this.http.post(config.apiUrl + '/Usuario/login/', data);
    }

    //Restaura
    restaura(id, data): Observable<any> {
      this.requestOptions = this.getHeader();
      return this.http.put(config.apiUrl + '/Usuario/Restaura/' + id, data);
    }

    //Restaura
    recupera(data): Observable<any> {
      this.requestOptions = this.getHeader();
      return this.http.put(config.apiUrl + '/Usuario/PutSendRandom', data);
    }

    //Alta
    addResponsableColegio(data): Observable<any> {
      this.requestOptions = this.getHeader();
      return this.http.post(config.apiUrl + '/Colegio_responsable/', data, this.requestOptions);
    }

    //Alta
    updResponsableColegio(id, data): Observable<any> {
      this.requestOptions = this.getHeader();
      return this.http.put(config.apiUrl + '/Colegio_responsable/' + id, data, this.requestOptions);
    }    

    //Consulta
    getResponsableColegio(id){
      this.requestOptions = this.getHeader();
      return this.http.get<[]>(config.apiUrl + '/Colegio_responsable/GetbyColegio/' + id, this.requestOptions).pipe(
        catchError((error) => {
          console.error('Error fetching responsables:', error); // Manejo del error
          return of([]); // Devuelve un array vacío en caso de error
        }));
    }

    //Consulta All
    getResponsableColegioAll(){
      this.requestOptions = this.getHeader();
      return this.http.get(config.apiUrl + '/Colegio_responsable/GetResponsablesColegio/0', this.requestOptions);
    } 
    

    //Consulta
    getColegioListado(){
      this.requestOptions = this.getHeader();
      return this.http.get(config.apiUrl + '/Colegio/GetActive', this.requestOptions);
    }

    //Consulta
    getColegioAll(){
      this.requestOptions = this.getHeader();
      return this.http.get(config.apiUrl + '/Colegio', this.requestOptions);
    }

    //Consulta colegios sin un usuario asignado para atenderlo
    getColegioSinUsAtiende(){
      this.requestOptions = this.getHeader();
      return this.http.get(config.apiUrl + '/Colegio/GetSinUsuarioAsignado', this.requestOptions);
    }

    //Consulta
    getColegio(id){
      this.requestOptions = this.getHeader();
      return this.http.get(config.apiUrl + '/Colegio/' + id, this.requestOptions);
    }

    //Agergar
    addColegio(data): Observable<any> {
      this.requestOptions = this.getHeader();
      return this.http.post(config.apiUrl + '/Colegio/', data, this.requestOptions);
    }

    //Cambio
    updColegio(id, data): Observable<any> {
      this.requestOptions = this.getHeader();
      return this.http.put(config.apiUrl + '/Colegio/' + id, data, this.requestOptions);
    }

    updColegioAsignaUsuario(data): Observable<any> {
      this.requestOptions = this.getHeader();
      return this.http.post(config.apiUrl + '/Colegio/AsignaUsuario', data, this.requestOptions);
    }
    updColegioDesAsignaUsuario(data): Observable<any> {
      return this.http.post(config.apiUrl + '/Colegio/DesAsignaUsuario', data, this.requestOptions);
    }    

    addUser(usuario) {
      localStorage.setItem('user', JSON.stringify(usuario));
    }

    getUser() {
      return JSON.parse(localStorage.getItem('user')) || [];
    }

    ex() {
      let usr = JSON.parse(localStorage.getItem('user')) || [];
      return (usr.usuario_tipoId === 2)
    }

    in() {
      let usr = JSON.parse(localStorage.getItem('user')) || [];
      return (usr.usuario_tipoId === 1)
    }

    int() {
      let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
      return (usr.usuario_tipoId === 1 || usr.usuario_tipoId === 3)
    }

    val() {
      let usr = JSON.parse(localStorage.getItem('user') || '{}') || [];
      return (Object.keys(usr).length > 0);
      //console.log(Object.keys(usr).length);
      //return (usr.usuario_tipoId != null)
    }    

    adm() {
      let usr = JSON.parse(localStorage.getItem('user')) || [];
      return (usr.perfilId === 1)
    }

    ser() {
      let usr = JSON.parse(localStorage.getItem('user')) || [];
      return (usr.perfilId === 3)
    }

    usr() {
      let usr = JSON.parse(localStorage.getItem('user')) || [];
      return usr.nombreCompleto;
    }

    validar() {

      let valido = localStorage.getItem('valido');
      //alert(valido);
      //console.log(valido);
      return (valido === '1')
  
    }    

}
