
<header *ngIf="us.ex()">
<nav class="navbar navbar-toggleable-md navbar-dark header-ibero">
    <div class="container">

      <a href="#page-top">
        <img class="logo-size" src="/assets/img/logo-ibero.svg" alt="">
      </a>

      <div class="row align-items-right">
        <li ngbDropdown class="nav-item lista-planes ">
          <a href (click)="false" class="nav-link text-white" ngbDropdownToggle>{{ us.usr() }}</a>
          <div ngbDropdownMenu>
            <button ngbDropdownItem [routerLink]="['/actualizar/2']">Editar perfil</button>
            <button ngbDropdownItem (click)="logout()">Cerrar sesión</button>
          </div>
        </li>
        <li ngbDropdown class="nav-item lista-planes">
          <a href (click)="false" class="nav-link text-white" ngbDropdownToggle><span class="navbar-toggler-icon text-white"></span></a>
          <div ngbDropdownMenu>
            <button ngbDropdownItem [routerLink]="['/portal']">Inicio</button>
            <button ngbDropdownItem [routerLink]="['actividad-form/2']">Actividades en tu colegio</button>
            <button ngbDropdownItem [routerLink]="['actividad-form/1']">Actividades en la Ibero</button>
            <button ngbDropdownItem [routerLink]="['actividad-form/4']">Actividades a distancia</button>
            <!--button ngbDropdownItem [routerLink]="['actividad-form/3']">Actividades de OV</button-->
            <button ngbDropdownItem [routerLink]="['/portal']" fragment="especial">Actividades especiales</button>
            <!--button ngbDropdownItem [routerLink]="['#especial']">Actividades especiales</button-->
            <!--div [routerLink]="['/portal/']" fragment="visita-guiada">
              <a href="#visita-guiada">Jump to 'visita-guiada' anchor </a>
            </div-->
            <!--button ngbDropdownItem [routerLink]="['/portal#especial']">Actividades especiales</button-->
            <button ngbDropdownItem [routerLink]="['/solicitud-status']">Estatus de solicitud</button>
            <!--div class="dropdown-divider"></div-->
          </div>
        </li>
        <!--
        <li ngbDropdown class="nav-item lista-planes">
            <a href (click)="false" class="nav-link text-white">Cart</a>
          </li>

          <li ngbDropdown class="nav-item lista-planes">
            <a href (click)="false" class="nav-link text-white">
                <img src="/assets/img/solicitud.svg" data-toggle="tooltip" [routerLink]="['/carrito']" class="cart" alt="" />
            </a>
          </li>
        
          <li ngbDropdown class="nav-item lista-planes">
            <button class="btn icon-btn" [routerLink]="['/carrito']" ngbTooltip="Completar solicitud(es)">
              <img class="cart" src="/assets/img/list_car_01.png" alt="">
            </button>
          </li>
        -->
          <li ngbDropdown class="nav-item lista-planes">
            <button class="btn icon-btn bubble-c" [routerLink]="['/carrito']" ngbTooltip="Completar solicitud(es)">
              <img class="cart" src="/assets/img/list_car_01.png" alt="">
              <!--fa-icon [icon]="['fas','chalkboard-teacher']" size="2x" class="text-white"></fa-icon-->
              <span id="carrito-cont" class="bubble">{{ cs.count() }}</span>
            </button>
            
          </li>          

      </div>
    </div>
  </nav>
</header>


<!--header *ngIf="tipoUsuario == 'interno'"-->
  <header *ngIf="us.in()">
    <nav class="nav navbar navbar-expand-lg mb-3" *ngIf="us.adm()">
      <div class="container">
        <a href="#page-top">
          <img class="logo-size" src="/assets/img/logo-ibero.svg" alt="">
        </a>
        <button class="navbar-toggler text-white" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
          &#9776;
        </button>
        <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse justify-content-end">
          <!--Menu que se muestra en la vista de Panel-->
          <ul class="navbar-nav">
            <li ngbDropdown class="nav-item lista-planes ">
              <a href (click)="false" class="nav-link text-white" ngbDropdownToggle>{{ us.usr() }}</a>
              <div ngbDropdownMenu>
                <button ngbDropdownItem [routerLink]="['/actualizar/1']">Editar perfil</button>
                <button ngbDropdownItem (click)="logout()">Cerrar sesión</button>
              </div>
            </li>
          </ul>
          <!--Menu que se muestra en la vista de Apoyo para Colegios-->
          <ul class="navbar-nav" *ngIf="!(router.url === '/agenda-list' || router.url === '/reporte' || router.url === '/dia-inhabil' || router.url === '/agenda' || router.url === '/panel')">
            <li class="nav-item active">
              <a class="nav-link" [routerLink]="['/panel']" (click)="isMenuCollapsed = true">Inicio</a>
            </li>
            <!--li ngbDropdown class="nav-item lista-planes ">
              <a href (click)="false" class="nav-link text-white" ngbDropdownToggle>{{ us.usr() }}</a>
              <div ngbDropdownMenu>
                <button ngbDropdownItem [routerLink]="['/actualizar/1']">Editar perfil</button>
                <button ngbDropdownItem (click)="logout()">Cerrar sesión</button>
              </div>
            </li-->
            <li class="nav-item active">
              <a class="nav-link" [routerLink]="['/solicitud']" (click)="isMenuCollapsed = true">Solicitudes</a>
            </li>
            <li class="nav-item" >
              <a class="nav-link" [routerLink]="['/usuario']" (click)="isMenuCollapsed = true">Usuarios</a>
            </li>
         
            <li ngbDropdown class="nav-item lista-planes">
              <a href (click)="false" class="nav-link text-white" ngbDropdownToggle><span class=" text-white">Catálogos</span></a>
              <div ngbDropdownMenu>
                <button ngbDropdownItem [routerLink]="['/actividad']">Actividades</button>
                <button ngbDropdownItem [routerLink]="['/actividad-rol']">Actividades por rol</button>
                <button ngbDropdownItem [routerLink]="['/categoria']">Categorías</button>
                <button ngbDropdownItem [routerLink]="['/subcategoria']">Sub categorías</button>
                <button ngbDropdownItem [routerLink]="['/tema']">Temas</button>
                <button ngbDropdownItem [routerLink]="['/solicitud-campo']">Campos de solicitud</button>
                <button ngbDropdownItem [routerLink]="['/banner']">Banners</button>
                <button ngbDropdownItem [routerLink]="['/banner-rol']">Banners por rol</button>
                <!--button ngbDropdownItem [routerLink]="['/agenda-list']">Agenda</!--button-->
                <!--button-- ngbDropdownItem [routerLink]="['/reporte']">Reporte</!button-->
                <!--button-- ngbDropdownItem [routerLink]="['/dia-inhabil']">Días Inhabiles</!--button-->
                <button ngbDropdownItem [routerLink]="['/grado']">Grados</button>
                <button ngbDropdownItem [routerLink]="['/transporte']">Tipos de transporte</button>
                <button ngbDropdownItem [routerLink]="['/colegio']">Colegios</button>
                <button ngbDropdownItem [routerLink]="['/responsable']">Responsables</button>                
              </div>
            </li>
          </ul>
          <!--Menu que se muestra en la vista de Control de Actividades-->
          <ul class="navbar-nav" *ngIf="router.url === '/agenda-list' || router.url === '/reporte' || router.url === '/dia-inhabil' || router.url === '/agenda' ">
            <li class="nav-item active">
              <a class="nav-link" [routerLink]="['/panel']" (click)="isMenuCollapsed = true">Inicio</a>
            </li>
            <!--li ngbDropdown class="nav-item lista-planes ">
              <a href (click)="false" class="nav-link text-white" ngbDropdownToggle>{{ us.usr() }}</a>
              <div ngbDropdownMenu>
                <button ngbDropdownItem [routerLink]="['/actualizar/1']">Editar perfil</button>
                <button ngbDropdownItem (click)="logout()">Cerrar sesión</button>
              </div>
            </li-->

            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/agenda-list']" (click)="isMenuCollapsed = true">Agenda</a>              
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/reporte']" (click)="isMenuCollapsed = true">Reporte</a>
            </li>            

            <li ngbDropdown class="nav-item lista-planes">
              <a href (click)="false" class="nav-link text-white" ngbDropdownToggle><span class=" text-white">Catálogos</span></a>
              <div ngbDropdownMenu>
                <button ngbDropdownItem [routerLink]="['/dia-inhabil']">Días Inhabiles</button>              
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <nav class="nav navbar navbar-expand-lg mb-3" *ngIf="us.ser()">
      <div class="container">
        <a href="#page-top">
          <img class="logo-size" src="/assets/img/logo-ibero.svg" alt="">
        </a>
        <button class="navbar-toggler text-white" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
          &#9776;
        </button>
        <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse justify-content-end">
          <!--Menu que se muestra en la vista de Panel-->
          <ul class="navbar-nav">
            <li ngbDropdown class="nav-item lista-planes ">
              <a href (click)="false" class="nav-link text-white" ngbDropdownToggle>{{ us.usr() }}</a>
              <div ngbDropdownMenu>
                <button ngbDropdownItem [routerLink]="['/actualizar/1']">Editar perfil</button>
                <button ngbDropdownItem (click)="logout()">Cerrar sesión</button>
              </div>
            </li>
          </ul>
            <!--li class="nav-item">
              <a class="nav-link" [routerLink]="['/notificacion']" (click)="isMenuCollapsed = true">Notificaciones</a>
            </li-->
            <!--Menu que se muestra en la vista de Apoyo para Colegios-->
            <ul class="navbar-nav" *ngIf="!(router.url === '/agenda-list' || router.url === '/reporte' || router.url === '/dia-inhabil' || router.url === '/agenda' || router.url === '/panel')">
              <li class="nav-item active">
                <a class="nav-link" [routerLink]="['/panel']" (click)="isMenuCollapsed = true">Inicio</a>
              </li>
              <!--li ngbDropdown class="nav-item lista-planes ">
                <a href (click)="false" class="nav-link text-white" ngbDropdownToggle>{{ us.usr() }}</a>
                <div ngbDropdownMenu>
                  <button ngbDropdownItem [routerLink]="['/actualizar/1']">Editar perfil</button>
                  <button ngbDropdownItem (click)="logout()">Cerrar sesión</button>
                </div>
              </li-->
              <li class="nav-item active">
                <a class="nav-link" [routerLink]="['/solicitud']" (click)="isMenuCollapsed = true">Solicitudes</a>
              </li>
              <li class="nav-item" >
                <a class="nav-link" [routerLink]="['/usuario']" (click)="isMenuCollapsed = true">Usuarios</a>
              </li>
            <li ngbDropdown class="nav-item lista-planes">
              <a href (click)="false" class="nav-link text-white" ngbDropdownToggle><span class=" text-white">Catálogos</span></a>
              <div ngbDropdownMenu>                
                <button ngbDropdownItem [routerLink]="['/categoria']">Categorías</button>
                <button ngbDropdownItem [routerLink]="['/subcategoria']">Sub categorías</button>
                <button ngbDropdownItem [routerLink]="['/tema']">Temas</button>
                <button ngbDropdownItem [routerLink]="['/solicitud-campo']">Campos de solicitud</button>
              </div>
            </li>
          </ul>

          <!--Menu que se muestra en la vista de Control de Actividades-->
          <ul class="navbar-nav" *ngIf="router.url === '/agenda-list' || router.url === '/reporte' || router.url === '/dia-inhabil' || router.url === '/agenda' ">
            <li class="nav-item active">
              <a class="nav-link" [routerLink]="['/panel']" (click)="isMenuCollapsed = true">Inicio</a>
            </li>
            <!--li ngbDropdown class="nav-item lista-planes ">
              <a href (click)="false" class="nav-link text-white" ngbDropdownToggle>{{ us.usr() }}</a>
              <div ngbDropdownMenu>
                <button ngbDropdownItem [routerLink]="['/actualizar/1']">Editar perfil</button>
                <button ngbDropdownItem (click)="logout()">Cerrar sesión</button>
              </div>
            </li-->

            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/agenda-list']" (click)="isMenuCollapsed = true">Agenda</a>              
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/reporte']" (click)="isMenuCollapsed = true">Reporte</a>
            </li>            

            <li ngbDropdown class="nav-item lista-planes">
              <a href (click)="false" class="nav-link text-white" ngbDropdownToggle><span class=" text-white">Catálogos</span></a>
              <div ngbDropdownMenu>
                <button ngbDropdownItem [routerLink]="['/dia-inhabil']">Días Inhabiles</button>              
              </div>
            </li>
          </ul>

        </div>
      </div>
    </nav>


  </header>





<!-- header *ngIf="router.url == '/'"
<header *ngIf="perfil == ''">
    <nav class="nav navbar navbar-expand-lg bg-dark " >
      <div class="container">
        <a href="#page-top">
          <img class="logo-size" src="/assets/img/logo-ibero.svg" alt="">
        </a>
        <div>
          <a routerLink="/registro" class="text-white">Crear nuevo usuario</a>
        </div>

      </div>
    </nav>
  </header>
-->
