import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpEventType, HttpClient } from '@angular/common/http';
import { BannerService } from '../../../core/services/banner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioService } from '../../../core/services/usuario.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styles: [
  ]
})
export class BannerComponent implements OnInit {
  //Upload File
  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();

  Banner;
  Banners;
  BannerFiltroTemp;
  BannerGrupo;
  forma: FormGroup;
  filtros: FormGroup;
  model;
  mf;
  constructor(
    public fb: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,
    private http: HttpClient,
    private bannerservice:BannerService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public modal: NgbModal
  ) {
    if(!this.usuarioService.int()) this.router.navigate(['/portal']);
    this.iniciarForm();
  }

  ngOnInit(): void {
    //this.Banner = this.bannerservice.bannerList();
    this.BannerGrupo = this.bannerservice.bannerGrupoAtivos();
    this.addBanners();

  }

  iniciarForm() {
    this.forma = this.fb.group({
      descripcion: ['', [Validators.required]],
      imagen: ['', [Validators.required]],
      bannerId: [0],
      banner_grupoId: 0,
      ruta: [''],
      referencia: [''],
      activo: ['S', [Validators.required]]
    })
    this.model = this.forma;

    //Filtro
    this.filtros = this.fb.group({
      banner_grupoId: 0,
      activo: '',
    });
    this.mf = this.filtros;
  }

  addBanners(){
    this.spinner.show();
    this.bannerservice.bannerList().toPromise()
    .then(data => {
      this.spinner.hide();
      this.Banner = data;
      //Asignamos el universo de items al listado, mediante una entidad de filtro
      this.Banners = this.Banner;
    })

  }

  filtrar(){
    //Establecemos los elementos totales de la lista
    this.BannerFiltroTemp = this.Banner;

    //alert(this.mf.actividadId);
    if(this.mf.banner_grupoId!=0 && this.mf.banner_grupoId!==undefined){
      this.BannerFiltroTemp = this.BannerFiltroTemp
      .filter(x => x.banner_grupoId === Number(this.mf.banner_grupoId));
    }

    if(this.mf.activo!=0 && this.mf.activo!==undefined){
      this.BannerFiltroTemp = this.BannerFiltroTemp
      .filter(x => x.activo === this.mf.activo);
    }


    this.Banners = this.BannerFiltroTemp;
    //console.log(this.TemasFiltradosTemp);
    //this.filtraTipoActividad();
  }

  showImg=false;
  SelGrupo(){
    //alert(this.model.banner_grupoId);
    //Establecemos los elementos totales de la lista

    //alert(this.mf.actividadId);
    this.showImg=false;
    if(this.model.banner_grupoId==7){
      this.showImg=true;
    }
  }  

  nuevo(content) {
    this.iniciarForm();
    this.model.activo = 'S';
    this.modal.open(content,{size:'xl'});
  }

  editar(content, modelo) {
    this.model = modelo;
    this.showImg=false;
    if(this.model.banner_grupoId==7){
      this.showImg=true;
    }
    this.modal.open(content,{size:'xl'});
  }

  guardar(bRec) {
    //console.log(this.forma.value);
    //alert(this.bRec);
    if (this.forma.controls["bannerId"].value == undefined) {
      this.bannerservice.addData(this.forma.value).subscribe(
        (res) => {
          if(bRec) {this.recargar();}
        },
        (error) => {console.log(error);});
    }
    else {
      this.bannerservice.updData(this.forma.controls["bannerId"].value, this.forma.value).subscribe(
          (res) => {
            if(bRec) {this.recargar();}
          },
          (error) => {console.log(error);});
    }
  }

  borrar(banner) {
    this.bannerservice.delData(banner.bannerId).subscribe(
      (res) => {
        //this.toastr.success('La información ha sido borrada.');
        this.recargar();
      },
      (error) => {
        console.log(error);
      });
  }  

  //bRec=true;
  recargar() {
    //alert('recarga');
    this.modal.dismissAll();
    this.addBanners();
    //this.Banner = this.bannerservice.getData();
  }

  //Upload Files
  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    let name = "sb_" + fileToUpload.name;
    name = name.replace(/ /g, '_');
    //name = name.replace('ñ', 'n');
    //let arrExt = name.split(".");
    //let ext = arrExt[arrExt.length-1];
    //this.model.imagen = "banner_"+this.model.bannerId + "." + ext;
    this.forma.controls["imagen"].setValue(name);
    //Actualizamos Valores
    //this.bRec=false;
    //this.guardar(false);
    //this.bRec=true;

    const formData = new FormData();
    formData.append('file', fileToUpload, name);

    
    this.bannerservice.uploadImg(formData).subscribe(
      (res) => {
        this.toastr.success('La imagen ha sido guardada.');
        //this.recargar();
      },
      (error) => {
        console.log(error);
      });    

    //formData.append('file', fileToUpload, fileToUpload.name);
    /*
    this.http.post('https://localhost:44345/api/upload', formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'La imagen fué guardada.';
          this.onUploadFinished.emit(event.body);
        }
      });
      */
  }  

}
