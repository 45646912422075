import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from './core/services/usuario.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'front';
  validar = false;
  tipoUsuario = localStorage.getItem('tipoUsuario');
  constructor(
    public us: UsuarioService,
    private router: Router
  ) {

  }

  ngOnInit(): void {

    let valor = localStorage.getItem('valido');
    if (valor == '1') {
      this.validar = true;
    }    

    //alert(this.tipoUsuario);
    //console.log(this.perfil);
  }
}
