<!--Contenedor-->
<div class="container align-items-center flex-column">

    <!--Encabezado-->
    <div class="text-center">
      <h4 class="title-section">Asignación de Colegios ({{uNombreCompleto}})</h4>
    </div>
  
  <!--Filtros-->
  <form  [formGroup]="filtros" >
    <div class="row justify-content-between py-2">
      
      <div class="col-8">

        <label for="colegioId"><strong>Buscar Colegio</strong></label>
        <ng-template #rt let-r="result" let-t="term">
          {{ r.nombre}}
        </ng-template>

        <input class="form-control"
               type="search"
               id="colegioId"
               name="colegioId"
               formControlName="colegioId"
               [(ngModel)]="colegioId"
               #instance="ngbTypeahead"
               placeholder="Búsqueda"
               aria-label="Search"
               [ngbTypeahead]="search"
               [resultTemplate]="rt"
               [inputFormatter]="formatter"
               (focus)="focus$.next($event.target.value)"
               (click)="click$.next($event.target.value)"
               (selectItem)="selectedItem($event)">
      </div>
      <div class="col-4 text-right">
        <label>&nbsp;</label><br/>
        <button class="btn btn-primary btn_sac mr-2" (click)="agregarColegio()">Agregar</button>
        <button class="btn btn-primary btn_sac" [routerLink]="['/usuario']">Regresar</button>
        <!--button class="btn btn-primary btn_sac" (click)="nuevo(content)">Agregar</button-->
      </div>      
    
    </div>
  </form>

    <!--Listado-->
  
    <table class="table table-striped text-center table-fixed">
      <thead>
        <tr>
          <th scope="col" class="encabezado orden" >#</th>
          <th scope="col" class="encabezado orden">Nombre del Colegio</th>
          <!--th scope="col" class="encabezado orden">Apellido Paterno</th>
          <th scope="col" class="encabezado orden">Apellido Materno</th>
          <th scope="col" class="encabezado orden">Correo</th-->
          <th scope="col" class="encabezado orden">Estatus</th>
          <th scope="col" class="encabezado orden">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr #item *ngFor="let item of ColegioUsuarioLista; let i = index">
          <td scope="row">{{ i+1 }}</td>
          <!--td class="w-25">{{ item.nombreCompleto }}</td-->
          <td class="w-20">{{ item.nombre }}</td>
          <!--td class="w-20">{{ item.apaterno }}</td>
          <td class="w-20">{{ item.amaterno }}</td>
          <td class="w-30">{{ item.correo }}</td-->
          <td class="w-5">
            {{ item.activo=='S'?"Activo":"Inactivo" }}
          </td>
          <td class="w-5">
            <button class="btn" (click)="quitarColegio(item)" ngbTooltip="Quitar">
              <fa-icon [icon]="['fas','trash-alt']" class="text-danger"></fa-icon>
            </button>         
          </td>
        </tr>
      </tbody>
      <tfoot class="tfoot-ibero text-center">
        <tr>
          <td colspan="8">{{ (ColegioUsuarioLista)?.length }} Registros</td>
        </tr>
      </tfoot>
    </table>
    </div>