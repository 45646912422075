<!-- Contenedor -->
<section class="mb-0">
    <div class="container-fluid align-items-center flex-column" >

    <!-- Header -->
    <div class="row text-center">
        <div class="col-md-4">
            &nbsp;
        </div>
        <div class="col-md-4">
            &nbsp;
            <h1 class="pagina-seccion-titulo titulo-seccion-2 text-dark">Solicitudes</h1>
            <!--h2 class="pagina-seccion-titulo sol-titulo text-secondary mb-12">
                Solicitudes
            </h2-->
        </div>
        <div class="col-md-4">
            &nbsp;
        </div>
    </div>
    <!-- Sin datos -->
    <!--
    <div class="row text-center" *ngIf="items.length <= 0" class="no-data text-center">
        <div class="col-md-12">
            <p >No existen solicitudes</p>
        </div>
    </div>
    -->
    <!-- Datos -->

      <!--div>
        <button type="button" class="btn btn-success" (click)="filtra()">Ok!</button>
      </div-->

      <table class="table table-striped text-center">
        <thead>
          <tr>
            <th scope="col" class="encabezado orden">#</th>
            <th scope="col" class="encabezado orden" width="30%">Actividad</th>
            <th scope="col" class="encabezado orden" width="30%">Tema</th>
            <th scope="col" class="encabezado orden">Fecha</th>
            <th scope="col" class="encabezado orden">Participantes</th>
            <th scope="col" class="encabezado orden">Estatus</th>
            <th scope="col" class="encabezado orden">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of Solicitud; let i = index ">
            <td scope="row">{{ i+1 }}</td>
            <td>
              {{ item.nombreActividad }}
            </td>
            <td>
              {{ item.descripcionTema }}
            </td>
            <td>
              {{ fechaDT(item.fecha_actividad) }}
            </td>
            <td>
              {{ item.num_alumnos_actividad }}
            </td>
            <td>
              {{ item.descripcionEstatus }}
            </td>
            <td>
              <button class="btn icon-btn" (click)="mostrarStatus(conteni2, item)" ngbTooltip="Ver estatus">
                <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
              </button>
              <!--button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Ver estatus">
                <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
              </button-->
            </td>
          </tr>
        </tbody>
      </table>


    </div>
</section>

  <!--ng-template #contenido let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
       <p class="modal-title pl-2">Solicitudes</p>
    </div>

    <div class="modal-body">
        <div class="row">
          <div class="col-lg-2"></div>
            <div class="col-lg-1">
                <button type="button" class="btn btn-outline-secondary mr-2" placement="top" ngbTooltip="12/08/2020">
                    <img class="img-box menu-image animate__animated animate__backInUp" src="/assets/img/proceso/aceptada.png" alt="" />
                  </button>
            </div>
            <div class="col-lg-1">
                <button type="button" class="btn btn-outline-secondary mr-2" placement="top" ngbTooltip="12/08/2020">
                    <img class="img-box menu-image animate__animated animate__backInUp" src="/assets/img/proceso/path-done.png" alt="" />
                  </button>
            </div>
            <div class="col-lg-1">

                <button type="button" class="btn btn-outline-secondary mr-2" placement="top" ngbTooltip="12/08/2020">
                    <img class="img-box menu-image animate__animated animate__backInUp" src="/assets/img/proceso/progreso.png" tooltip="" />
                    <p></p>
                  </button>


            </div>

            <div class="col-lg-1">

                <button type="button" class="btn btn-outline-secondary mr-2" placement="top" ngbTooltip="12/08/2020">
                    <img class="img-box menu-image animate__animated animate__backInUp" src="/assets/img/proceso/path-current.png" alt="" />
                    <p></p>
                  </button>


            </div>

            <div class="col-lg-1">

                <button type="button" class="btn btn-outline-secondary mr-2" placement="top" ngbTooltip="20/08/2020">
                    <img class="img-box menu-image animate__animated animate__backInUp" src="/assets/img/proceso/pausa.png" tooltip="" />
                    <p></p>
                  </button>


            </div>

            <div class="col-lg-1">

                <button type="button" class="btn btn-outline-secondary mr-2" placement="top" ngbTooltip="20/08/2020">
                    <img class="img-box menu-image animate__animated animate__backInUp" src="/assets/img/proceso/path-new.png" alt="" />
                    <p></p>
                  </button>


            </div>

            <div class="col-lg-1">

                <button type="button" class="btn btn-outline-secondary mr-2" placement="top" ngbTooltip="">
                    <img class="img-box menu-image animate__animated animate__backInUp" src="/assets/img/proceso/cancelado.png" alt="" />
                    <p></p>
                  </button>


            </div>
            <div class="col-lg-4"></div>
        </div>
    </div>





    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="modal.close()">Ok!</button>
    </div>

   </ng-template-->


   <ng-template #conteni2 let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h3 class="modal-title" id="modal-basic-title">Estatus de la solicitud</h3>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="track">
        <div [ngClass]="{step: true, active: true}">
          <!--div class="step active"-->
          <span class="icon" placement="top" ngbTooltip="Solicitud enviada" tooltipClass="my-custom-class">
            <fa-icon [icon]="['fas','paper-plane']" class="text-white"></fa-icon>
          </span>
          <span class="text">Enviada</span>
        </div>
        <div [ngClass]="{step: true, active: isConfirmada}" *ngIf="!isReprogramada">
          <span class="icon" placement="top" ngbTooltip="Solicitud confirmada" tooltipClass="my-custom-class">
            <fa-icon [icon]="['fas','chalkboard-teacher']" class="text-white"></fa-icon>
          </span>
          <span class="text"> Confirmada </span>
        </div>
        <!--div [ngClass]="{step: true, active: isProceso}">
    <span class="icon" placement="top" ngbTooltip="Solicitud en proceso" tooltipClass="my-custom-class">
      <fa-icon [icon]="['fas','chalkboard-teacher']" class="text-white"></fa-icon>
    </span>
    <span class="text"> Solicitud en proceso</span>
  </div-->
        <div [ngClass]="{step: true, active: isReprogramada}" *ngIf="isReprogramada">
          <span class="icon" placement="top" ngbTooltip="Solicitud reprogramada" tooltipClass="my-custom-class">
            <fa-icon [icon]="['fas','clock']" class="text-white"></fa-icon>
          </span>
          <span class="text"> Reprogramada </span>
        </div>
        <div [ngClass]="{step: true, active: isFinalizada}">
          <span class="icon" placement="top" ngbTooltip="Solicitud realizada" tooltipClass="my-custom-class">
            <fa-icon [icon]="['fas','check-circle']" class="text-white"></fa-icon>
          </span>
          <span class="text"> Realizada </span>
        </div>
      </div>
    </div>
  </ng-template>

   <ng-template  let-modal >
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
       <p class="modal-title pl-2">Estatus de Solicitudes</p>
    </div>

    <div class="modal-body text-center">
      <div class="row align-items-center">
        <div class="col-md-6 .col-offset-4">
          <div class="track">
            <div class="step active">
              <span class="icon"> <i class="fa fa-check"></i> </span>
              <span class="text">Solicitud enviada</span>
            </div>
            <div class="step active">
              <span class="icon"> <i class="fa fa-user"></i> </span>
              <span class="text"> Solicitud en proceso</span>
            </div>
            <div class="step">
              <span class="icon"><i class="fa fa-truck"></i> </span>
              <span class="text">Confirmada/Reprogramada </span>
            </div>
        </div>
        </div>
      </div>



        <!--div class="row">
          <div class="col-lg-2"></div>
            <div class="col-lg-1">
                <button type="button" class="btn btn-outline-secondary mr-2" placement="top" ngbTooltip="12/08/2020">
                    <img class="img-box menu-image animate__animated animate__backInUp" src="/assets/img/proceso/aceptada.png" alt="" />

                  </button>
            </div>
            <div class="col-lg-1">
                <button type="button" class="btn btn-outline-secondary mr-2" placement="top" ngbTooltip="12/08/2020">
                    <img class="img-box menu-image animate__animated animate__backInUp" src="/assets/img/proceso/path-done.png" alt="" />
                  </button>
            </div>
            <div class="col-lg-1">

                <button type="button" class="btn btn-outline-secondary mr-2" placement="top" ngbTooltip="12/08/2020">
                    <img class="img-box menu-image animate__animated animate__backInUp" src="/assets/img/proceso/progreso.png" tooltip="" />
                    <p></p>
                  </button>


            </div>

            <div class="col-lg-1">

                <button type="button" class="btn btn-outline-secondary mr-2" placement="top" ngbTooltip="12/08/2020">
                    <img class="img-box menu-image animate__animated animate__backInUp" src="/assets/img/proceso/path-current.png" alt="" />
                    <p></p>
                  </button>


            </div>

            <div class="col-lg-1">

                <button type="button" class="btn btn-outline-secondary mr-2" placement="top" ngbTooltip="20/08/2020">
                    <img class="img-box menu-image animate__animated animate__backInUp" src="/assets/img/proceso/pausa.png" tooltip="" />
                    <p></p>
                  </button>


            </div>

            <div class="col-lg-1">

                <button type="button" class="btn btn-outline-secondary mr-2" placement="top" ngbTooltip="20/08/2020">
                    <img class="img-box menu-image animate__animated animate__backInUp" src="/assets/img/proceso/path-new.png" alt="" />
                    <p></p>
                  </button>


            </div>

            <div class="col-lg-1">

                <button type="button" class="btn btn-outline-secondary mr-2" placement="top" ngbTooltip="">
                    <img class="img-box menu-image animate__animated animate__backInUp" src="/assets/img/proceso/cancelado.png" alt="" />
                    <p></p>
                  </button>


            </div>
            <div class="col-lg-4"></div>
        </div-->
    </div>





    <!--div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="modal.close()">Ok!</button>
    </div-->

   </ng-template>
