import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
//import { MnFullpageModule } from 'ngx-fullpage';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ExternoModule } from './modules/externo/externo.module';
import { InternoModule } from './modules/interno/interno.module';
import { UsuarioModule } from './modules/usuario/usuario.module';
import { CommonsModule } from './shared/commons/commons.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
//import { RecaptchaModule } from 'ng-recaptcha';
//import { NavBarComponent } from './shared/commons/nav-bar/nav-bar.component';
//import { FooterComponent } from './shared/commons/footer/footer.component';
import { RouterModule } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
//import { ResponsableComponent } from './modules/interno/responsable/responsable.component';



@NgModule({
  declarations: [
    AppComponent
    //NavBarComponent,
    //FooterComponent
  ],
  imports: [
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    //MnFullpageModule.forRoot(),
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    //NgbModule,
    FormsModule,
    ReactiveFormsModule,
    //RecaptchaModule,
    HttpClientModule,
    CommonModule,
    ExternoModule,
    InternoModule,
    UsuarioModule,
    CommonsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule {}
