import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, forkJoin, Observable } from 'rxjs';
import { config } from '../services/config';
import { UsuarioService } from '../services/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class ActividadesService {
  public _actividades: any[] = [];
  user = [];
  Usuario:any;
  requestOptions:any;
  requestFileOptions:any;
  constructor( private http: HttpClient,
    private usuarioService: UsuarioService ) {    
    this.requestOptions = this.getHeader();
    this.requestFileOptions = this.getFileHeader();
  }

  getHeader() {
    this.Usuario = this.usuarioService.getUser();
    let header = new HttpHeaders({ "Authorization": "Bearer " + this.Usuario.token });
    return { headers: header };
  }

  getFileHeader() {
    this.Usuario = this.usuarioService.getUser();
    let header = new HttpHeaders({ "Authorization": "Bearer " + this.Usuario.token });
    return { headers: header, responseType: 'blob' }
  } 

  //Consulta
  getData() {
    return this.http.get(config.apiUrl+'/Actividad', this.requestOptions);
  }

  //Alta
  addData(data): Observable<any> {
    return this.http.post(config.apiUrl+'/Actividad/',data, this.requestOptions);
  }

  //Cambio
  updData(id, data): Observable<any> {
    return this.http.put(config.apiUrl+'/Actividad/'+id, data, this.requestOptions);
  }

  //Cambio
  updCampoSel(id, data): Observable<any> {
    return this.http.put(config.apiUrl+'/Actividad/PutCampoSeleccion/'+id, data, this.requestOptions);
  }

  //TipoActividad
  getActividadUsuario(usuarioId){
    return this.http.get(config.apiUrl+'/Actividad/GetActividadList/'+usuarioId, this.requestOptions);
  }

  getActividadesActivas(usuarioId){
    return this.http.get(config.apiUrl+'/Actividad/GetActividadActivas/'+usuarioId, this.requestOptions);
  }

  //Actividad_campo
  getActividad_campos(){
    return this.http.get(config.apiUrl+'/Actividad_campo/getActive', this.requestOptions);
  }

  //Actividad - Todos (Ids, descripciones)
  //Actividad/GetActive - Todos Activos (Ids, descripciones)
  //Actividad/GetById - RegistroUnico (Ids, descripciones)

  //TipoActividad - SitioActividad
  getActividadSitio(){
    return this.http.get(config.apiUrl+'/Actividad_sitio', this.requestOptions);
  }

  //TipoActividad
  getActividadGrupo(){
    return this.http.get(config.apiUrl+'/actividad_grupo', this.requestOptions);
  }

  //TipoActividad
  getTipoActividadById(id){
    return this.http.get(config.apiUrl+'/actividad/'+id, this.requestOptions);
  }

  //TipoActividad
  getActividadById(id){
    return this.http.get(config.apiUrl+'/actividad/'+id, this.requestOptions);
  }

  get_TipoActividad(): Observable <any[]> {
    return this.http.get<any[]>(config.apiUrl+'/actividad');
  }
  public getAllTipoActividad(): void {
    this.get_TipoActividad()
    .subscribe( (resp) => {
      resp.forEach((actividad) => {
        this._actividades.push(actividad);
      });
    },
    (err) => {
      console.log(`metodo getAllUsuarios error ${err}`);
    });
  }

}
