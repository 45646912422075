import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';
import { config } from '../services/config';
import { UsuarioService } from '../services/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class CarritoService {
  items = [];
  banners = [];
  user = [];
  Usuario:any;
  requestOptions:any;
  requestFileOptions:any;
  constructor( private http: HttpClient,
    private usuarioService: UsuarioService ) {    
    this.requestOptions = this.getHeader();
    this.requestFileOptions = this.getFileHeader();
  }

  getHeader() {
    this.Usuario = this.usuarioService.getUser();
    let header = new HttpHeaders({ "Authorization": "Bearer " + this.Usuario.token });
    return { headers: header };
  }

  getFileHeader() {
    this.Usuario = this.usuarioService.getUser();
    let header = new HttpHeaders({ "Authorization": "Bearer " + this.Usuario.token });
    return { headers: header, responseType: 'blob' }
  } 

  agregarActividad(actividad) {
    //Obtenemos la lista
    this.items = JSON.parse(localStorage.getItem('carrito')) || [];
    //Agregamos el elemento
    this.items.push(actividad);
    //Guardamos la lista
    localStorage.setItem('carrito', JSON.stringify(this.items));    
  }

  obtenerActividades() {
    //Obtenemos la lista
    this.items = JSON.parse(localStorage.getItem('carrito')) || [];
    return this.items;    
  }

  quitarActividades(actividadId, temaId) {
    //Obtenemos la lista
    this.items = JSON.parse(localStorage.getItem('carrito')) || [];
    //removemos el item
    if(temaId==0){
      this.items.splice(this.items.findIndex(v => v.actividadId === Number(actividadId)), 1);
    }
    else{
      this.items.splice(this.items.findIndex(v => v.temaId === Number(temaId)), 1);
    }
    //guardamos la lista
    localStorage.setItem('carrito', JSON.stringify(this.items)); 
    return this.items;
  }  

  quitarActividad(id) {
    //Obtenemos la lista
    this.items = JSON.parse(localStorage.getItem('carrito')) || [];
    //removemos el item
    this.items.splice(this.items.findIndex(v => v.id_tema === Number(id)), 1);
    //guardamos la lista
    localStorage.setItem('carrito', JSON.stringify(this.items)); 
    return this.items;
  }

  vaciarActividades() {
    localStorage.removeItem('carrito');
    return [];
  }  

  setBanners(bans, object){
    this.banners = JSON.parse(localStorage.getItem(bans)) || [];
    this.banners.push(object);
    localStorage.setItem(bans, JSON.stringify(this.banners)); 
  }

  getBanners(bans){
    this.banners = JSON.parse(localStorage.getItem(bans)) || [];
    return this.banners;  
  }

  ban(ban) {
    let ref = JSON.parse(localStorage.getItem(ban)[0]) || [];
    return ref.referencia;
  }  

  count() {
    this.items = JSON.parse(localStorage.getItem('carrito')) || [];
    return this.items.length;
  }    

}
