import { Component, OnInit } from '@angular/core';
import { CarritoService } from '../../../core/services/carrito.service';

@Component({
  selector: 'app-becas',
  templateUrl: './becas.component.html',
  styles: [
  ]
})
export class BecasComponent implements OnInit {
  arrBanners;
  constructor(
    private carritoService: CarritoService
  ) { 
    //this.arrBanners = this.carritoService.getBanners('beca');
  }

  ngOnInit(): void {
    //this.arrBanners = this.carritoService.getBanners('beca');
    //console.log(this.arrBanners);
  }

  cs(){
    this.arrBanners = this.carritoService.getBanners('beca');
    return true;
  }

}
