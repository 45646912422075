<!-- Planes Section-->
<section class="section pee74">
  <div class="container-fluid d-flex align-items-center flex-column">
    <div class="row sol-titulo-blanco pt-5 align-items-center">
      <div class="col-md-12 text-center">
        <h2 class="titulo-seccion-planes text-secondary">
          Planes de estudio y fechas de examen
        </h2>
      </div>
    </div>

    <div class="row py-2 align-items-center">
      <div class="col-md-12 text-center text-white font-weight-bold">
        <p><a href="https://enlinea.uia.mx/admision_registro/login_form.cfm" target="_blank" class="bold text-white">Consulta las fechas de examen</a></p>
      </div>
    </div>    

    <div class="container-fluid">
      <div class="row pt-2">
        <div class="col-sm-12 col-md-6 col-lg-3 text-center text-white">
          <h2 class="text-secondary sol-titulo-blanco-c">A</h2>
          <ul class="lista-planes">
            <li>
              <a class="link-plan" href="https://licenciaturas.ibero.mx/actuaria" target="_blank">Actuaría</a>
            </li>
            <li>
              <a class="link-plan" href="https://licenciaturas.ibero.mx/licenciatura-en-administracion-de-empresas" target="_blank">Administración de Empresas</a>
            </li>
            <li>
              <a class="link-plan" href="https://licenciaturas.ibero.mx/licenciatura-en-administracion-de-la-hospitalidad"  target="_blank">Administración de la Hospitalidad</a>
            </li>
            <li>
              <a class="link-plan" href="https://licenciaturas.ibero.mx/licenciatura-en-arquitectura" target="_blank">Arquitectura</a>
            </li>
          </ul>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3 text-center text-white">
          <h2 class="text-secondary sol-titulo-blanco-c">C</h2>
          <ul class="lista-planes">
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/licenciatura-en-ciencias-teologicas">Ciencias Teológicas</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/licenciatura-en-ciencias-politicas-y-administracion-publica">Ciencias Políticas y Administración Pública</a>
            </li>
            <!--li>
              <a class="link-plan" target="_blank" href="http://preuniversitarios.ibero.mx/ContaduriayGestionEmpresarial">Contaduria y Gestión Empresarial</a>
            </li-->
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/contaduria-y-direccion-de-negocios">Contaduría y Dirección de Negocios</a>
            </li>            
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/licenciatura-en-comunicacion">Comunicación</a>
            </li>
          </ul>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3 text-center text-white">
          <h2 class="text-secondary sol-titulo-blanco-c">D</h2>
          <ul class="lista-planes">
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/licenciatura-en-derecho">Derecho</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/disenio-de-ficciones-y-narrativas-transmedia">Diseño de Ficciones y Narrativas Transmedia</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/disenio-de-moda-y-textiles-sostenibles">Diseño de Moda y Textiles Sostenibles</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/disenio-de-productos-y-experiencias">Diseño de Productos y Experiencias</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/disenio-sensorial-y-direccion-creativa">Diseño Sensorial y Dirección Creativa</a>
            </li>          
            <!--li>
              <a class="link-plan" target="_blank" href="http://preuniversitarios.ibero.mx/DisenoGrafico">Diseño Gráfico</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="http://preuniversitarios.ibero.mx/IndumentariaYModa">Diseño de Indumentaria y Moda</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="http://preuniversitarios.ibero.mx/DisenoInteractivo">Diseño Interactivo</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="http://preuniversitarios.ibero.mx/DisenoIndustrial">Diseño Industrial</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="http://preuniversitarios.ibero.mx/DisenoTextil">Diseño Textil</a>
            </li-->
          </ul>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3 text-center text-white">
          <h2 class="text-secondary sol-titulo-blanco-c">E</h2>
          <ul class="lista-planes">
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/licenciatura-en-economia">Economía</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row pt-2">
        <div class="col-sm-12 col-md-6 col-lg-3 text-center text-white">
          <h2 class="text-secondary sol-titulo-blanco-c">F</h2>
          <ul class="lista-planes">
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/licenciatura-en-filosofia">Filosofía</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/licenciatura-en-finanzas">Finanzas</a>
            </li>
          </ul>
          <h2 class="text-secondary sol-titulo-blanco-c pt-5">M</h2>
          <ul class="lista-planes">
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/licenciatura-en-mercadotecnia">Mercadotecnia</a>
            </li>
          </ul>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-3 text-center text-white">
          <h2 class="text-secondary sol-titulo-blanco-c">H</h2>
          <ul class="lista-planes">
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/licenciatura-en-historia">Historia</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/licenciatura-en-historia-del-arte">Historia del Arte</a>
            </li>
          </ul>
          <h2 class="text-secondary sol-titulo-blanco-c pt-5">N</h2>
          <ul class="lista-planes">
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/licenciatura-en-negocios-globales">Negocios Globales</a>
            </li>
          </ul>          
          <ul class="lista-planes">
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/licenciatura-en-nutricion-y-ciencia-de-los-alimentos">Nutrición y Ciencia de los Alimentos</a>
            </li>
          </ul>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3 text-center text-white">
          <h2 class="text-secondary sol-titulo-blanco-c">I</h2>
          <ul class="lista-planes">
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/ingenieria-de-alimentos">Ingeniería de Alimentos</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/ingenieria-en-ciencia-de-datos">Ingeniería en Ciencia de Datos</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/ingenieria-en-tecnologias-de-computo-y-telecomunicaciones">Ingeniería en Tecnologías de Cómputo y Telecomunicaciones</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/ingenieria-biomedica">Ingeniería Biomédica</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/ingenieria-civil">Ingeniería Civil</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/ingenieria-fisica">Ingeniería Física</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/ingenieria-industrial">Ingeniería Industrial</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/ingenieria-mecanica-y-electrica">Ingeniería Mecánica y Eléctrica</a>
            </li>
            <!--li>
              <a class="link-plan" target="_blank" href="http://preuniversitarios.ibero.mx/IngMecatronicayProduccion">Ingeniería en Mecatrónica y Producción</a>
            </li-->
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/ingenieria-en-mecatronica-y-sistemas-ciberfisicos">Ingeniería en Mecatrónica y Sistemas Ciberfísicos</a>
            </li>            
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/ingenieria-quimica">Ingeniería Química</a>
            </li>
          </ul>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3 text-center text-white">
          <h2 class="text-secondary sol-titulo-blanco-c">L</h2>
          <ul class="lista-planes">
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/licenciatura-en-literatura-latinoamericana">Literatura Latinoamericana</a>
            </li>
          </ul>
          <h2 class="text-secondary sol-titulo-blanco-c pt-5">S</h2>
          <ul class="lista-planes">
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/sustentabilidad-ambiental">Sustentabilidad Ambiental</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row ">
        <div class="col-sm-12 col-md-6 col-lg-3 text-center text-white">
          <h2 class="text-secondary sol-titulo-blanco-c">P</h2>
          <ul class="lista-planes">
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/licenciatura-en-pedagogia">Pedagogía</a>
            </li>
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/licenciatura-en-psicologia">Psicología</a>
            </li>
          </ul>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-3 text-center text-white">
          <h2 class="text-secondary sol-titulo-blanco-c">R</h2>
          <ul class="lista-planes">
            <li>
              <a class="link-plan" target="_blank" href="https://licenciaturas.ibero.mx/licenciatura-en-relaciones-internacionales">Relaciones Internacionales</a>
            </li>

          </ul>

        </div>

      </div>
    </div>
  </div>
</section>
