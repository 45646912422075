import { Component, OnInit } from '@angular/core';
import { CarritoService } from '../../../core/services/carrito.service';

@Component({
  selector: 'app-visita-guiada',
  templateUrl: './visita-guiada.component.html',
  styles: [
  ]
})
export class VisitaGuiadaComponent implements OnInit {
  Banner;
  vLink;
  constructor(
    private carritoService: CarritoService
  ) { }

  ngOnInit(): void {
    //this.arrBanners = this.carritoService.ban('visita');
    //console.log(this.arrBanners);
    //this.vLink = this.arrBanners.referencia;

  }

  ir(){
    this.Banner = this.carritoService.getBanners('visita')[0];
    window.open(this.Banner.referencia, "_blank");
  }

}
