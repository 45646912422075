<ng-container *ngFor="let item of BannerList; let c = index">
<section class="section actividad-especial" style="background-image: url(/assets/img/{{ item.imagen }})" id="{{item.bannerId}}">
    <div class="container-fluid flex-column">
      <div class="row align-items-center  min-vh-100">
          <div class="ibero-texto col-sm-12 col-md-5 offset-md-5 text-center btn-xplora">
             <button type="button" class="btn btn-danger btn-lg" (click)="ir(item)">
                 Registrarse
                </button>
          </div>
        </div>
        </div>
  </section>
</ng-container>
