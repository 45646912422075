<!--Contenedor-->
<div class="container align-items-center flex-column">

<!--Encabezado-->
<div class="text-center">
  <h4 class="title-section">Catálogo - Banner</h4>
</div>

  <!--Filtros-->
  <form  [formGroup]="filtros" >
    <div class="row">
      <div class="col-lg-6">
        <label>Grupo</label>
        <select class="form-control"
        formControlName="banner_grupoId" [(ngModel)]="mf.banner_grupoId" (change)="filtrar()">
          <option [ngValue]="0">--Seleccionar--</option>
          <option *ngFor="let item of BannerGrupo | async" 
          [ngValue]="item.banner_grupoId">
            {{ item.descripcion }}
            </option>
        </select>
      </div>
      <div class="col-lg-6">
        <label>Estado</label>
        <select class="form-control"
          formControlName="activo" [(ngModel)]="mf.activo" (change)="filtrar()">
          <option value="">--Seleccionar--</option>
          <option value="S">Activo</option>
          <option value="N">Inactivo</option>
        </select>
      </div>      

    </div>
    </form>

<!--Listado-->
<div class="row justify-content-between py-2">
  <div class="col-md-4 col-sm-12">
      <h5 class="title-subsection">Datos del banner</h5>
  </div> 
  <div class="col-md-4 col-sm-12 text-right">
      <button class="btn btn-primary btn_sac" (click)="nuevo(content)">Agregar</button>
  </div>     
</div>

<table class="table table-striped text-center table-fixed">
  <thead>
    <tr>
      <th scope="col" class="encabezado">#</th>
      <th style="width: 20%;" scope="col" class="encabezado">Descripción</th>
      <th style="width: 10%;" scope="col" class="encabezado">Grupo</th>
      <th style="width: 40%;"scope="col" class="encabezado">Referencia</th>
      <!--th scope="col" class="encabezado">Ruta</th-->
      <th style="width: 10%;" scope="col" class="encabezado">Estatus</th>
      <th style="width: 20%;" scope="col" class="encabezado">Acciones</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of Banners; let i = index">
      <td scope="row">{{ i+1 }}</td>
      <td>{{ item.descripcion }}</td>
      <td>{{ item.bannerGrupoDescripcion }}</td>
      <td>{{ item.referencia }}</td>
      <!--td>{{ item.ruta }}</td-->
      <td>
        {{ item.activo=='S'?"Activo":"Inactivo" }}
      </td>
      <td>
        <button class="btn icon-btn" (click)="borrar(item)" ngbTooltip="Borrar" *ngIf="false">
          <fa-icon [icon]="['fas','trash']" class="text-danger"></fa-icon>
        </button>        
        <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar">
          <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
        </button>
      </td>
    </tr>
  </tbody>
  <tfoot class="tfoot-ibero text-center">
    <tr>
      <td colspan="6">{{ (Banners)?.length }} Registros</td>
    </tr>
  </tfoot>   
</table>
</div>

<!--Formulario-->

<ng-template #content let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Datos del banner</div>
      <div class="card-body text-dark">

        <div class=" card-body row">
          <div class="col-12">
            <form [formGroup]="forma">
              <div class="row text-dark">
                <input class="form-control" type="hidden" formControlName="bannerId"
                       [(ngModel)]="model.bannerId">
                <input class="form-control" type="hidden" formControlName="imagen"
                       [(ngModel)]="model.imagen">                       
              </div>
              <div class="row text-dark">
                <div class="form-group col-lg-6">
                  <label for="descripcion">Descripción:</label>
                  <input class="form-control" type="text" formControlName="descripcion"
                         [(ngModel)]="model.descripcion">
                </div>

                <div class="form-group col-lg-6">
                  <label for="banner_grupoId">Grupo:</label>
                  <select class="form-control" (change)="SelGrupo();"
                  formControlName="banner_grupoId" [(ngModel)]="model.banner_grupoId">
                    <option [ngValue]="0">--Seleccionar--</option>
                    <option *ngFor="let item of BannerGrupo | async" 
                    [ngValue]="item.banner_grupoId">
                      {{ item.descripcion }}
                      </option>
                  </select>
                </div>
                <!--
                <div class="form-group col-lg-6">
                  <label for="ruta">Ruta:</label>
                  <input class="form-control" type="text" formControlName="ruta"
                         [(ngModel)]="model.ruta">
                </div>
                -->
                <div class="form-group col-lg-6">
                  <label for="referencia">Referencia (http://):</label>
                  <input class="form-control" type="text" formControlName="referencia"
                         [(ngModel)]="model.referencia">
                </div>
                <div class="form-group col-lg-6">
                  <label for="activo">Estatus</label>
                  <select class="form-control" formControlName="activo"
                          [(ngModel)]="model.activo">
                    <option value="">--Seleccionar--</option>
                    <option value="S">Activo</option>
                    <option value="N">Inactivo</option>
                  </select>
                </div>
                <!--
                <div class="form-group col-lg-6">
                  <label for="imagen">Imagen:</label>
                  <input class="form-control" type="text" formControlName="imagen"
                         [(ngModel)]="model.imagen">
                </div>
                -->
                <div class="col-md-3" *ngIf="showImg">
                  <input type="file" #file placeholder="Choose file" (change)="uploadFile(file.files)" style="display:none;">
                  <button type="button" class="btn btn-danger" (click)="file.click()">Seleccionar Imagen (.svg)</button>
                </div>
                <!--
                <div class="col-md-4" *ngIf="showImg">
                  <span class="upload" *ngIf="progress > 0">
                    {{progress}}%
                  </span>
                  <span class="upload" *ngIf="message">
                    {{message}}
                  </span>
                </div>
                -->
                <div class="col-md-3 text-left mt-1" *ngIf="showImg">
                  <p><a class="vimg" href="/assets/img/{{ model.imagen }}" target="_blank">Ver Imagen</a></p>
                </div>
              </div>
              <div class="row text-dark pt-3">
                <div class="col-lg-12">
                  <div class="buttonSelect text-center">
                    <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                    <button class="btn btn-danger" (click)="guardar(true)">Guardar</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

