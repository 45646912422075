import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule, FaIconLibrary  } from '@fortawesome/angular-fontawesome'
import { faCircle,faSquare } from '@fortawesome/free-solid-svg-icons';
import { faCircle as farCircle,faSquare as farSquare } from '@fortawesome/free-regular-svg-icons';
import { faStackOverflow, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';
import { faTrashAlt,faCheckCircle,faTimesCircle,faEdit, 
  faCheckDouble, faListAlt, faChalkboard, faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';

import { FooterComponent } from './footer/footer.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';

@NgModule({
  declarations: [FooterComponent, NavBarComponent],
  imports: [
    CommonModule,
    NgbModule,
    FontAwesomeModule,
    RouterModule,
  ],
  exports: [FooterComponent, NavBarComponent],
})
export class CommonsModule {

  constructor(library: FaIconLibrary) {

    // Add an icon to the library for convenient access in other components
    // library.addIconPacks(fas);
    library.addIcons(faCircle,faSquare,farCircle,farSquare
      ,faStackOverflow,faGithub,faMedium,faTrashAlt,faCheckCircle,
      faTimesCircle,faEdit,
      faTrashAlt, faCheckDouble, faListAlt, faChalkboard, faChalkboardTeacher );
    //library.addIcons(faStar, faPlus, faDownload, faFacebookF, faTwitch, faLinkedinIn, faDribbble, faChevronUp, faTwitter);
    //library.addIcons(faCircle,faSquare,farCircle,farSquare
    //      ,faStackOverflow,faGithub,faMedium);
  }

 }
