<!-- Contenedor -->
<section class="mb-0">
<div class="container-fluid align-items-center flex-column" >

<!-- Header -->
<div class="row text-center">
    <div class="col-md-12 py-3">
        <h1 class="pagina-seccion-titulo titulo-seccion-2 text-dark">Solicitudes</h1>
    </div>

</div>
<!-- Sin datos -->
<div class="row text-center" *ngIf="items.length <= 0" class="no-data text-center">
    <div class="col-md-12">
        <p >No existen solicitudes</p>
    </div>
</div>
<!-- Datos -->
    <div *ngFor="let item of items;let i = index">
    <div class="row" [hidden]="hideme[i]">
    <!--Datos de la actividad-->
    <div *ngIf="item.temaId !== undefined" class="col-lg-4">
        <div class="card border-white mb-0">
        <div class="card-header text-white bg-dark">DATOS DE LA ACTIVIDAD
            <button class="btn" (click)="removeFromCart(item.actividadId, item.temaId)" ngbTooltip="Borrar ">
                <fa-icon [icon]="['fas','trash-alt']" class="text-danger"></fa-icon>
                </button>

        </div>
            <div class="card-body text-dark peffe">
                <!--h5 class="card-title">Dark card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p-->
                <label class="text-dark">Categoría:</label>
                <p class="card-text text-danger">{{item.categoriaDescripcion}}</p>

                <label class="text-dark">SubCategoría:</label>
                <p class="card-text text-danger">{{item.subCategoriaDescripcion}}</p>

                <label class="text-dark">Descripción:</label>
                <p class="card-text text-danger">{{item.descripcion}}</p>

                <label class="text-dark">Objetivo:</label>
                <p class="card-text text-danger">{{item.objetivo}}</p>

                <label class="text-dark">Idioma:</label>
                <p class="card-text text-danger">{{item.descripcionIdioma}}</p>

                <!--p class="card-text"><strong class="text-danger">Costo:</strong> <br/>{{vCosto}}</p-->
        </div>
        </div>
    </div>

    <div *ngIf="item.nombre !== undefined" class="col-lg-4">
        <div class="card border-white mb-0">
        <div class="card-header text-white bg-dark">DATOS DE LA ACTIVIDAD
                <button class="btn pl-2" (click)="removeFromCart(item.actividadId, 0)" ngbTooltip="Borrar ">
                    <fa-icon [icon]="['fas','trash-alt']" class="text-danger"></fa-icon>
                </button>

        </div>


            <div class="card-body text-dark peffe">
                <!--h5 class="card-title">Dark card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p-->
                <!--label class="text-dark">Nombre:</label>
                <p class="card-text text-danger">{{item.nombre}}</p-->

                <label class="text-dark">Nombre:</label>
                <p class="card-text text-danger">{{item.nombreaportal}}</p>

                <!--label class="text-dark">Descripción:</label>
                <p class="card-text text-danger">{{item.descripcion}}</p>

                <label class="text-dark">Objetivo:</label>
                <p class="card-text text-danger">{{item.objetivo}}</p>

                <label class="text-dark">Idioma:</label>
                <p class="card-text text-danger">{{item.descripcionIdioma}}</p-->

                <!--p class="card-text"><strong class="text-danger">Costo:</strong> <br/>{{vCosto}}</p-->
        </div>
        </div>
    </div>
    <!--detalle de la actividad-->
    <div class="col-lg-8">
        <div class="card border-white mb-0">
        <div class="card-header text-white bg-dark">DETALLE DE LA ACTIVIDAD</div>
            <div class="card-body text-dark pef6f">

                    <form [formGroup]="checkoutForm">
                        <div class="row text-dark">
                            <input class="form-control" type="hidden"
                            id="actividadId" >
                            <input class="form-control" type="hidden"
                            id="temaId" >
                            <!--div class="col-lg-6"-->
                            <!--div [ngClass]="visible(item,1) ? 'col-lg-6 d-b' : 'col-lg-6 d-n'"-->
                            <div class="form-group col-lg-6" *ngIf="visible(item,10)">
                                <label for="fecha">Fecha de visita ({{item.tmpo_min_sol}} días mínimo de anticipación)</label><br/>
                                <div class="input-group">
                                    <input class="form-control" placeholder="yyyy-mm-dd" [readonly]="true"
                                        id="fecha_actividad" formControlName="fecha_actividad" [minDate]="minDate" ngbDatepicker #d="ngbDatepicker">
                                    <div class="input-group-append">
                                      <button class="btn btn-outline-secondary calendar" (click)="tiempoMin(item.tmpo_min_sol);d.toggle()" type="button"></button>
                                    </div>
                                  </div>

                            </div>
                            <div class="col-lg-6" *ngIf="visible(item,1)">
                                <label for="num_alumnos_actividad">Número de alumnos</label><br/>
                                <input class="form-control" type="number" value="" id="num_alumnos_actividad" formControlName="num_alumnos_actividad"
                                min="5" max="80">
                                <div class="text-danger" *ngIf="checkoutForm.controls['num_alumnos_actividad'].touched && checkoutForm.controls['num_alumnos_actividad'].errors?.required">El número de alumnos es requerido</div>
                                <div class="text-danger" *ngIf="checkoutForm.controls['num_alumnos_actividad'].touched && checkoutForm.controls['num_alumnos_actividad'].errors?.min">El mínimo de alumnos debe ser 5</div>
                                <!--input type="text" id="numero" class="form-control" formControlName="numero"-->
                            </div>
                            <div class="col-lg-6" *ngIf="visible(item,8)">
                                <label for="hora">Hora de Inicio</label>
                                <input class="form-control" type="time" value="00:00:00" id="hora_ini_actividad" formControlName="hora_ini_actividad" >
                                <div class="text-danger" *ngIf="checkoutForm.controls['hora_ini_actividad'].touched && checkoutForm.controls['hora_ini_actividad'].errors?.required">La hora de inicio es requerida</div>
                            </div>
                            <div class="col-lg-6" *ngIf="visible(item,9)">
                                <label for="hora">Hora de Fin</label>
                                <input class="form-control" type="time" value="00:00:00" id="hora_fin_actividad" formControlName="hora_fin_actividad" >
                                <div class="text-danger" *ngIf="checkoutForm.controls['hora_fin_actividad'].touched && checkoutForm.controls['hora_fin_actividad'].errors?.required">La hora de fin es requerida</div>
                            </div>
                        <!--/div>
                        <div class="row text-dark"-->

                            <div class="col-lg-6" *ngIf="true">
                                <label for="enlace_plataforma">Proporciona la plataforma o liga de acceso (en su caso)</label>
                                <input class="form-control" type="text" formControlName="enlace_plataforma"
                                       [(ngModel)]="enlace_plataforma">
                              </div>  

                            <div class="col-lg-6" *ngIf="visible(item,4)">
                                <label for="gradoId">Grado</label>
                                <select class="form-control"
                                        formControlName="gradoId"
                                        [(ngModel)]="gradoId">
                                    <option value="">--Seleccionar--</option>
                                    <option *ngFor="let item of Grados | async"
                                            [ngValue]="item.gradoId">
                                    {{ item.descripcion }}
                                    </option>
                                </select>
                                <div class="text-danger" *ngIf="checkoutForm.controls['gradoId'].touched && checkoutForm.controls['gradoId'].errors?.required">El grado es requerido</div>
                            </div>
                        <!--/div>
                        <div class="row text-dark"-->
                            <div class="col-lg-6" *ngIf="visible(item,5)">
                                <label for="ind_computadora">¿Cuenta con computadora?</label><br/>
                                <select class="form-control" id="ind_computadora" formControlName="ind_computadora">
                                    <option value="">--Seleccionar--</option>
                                    <option value="S">SI</option>
                                    <option value="N">NO</option>
                                </select>
                                <div class="text-danger" *ngIf="checkoutForm.controls['ind_computadora'].touched && checkoutForm.controls['ind_computadora'].errors?.required">La respuesta es requerida</div>
                            </div>
                            <div class="col-lg-6" *ngIf="visible(item,6)">
                                <label for="ind_internet">¿Cuenta con servicio de internet?</label><br/>
                                <select class="form-control" id="ind_internet" formControlName="ind_internet">
                                    <option value="">--Seleccionar--</option>
                                    <option value="S">SI</option>
                                    <option value="N">NO</option>
                                </select>
                                <div class="text-danger" *ngIf="checkoutForm.controls['ind_internet'].touched && checkoutForm.controls['ind_internet'].errors?.required">La respuesta es requerida</div>
                            </div>
                        <!--/div>
                        <div class="row text-dark"-->
                            <div class="col-lg-6" *ngIf="visible(item,7)">
                                <label for="ind_estacionamiento">¿Cuenta con servicio de estacionamiento?</label><br/>
                                <select class="form-control" id="ind_estacionamiento" formControlName="ind_estacionamiento">
                                    <option value="">--Seleccionar--</option>
                                    <option value="S">SI</option>
                                    <option value="N">NO</option>
                                </select>
                                <div class="text-danger" *ngIf="checkoutForm.controls['ind_estacionamiento'].touched && checkoutForm.controls['ind_estacionamiento'].errors?.required">La respuesta es requerida</div>
                            </div>
                            <div class="col-lg-6" *ngIf="visible(item,11)">
                                <label for="colegio_responsableId">Si usted no será el responsable, selecciónelo</label>
                                <div class="row">
                                    <div class="col-md-8">
                                        <select class="form-control"
                                                formControlName="colegio_responsableId"
                                                [(ngModel)]="colegio_responsableId">
                                            <option [ngValue]="0">--Seleccionar--</option>
                                            <option *ngFor="let item of ResponsableColegio"
                                                    [ngValue]="item.colegio_responsableId">
                                            {{ item.nombre }}  {{ item.apaterno }}  {{ item.amaterno }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-danger" (click)="showResponsable(contenido)">Agregar</button>
                                    </div>
                                  </div>
                            </div>
                        <!--/div>
                        <div class="row text-dark align-items-center"-->
                            <!--div class="col-lg-6" *ngIf="visible(item,9)">
                                <label for="notas">Notas</label><br/>
                                <textarea id="notas" class="form-control" formControlName="notas" rows="2"></textarea>
                            </div-->
                            <div class="col-lg-6">
                                <div class="buttonSelect text-center pt-4">
                                    <button class="btn btn-danger" (click)="guardarSolicitud(item)" [disabled]="checkoutForm.invalid">Enviar Solicitud</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>

    </div>
</div>

<!--Formulario-->

<ng-template #contenido let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span inert >&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">DATOS DEL RESPONSABLE</div>
        <div class="card-body text-dark">

          <form [formGroup]="responsableForm">

            <div class="row text-dark">
                <div class="col-lg-6">
                    <input class="form-control" type="hidden" formControlName="colegioId" [(ngModel)]="colegioId">
                    <label for="nombre"><span class="text-danger">*</span>Nombre</label><br/>
                    <input type="text" id="nombre" class="form-control" formControlName="nombre">
                    <div class="text-danger" *ngIf="responsableForm.controls['nombre'].touched && responsableForm.controls['nombre'].errors?.required">El nombre es requerido</div>
                    <div class="text-danger" *ngIf="responsableForm.controls['nombre'].touched && responsableForm.controls['nombre'].errors?.pattern">Ingresa solo letras</div>
                </div>
                <div class="col-lg-6">
                    <label for="apaterno"><span class="text-danger">*</span>Apellido paterno</label><br/>
                    <input type="text" id="apaterno" class="form-control" formControlName="apaterno">
                    <div class="text-danger" *ngIf="responsableForm.controls['apaterno'].touched && responsableForm.controls['apaterno'].errors?.required">El apellido paterno es requerido</div>
                    <div class="text-danger" *ngIf="responsableForm.controls['apaterno'].touched && responsableForm.controls['apaterno'].errors?.pattern">Ingresa solo letras</div>
                </div>
            </div>
            <div class="row text-dark">
                <div class="col-lg-6">
                    <label for="amaterno">Apellido materno</label><br/>
                    <input type="text" id="amaterno" class="form-control" formControlName="amaterno">
                    <div class="text-danger" *ngIf="responsableForm.controls['amaterno'].touched && responsableForm.controls['amaterno'].errors?.pattern">Ingresa solo letras</div>
                </div>
                <div class="col-lg-6">
                    <label for="correo"><span class="text-danger">*</span>Correo electrónico</label><br/>
                    <input type="text" id="correo" class="form-control" formControlName="correo">
                    <div class="text-danger" *ngIf="responsableForm.controls['correo'].touched && responsableForm.controls['correo'].errors?.required">El correo es requerido</div>
                    <div class="text-danger" *ngIf="responsableForm.controls['correo'].touched && responsableForm.controls['correo'].errors?.email">Ingrese un correo válido</div>
                </div>
            </div>
            <div class="row text-dark">
                <div class="col-lg-6">
                    <label for="telefono"><span class="text-danger">*</span>Teléfono</label><br/>
                    <input type="text" id="telefono" class="form-control" formControlName="telefono" maxlength="10">
                    <div class="text-danger" *ngIf="responsableForm.controls['telefono'].touched && responsableForm.controls['telefono'].errors?.required">El teléfono es requerido</div>
                    <div class="text-danger" *ngIf="responsableForm.controls['telefono'].touched && responsableForm.controls['telefono'].errors?.pattern">Ingrese solo números</div>
                    <div class="text-danger" *ngIf="responsableForm.controls['telefono'].touched && responsableForm.controls['telefono'].errors?.minlength || responsableForm.controls['telefono'].errors?.maxlength">El teléfono debe contener 10 dígitos</div>
                    </div>
                <div class="col-lg-6">
                    <label for="extension">Extensión</label><br/>
                    <input type="text" id="extension" class="form-control" formControlName="extension" maxlength="5">
                    <div class="text-danger" *ngIf="responsableForm.controls['extension'].touched && responsableForm.controls['extension'].errors?.pattern">Ingrese solo números</div>
                    <div class="text-danger" *ngIf="responsableForm.controls['extension'].touched && responsableForm.controls['extension'].errors?.minlength || responsableForm.controls['extension'].errors?.maxlength">El teléfono debe contener máximo 5 dígitos</div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="buttonSelect text-center pt-3">
                  <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                  <button class="btn btn-danger" (click)="addResponsable()"  [disabled]="responsableForm.invalid">Guardar</button>
                </div>
              </div>

                <!--div class="row text-dark pt-3">
                  <div class="col-lg-12">
                    <div class="buttonSelect text-center">
                      <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                      <button class="btn btn-danger" (click)="addResponsable()">Guardar</button>
                    </div>
                  </div>
                </div-->
          </form>
        </div>
      </div>

    </div>
  </ng-template>
  <!--app-toasts aria-live="polite" aria-atomic="true"></app-toasts-->
</div>
</section>
<!--div class="col-lg-4">
    <div class="cart-item" *ngFor="let item of items">
    <span>{{ item.tema }}</span>

    <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)">
        <div>
        <label for="name">
            Name
        </label>
        <input id="name" type="text" formControlName="name">
        </div>
        <div>
        <label for="address">
            Address
        </label>
        <input id="address" type="text" formControlName="address">
        </div>
        <button class="button" type="submit">Purchase</button>
    </form>
    </div>
</div-->
