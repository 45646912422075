import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { GradoService } from '../../../core/services/grado.service';
import { CarritoService } from '../../../core/services/carrito.service';
import { UsuarioService } from '../../../core/services/usuario.service';
import { SolicitudService } from '../../../core/services/solicitud.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerConfig} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { max } from 'rxjs/operators';

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
  ]
})
export class CarritoComponent implements OnInit {
  items;
  Grados;
  checkoutForm;
  responsableForm;
  hideme=[];
  gradoId;
  ResponsableColegio = [];
  temaId;
  User;
  //colegioId;

  forma: FormGroup;
  //model;

  constructor(
    private carritoService: CarritoService,
    private gradoservice: GradoService,
    private usuarioService: UsuarioService,
    private solicitudService: SolicitudService,
    private formBuilder: FormBuilder,
    public modal: NgbModal,
    private config: NgbDatepickerConfig,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {

    this.checkoutForm = this.formBuilder.group({
      solicitud_actividadId: 0,
      actividadId: 0,
      temaId: 0,
      num_alumnos_actividad: ['0', [Validators.required, Validators.min(5)]],
      otro_tema: [' ', [Validators.required]],
      enlace_plataforma: [' '],
      gradoId: ['0', [Validators.required]],
      ind_computadora: ['S', [Validators.required]],
      ind_internet: ['S', [Validators.required]],
      ind_estacionamiento: ['S', [Validators.required]],
      colegio_responsableId: [0],
      hora_ini_actividad: ['0', [Validators.required]],
      hora_fin_actividad: ['0', [Validators.required]],
      fecha_actividad: ['', [Validators.required]],
      cve_carrera:['0', [Validators.required]],
      notas: [' ', [Validators.required]],
      usuario_solId: 0
    });

    this.responsableForm = this.formBuilder.group({
      colegio_responsableId: 0,
      nombre: ['', [Validators.required, Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)]],
      colegioId: '',
      apaterno: ['', [Validators.required, Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)]],
      amaterno: ['', [Validators.pattern(/^[a-zA-ZáéíóúAÉÍÓÚÑñ ]+$/)]],
      correo: ['', [Validators.required, Validators.email]],
      telefono: ['', [Validators.required, Validators.pattern("^[0-9]*$"),
      Validators.minLength(10), Validators.maxLength(10)]],
      extension: ['', [Validators.pattern("^[0-9]*$"),
      Validators.minLength(1), Validators.maxLength(5)]],
      activo:'S'
    });



  }

  ngOnInit(): void {
    this.items = this.carritoService.obtenerActividades();
    //console.log(this.items);
    this.Grados = this.gradoservice.getDataActive();
    this.User = this.usuarioService.getUser();
    //console.log(this.User);
    //this.colegioId = this.User.colegioId;
   this.ResponsablesColegio();
    //this.tiempoMin();
  }

  ResponsablesColegio(){
    this.usuarioService.getResponsableColegio(this.User.colegioId)
    .toPromise()
      .then(data => {       
        this.ResponsableColegio = Array.isArray(data) ? data : [];
        //this.SubCategoria = this.SubCategoria.filter(x => x.tema_categoriaId === Number(this.model.tema_categoriaId));
        //this.filtra();
      })
  }

  showResponsable(contenido) {
    this.responsableForm.reset();
    this.responsableForm.controls["colegioId"].setValue(this.User.colegioId);
    this.responsableForm.controls["colegio_responsableId"].setValue(0);
    this.responsableForm.controls["activo"].setValue('S');
    //this.responsableForm.controls["amaterno"].setValue(' ');
    this.modal.open(contenido,{size:'xl'});
  }

  addResponsable(contenido) {
    console.log(contenido);
    
    this.responsableForm.controls["colegioId"].setValue(this.User.colegioId);
    //console.log(this.responsableForm.value);
    this.usuarioService.addResponsableColegio(this.responsableForm.value).subscribe(
      (res) => {
        this.recargar();
        
        this.toastr.success('El responsable ha sido agregado a la lista.');
      },
      (error) => {console.log(error);});
    // this.modal.open(contenido,{size:'xl'});
  }

  guardarSolicitud(item) {
    //console.log(item);
    let ini = this.checkoutForm.controls["hora_ini_actividad"].value;
    let fin = this.checkoutForm.controls["hora_fin_actividad"].value;
    if (this.tiempoInicioMayor(ini, fin)) {
      this.toastr.error('La hora de Inicio de actividad es mayor a la hora de Fin.');
      return false;
    }
    //alert('enviar');
    //return false;
    if(item.temaId === undefined){
      this.temaId=0;
      //this.checkoutForm.controls["temaId"].setValue(0);
    }
    else{
      this.temaId=item.temaId;
      //this.checkoutForm.controls["temaId"].setValue(item.temaId);
    }
    this.checkoutForm.controls["usuario_solId"].setValue(this.User.usuarioId);
    this.checkoutForm.controls["temaId"].setValue(this.temaId);
    this.checkoutForm.controls["actividadId"].setValue(item.actividadId);
    //this.checkoutForm.controls["fecha_actividad"].setValue(new Date(this.checkoutForm.controls["fecha_actividad"].value));
    //let fecha = this.checkoutForm.controls["fecha_actividad"].value;
    /*
    alert(fecha);
    alert(fecha.year);
    alert(fecha.month);
    alert(fecha.day);

    let nfecha = fecha.year + '-'
                + ("0" + (fecha.month + 1)).slice(-2) + '-'
                + fecha.day;
    alert(nfecha);
    */
    //this.model.fecha_actividad = new Date(modelo.fecha_actividad);
    //this.checkoutForm.controls["fecha_actividad"].setValue(nfecha);
    //this.checkoutForm.controls["fecha_actividad"].setValue(objToDate(fecha));
    //var date = object.year + '/' + object.month + '/'+ object.day
    //this.checkoutForm.controls["fecha_actividad"].setValue('2020-09-01');
    //console.log(item);
    //this.checkoutForm.value
    //console.log(this.checkoutForm.value);
    //return false;
    //console.log(this.checkoutForm.value);
    this.spinner.show();
    this.solicitudService.addData(this.checkoutForm.value).subscribe(
      (res) => {
        this.spinner.hide();
        this.toastr.success('Tu solicitud ha sido enviada.');
        this.items = this.carritoService.quitarActividades(item.actividadId, this.temaId);

        //this.recargar();
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      });
  }

  recargar(){
    this.modal.dismissAll();
    console.log(this.User.colegioId);
    
    this.usuarioService.getResponsableColegio(this.User.colegioId).subscribe(
      (data: any []) => {
        this.ResponsableColegio = data; // Asigna el resultado correctamente
        console.log(this.ResponsableColegio); // Verifica que es un array
      },
      (error) => {
        console.error(error); // Maneja el error
      }
    );
    
    //this.items = this.carritoService.obtenerActividades();
  }

  objToDate(fecha){
    return fecha.year + '-' + ("0" + (fecha.month + 1)).slice(-2) + '-'+ fecha.day;
  }

  visible(item,id){
    //console.log(item);
    //item.campo_seleccionado
    let permiso = item.campo_seleccionado;
    //console.log(permiso);
    let array = permiso.replace(/\s/g, '').split(",");
    //alert(id + ", "+array.includes(id));
    return array.includes(String(id));
  }

  removeFromCart(actividadId, temaId) {
    //alert(actividadId +", "+ temaId);
    this.items = this.carritoService.quitarActividades(actividadId, temaId);
    this.toastr.success('La actividad ha sido eliminada de tu lista.');
    //this.items = this.carritoService.quitarActividad(actividadId);
  }

  minDate = undefined;
  tiempoMin(dias){
    const result = new Date();
    result.setDate(result.getDate() + dias);
    this.minDate = {
      year: result.getFullYear(),
      month: result.getMonth() + 1,
      day: result.getDate()
    };
  }


  tiempoInicioMayor(ini, fin) {
    var esMayor = false;
    var a = '01/01/2001 ' + ini; //"11/24/2014 3:10 PM";
    var b = '01/01/2001 ' + fin; //"11/23/2014 7:45 AM";

    //alert(a + ' > ' + b);
    var aDate = new Date(a).getTime();
    var bDate = new Date(b).getTime();

    if (aDate > bDate) {
      esMayor = true;
    }

    return esMayor;
  }


}
