<!--Contenedor-->
<div class="container">  
    <div class="row  text-center align-items-center justify-content-center ">
        
      <h2 class="pt-5 pb-5">Bienvenida(o), selecciona la opción que desee consultar.</h2>
        <div class="col-md-6">
            <button class="btn icon-btn" ngbTooltip="" [routerLink]="['/solicitud']">
                <img class="img-box menu-image animate__animated animate__backInLeft" src="/assets/img/icono_colegio02.svg" alt="" />
            </button>
            <p class="text-danger"><strong>Apoyo para Colegios</strong></p>
        </div>
        <div class="col-md-6">
            <button class="btn icon-btn" ngbTooltip="" [routerLink]="['/agenda-list']">
                <img class="img-box menu-image animate__animated animate__backInLeft" src="/assets/img/actividad/1.svg" alt="" />
            </button>
            <p class="text-danger"><strong>Control de Actividades</strong></p>
        </div>
    </div>
</div>