<!-- About Section-->
<section class="section" *ngIf="this.cs()">
    <div class="container-fluid d-flex align-items-center flex-column">
      <div class="row sol-titulo pt-3">
        <div class="col-md-12 text-center">
          <h2 class="titulo-seccion text-secondary">
            Orientadores y orientadoras vocacionales
          </h2>
        </div>
      </div>
      <div class="container">
        <div class="row pt-3">
          <div class="col-12 text-secondary text-left texto-orientadores pb-5">
            La labor de orientación vocacional es un reto en el que te queremos
            apoyar mediante diferentes actividades que les brinden herramientas a
            tus alumnas y alumnos para facilitar la toma de decisiones en la
            elección profesional.
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-12 col-md-8 col-lg-8 text-right content_img_orientador">
          <img class="img_globo_orientador seccion-avatar-01 animate__animated animate__backInUp"
            src="/assets/img/Teacher.svg"  alt=""  />
          </div>

          <div class=" col-sm-12 col-md-4 col-lg-4 text-left pt-5 link_orientadores">
            <p *ngFor="let item of arrBanners">
              <a class="text-danger" href="{{ item.referencia }}" target="_blank">{{ item.descripcion }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
