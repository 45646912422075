<div class="container-fluid  py-3">
  <div class="content">
    <img src="/assets/img/medio-sol.svg" alt="" style="float:left; margin-left:-100px; width: 250px; padding-top: 5%; z-index:2; position: absolute; ">
  </div>
<!--div class="content">
  <img src="/assets/img/medio-sol.svg" alt="" style="float:left; margin-left:-100px; width: 250px; padding-top: 100%; z-index:2; position: absolute; ">
</div-->
  <div class="row">
  <div class="col-sm-12 col-md-12 text-center">
    <h1 class="titulo-registro text-danger py-5">
      Editar Perfil
  </h1>
  </div>
</div>
  <div class="row justify-content-center py-5">
    <div class="col-sm-12 col-md-7 content-registro peffe shadow">
      <div id="contiene-img-registro">
        <img src="./assets/img/registro/icono-usuario-registro.svg" alt="">
      </div>
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-8 py-2">
          <h3 class="text-danger pt-5">Datos del Usuario</h3>
         <form [formGroup]="forma">
           <div class="form-group">
             <input class="form-control" type="hidden" formControlName="colegioId" [(ngModel)]="model.colegioId">
             <input class="form-control" type="hidden" formControlName="perfilId" [(ngModel)]="model.perfilId">
             <input class="form-control" type="hidden" formControlName="rolId" [(ngModel)]="model.rolId">
             <input class="form-control" type="hidden" formControlName="usuarioId" [(ngModel)]="model.usuarioId">
             <input class="form-control" type="hidden" formControlName="usuario_tipoId" [(ngModel)]="model.usuario_tipoId">
             <input class="form-control" type="hidden" formControlName="activo" [(ngModel)]="model.activo">

            <label for="nombre"><span class="text-danger">*</span>Nombre</label>
            <input class="form-control" type="text" formControlName="nombre" [(ngModel)]="model.nombre">
            <div class="text-danger" *ngIf="forma.controls['nombre'].touched && forma.controls['nombre'].errors?.required">El nombre es requerido</div>
            <div class="text-danger" *ngIf="forma.controls['nombre'].touched && forma.controls['nombre'].errors?.pattern">Ingresa solo letras</div>
          </div>
          <div class="form-group">
            <label for="apaterno"><span class="text-danger">*</span>Apellido Paterno</label>
            <input class="form-control" type="text" formControlName="apaterno" [(ngModel)]="model.apaterno">
            <div class="text-danger" *ngIf="forma.controls['apaterno'].touched && forma.controls['apaterno'].errors?.required">El apellido paterno es requerido</div>
            <div class="text-danger" *ngIf="forma.controls['apaterno'].touched && forma.controls['apaterno'].errors?.pattern">Ingresa solo letras</div>
           </div>
           <div class="form-group">
            <label for="amaterno">Apellido Materno</label>
            <input class="form-control" type="text" formControlName="amaterno" [(ngModel)]="model.amaterno">
            <div class="text-danger" *ngIf="forma.controls['amaterno'].touched && forma.controls['amaterno'].errors?.pattern">Ingresa solo letras</div>
           </div>
           <div class="form-group form-row">
            <div class="col-sm-12 col-md-6">
              <label for="telefono"><span class="text-danger">*</span>Teléfono</label>
              <input class="form-control" type="text" formControlName="telefono" [(ngModel)]="model.telefono">
              <div class="text-danger" *ngIf="forma.controls['telefono'].touched && forma.controls['telefono'].errors?.required">El teléfono es requerido</div>
              <div class="text-danger" *ngIf="forma.controls['telefono'].touched && forma.controls['telefono'].errors?.pattern">Ingrese solo números</div>
              <div class="text-danger" *ngIf="forma.controls['telefono'].touched && forma.controls['telefono'].errors?.minlength || forma.controls['telefono'].errors?.maxlength">El teléfono debe contener 10 dígitos</div>
            </div>
            <div class="col-sm-12 col-md-6">
              <label for="extension">Extensión</label>
              <input class="form-control" type="text" formControlName="extension" [(ngModel)]="model.extension">
              <div class="text-danger" *ngIf="forma.controls['extension'].touched && forma.controls['extension'].errors?.pattern">Ingrese solo números</div>
              <div class="text-danger" *ngIf="forma.controls['extension'].touched && forma.controls['extension'].errors?.minlength">La extensión debe contener máximo 5 dígitos</div>
              <div class="text-danger" *ngIf="forma.controls['extension'].touched && forma.controls['extension'].errors?.maxlength">La extensión debe contener máximo 5 dígitos</div>
            </div>
           </div>
           <div class="form-group">
            <label for="correo"><span class="text-danger">*</span>Correo electrónico</label>
            <input class="form-control" type="text" formControlName="correo" [(ngModel)]="model.correo" disabled>
            <div class="text-danger" *ngIf="forma.controls['correo'].touched && forma.controls['correo'].errors?.required">El correo es requerido</div>
            <div class="text-danger" *ngIf="forma.controls['correo'].touched && forma.controls['correo'].errors?.email">Ingrese un correo válido</div>
           </div>
           <div class="form-group">
            <label for="contrasenia"><span class="text-danger">*</span>Contraseña:</label>
            <input class="form-control" type="password" formControlName="contrasenia" [(ngModel)]="model.contrasenia">
            <div class="text-danger" *ngIf="forma.controls['contrasenia'].touched && forma.controls['contrasenia'].errors?.required">La contraseña es requerida</div>
           </div>
           <div class="form-group">
            <label><span class="text-danger">*</span>Confirmar Contraseña</label>
            <input type="password" class="form-control" formControlName="confirmacontrasenia" [(ngModel)]="model.confirmacontrasenia">
            <div class="text-danger" *ngIf="forma.controls['confirmacontrasenia'].touched && forma.controls['confirmacontrasenia'].errors?.required">La confirmación de contraseña es requerida</div>
            <div class="text-danger" *ngIf="forma.errors?.invalid"> Las contraseñas no coinciden
            </div>
           </div>

           <div class="form-group" *ngIf="model.colegioId > 0">
             <label for="nombreColegio">Colegio:</label>
             <input class="form-control" type="text" formControlName="nombreColegio" disabled
                    [(ngModel)]="model.nombreColegio">
           </div>
            <!--label for="colegioId">Colegios:</label>
            <input id="colegioId" type="text"
                   name="colegioId"
                   class="form-control"
                   [(ngModel)]="Colegios.colegioId"
                   [ngbTypeahead]="search"
                   [resultFormatter]="resultFormatBandListValue"
                   [inputFormatter]="inputFormatBandListValue"
                   #instance="ngbTypeahead"
              /-->
              <!--p>
              <label for="typeahead-basic"><span class="text-danger">*</span>Colegios</label>
              </p>

              <ng-template #rt let-r="result" let-t="term">
                {{ r.nombre}}
              </ng-template>


              <input
                class="form-control"
                type="search"
                id="colegioId"
                name="colegioId"
                formControlName="colegioId"
                [(ngModel)]="model.colegioId"
                #instance="ngbTypeahead"
                placeholder="Búsqueda"
                aria-label="Search"
                [(ngModel)]="modelo"
                [ngbTypeahead]="search"
                [resultTemplate]="rt"
                [inputFormatter]="formatter"
                (focus)="focus$.next($event.target.value)"
                (click)="click$.next($event.target.value)"
                disabled
                >

           <div class="text-danger" *ngIf="forma.controls['colegioId'].touched && forma.controls['colegioId'].errors?.required">El colegio es requerido
           </div-->

          <div class="text-center">
            <button class="btn btn-danger" (click)="cancelar()">Cancelar</button>&nbsp;
            <button type="submit" class="btn btn-danger my-2" (click)="guardar()" [disabled]="forma.invalid">Guardar</button>
           </div>

         </form>
        </div>
      </div>
  </div>
  </div>
</div>
