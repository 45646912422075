import { Component, OnInit } from '@angular/core';
import { CarritoService } from '../../../core/services/carrito.service';

@Component({
  selector: 'app-tu-espacio',
  templateUrl: './tu-espacio.component.html',
  styles: [
  ]
})
export class TuEspacioComponent implements OnInit {
  Banner;
  constructor(
    private carritoService: CarritoService
  ) { }

  ngOnInit(): void {
    //this.cs();
  }

  ir(){
    this.Banner = this.carritoService.getBanners('espacio')[0];
    window.open(this.Banner.referencia, "_blank");
  }

}
