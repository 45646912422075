<div class="container">
  <div class="row justify-content-center align-items-center">
    <div class="col-md-10 pt-5">
      <h2 class="titulo-seccion text-danger text-center py-5 titulo-seccion-cursos">Restablecer contraseña</h2>
        <div class="row justify-content-center content-registro peffe">
          <p class="pt-5 text-center font-weight-bold">Introduzce la nueva contraseña para poder acceder a tu cuenta.</p>
          <div class="col-sm-12 col-md-8 pt-5">
            <form [formGroup]="forma" >
              <div class="form-group">
                <label class="font-weight-bold">Nueva contraseña</label>
                <input type="password" class="form-control" id="password1" formControlName="password1" />
              </div>
              <div class="form-group">
                <label class="font-weight-bold">Confirmar contraseña</label>
                <input type="password" class="form-control" id="password2" formControlName="password2" />
              </div>
              <div class="form-group text-center pt-5">
                <button class="btn btn-danger mr-3" [routerLink]="['/']">Cancelar</button>&nbsp;
                <button class="btn btn-danger" (click)="entrar()">
                  Cambiar contraseña
                </button>
              </div>
              <div class="text-center">
                <span class="text-danger">
                  {{ Mensaje }}
                </span>
              </div>              
            </form>
          </div>
        </div>
    </div>
  </div>
</div>
