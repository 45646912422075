import { Component, OnInit } from '@angular/core';
import { CarritoService } from '../../../core/services/carrito.service';

@Component({
  selector: 'app-x-plora',
  templateUrl: './x-plora.component.html',
  styles: [
  ]
})
export class XPloraComponent implements OnInit {
  Banner;
  vLink;
  constructor(
    private carritoService: CarritoService
  ) { }

  ngOnInit(): void {
    //this.arrBanners = this.carritoService.getBanners('xplora')[0];
    //this.vLink = this.arrBanners.referencia;
  }

  ir(){
    this.Banner = this.carritoService.getBanners('xplora')[0];
    window.open(this.Banner.referencia, "_blank");
  }

}
