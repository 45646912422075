import { Component, OnInit } from '@angular/core';
import { TemasListadoService } from '../../../core/services/temas-listado.service';
import { SolicitudService } from '../../../core/services/solicitud.service';
import { UsuarioService } from '../../../core/services/usuario.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-solicitud-status',
  templateUrl: './solicitud-status.component.html',
  styles: [
  ]
})
export class SolicitudStatusComponent implements OnInit {
  Temas;
  TemasFiltrados;
  SelSolicitud;
  User;
  Solicitud;

  constructor(
    private solicitudservice:SolicitudService,
    private temasListadoService:TemasListadoService,
    private usuarioService: UsuarioService,
    private activatedRoute: ActivatedRoute,
    public modal: NgbModal
  ) { }

  ngOnInit(): void {
    //this.Temas = this.temasListadoService.getTemasListado();
    //console.log(this.Temas);
    //this.temasListadoService.getAllTemasListado();
    this.addSolicitudes();
    this.sort();
  }
  filtra(){
    this.TemasFiltrados = this.temasListadoService._temas;
  }

  addSolicitudes(){
    //this.Solicitud = this.solicitudservice.getData();
    //this.actividadesService.getData().toPromise()
    this.User = this.usuarioService.getUser();
    this.solicitudservice.getSolUsuario(this.User.usuarioId).toPromise()
    .then(data => {
      this.SelSolicitud = data;
      //this.User = this.usuarioService.getUser();
      //console.log(this.SelSolicitud);
      //let arr = this.User.actividad_seleccionado.replace(/\s/g, '').split(",");
      //console.log(this.SelSolicitud.length);
      //let status = 0;
      //var min;
      let fecha;
      var d1;
      var d2;
      for (let i = 0; i < this.SelSolicitud.length; i++) {
        fecha = this.SelSolicitud[i].fecha_actividad;
        //console.log(fecha);
        fecha = fecha.split("T")[0] + ' ' + this.SelSolicitud[i].hora_fin_actividad;
        //fecha = fecha.split("T")[0] + ' ' + '14:00';
        //console.log(fecha);
        d1 = new Date();
        d2 = new Date(fecha);
        //console.log(d1 + " > " + d2);
        this.SelSolicitud[i]["finalizada"] = false;
        if (d1.getTime() > d2.getTime()) {
          this.SelSolicitud[i]["finalizada"] = true;
          this.SelSolicitud[i].descripcionEstatus = 'Realizada';
        }
      }

      this.Solicitud = this.SelSolicitud;
      //console.log(this.SelSolicitud);

    })
  }

  isConfirmada=false;
  isReprogramada = false;
  isFinalizada = false;
  mostrarStatus(contenido, item) {
    //console.log(item);
    this.isConfirmada=false;
    this.isReprogramada=false;
    this.isFinalizada=false;
    if(item.estatus_actividadId==2){
      this.isConfirmada=true;
    }
    if(item.estatus_actividadId==4){
      this.isConfirmada=true;
      this.isReprogramada=true;
    }

    if(item.finalizada){
      this.isFinalizada = true;
    }

    this.modal.open(contenido,{size:'xl'});
  }

  fechaDT(value: string) {
    return value.split('T')[0];
  }

  asc;
  sort() {
    const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;
    const comparer = (idx, asc) => (a, b) => ((v1, v2) =>
      v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
    )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

    // do the work...
    document.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
      const table = th.closest('table');
      const tbody = table.querySelector('tbody');
      Array.from(tbody.querySelectorAll('tr'))
        .sort(comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc))
        .forEach(tr => tbody.appendChild(tr));
    })));
  }

}
