import { Component, OnInit } from '@angular/core';
import { CarritoService } from '../../../core/services/carrito.service';

@Component({
  selector: 'app-cursos',
  templateUrl: './cursos.component.html',
  styles: [
  ]
})
export class CursosComponent implements OnInit {
arrBanners;
  constructor(
    private carritoService: CarritoService
  ) { }

  ngOnInit(): void {
    //this.arrBanners = this.carritoService.getBanners('curso');
  }

  cs(){
    this.arrBanners = this.carritoService.getBanners('curso');
    return true;
  }  

}
