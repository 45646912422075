import { Component, OnInit } from '@angular/core';
import { BannerService } from '../../../core/services/banner.service';
import { UsuarioService } from '../../../core/services/usuario.service';
import { BannerPorRolService } from '../../../core/services/banner-por-rol.service';
import { CarritoService } from '../../../core/services/carrito.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styles: [
  ]
})
export class PortalComponent implements OnInit {
  BannerRol;
  Banners;
  rolId;
  model;
  User;
  arrBanner;
  SelBanner;
  bEspacio=false;
  bExplora=false;
  bVisita=false;
  constructor(
    private router: Router,
    private bannerporrolservice:BannerPorRolService,
    private carritoService: CarritoService,
    private usuarioService: UsuarioService,
    private bannerService: BannerService
  ) { 
    if(!this.usuarioService.val()) this.router.navigate(['/']);
    //this.delBanners();
    //this.addBanners();
    this.setBanners();
  }

  ngOnInit(): void {
    //this.BannerRol = this.bannerporrolservice.getBannerRol();
    //alert(this.BannerRol);
    //console.log(this.BannerRol);
    //this.delBanners();
    //this.addBanners();
  }

  addBanners(){ 
    //this.bannerporrolservice.getRolBanner(this.model.rolId).toPromise()
    
    //alert(this.User);
    //console.log(this.User.banner_seleccionado);
    //this.setBanners();

    //this.rolId = localStorage.getItem(this.User.rolId);
    /*
    this.bannerporrolservice.getRolBanner(this.User.rolId).toPromise()
    .then(data => {
      this.BannerRol = data;
      //console.log(this.BannerRol);
      this.setBanners();
      //this.carritoService.setBanners(this.BannerRol[0].banner_seleccionado);
    })
    */
  }

  bEspecial;
  setBanners(){ 
    
    this.bannerService.getData().toPromise()
    .then(data => {
      this.SelBanner = data;
      this.User = this.usuarioService.getUser();
      let arr = this.User.banner_seleccionado.replace(/\s/g, '').split(",");

      /*
      console.log(this.SelBanner);
      this.Banners = this.SelBanner.filter(i => arr.includes(i.bannerId))
      console.log(this.Banners);
      */
      
      //console.log(arr);
      this.Banners = this.SelBanner.filter(function(item){
        return arr.indexOf(String(item.bannerId)) >= 0;
      });
      //console.log(this.Banners); iggrupo, banerid
      //banner_grupoId, 1 
      let esp = this.Banners.filter(x => x.banner_grupoId === Number(7));
      this.bEspecial = false;
      if(esp != undefined) this.bEspecial = true;
      //alert(this.bEspecial);

      this.delBanners();
      
      for (var i=0;i<this.Banners.length;i++){
        //if(this.User.banner_seleccionado.indexOf(this.Banners[i].bannerId)){
          switch (this.Banners[i].banner_grupoId) {
            case 1: 
              this.carritoService.setBanners('curso',this.Banners[i]);
              //alert(this.Banners[i]);
              break;          
            case 2:
              this.carritoService.setBanners('concurso',this.Banners[i]);
              break;
            case 3:
              this.carritoService.setBanners('torneo',this.Banners[i]);
              break;
            case 4:
              this.carritoService.setBanners('beca',this.Banners[i]);
              break;
            case 5:
              this.carritoService.setBanners('orientador',this.Banners[i]);
              break;
              /*
            case 6:
              this.carritoService.setBanners('xplora',this.Banners[i]);
              this.bExplora=true;
              break;
            case 7:
              this.carritoService.setBanners('espacio',this.Banners[i]);
              this.bEspacio=true;
              break; 
            case 8:
              this.carritoService.setBanners('visita',this.Banners[i]);
              this.bVisita=true;
              break;                          
              */
            default: 
            break;
          }
        //}
      }


      //bannerList
      //bannerGrupo
      //banner_grupoId
      /*
      for (var i=0;i<this.Banners.length;i++){
        //if(this.User.banner_seleccionado.indexOf(this.Banners[i].bannerId)){
          switch (this.Banners[i].bannerId) {
            case 1: case 13: case 15: 
              this.carritoService.setBanners('curso',this.Banners[i]);
              //alert(this.Banners[i]);
              break;          
            case 5: case 6: case 7: case 8: case 9: case 20:
              this.carritoService.setBanners('concurso',this.Banners[i]);
              break;
            case 10: case 11: case 12:
              this.carritoService.setBanners('torneo',this.Banners[i]);
              break;
            case 19: case 21: case 22:
              this.carritoService.setBanners('beca',this.Banners[i]);
              break;
            case 2: case 14: case 17: case 18:
              this.carritoService.setBanners('orientador',this.Banners[i]);
              break;
            case 3:
              this.carritoService.setBanners('xplora',this.Banners[i]);
              this.bExplora=true;
              break;
            case 4:
              this.carritoService.setBanners('espacio',this.Banners[i]);
              this.bEspacio=true;
              break; 
            case 16:
              this.carritoService.setBanners('visita',this.Banners[i]);
              this.bVisita=true;
              break;                          
            default: 
            break;
          }
        //}
      }
      */
      //console.log(this.carritoService.getBanners('concurso'));
    })
  }

  delBanners(){ 
    localStorage.removeItem('curso');
    localStorage.removeItem('concurso');
    localStorage.removeItem('torneo');
    localStorage.removeItem('beca');
    localStorage.removeItem('orientador');
    localStorage.removeItem('xplora');
    localStorage.removeItem('espacio');
    localStorage.removeItem('visita');
    //alert('limpia');
  }



}
