<!--Contenedor-->
<div class="container align-items-center flex-column">

<!--Encabezado-->
<div class="text-center">
  <h4 class="title-section">Catálogo - Sub Categoría</h4>
</div>


  <!--Filtros-->
  <form  [formGroup]="filtros" >
    <div class="row">
      <div class="col-lg-6">
        <label>Grupo</label>
        <select class="form-control"
        formControlName="tema_categoriaId" [(ngModel)]="mf.tema_categoriaId" (change)="filtrar()">
          <option value="">--Seleccionar--</option>
          <option *ngFor="let item of Categoria | async" 
            value="{{ item.tema_categoriaId }}" >
            {{ item.descripcion }}
            </option>
        </select>
      </div>
      <div class="col-lg-6">
        <label>Estado</label>
        <select class="form-control"
          formControlName="activo" [(ngModel)]="mf.activo" (change)="filtrar()">
          <option value="">--Seleccionar--</option>
          <option value="S">Activo</option>
          <option value="N">Inactivo</option>
        </select>
      </div>      

    </div>
    </form>

<!--Listado-->
<div class="row justify-content-between py-2">
  <div class="col-md-4 col-sm-12">
      <h5 class="title-subsection">Datos de la sub categoría</h5>
  </div> 
  <div class="col-md-4 col-sm-12 text-right">
      <button class="btn btn-primary btn_sac" (click)="nuevo(content)">Agregar</button>
  </div>     
</div>

<table class="table table-striped text-center table-fixed">
  <thead>
    <tr>
      <th scope="col" class="encabezado">#</th>
      <th scope="col" class="encabezado">Descripción</th>
      <th scope="col" class="encabezado">Categoría</th>
      <th scope="col" class="encabezado">Estatus</th>
      <th class="encabezado">Acciones</th>
    </tr>
  </thead>
  <tbody>
    <tr #item *ngFor="let item of SubCategorias; let i = index">
      <td scope="row">{{ i+1 }}</td>
      <td class="w-25">{{ item.descripcion }}</td>
      <td class="w-25">{{ item.categoriaDescripcion }}</td>
      <td>{{ item.activo=='S'?"Activo":"Inactivo" }}</td>
      <td>
        <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar">
          <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
        </button>
      </td>
    </tr>
  </tbody>
  <tfoot class="tfoot-ibero text-center">
    <tr>
      <td colspan="5">{{ (SubCategorias)?.length }} Registros</td>
    </tr>
  </tfoot>   
</table>

</div>
<!--Código nueva vista-->

<ng-template #content let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Datos de la sub categoría</div>
      <div class="card-body text-dark">

        <form [formGroup]="forma">
          <div class="row text-dark">
            <input class="form-control" type="hidden" formControlName="tema_subcategoriaId"
                   [(ngModel)]="model.tema_subcategoriaId">
            <input class="form-control" type="hidden" formControlName="tema_categoriaId"
                   [(ngModel)]="model.tema_categoriaId">
          </div>
          <div class="row text-dark">
            <div class="form-group col-lg-6">
              <label for="descripcion">Descripción:</label>
              <input class="form-control" type="text" formControlName="descripcion"
                     [(ngModel)]="model.descripcion">
            </div>
            <div class="form-group col-lg-6">
              <label for="tema_categoriaId">Categoría:</label>
              <select class="form-control"
                      formControlName="tema_categoriaId"
                      [(ngModel)]="model.tema_categoriaId">
                <option value="">--Seleccionar--</option>
                <option *ngFor="let item of Categoria | async"
                        [ngValue]="item.tema_categoriaId">
                  {{ item.descripcion }}
                </option>
              </select>
            </div>
          </div>
          <div class="row text-dark">
            <div class="form-group col-lg-6">
              <label for="activo">Estatus</label>
              <select class="form-control" formControlName="activo"
                      [(ngModel)]="model.activo">
                <option value="">--Seleccionar--</option>
                <option value="S">Activo</option>
                <option value="N">Inactivo</option>
              </select>
            </div>
            </div>
            <div class="row text-dark pt-3">
              <div class="col-lg-12">
                <div class="buttonSelect text-center">
                  <button class="btn btn-danger" (click)="modal.close()">Regresar</button>&nbsp;
                  <button class="btn btn-danger" (click)="guardar()">Guardar</button>
                </div>
              </div>
            </div>
</form>
      </div>
    </div>

  </div>
</ng-template>
