import { Component, OnInit, Input } from '@angular/core';
import { DiaInhabilService } from '../../../core/services/dia-inhabil.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioService } from '../../../core/services/usuario.service';

@Component({
  selector: 'app-dia-inhabil',
  templateUrl: './dia-inhabil.component.html',
  styles: [
  ]
})
export class DiaInhabilComponent implements OnInit {
    DiaInhabiles;
    DiaInhabil;
    forma: FormGroup;
    model;
    constructor(
        public fb: FormBuilder,
        private router: Router,
        private usuarioService: UsuarioService,
        private http: HttpClient,
        private diaInhabilService: DiaInhabilService,
        private spinner: NgxSpinnerService,
        public modal: NgbModal
    ) {
        if(!this.usuarioService.int()) this.router.navigate(['/portal']);
        this.iniciarForm();
    }

    ngOnInit(): void {
        this.loadData();
    }

    iniciarForm() {
        this.forma = this.fb.group({
            diainhabilId: [0],
            nombre: ['', [Validators.required]],
            fecha_diainhabil: [0, [Validators.required]],
            activo: ['S', [Validators.required]]
        })
        this.model = this.forma;
    }

    loadData(){
        //this.Grados = this.gradoservice.getData();
        this.spinner.show();
        this.diaInhabilService.getData().toPromise()
        .then(data => {
            this.spinner.hide();
            //Asignamos el universo de items al listado, mediante una entidad de filtro
            this.DiaInhabiles = data;
        })
    }

    nuevo(content) {
        this.iniciarForm();
        this.model.activo = 'S';
        this.modal.open(content,{size:'xl'});
    }

    editar(content, modelo) {
        this.model = modelo;
        this.model.fecha_diainhabil = new Date(modelo.fecha_diainhabil);
        this.modal.open(content,{size:'xl'});
    }

    guardar() {
        if (this.forma.controls["diainhabilId"].value == undefined) {
            this.diaInhabilService.addData(this.forma.value).subscribe(
                (res) => {this.recargar();},
                (error) => {console.log(error);});
        }
        else {
            this.diaInhabilService.updData(this.forma.controls["diainhabilId"].value, this.forma.value).subscribe(
                (res) => {this.recargar();},
                (error) => {console.log(error);});
        }
    }

    recargar() {
        this.modal.dismissAll();
        //this.Grados = this.gradoservice.getData();
        this.loadData();
    }
}
