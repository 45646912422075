<!--Contenedor-->
<div class="container align-items-center flex-column">

<!--Encabezado-->
<div class="text-center">
    <h4 class="title-section">Catálogo - Campos de solicitud</h4>
  </div>
 
  <!--Filtros-->
  <form  [formGroup]="filtros" >
    <div class="row">   
      <div class="col-lg-6">
        <label>Grupo actividad</label>
        <select class="form-control"
        formControlName="actividad_grupoId" [(ngModel)]="mf.actividad_grupoId" (change)="filtrar()">
          <option value="">--Seleccionar--</option>
          <option *ngFor="let item of ActividadGrupo | async" 
            value="{{ item.actividad_grupoId }}" >
            {{ item.descripcion }}
            </option>
        </select>
      </div>
      <div class="col-lg-6">
        <label>Estado</label>
        <select class="form-control"
          formControlName="activo" [(ngModel)]="mf.activo" (change)="filtrar()">
          <option value="">--Seleccionar--</option>
          <option value="S">Activo</option>
          <option value="N">Inactivo</option>
        </select>
      </div>      

    </div>
    </form>

<!--Listado-->
<div class="row justify-content-between py-2">
    <div class="col-md-4 col-sm-12">
        <h5 class="title-subsection">Datos de la solicitud</h5>
    </div> 
    <div class="col-md-4 col-sm-12 text-right">
        <!--button class="btn btn-primary btn_sac" (click)="nuevo(content)">Agregar</button-->
    </div>     
</div>   
  
<table class="table table-striped text-center">
  <thead>
    <tr>
      <th scope="col" class="encabezado">#</th>
      <th scope="col" class="encabezado">Nombre actividad</th>
      <th scope="col" class="encabezado">Nombre actividad (portal)</th>
      <th scope="col" class="encabezado">Grupo actividad</th>
      <th scope="col" class="encabezado">Estatus</th>
      <th scope="col" class="encabezado">Acciones</th>
    </tr>
  </thead>
  <tbody>
    <tr #item *ngFor="let item of Actividades; let i = index">
      <td scope="row">{{ i+1 }}</td>
      <td class="w-25">{{ item.nombre }}</td>
      <td class="w-25">{{ item.nombreaportal }}</td>
      <td class="w-25">{{ item.actividadGrupoDescripcion }}</td>
      <td>{{ item.activo=='S'?"Activo":"Inactivo" }}</td>
      <td>
        <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar">
          <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
        </button>
      </td>
    </tr>
  </tbody>
  <tfoot class="tfoot-ibero text-center">
    <tr>
      <td colspan="6">{{ (Actividades)?.length }} Registros</td>
    </tr>
  </tfoot>    
</table>

</div>

  <!--Formulario-->
  <ng-template #content let-modal>
    <div class="modal-hidden">
      <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card border-dark mb-0">
        <div class="card-header text-white text-center bg-dark">Campos de Solicitud</div>
        <div class="card-body text-dark">
  
          <form [formGroup]="forma">
            <div class="row text-dark text-center">
              <div class="form-group col-lg-12">
              <input class="form-control" type="hidden" formControlName="actividadId"
                [(ngModel)]="model.actividadId">

                <h5 class="title-section">{{model.nombre}}</h5>
              </div>
            </div>

<!--Checkboxes-->
            <div class="row text-dark">

              <div class="form-group col-lg-6">
                <h5 class="title-subsection">Solicitud</h5>
                <ng-container *ngFor="let item of TotCampos">
                  
                  <p *ngIf="item.tipo_campo===1">
                    <label>
                      <input type="checkbox"
                      name="opts"
                      id="{{item.orv_ac_actividad_campoId}}"
                      value="{{item.tipo_campo}}"
                      >
                      <span class="label-text text-danger ml-1">{{ item.descripcion }}</span>
                    </label>
                  </p>
                </ng-container>

                <label>
                  <input type="checkbox"
                  name="mast01"
                  id="mast01"
                  (click)="allChecks(1)"
                  >
                  <span class="label-text text-danger pl-1">Seleccionar Todos</span>
                </label>

              </div>
              <div class="form-group col-lg-6">
                <h5 class="title-subsection">Internos</h5>
                <ng-container *ngFor="let item of TotCampos">
                  <p *ngIf="item.tipo_campo===2">
                    <label>
                      <input type="checkbox" 
                      name="opts"
                      id="{{item.orv_ac_actividad_campoId}}"
                      value="{{item.tipo_campo}}"
                      >
                      <span class="label-text text-danger ml-1">{{ item.descripcion }}</span>
                    </label>
                  </p>
                </ng-container>

                <label>
                  <input type="checkbox"
                  name="mast02"
                  id="mast02"
                  (click)="allChecks(2)"
                  >
                  <span class="label-text text-danger pl-1">Seleccionar Todos</span>
                </label>

              </div> 
            </div>
              
              <!--div class="form-group col-lg-6">
                <h5 class="title-subsection">Solicitud</h5>
                <p *ngFor="let item of Rol | async">
                <label>
                  <input type="checkbox" 
                  name="campos" [ngValue]="item.rolId">
                  <span class="label-text">{{ item.descripcion }}</span>
                </label>
                </p>
              </div>
              <div class="form-group col-lg-6">
                <h5 class="title-subsection">Internos</h5>
                <p *ngFor="let item of Rol | async">
                <label>
                  <input type="checkbox" 
                  name="campos" [ngValue]="item.rolId">
                  <span class="label-text">{{ item.descripcion }}</span>
                </label>
                </p>
              </div>              
          </div-->
<!--Formulario
            <div class="row text-dark">
                <div class="form-group col-lg-6">
                  <label for="descripcion">Descripción:</label>
                  <input class="form-control" type="text" formControlName="descripcion"
                         [(ngModel)]="model.descripcion">
                </div>
                <div class="form-group col-lg-6">
                  <label for="activo">Estatus</label>
                  <select class="form-control" formControlName="activo"
                          [(ngModel)]="model.activo">
                    <option value="">--Seleccionar--</option>
                    <option value="S">Activo</option>
                    <option value="N">Inactivo</option>
                  </select>
                </div>                            
            </div>
            -->
            <div class="row text-dark pt-3">
                <div class="col-lg-12">
                    <div class="buttonSelect text-center">
                        <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                        <button class="btn btn-danger" (click)="guardar()">Guardar</button>
                    </div>
                </div>
            </div>
        </form>  
        </div>
      </div>
      
    </div>
   </ng-template>