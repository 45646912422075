<!-- Becas Section-->
<section class="section pef6f" *ngIf="this.cs()">
  <img src="/assets/img/sol_bg_becas.svg" alt="" style="float: left; margin-left:-100px; width: 250px; padding-top: 80px; z-index:2; position: absolute; ">
    <div class="container-fluid d-flex align-items-center flex-column">
        <div class="row sol-titulo pt-5">
            <div class="col-md-12 text-center my-auto">
                <h2 class="titulo-seccion-becas text-secondary mb-12">
                    Becas
                </h2>
            </div>
        </div>
        <div class="container">
          <div class="row pt-5">
            <div class="col-md-12 text-secondary text-left text-parrafo texto_becas">
                Nos esforzamos en abrir las puertas a tus estudiantes para que puedan colaborar eficazmente al bien común de nuestro
                país, facilitando las posibilidades de estudio mediante distintos programas de becas que podrás consultar en esta
                sección.
            </div>
          </div>

           <div class="row align-items-center pt-3">
             <div class="col-sm-12 col-md-7 col-lg-7  content-img-becas">
              <img  class="img_student_beca seccion-avatar-01 animate__animated animate__backInUp student" src="/assets/img/Becario.svg" alt="" />
             </div>
             <div class="col-sm-12 col-md-5 col-lg-5 text-left link_becas">
              <p *ngFor="let item of arrBanners">
                <a class="text-danger" href="{{ item.referencia }}" target="_blank">{{ item.descripcion }}</a>
              </p>
             </div>
           </div>
        </div>
    </div>
</section>
