<!--Contenedor-->
<div class="container align-items-center flex-column">
    <!--Encabezado-->
    <div class="text-center">
        <h4 class="title-section">Agenda</h4>
    </div>

    <div class="alert alert-success" *ngIf="!(this.authService.user === undefined)">
        Se encuentra autenticado correctamente. Para cerrar sesión dar click
        <span class="MGClass_LogOutLink" (click)="this.authService.signOut()">aquí</span>
    </div>
    <div class="alert alert-danger" *ngIf="(this.authService.user === undefined)">
        Para poder agregar o editar las actividades, es necesario autenticarse. Para hacerlo, da click <span
            class="MGClass_LogInLink" (click)="MGEvent_AskUserToLogIn()">aquí</span>
    </div>


    <!--Listado-->
    <div class="row justify-content-between py-2">
        <div class="col-md-4 col-sm-12">
            <h5 class="title-subsection">Lista de Actividades</h5>
        </div>
        <div class="col-md-4 col-sm-12 text-right">
            <a class="mr-2 btn btn-primary btn_sac" [routerLink]="['/agenda']">
                <fa-icon [icon]="['far','calendar-alt']"></fa-icon>
            </a>
            <button class="btn btn-primary btn_sac" (click)="nuevo(content)">
                <fa-icon [icon]="['far','calendar-plus']"></fa-icon>
            </button>
        </div>
    </div>

    <table class="table table-striped text-center table-fixed">
        <thead>
            <tr>
                <th scope="col" class="encabezado">#</th>
                <th scope="col" class="encabezado">Actividad</th>
                <th scope="col" class="encabezado">Tipo de Actividad</th>
                <th scope="col" class="encabezado">Fecha y Hora Reservación</th>
                <th scope="col" class="encabezado">Duración</th>
                <th scope="col" class="encabezado">Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr #item *ngFor="let item of Agenda; let i = index">
                <td scope="row">{{ i+1 }}</td>
                <td class="w-25">
                    {{ item.nombre }}
                </td>
                <td class="w-25">
                    {{ item.tipo }}
                </td>
                <td class="w-25">
                    {{ item.fecha_actividad | date:'yyyy-MM-dd' }} {{ item.hora_actividad }}
                </td>
                <td>
                    {{ item.duracion }}
                </td>
                <td>
                    <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar">
                        <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
                    </button>
                    <button class="btn icon-btn" (click)="MostrarVentana_BorradoDeActividad(deleteWindow, item)"
                        ngbTooltip="Borrar">
                        <fa-icon [icon]="['fas','trash']" class="text-dark"></fa-icon>
                    </button>
                </td>
            </tr>
        </tbody>
        <tfoot class="tfoot-ibero text-center">
            <tr>
                <td colspan="6">{{ (Agenda)?.length }} Registros</td>
            </tr>
        </tfoot>
    </table>
</div>

<!--Formulario-->
<ng-template #deleteWindow let-modal>
    <div class="modal-hidden">
        <button class="close" aria-label="close" type="button" (click)="modal.dismiss(); this.recargar()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card border-dark mb-0">
            <div class="card-header text-white text-center bg-dark">Borrar Actividad</div>
            <div class="card-body text-dark">
                <div class=" card-body row d-flex justify-content-center">

                    <div>
                        <div>¿Está seguro de borrar la actividad?</div>

                        <div class="d-flex justify-content-center"><b>{{model.nombre}}</b></div>

                        <br />
                        <div>
                            <button class="btn btn-danger spaceHorizontal" (click)="BorrarActividad()"
                                ngbTooltip="Borrar">
                                Borrar
                            </button>

                            <button class="btn btn-secondary spaceHorizontal" (click)="modal.dismiss()"
                                ngbTooltip="Cancelar">
                                Cancelar
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #content let-modal>
    <div class="modal-hidden">
        <button class="close" aria-label="close" type="button" (click)="modal.dismiss();this.recargar()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card border-dark mb-0">
            <div class="card-header text-white text-center bg-dark">Actividad</div>
            <div class="card-body text-dark">
                <div class=" card-body row">
                    <div class="col-12">
                        <form [formGroup]="forma">
                            <div class="row text-dark">
                                <input class="form-control" type="hidden" formControlName="agenda_actividadId"
                                    [(ngModel)]="model.agenda_actividadId">
                            </div>
                            <div class="row text-dark">
                                <div class="form-group col-lg-12">
                                    <label for="nombre">Actividad:</label>
                                    <input class="form-control" type="text" formControlName="nombre"
                                        [(ngModel)]="model.nombre">
                                </div>
                            </div>
                            <div class="row text-dark">
                                <div class="form-group col-lg-6">
                                    <label>Tipo de Actividad</label>
                                    <select class="form-control" formControlName="tipo" [(ngModel)]="model.tipo">
                                        <option value="">--Seleccionar--</option>
                                        <option value="Interna">Interna</option>
                                        <option value="Externa">Externa</option>
                                    </select>
                                </div>
                                <div class="form-group col-lg-6">
                                    <label>Calendario</label>
                                    <select class="form-control" formControlName="microGraphCalendarId"
                                        [(ngModel)]="model.microGraphCalendarId">
                                        <!--option value="">--Seleccionar--</option-->
                                        <option *ngFor="let calendario of this.calendars" value="{{calendario.id}}" >
                                            {{calendario.name}}</option>
                                    </select>
                                    <input type="hidden" formControlName="microGraphEventId"
                                        [(ngModel)]="model.microGraphEventId" value="{{model.microGraphEventId}}">
                                </div>
                                <div class="form-group col-lg-12">
                                    <label>Invitados:</label>
                                    <ng-multiselect-dropdown [placeholder]="'--Seleccionar--'"
                                        [settings]="dropdownSettings" [data]="dropdownList" formControlName="invitados"
                                        [(ngModel)]="model.invitados" (onSelect)="onItemSelect($event)"
                                        (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                                </div>
                                <div class="form-group col-lg-4">
                                    <label for="fecha_actividad">Fecha:</label>
                                    <div class="input-group">
                                        <input class="form-control" placeholder="yyyy-mm-dd" [readonly]="true"
                                            id="fecha_actividad" [(ngModel)]="model.fecha_actividad"
                                            formControlName="fecha_actividad" ngbDatepicker #d="ngbDatepicker"
                                            [markDisabled]="isDisabled">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                                type="button"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-lg-4">
                                    <label>Hora de Inicio</label>
                                    <input class="form-control" type="time" value="08:00:00"
                                        formControlName="hora_actividad" [(ngModel)]="model.hora_actividad">
                                </div>
                                <div class="form-group col-lg-4">
                                    <label>Hora Fin</label>
                                    <input class="form-control" type="time" value="08:00:00" formControlName="hora_fin"
                                        [(ngModel)]="model.hora_fin">
                                </div>
                            </div>
                            <div class="row text-dark pt-3">
                                <div class="col-lg-12">
                                    <div class="buttonSelect text-center">
                                        <button class="btn btn-danger"
                                            (click)="modal.close(); this.recargar();">Cancelar</button>&nbsp;
                                        <button class="btn btn-danger" (click)="guardar()"
                                            [disabled]="forma.invalid">Guardar</button>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>