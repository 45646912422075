import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioService } from '../../../core/services/usuario.service';

@Component({
  selector: 'app-notificacion',
  templateUrl: './notificacion.component.html',
  styles: [
  ]
})
export class NotificacionComponent implements OnInit {

  forma: FormGroup;
  model;
  constructor(
    public fb: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,
    public modal: NgbModal
  ) { 
    if(!this.usuarioService.int()) this.router.navigate(['/portal']);
  }

  ngOnInit(): void {
  }

  iniciarForm() {
    this.forma = this.fb.group({
      descripcion: ['', [Validators.required]],
      gradoId: [0],
      activo: ['S', [Validators.required]]
    })
    this.model = this.forma;
  }

  nuevo(content) {
    this.iniciarForm();
    this.model.activo = 'S';
    this.modal.open(content,{size:'xl'});
  }

}
