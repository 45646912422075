import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';
import { config } from '../services/config';
import { UsuarioService } from '../services/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class DiaInhabilService {
  user = [];
  Usuario:any;
  requestOptions:any;
  requestFileOptions:any;
  constructor( private http: HttpClient,
    private usuarioService: UsuarioService ) {    
    this.requestOptions = this.getHeader();
    this.requestFileOptions = this.getFileHeader();
  }

  getHeader() {
    this.Usuario = this.usuarioService.getUser();
    let header = new HttpHeaders({ "Authorization": "Bearer " + this.Usuario.token });
    return { headers: header };
  }

  getFileHeader() {
    this.Usuario = this.usuarioService.getUser();
    let header = new HttpHeaders({ "Authorization": "Bearer " + this.Usuario.token });
    return { headers: header, responseType: 'blob' }
  } 
  //Consulta
  getData() {
    return this.http.get(config.apiUrl + '/dia_inhabil', this.requestOptions);
  }

  //Alta
  addData(data): Observable<any> {
    return this.http.post(config.apiUrl + '/dia_inhabil/', data, this.requestOptions);
  }

  //Cambio
  updData(id, data): Observable<any> {
    return this.http.put(config.apiUrl + '/dia_inhabil/' + id, data, this.requestOptions);
  }

}
