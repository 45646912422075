<!--Contenedor-->
<div class="container align-items-center flex-column">

  <!--Encabezado-->
  <div class="text-center">
    <h4 class="title-section">Catálogo - Actividades</h4>
  </div>

  <!--Filtros-->
  <form  [formGroup]="filtros" >
    <div class="row">
      <div class="col-lg-6">
        <label>Grupo actividad</label>
        <select class="form-control"
        formControlName="actividad_grupoId" [(ngModel)]="mf.actividad_grupoId" (change)="filtrar()">
          <option value="">--Seleccionar--</option>
          <option *ngFor="let item of ActividadGrupo | async"
            value="{{ item.actividad_grupoId }}" >
            {{ item.descripcion }}
            </option>
        </select>
      </div>
      <div class="col-lg-6">
        <label>Estado</label>
        <select class="form-control"
          formControlName="activo" [(ngModel)]="mf.activo" (change)="filtrar()">
          <option value="">--Seleccionar--</option>
          <option value="S">Activo</option>
          <option value="N">Inactivo</option>
        </select>
      </div>

    </div>
    </form>

  <!--Listado-->
  <div class="row justify-content-between py-2">
    <div class="col-md-4 col-sm-12">
        <h5 class="title-subsection">Datos de la actividad</h5>
    </div>
    <div class="col-md-4 col-sm-12 text-right">
        <button class="btn btn-primary btn_sac" (click)="nuevo(content)">Agregar</button>
    </div>
  </div>

  <table class="table table-striped text-center">
    <thead>
      <tr>
        <th scope="col" class="encabezado">#</th>
        <th scope="col" class="encabezado">Nombre actividad</th>
        <th scope="col" class="encabezado">Descripción (portal)</th>
        <th scope="col" class="encabezado">Grupo actividad</th>
        <th scope="col" class="encabezado">Estatus</th>
        <th scope="col" class="encabezado">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr #item *ngFor="let item of Actividades; let i = index">
        <td scope="row">{{ i+1 }}</td>
        <td class="w-25">{{ item.nombre }}</td>
        <td class="w-25">{{ item.nombreaportal }}</td>
        <td class="w-25">{{ item.actividadGrupoDescripcion }}</td>
        <td>{{ item.activo=='S'?"Activo":"Inactivo" }}</td>
        <td>
          <button class="btn icon-btn" (click)="editar(content, item)" ngbTooltip="Editar">
            <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
          </button>
        </td>
      </tr>
    </tbody>
    <tfoot class="tfoot-ibero text-center">
      <tr>
        <td colspan="6">{{ (Actividades)?.length }} Registros</td>
      </tr>
    </tfoot>
  </table>
</div>

<!--Formulario-->
<ng-template #content let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Datos de la actividad</div>
      <div class="card-body text-dark">

        <form [formGroup]="forma">
          <div class="row text-dark">
            <input class="form-control" type="hidden" formControlName="actividadId"
                   [(ngModel)]="model.actividadId">
          </div>
          <div class="row text-dark">
              <!--div class="col-lg-6">
                  <label for="clave">Clave de la actividad</label><br/>
                  <input type="text" id="clave" class="form-control" formControlName="clave" [(ngModel)]="model.clave">
              </div-->
              <div class="form-group col-lg-6">
                <label for="nombreaportal">Descripción (portal)</label>
                <input class="form-control" type="text" formControlName="nombreaportal" [(ngModel)]="model.nombreaportal">
              </div>
              <div class="col-lg-6">
                  <label for="nombre">Nombre de la actividad</label><br/>
                  <input type="text" id="nombre" class="form-control" formControlName="nombre" [(ngModel)]="model.nombre">
              </div>
          </div>
          <div class="row text-dark">
            <div class="col-lg-6">
              <label for="tmpo_min_sol">Tiempo mínimo solicitado (días)</label><br/>
              <input type="number" id="tmpo_min_sol" class="form-control" formControlName="tmpo_min_sol" [(ngModel)]="model.tmpo_min_sol">
            </div>
            <div class="col-lg-6">
              <label for="ind_req_pago">¿Requiere pago?</label><br/>
              <select class="form-control" id="ind_req_pago"
              formControlName="ind_req_pago"
              [(ngModel)]="model.ind_req_pago">
                  <option value="">--Seleccionar--</option>
                  <option value="S">SI</option>
                  <option value="N">NO</option>
              </select>
            </div>
          </div>
          <div class="row text-dark">
            <div class="form-group col-lg-6">
              <label for="actividad_sitioId">Tipo actividad (Sitio)</label>
              <select class="form-control"
                      formControlName="actividad_sitioId"
                      [(ngModel)]="model.actividad_sitioId">
                <option [ngValue]="">--Seleccionar--</option>
                <option *ngFor="let item of ActividadSitio | async"
                        [ngValue]="item.actividad_sitioId">
                  {{ item.descripcion }}
                </option>
              </select>
            </div>
            <div class="form-group col-lg-6">
              <label for="actividad_grupoId">Grupo actividad</label>
              <select class="form-control"
                      formControlName="actividad_grupoId"
                      [(ngModel)]="model.actividad_grupoId">
                <option [ngValue]="">--Seleccionar--</option>
                <option *ngFor="let item of ActividadGrupo | async"
                        [ngValue]="item.actividad_grupoId">
                  {{ item.descripcion }}
                </option>
              </select>
            </div>
          </div>
          <div class="row text-dark">
            <div class="form-group col-lg-6">
              <label for="activo">Modalidad</label>
              <select class="form-control" formControlName="modalidadId"
                      [(ngModel)]="model.modalidadId">
                <option [ngValue]="">--Seleccionar--</option>
                <option [ngValue]="1">Presencial</option>
                <option [ngValue]="2">En línea</option>
                <option [ngValue]="3">Ambas</option>
              </select>
          </div>
            <div class="col-lg-6">
              <label for="activo">Estatus</label>
              <select class="form-control" formControlName="activo"
                      [(ngModel)]="model.activo">
                <option value="">--Seleccionar--</option>
                <option value="S">Activo</option>
                <option value="N">Inactivo</option>
              </select>
            </div>
          </div>
          <div class="row text-dark pt-3">
              <div class="col-lg-12">
                  <div class="buttonSelect text-center">
                      <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                      <button class="btn btn-danger" (click)="guardar()">Guardar</button>
                  </div>
              </div>
          </div>

      </form>


      </div>
    </div>

  </div>
  <!--div class="modal-footer">
    <button class="btn btn-danger" (click)="modal.close()">Regresar</button>&nbsp;
    <button class="btn btn-danger" (click)="modal.close()">Guardar</button>
  </div-->

 </ng-template>
