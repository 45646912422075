import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UsuarioService } from '../../../core/services/usuario.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
//import { RouterModule, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { config } from '../../../core/services/config';
import { NgRecaptcha3Service } from 'ng-recaptcha3';
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [
  ]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  enabled = false;
  User;
  /*
  usuario;
  password;
  contrasenia;
  correo;
  */
  constructor(
    private formBuilder: FormBuilder,
    private usuarioservice: UsuarioService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private recaptcha3: NgRecaptcha3Service
    //public router: Router
  ) {
    localStorage.setItem('valido', '0');
    this.iniciarForm();
    //this.clear();
  }
  Mensaje;

  ngOnInit(): void {
    //localStorage.removeItem('user');
    //alert(this.usuarioservice.val());
    localStorage.setItem('csh', '');
    if (config.clientKey != "") {
      //console.log(config);
      this.recaptcha3.init(config.clientKey).then(status => {
        // status: success/error
        // success - script is loaded and greaptcha is ready
        // error - script is not loaded
        //console.log(status)
        if (status == 'error') {
          this.toastr.error('No se cargó el script. Intente de nuevo más tarde.');
        }
      });
    }
    //this.router.navigateByUrl('/login');
    this.clr();
    this.clear();
  }

  clear(){

    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    localStorage.removeItem('tipoUsuario');
    localStorage.removeItem('perfil');
    localStorage.removeItem('rolId');
    localStorage.removeItem('usuario');
    localStorage.removeItem('banner');
    localStorage.removeItem('actividad');
    localStorage.removeItem('curso');
    localStorage.removeItem('concurso');
    localStorage.removeItem('torneo');
    localStorage.removeItem('beca');
    localStorage.removeItem('orientador');
    localStorage.removeItem('xplora');
    localStorage.removeItem('espacio');
    localStorage.removeItem('visita');

    //localStorage.removeItem('carrito');
  }

  clr(){
      var cookies = document.cookie.split("; ");
      for (var c = 0; c < cookies.length; c++) {
          var d = window.location.hostname.split(".");

          while (d.length > 0) {
              var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';

              var p = location.pathname.split('/');
              document.cookie = cookieBase + '/';
              while (p.length > 0) {
                  document.cookie = cookieBase + p.join('/');
                  p.pop();
              };
              d.shift();
          }
      }
  };

  tokenCaptcha;
  entrar1(){
    if (config.clientKey != "") {
      this.recaptcha3.getToken().then(token => {
        this.tokenCaptcha = token;
        this.loginForm.controls["token"].setValue(this.tokenCaptcha);
        this.entrar();
        /*
        this.usuarioService.loginAccount(this.loginForm.value).toPromise()
          .then(data => {
            this.Cuenta = data;
            console.log(this.Cuenta);
            this.spinner.hide();
            this.login();
          })
          */

      }, error => {
        // get error, e.g. if key is invalid
        this.toastr.error(error);
      });
    }
    else {
      this.tokenCaptcha = "";
      this.entrar();
      /*
      this.usuarioService.loginAccount(this.flogin.value).toPromise()
        .then(data => {
          this.Cuenta = data;
          console.log(this.Cuenta);
          this.spinner.hide();
          this.login();
        });
        */
    }    
  }
  entrar(){
    //this.usuarioservice.login(this.loginForm.value).toPromise()
    //this.actividadporrolservice.getRolBanner(this.model.rolId).toPromise()
    this.spinner.show();
    this.usuarioservice.loginSHA(this.loginForm.value).toPromise()
    .then(data => {
      //alert(data);
      //console.log(data)
      this.spinner.hide();
      this.User = data;
      this.usuarioservice.addUser(this.User);
      
      //console.log(this.User);
      //return false;
      if(this.User.activo=='S'){
        //alert("fin");return false;
        if(this.User.result==2){
          this.router.navigate(['/restaurar']);
          return false;
        }
        
        if(this.User.result==1){
          localStorage.setItem('valido', '1');
          if(this.User.usuario_tipoId==2){
            this.router.navigate(['/portal']);
            return false;
          }
          else{
            this.router.navigate(['/panel']);
            return false;
          }
        }
        else{
            if(this.User.result==4){
              this.Mensaje = "El usuario no tiene perfil configurado.";
              return false;
            }
            else{
                this.Mensaje = "El usuario y/o la contraseña no son válidos.";
                return false;
            }
          //alert("El usuario y/o la contraseña no son válidos.");
          //return false;
        }
      }
      else{
        if(this.User.activo=='N'){
          this.Mensaje = "El usuario está inactivo. Favor de comunicarse con el administrador del Sistema.";
          return false;
          //alert('El usuario está inactivo. Favor de comunicarse con el administrador del Sistema.');
        }
        else{
          this.Mensaje = "El usuario y/o la contraseña no son válidos.";
          return false;
          //alert("El usuario y/o la contraseña no son válidos.");
        }
      }
    });
    
    return false;
    //alert("fin");
    //return false;
  }

  iniciarForm() {
    this.loginForm = this.formBuilder.group({
      correo: ['', Validators.required],
      contrasenia: ['', Validators.required],
      token: [''],
      
    });
  }

  public ngOnDestroy() {
    this.recaptcha3.destroy();
  }

}
