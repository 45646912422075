import { Component, OnInit } from '@angular/core';
import { SolicitudService } from '../../../core/services/solicitud.service';
import { ActividadesService } from '../../../core/services/actividades.service';
import { TransportService } from '../../../core/services/transport.service';
import { UsuarioService } from '../../../core/services/usuario.service';
import { TemaService } from '../../../core/services/tema.service';
import { GradoService } from '../../../core/services/grado.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { GraphService } from './../../../MicrosoftGraphServices/graph.service';
import { AgendaService } from '../../../core/services/agenda.service';
import { Invitado } from 'src/app/core/HelperClases/Invitado';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import Swal from 'sweetalert2';
import { ToastService } from 'src/app/services/toast.service';

//import { Solicitud } from '../../../shared/models/solicitud';

@Component({
  selector: 'app-solicitud',
  templateUrl: './solicitud.component.html',
  styles: [
  ]
})
export class SolicitudComponent implements OnInit {

  //Solicitud;
  //Solicitudes;
  //SolicitudesFiltro;
  SolicitudesFiltroTemp;
  //EstadoActividad;
  //NombreActividad;

  model;
  mf;

  SelSolicitud;
  User;

  forma: FormGroup;
  filtros: FormGroup;
  formaServicio: FormGroup;

  fevento: FormGroup;
  mevento;
  //Solicitud;

  public paginatedItems: any[] = [];
  public searchText: string = '';
  public currentPage: number = 1;
  public itemsPerPage: number = 15; 

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,
    private solicitudservice:SolicitudService,
    private actividadesService:ActividadesService,
    private transportService:TransportService,
    private temaService: TemaService,
    private gradoService: GradoService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal,
    public graphService: GraphService,
    private agendaService: AgendaService,
    private toastService: ToastService

  ) {
    if(!this.usuarioService.int()) this.router.navigate(['/portal']);
    //Inicializamos formulario y modelo, sin llamar ninguna función
    this.iniciarForma();
  }

  iniciarForma(){
    this.forma = this.fb.group({

      solicitud_actividadId: 0,
      actividadId: 0,
      num_alumnos_actividad:0,
      num_alumnos_material:0,
      temaId: 0,
      tema_categoriaId:0,
      tema_subcategoriaId:0,
      otro_tema: '',
      gradoId: 0,
      ind_computadora: '',
      ind_internet: '',
      ind_estacionamiento: '',
      hora_ini_actividad: '',
      hora_fin_actividad: '',
      fecha_actividad: '',
      usuario_solId: 0,
      ind_usu_sol_resp: '',
      colegioId: 0,
      colegio_responsableId: 0,
      notas: '',
      notas_transporte: '',
      notas_material: '',
      notas_viaticos: '',
      cve_carrera: '',
      fecha_registro: '',
      estatus_actividadId: 0,
      usuario_estatusId: 0,
      fecha_estatus: '',
      nombreActividad: '',
      nombreAPortalActividad: '',
      descripcionTema: '',
      descripcionGrado: '',
      nombreColegioResponsable: '',
      apaternoColegioResponsable: '',
      amaternoColegioResponsable: '',
      nombreColegio: '',
      descripcionEstatus: '',
      usuarioId: 0,
      nombreUsuario: '',
      apaternoUsuario: '',
      amaternoUsuario: '',
      transporteId: 0,
      hora_sal_colegio: '',
      hora_sal_ibero: '',
      correoSolicita:'',
      telefonoSolicita:'',
      correoResponsable:'',
      telefonoResponsable:'',
      enlace_plataforma:'',
      solicitud_servicioId:0,
      usuario_tipoId:0,
      perfilId:0,
      rolId:0,
      idusuarioInternoResponsable:0,
      usuarioInternoResponsable:'',
      correoInternoResponsable:'',
      //agenda_actividadId:0,
      microGraphCalendarId:'',
      microGraphEventId:'',
    });

    //Inicializamos el modelo
    this.model = this.forma;

    this.filtros = this.fb.group({
      estatus_actividadId: 0,
      actividadId: 0,
      fecha: '',
      usuarioInternoResponsable: ''
    });
    this.mf = this.filtros;

    //modelo de evento
    this.fevento = this.fb.group({
      agenda_actividadId: [0],
      nombre: ['', [Validators.required]],
      tipo: ['', [Validators.required]],
      fecha_actividad: [0, [Validators.required]],
      hora_actividad: [0, [Validators.required]],
      hora_fin: [0, [Validators.required]],
      duracion: [0, [Validators.required]],
      invitados: [[]],
      usuario_registroId: [0],
      usuario_modificaId: [0],
      microGraphCalendarId: ['', [Validators.required]],
      microGraphEventId: [''],
      solicitud_actividadId: [0],
    });
    this.mevento = this.fevento;    

  }  

  bBorrar;  
  ngOnInit(): void {
    this.User = this.usuarioService.getUser();
    //console.log(this.User);
    this.bBorrar=false;
    if(this.User.perfilId == 1){
      this.bBorrar=true;
    }

    //Cargamos Listas
    this.addEstadoActividad();
    this.addActividades();
    this.addGrados();
    this.addTransporte();

    this.addSolicitudes();
    this.addCatalogos();
    this.sort();

  }

  EstadoActividad;
  addEstadoActividad(){
    this.solicitudservice.getEstadoActividad().toPromise()
      .then(data => {
        this.EstadoActividad = data;
        //console.log(this.EstadoActividad);
        //Agregamos estatus virtual de Realizada
        //let realizada = { estatus_actividadId: 100, perfilId: 1, descripcion: "Realizada", activo: "S" }
        //this.EstadoActividad.push(realizada);
      });  
  }

  NombreActividad;
  addActividades(){
    this.actividadesService.getActividadesActivas(this.User.usuarioId).toPromise()
    .then(data => {this.NombreActividad = data;});
  }

  Grados;
  addGrados(){
    this.gradoService.getData().toPromise()
    .then(data => {this.Grados = data;});  
  }

  Transporte;
  addTransporte(){
    this.transportService.getData().toPromise()
    .then(data => {this.Transporte = data;});    
  }  

  Solicitud;
  Solicitudes = [];
  SolicitudesTemp = [];
  addSolicitudes(){
    this.User = this.usuarioService.getUser();
    this.spinner.show();
    this.solicitudservice.getSolicitudRol(this.User.rolId).toPromise()
    .then(data => {

      if (Array.isArray(data)) {
        this.SolicitudesTemp = data; // Ahora sabemos que es un array

        this.Solicitud = this.SolicitudesTemp.sort((a, b) => {
          return new Date(b.fecha_registro).getTime() - new Date(a.fecha_registro).getTime();
        });

    
      let fecha;
      var d1;
      var d2;
      for (let i = 0; i < this.Solicitud.length; i++) {
        fecha = this.Solicitud[i].fecha_actividad;
        if(fecha.includes("T")){
          fecha = fecha.split("T")[0];
        }
        d1 = new Date();
        d2 = new Date(fecha);
        //Agregamos estatus virtual de Realizada
        if (d1.getTime() > d2.getTime()) {
          this.Solicitud[i].descripcionEstatus = 'Realizada';
          this.Solicitud[i].estatus_actividadId = 6;
          //this.SelSolicitud[i]["finalizada"] = true;
        }
      }
      }
  
   

      //Asignamos el universo de items al listado, mediante una entidad de filtro
      this.Solicitudes = this.Solicitud;
      this.updatePaginatedItems();

      this.spinner.hide();
    })
  }


addCatalogos(){
  
  //this.addTemas();
  this.addTipoUsuario();
  this.addPerfil();
  this.addRol();
  this.addUsuarios();
  this.addResponsables();
  this.addTemas();
} 

Temas;
TemaLista;
TemasFiltradosTemp;
TemasFiltrados;
addTemas(){
  //this.spinner.show();
  this.User = this.usuarioService.getUser();
  this.temaService.getTemasUsuario(this.User.usuarioId).toPromise()
  .then(data => {
    this.Temas = data;
    //console.log(this.Temas);
    this.Actividad = this.getActividades();
    //console.log(this.Actividad);
    //this.spinner.hide();
  })  
}

TipoUsuario;
TipoUsuarioLista;
addTipoUsuario(){
  this.usuarioService.getTipoUsuario().toPromise()
  .then(data => {
    this.TipoUsuario = data;
    this.TipoUsuario = this.TipoUsuario.filter(x => x.usuario_tipoId === 2);
    this.TipoUsuarioLista = this.TipoUsuario;
  })
}

Perfil;
PerfilLista;
addPerfil(){
  this.usuarioService.getPerfil().toPromise()
    .then(data => {
      this.Perfil = data;
      this.PerfilLista = this.Perfil;
    })
}

Rol;
RolLista;
addRol(){
  this.usuarioService.getRol().toPromise()
    .then(data => {
      this.Rol = data;
      this.RolLista = this.Rol;
    })
}

Responsable;
ResponsableLista;
addResponsables(){
  //this.spinner.show();
  this.usuarioService.getResponsableColegioAll().toPromise()
  .then(data => {
    this.Responsable = data;
    //Asignamos el universo de items al listado, mediante una entidad de filtro
    this.ResponsableLista = this.Responsable;
    //this.spinner.hide();
  })
}

Usuario;
UsuarioLista;
addUsuarios(){
  this.usuarioService.getUsuarioAll().toPromise()
  .then(data => {
    this.Usuario = data;
    //Asignamos el universo de items al listado, mediante una entidad de filtro
    //Dejamos solo los usuarios externos (usuario_tipoId = 2)
    this.Usuario = this.Usuario.filter(x => x.usuario_tipoId === 2);
    this.Usuario = this.Usuario.sort((a, b) => a.colegioNombre > b.colegioNombre ? 1 : -1);
    this.UsuarioLista = this.Usuario;
    //console.log(this.UsuarioLista);
    //this.spinner.hide();
  })
}

fPerfil(){
  this.PerfilLista = this.Perfil.filter(x => x.usuario_tipoId === Number(this.model.usuario_tipoId));
  this.model.perfilId=0;
}

fRol(){
  this.RolLista = this.Rol.filter(x => x.perfilId === Number(this.model.perfilId));  
  this.model.rolId=0;
}

fUsuario(){
  this.UsuarioLista = this.Usuario.filter(x => x.rolId === Number(this.model.rolId));
  this.model.usuarioId=0;
  //Vaciamos valores de usuario
  this.model.nombreUsuario = '';
  this.model.apaternoUsuario = '';
  this.model.amaternoUsuario = '';
  this.model.correoSolicita = '';
  this.model.telefonoSolicita = '';
  this.model.colegioId = 0;  
}

setDatosUsuario(ind){
  let usr = this.Usuario.find(x => x.usuarioId === Number(this.model.usuario_solId));
  //console.log(usr);
  if(usr != undefined){
    this.model.nombreUsuario = usr.nombre;
    this.model.apaternoUsuario = usr.apaterno;
    this.model.amaternoUsuario = usr.amaterno;
    this.model.correoSolicita = usr.correo;
    this.model.telefonoSolicita = usr.telefono + ' Ext: ' + usr.extension;
    this.model.colegioId = usr.colegioId;

    //filtramos los responsables del colegio
    this.ResponsableLista = this.Responsable.filter(x => x.colegioId === Number(this.model.colegioId));
    if(this.ResponsableLista.length==0){
      this.model.colegio_responsableId = 0;
    }

    //Si la llamada al evento viene de un cambio en el combo, reseteamos el colegio_responsableId
    //De lo contrario, preservamos el valor original (para el caso de la carga automática al editar)
    if(ind==1){
      this.model.colegio_responsableId = 0;
    }

  }
  else{
    this.model.nombreUsuario = '';
    this.model.apaternoUsuario = '';
    this.model.amaternoUsuario = '';
    this.model.correoSolicita = '';
    this.model.telefonoSolicita = '';
    this.model.colegioId = 0;
    //this.model.colegio_responsableId = 0;
  }

  this.model.nombreColegioResponsable = '';
  this.model.apaternoColegioResponsable = '';
  this.model.amaternoColegioResponsable = '';
  this.model.correoResponsable = '';
  this.model.telefonoResponsable = '';
  //this.model.colegio_responsableId = 0;
  //this.model.colegioId = 0;

}

setDatosResponsable(){
  if(this.model.colegio_responsableId!=0 && this.model.colegio_responsableId!=undefined){
    let usr = this.Responsable.find(x => x.colegio_responsableId === Number(this.model.colegio_responsableId));
    this.model.nombreColegioResponsable = usr.nombre;
    this.model.apaternoColegioResponsable = usr.apaterno;
    this.model.amaternoColegioResponsable = usr.amaterno;
    this.model.correoResponsable = usr.correo;
    this.model.telefonoResponsable = usr.telefono + ' Ext: ' + usr.extension; 
  } 
  else{
    this.model.nombreColegioResponsable = '';
    this.model.apaternoColegioResponsable = '';
    this.model.amaternoColegioResponsable = '';
    this.model.correoResponsable = '';
    this.model.telefonoResponsable = '';
    this.model.colegioId = 0;
  }
}

Actividad;
ActividadLista;
getActividades(){
  return Array.from(new Set(this.Temas
    .map((item: any) => item.actividadId)))
    .map( actividadId=> { return this.Temas.find(obj => obj.actividadId === actividadId) } )
}

Categoria;
CategoriaLista;
bTemas;
getCategoria(){
  this.CategoriaLista = Array.from(new Set(this.TemasFiltrados
    .map((item: any) => item.tema_categoriaId)))
    .map( tema_categoriaId=> { return this.TemasFiltrados.find(obj => obj.tema_categoriaId === tema_categoriaId) } )
    this.bTemas=true;
    if(this.CategoriaLista.length==0){this.bTemas=false;}
}

setCategoria(){
  this.filtra();
  this.getCategoria();
}

//actividadId;
eActividad;
selCategoria(){
  this.CategoriaLista=[];
  this.model.tema_categoriaId=0;
  this.SubCategoriaLista=[];
  this.model.tema_subcategoriaId=0;
  this.model.temaId=0;
  //this.actividadId = this.model.actividadId;
  this.eActividad = this.NombreActividad.find(x => x.actividadId === Number(this.model.actividadId));  
  this.filtra();
  this.getCategoria();
}

SubCategoria;
SubCategoriaLista;
getSubCategoria(){
  this.SubCategoriaLista = Array.from(new Set(this.TemasFiltrados
    .map((item: any) => item.tema_subcategoriaId)))
    .map( tema_subcategoriaId=> { return this.TemasFiltrados.find(obj => obj.tema_subcategoriaId === tema_subcategoriaId) } )  
}
setSubCategoria(){
  this.filtra();
  this.getSubCategoria();
}
selSubCategoria(){
  this.SubCategoriaLista='';
  this.model.tema_subcategoriaId=0;
  this.model.temaId=0;
  this.filtra();
  this.getSubCategoria();
}

filtra(){
  this.spinner.show();
  this.TemasFiltradosTemp = this.Temas;
  //console.log(this.TemasFiltradosTemp);
  if(this.model.actividadId!=0 && this.model.actividadId!==undefined){
    this.TemasFiltradosTemp = this.TemasFiltradosTemp
    .filter(x => x.actividadId === Number(this.model.actividadId));
  }    

  if(this.model.tema_categoriaId!=0 && this.model.tema_categoriaId!==undefined){
    this.TemasFiltradosTemp = this.TemasFiltradosTemp
    .filter(x => x.tema_categoriaId === Number(this.model.tema_categoriaId));
  }

  if(this.model.tema_subcategoriaId!=0 && this.model.tema_subcategoriaId!==undefined){
    this.TemasFiltradosTemp = this.TemasFiltradosTemp
    .filter(x => x.tema_subcategoriaId === Number(this.model.tema_subcategoriaId));
  }

  this.TemasFiltrados = this.TemasFiltradosTemp;

  this.spinner.hide();
}

  filtrar(){
    //Establecemos los elementos totales de la lista
    this.SolicitudesFiltroTemp = this.Solicitud;

    if(this.mf.estatus_actividadId!=0 && this.mf.estatus_actividadId!==undefined){
      this.SolicitudesFiltroTemp = this.SolicitudesFiltroTemp
      .filter(x => x.estatus_actividadId === Number(this.mf.estatus_actividadId));
    }

    if(this.mf.actividadId!=0 && this.mf.actividadId!==undefined){
      this.SolicitudesFiltroTemp = this.SolicitudesFiltroTemp
      .filter(x => x.actividadId === Number(this.mf.actividadId));
    }

    if(this.mf.usuarioInternoResponsable!=0 && this.mf.usuarioInternoResponsable!==undefined){
      //alert(this.mf.usuarioInternoResponsable);
      if(this.mf.usuarioInternoResponsable=="S"){
        this.SolicitudesFiltroTemp = this.SolicitudesFiltroTemp
        .filter(x => x.usuarioInternoResponsable != "");
      }
      else{
        this.SolicitudesFiltroTemp = this.SolicitudesFiltroTemp
        .filter(x => x.usuarioInternoResponsable == "");        
      }

    }    

    this.Solicitudes = this.SolicitudesFiltroTemp;
    //console.log(this.TemasFiltradosTemp);
    //this.filtraTipoActividad();
  }


  guardar() {
    let sal = this.forma.controls["hora_sal_ibero"].value;
    let ini = this.forma.controls["hora_ini_actividad"].value;
    let fin = this.forma.controls["hora_fin_actividad"].value;
    //alert(sal);
    //return false;
    if (sal != undefined) {
      if (this.tiempoInicioMayor(sal, ini)) {
        //alert('La hora de Inicio de actividad es mayor a la hora de Fin.');
        this.toastr.error('La hora de salida de Ibero es mayor a la hora de Inicio.');
        return false;
      }
    }

    if (this.tiempoInicioMayor(ini, fin)) {
      //alert('La hora de Inicio de actividad es mayor a la hora de Fin.');
      this.toastr.error('La hora de Inicio de actividad es mayor a la hora de Fin.');
      return false;
    }

    this.User = this.usuarioService.getUser();
    this.forma.controls["usuarioId"].setValue(this.User.usuarioId);
    this.forma.controls["usuario_estatusId"].setValue(this.User.usuarioId);
    //this.forma.controls["fecha_actividad"].setValue("2021-01-01");
    this.forma.controls["fecha_registro"].setValue("2020-01-01");
    this.forma.controls["fecha_estatus"].setValue("2020-01-01");
    if(this.model.temaId==0){
      this.forma.controls["temaId"].setValue(undefined);
      this.forma.controls["tema_categoriaId"].setValue(undefined);
      this.forma.controls["tema_subcategoriaId"].setValue(undefined);
    }
    //this.forma.controls["colegio_responsableId"].setValue(Number(this.forma.controls["colegio_responsableId"].value));
    //this.forma.controls["colegio_responsableId"].setValue(Number(this.forma.controls["colegio_responsableId"].value));
    

    this.spinner.show();
    //console.log(this.forma.value);
    if (this.forma.controls["solicitud_actividadId"].value == undefined) {
      this.solicitudservice.addData(this.forma.value).subscribe(
        (res) => {
          //Si está confirmada, la guardamos en el calendario
          if(this.forma.controls["estatus_actividadId"].value==2){
            this.guardarEvento();
          }
          this.recargar();
        },
        (error) => {
          this.toastr.error(error.error.message);
        });
    }
    else {
      this.solicitudservice.updData(this.forma.controls["solicitud_actividadId"].value, this.forma.value).subscribe(
          (res) => {
            //Si está confirmada, la guardamos en el calendario
            if(this.forma.controls["estatus_actividadId"].value==2){ 
              this.guardarEvento();
            }
            this.recargar();
          },
          (error) => {
            this.spinner.hide();
            console.log(error);
            alert("Ocurrió una excepción");
            this.modal.dismissAll();
          });
    }
  }

  guardarEvento() {

    /*
    agenda_actividadId: [0],
    nombre: ['', [Validators.required]],
    tipo: ['', [Validators.required]],
    fecha_actividad: [0, [Validators.required]],
    hora_actividad: [0, [Validators.required]],
    hora_fin: [0, [Validators.required]],
    duracion: [0, [Validators.required]],
    invitados: [[]],
    usuario_registroId: [0],
    usuario_modificaId: [0],
    microGraphCalendarId: ['', [Validators.required]],
    microGraphEventId: [''] 
    */
    //console.log(this.fevento.controls["agenda_actividadId"].value);
    this.fevento.controls["agenda_actividadId"].setValue(Number(this.model.agenda_actividadId)); //Para relacionar el evento y la actividad

    this.fevento.controls["solicitud_actividadId"].setValue(Number(this.model.solicitud_actividadId)); //Para relacionar el evento y la actividad
    
    var nActividad = this.NombreActividad.find(x => x.actividadId === Number(this.model.actividadId));
    //console.log(nActividad.nombreaportal);
    this.fevento.controls["nombre"].setValue(nActividad.nombreaportal);
    this.fevento.controls["tipo"].setValue("Externa"); //Por ser de AC
    this.fevento.controls["fecha_actividad"].setValue(this.forma.controls["fecha_actividad"].value);
    this.fevento.controls["hora_actividad"].setValue(this.forma.controls["hora_ini_actividad"].value);
    this.fevento.controls["hora_fin"].setValue(this.forma.controls["hora_fin_actividad"].value);
    //duracion: calculado
    //invitados: invitados
    //usuario_registroId: automático
    const microGraphCalendarId = "AAMkADI3ZGQwOWVkLWI2YTItNDdlYy1hMjM2LWMzZjU1ZDVjYTg2NgBGAAAAAAB3PulJYKcFQqsJ-kotIReFBwCKI_sz-F8iQIgspgrbj2D6AAAAAAEGAACKI_sz-F8iQIgspgrbj2D6AAAAAJvGAAA=";
    this.fevento.controls["microGraphCalendarId"].setValue(microGraphCalendarId);
    this.fevento.controls["microGraphEventId"].setValue(this.model.microGraphEventId);
    
    this.fevento.controls["usuario_registroId"].setValue(this.forma.controls["usuarioId"].value);
    this.fevento.controls["usuario_modificaId"].setValue(this.usuarioService.getUser().usuarioId);
    
    let resultList: Invitado[] = [];
    let newInvitado = new Invitado();
    newInvitado.usuarioId = this.model.idusuarioInternoResponsable;
    newInvitado.item_text = this.model.usuarioInternoResponsable + " (" + this.model.correoInternoResponsable + ")";
    resultList.push(newInvitado);
    this.fevento.controls["invitados"].setValue(resultList);
    //console.log(this.UsuarioLista);
    //console.log(this.fevento);
    //return false;
    this.spinner.show();
    const hora_i = this.fevento.controls["hora_actividad"].value;
    const hora_f = this.fevento.controls["hora_fin"].value;

    const timeStart = new Date("01/01/2007 " + hora_i).getTime();
    const timeEnd = new Date("01/01/2007 " + hora_f).getTime();

    let timeDiff = timeEnd - timeStart;

    if (timeDiff < 0) {
        timeDiff += 24 * 60 * 60 * 1000;
    }

    var hours = Math.floor(timeDiff / (60000 * 60));
    var minutes = ((timeDiff % (60000 * 60)) / 60000).toFixed(0);

    this.fevento.controls["duracion"].setValue((hours < 10 ? '0' : '') + hours + ":" + (parseInt(minutes) < 10 ? '0' : '') + minutes);

    /*
    if (!this.FormularioCumpleConValidacionesLogicas(this.forma.value)) {

        this.spinner.hide();
        return;
    }
    */
    //console.log(this.fevento.controls["agenda_actividadId"].value);
    if (this.fevento.controls["agenda_actividadId"].value == undefined || this.fevento.controls["agenda_actividadId"].value == 0) {
        this.fevento.controls["usuario_registroId"].setValue(this.usuarioService.getUser().usuarioId);

        /*
            GUARDAR EN MICROSOFT GRAPH
        */
        let event = this.initializeEvent(this.fevento.value);
        //console.log(this.fevento);
        //console.log(event);
        this.graphService.addEventToCalendar(event, this.fevento.value.microGraphCalendarId).then(message => {
            //console.log(message.id);
            this.fevento.value.microGraphEventId = message.id;

            this.agendaService.addData(this.fevento.value).subscribe(
                (res) => {
                    console.log(res);
                    this.toastr.success("Actividad guardada correctamente en el calendario.");
                    this.recargar();
                    this.spinner.hide();
                },
                (error) => {
                    console.log(error);
                    this.toastr.error(error);
                    this.spinner.hide();
                });
        });
    }
    else {

        let event = this.initializeEvent(this.fevento.value);
        //console.log(event);
        //console.log(this.fevento.value);

        this.graphService
            .updateEventToCalendar(event, this.fevento.value.microGraphEventId)
            .then((successfull) => {
                this.fevento.controls["usuario_modificaId"].setValue(this.usuarioService.getUser().usuarioId);
                this.agendaService.updData(this.fevento.controls["agenda_actividadId"].value, this.fevento.value).subscribe(
                    (res) => {
                        //console.log(res);
                        this.toastr.success("Actividad editada correctamente.");;
                        this.recargar();
                        this.spinner.hide();
                    },
                    (error) => { 
                      console.log(error);
                      this.toastr.error(error); this.spinner.hide(); 
                    });
            });
    }

}

  recargar() {
    this.modal.dismissAll();
    this.addSolicitudes();
  }

  aTitulo='';
  nuevo(content) {
    this.aTitulo='Alta';
    this.bEditar = false;
    this.iniciarForma();
    //Indicamos el estatus 1, para nueva con el control oculto
    this.model.estatus_actividadId = 1;
    this.model.usuario_tipoId = 2;
    this.model.perfilId = 2;
    this.fRol();
    //this.forma.controls["fecha_registro"].setValue("2020-01-01");
    this.modal.open(content, { size: 'xl' });
    //alert(this.bEditar);
  }

  //item;
  bEditar=false;
  editar(content, modelo) {
    this.aTitulo='Activación';
    this.bEditar = true;
    this.model = modelo;
    this.eActividad = this.NombreActividad.find(x => x.actividadId === Number(this.model.actividadId)); 
    this.setCategoria();
    this.setSubCategoria();
    this.model.fecha_actividad = new Date(modelo.fecha_actividad);
    this.modal.open(content, { size: 'xl' });
    let usr = this.Usuario.find(x => x.usuarioId === Number(this.model.usuarioId));
    this.model.usuario_tipoId = usr.usuario_tipoId;
    this.model.perfilId = usr.perfilId;
    this.model.rolId = usr.rolId;
    //this.model.colegioId = usr.colegioId;

    this.setFiltroUsuario();
    //this.model.usuarioId = 0;
    this.setDatosUsuario(0);
    this.setDatosResponsable();
    /*
    this.fPerfil();
    this.fRol();
    this.fUsuario();    
    */
  }

  setFiltroUsuario(){
    this.PerfilLista = this.Perfil.filter(x => x.usuario_tipoId === Number(this.model.usuario_tipoId));
    this.RolLista = this.Rol.filter(x => x.perfilId === Number(this.model.perfilId));  
    this.UsuarioLista = this.Usuario.filter(x => x.rolId === Number(this.model.rolId));
    //console.log(this.model.colegioId);
    //console.log(this.Responsable);
    this.ResponsableLista = this.Responsable.filter(x => x.colegioId === Number(this.model.colegioId));
  }

  borrar(modelo) {
    this.User = this.usuarioService.getUser();
    modelo.usuarioId=this.User.usuarioId;
    modelo.usuario_estatusId=this.User.usuarioId;
    modelo.estatus_actividadId=5;

    if(modelo.fecha_actividad.includes("T"))
      modelo.fecha_actividad=modelo.fecha_actividad.split("T")[0];

    if(modelo.fecha_registro.includes("T"))
      modelo.fecha_registro=modelo.fecha_registro.split("T")[0];
    
    if(modelo.fecha_estatus.includes("T"))
      modelo.fecha_estatus=modelo.fecha_estatus.split("T")[0];

    var txt;
    var r = confirm("Desea borrar la solicitud?");
    if (!r) {
      return false;
    } 
    
    this.spinner.show();
      this.solicitudservice.updData(modelo.solicitud_actividadId, modelo).subscribe(
          (res) => {
            this.spinner.hide();
            this.recargar();
          },
          (error) => {
            this.spinner.hide();
            console.log(error);
            alert("Ocurrió una excepción");
            this.modal.dismissAll();
          });
  }

 objToDate(fecha){
  return fecha.year + '-' + ("0" + (fecha.month + 1)).slice(-2) + '-'+ fecha.day;
  }

  visible(item, id){
    let flag = true;

    if(this.eActividad!==undefined){
      let permiso = this.eActividad.campo_seleccionado;
      let array = permiso.replace(/\s/g, '').split(",");
      flag = array.includes(String(id));
    }
    return flag;
  }

  asc;
  sort(){
    const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;
    const comparer = (idx, asc) => (a, b) => ((v1, v2) =>
        v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
        )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

    // do the work...
    document.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
      const table = th.closest('table');
      const tbody = table.querySelector('tbody');
      Array.from(tbody.querySelectorAll('tr'))
        .sort(comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc))
        .forEach(tr => tbody.appendChild(tr) );
    })));
  }

  tiempoInicioMayor(ini, fin) {
    var esMayor = false;
    var a = '01/01/2001 ' + ini; //"11/24/2014 3:10 PM";
    var b = '01/01/2001 ' + fin; //"11/23/2014 7:45 AM";

    //alert(a + ' > ' + b);
    var aDate = new Date(a).getTime();
    var bDate = new Date(b).getTime();

    if (aDate > bDate) {
      esMayor = true;
    }

    return esMayor;
  }


  /***Graph */
  initializeEvent(formulario: any): MicrosoftGraph.Event {
    console.clear();
    //console.log("ingresando a initialize event");
    //console.log(formulario);

    let activitySubject: string = formulario.nombre;
    let startDate: string = "";
    let endDate: string = "";
    let timeZone = "America/Mexico_City";

    let fechaTransformadaDeActividad: Date = formulario.fecha_actividad;


    let fechaYHoraDeInicio = this.ConcatenarFechaYTiempo(fechaTransformadaDeActividad, formulario.hora_actividad);
    startDate = this.TransformDateToMicrosoftGraphDate(fechaYHoraDeInicio);


    let durationInMinutes = this.TransformDurationToMinutes(formulario.duracion);
    let fechaYHoraFin = new Date(fechaYHoraDeInicio);
    fechaYHoraFin.setMinutes(fechaYHoraDeInicio.getMinutes() + durationInMinutes);
    endDate = this.TransformDateToMicrosoftGraphDate(fechaYHoraFin);


    const graphEvent: MicrosoftGraph.Event = {
        subject: activitySubject,
        start: {
            dateTime: startDate,
            timeZone: timeZone
        },
        end: {
            dateTime: endDate,
            timeZone: timeZone
        }
    };

    graphEvent.attendees = [];

    const emails = this.ExtractEmailsFromAppAttendees(formulario.invitados);// nombreDeUsuario ( correo@ibero.mx )
    //console.log(emails);

    emails.forEach(email => {
        graphEvent.attendees?.push({
            type: 'required',
            emailAddress: {
                address: email
            }
        });
    });

    graphEvent.body = {
        contentType: 'text',
        content: activitySubject
    };

    return graphEvent;
  }

  ExtractEmailsFromAppAttendees(invitados: any[]): string[] {
      let emails = []; //new Array(invitados.length);

      for (let i = 0; i < invitados.length; i++) {
          let currentInvitado = invitados[i].item_text;
          emails.push(this.ExtractEmail(currentInvitado));//Extraer de: "nombreDeUsuario ( correo@ibero.mx )"
      }

      return emails;
  }

  ExtractEmail(attendee: string): string {
      //return "calendario.share1@ibero.mx";
      return attendee.split('(')[1].split(')')[0];
  }

  TransformDateToMicrosoftGraphDate(dateToChange: Date): string {
      //YYYY-MM-DDTHH:mm:ss
      let year = dateToChange.getFullYear();
      let month = this.ZeroFirstNumberToString(dateToChange.getMonth() + 1);//los meses estan en base 0
      let day = this.ZeroFirstNumberToString(dateToChange.getDate());
      let hour = this.ZeroFirstNumberToString(dateToChange.getHours());
      let minutes = this.ZeroFirstNumberToString(dateToChange.getMinutes());
      let seconds = this.ZeroFirstNumberToString(dateToChange.getSeconds());

      return year + "-" + month + "-" + day + "T" + hour + ":" + minutes + ":" + seconds;
  }

  ZeroFirstNumberToString(number: number): string {
      //Example: if 5 then return "05"
      //Example: if 20 then return "20"
      return ((number >= 10) ? number : "0" + number).toString();
  }  

  ConcatenarFechaYTiempo(startDate: Date, horaDeInicio: string): Date {
    let durationElements = horaDeInicio.toString().split(':');
    let hours = Number(durationElements[0]);
    let minutes = Number(durationElements[1]);

    return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), hours, minutes);
  }

  TransformDurationToMinutes(duration: string): number {
      let resultingMinutes = 0;

      let durationElements = duration.toString().split(':');
      let hours = Number(durationElements[0]);
      let minutes = Number(durationElements[1]);
      resultingMinutes = (hours * 60) + minutes;

      return resultingMinutes;
  }
// ---------------------------------------------------------------
  updatePaginatedItems() {    
    const filteredItems = this.Solicitudes.filter(item => 
      this.matchesSearch(item)
    );

    let start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;

    this.paginatedItems = filteredItems.slice(start, end);
    
  }

  matchesSearch(item: any): boolean {
    const searchLower = this.searchText.toLowerCase();
    return (
      item.nombreAPortalActividad.toLowerCase().includes(searchLower) ||
      item.nombreColegio.toLowerCase().includes(searchLower) ||
      item.usuarioInternoResponsable.toLowerCase().includes(searchLower) ||
      (item.fecha_registro && item.fecha_registro.toString().includes(searchLower)) ||
      (item.fecha_actividad && item.fecha_actividad.toString().includes(searchLower))
    );
  }

  onSearchChange() {
    this.currentPage = 1; // Reiniciar a la primera página en cada búsqueda
    this.updatePaginatedItems();
  }

  changePage(page: number) {
    if (page < 1 || page > this.totalPages) return; // Evitar cambiar a páginas inválidas
    this.currentPage = page;
    this.updatePaginatedItems();
  }

  get totalPages(): number {
    const filteredItemsCount = this.Solicitudes.filter(item => 
      this.matchesSearch(item)
    ).length;
    return Math.ceil(filteredItemsCount / this.itemsPerPage);
  }

}
