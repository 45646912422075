import { Component, OnInit } from '@angular/core';
//import { ApiService } from '../shared/services/api.service';
import { Router } from '@angular/router';
import { UsuarioService } from '../../../core/services/usuario.service';
import { CarritoService } from '../../../core/services/carrito.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styles: [
  ]
})
export class NavBarComponent implements OnInit {
  interno:boolean = true;
  isExpanded = false;
  usuario;
  rol;
  tipoUsuario = localStorage.getItem('tipoUsuario');
  user;

  public isMenuCollapsed: boolean;
  constructor(
    //private apiService: ApiService, 
    public us: UsuarioService,
    public cs: CarritoService,
    public router: Router) {
      //this.interno = true;
      //this.perfil = localStorage.getItem('perfil');
      this.isMenuCollapsed = true;
    //alert(router.url);
    this.usuario = us.getUser();
    //console.log(this.usuario);
    this.user = this.usuario.nombreCompleto;
    
    //alert(this.perfil);
  }

  ngAfterContentInit() {
      if (localStorage.getItem('token') != null && localStorage.getItem('role') != null) {
          //this.apiService.utilsService.role = localStorage.getItem('role');
      }
  }

  collapse() {
      this.isExpanded = false;
  }

  toggle() {
      this.isExpanded = !this.isExpanded;
  }

  logout() {

      //this.apiService.utilsService.role = undefined;
     // localStorage.removeItem('role');
      //localStorage.removeItem('token');
      //localStorage.removeItem('perfil');
      //localStorage.removeItem('usuario');
      this.clear();
      this.router.navigateByUrl('/');
  }

  clear(){
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    localStorage.removeItem('tipoUsuario');
    localStorage.removeItem('perfil');
    localStorage.removeItem('rolId');
    localStorage.removeItem('usuario');
    localStorage.removeItem('banner');
    localStorage.removeItem('actividad');
    localStorage.removeItem('curso');
    localStorage.removeItem('concurso');
    localStorage.removeItem('torneo');
    localStorage.removeItem('beca');
    localStorage.removeItem('orientador');
    localStorage.removeItem('xplora');
    localStorage.removeItem('espacio');
    localStorage.removeItem('visita');
    //localStorage.removeItem('carrito');
  }  

  ngOnInit(): void {
  }

}
