<section class=" mb-0" id="about"> 
    <div class="container align-items-center flex-column">
        <div class="row text-center">
            <div class="col-md-4 my-auto">
                &nbsp;
            </div>            
            <div class="col-md-4 my-auto">
                &nbsp;
                <h2 class="pagina-seccion-titulo sol-titulo text-secondary mb-12">
                    Actividades
                </h2> 
            </div>
            <div class="col-md-4 my-auto">
                &nbsp;
            </div>            
        </div>

        <div class="row">
            <div class="col-md-12 text-secondary text-left">&nbsp;</div>
        </div> 

    <div class="card flex-column">      
        <div class="row">
            <div class="col-md-12 text-left">
                <p>Actividades para:</p>
            </div>
        </div>
        <div class="row">                  
            <div class="col-md-3 text-center">
                <img class="animate__animated animate__backInUp" src="/assets/img/icono_ibero02.svg" alt="" />
                <p>Ibero</p> 
            </div>
            <div class="col-md-3 text-center">
                <img class="animate__animated animate__backInUp" src="/assets/img/icono_colegio02.svg" alt="" />
                <p>En tu colegio</p> 
            </div>
            <div class="col-md-3 text-center">
                 <img class="animate__animated animate__backInUp" src="/assets/img/icono_orientadores vocacionales02.svg" alt="" />              
                 <p>Orientación vocacional</p>
            </div>
            <div class="col-md-3 text-center">
                 <img class="animate__animated animate__backInUp" src="/assets/img/icono_adistancia02.svg" alt="" />
                 <p>Actividades a distancia</p>
            </div>
        </div>                
    </div>
    
</div>
</section>