import { Component, OnInit } from '@angular/core';
import { ActividadesService } from '../../../core/services/actividades.service';
import { SubCategoriaService } from '../../../core/services/subCategoria.service';
import { CategoriaService } from '../../../core/services/categoria.service';
import { TemaService } from '../../../core/services/tema.service';
import { UsuarioService } from '../../../core/services/usuario.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tema',
  templateUrl: './tema.component.html',
  styles: [
  ]
})
export class TemaComponent implements OnInit {
  tipoTema;
  //TemaList;
  Actividad;
  //Categoria;
  //Subcategoria;
  TipoPublico;
  Idioma;
  User;

  Tema;
  Temas;
  Filtro;

  forma: FormGroup;
  filtros: FormGroup;
  model;
  mf;

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private usuarioService: UsuarioService,
    private subcategoriaservice: SubCategoriaService,
    private categoriaservice: CategoriaService,
    private temaservice:TemaService,
    private actividadesService: ActividadesService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal
  ) {
    if(!this.usuarioService.int()) this.router.navigate(['/portal']);
    this.iniciarForm();
  }

  ngOnInit(): void {
    this.addFiltros();
    this.addLista(false);
  }

  iniciarForm() {
    this.forma = this.fb.group({
      temaId: [0],
      descripcion: [''],
      tema_categoriaId: [0],
      categoriaDescripcion: [''],
      tema_subcategoriaId: [0],
      subCategoriaDescripcion: [0],
      actividadId: [0],
      tema_tipo_publicoId: [0],
      tipoPublicoDescripcion: [''],
      tema_tipoId: [0],
      tipoTemaDescripcion: [''],
      objetivo: [''],
      contenido: [''],
      idiomaId: [0],
      cve_idioma: [''],
      costo: [0],
      nombreactividad: [''],
      activo: ['S', [Validators.required]]
    });
    this.model = this.forma;

    //Filtro
    this.filtros = this.fb.group({
      tema_categoriaId: 0,
      tema_subcategoriaId: 0,
      actividadId:0
    });
    this.mf = this.filtros;


  }

  ActividadLista;
  addLista(bfilter){
    this.spinner.show();
    this.User = this.usuarioService.getUser();
    this.temaservice.getTemasUsuarioInterno(this.User.usuarioId).toPromise()
    .then(data => {
      this.spinner.hide();
      this.Temas = data;
      this.TemasFiltrados = this.Temas;
      //Asignamos el universo de items al listado, mediante una entidad de filtro
      //this.TemaList = this.Temas;
      //this.Actividad = this.getActividades();
      this.ActividadLista = this.getActividades();

      //Si es recarga, aplicamos filtro
      if (bfilter) this.filtra();

      //this.selCategoria();
    })

  }

  addFiltros() {
    this.loadCategoria();
    /*
    this.Categoria = this.temaservice.getTemaCategoria();
    */
    this.tipoTema = this.temaservice.getTipoTema();
    this.TipoPublico = this.temaservice.getTemaTipoPublico();
    this.Idioma = this.temaservice.getIdioma();
    /*
    this.User = this.usuarioService.getUser();
    this.actividadesService.getActividadUsuario(this.User.usuarioId).toPromise()
      .then(data => {
        this.Actividad = data;
      })
      */

  }


  getActividades() {
    return Array.from(new Set(this.Temas
      .map((item: any) => item.actividadId)))
      .map(actividadId => { return this.Temas.find(obj => obj.actividadId === actividadId) })
  }

  CategoriaLista;
  TemasFiltrados;
  selCategoria() {
    //this.TemasFiltradosTemp = this.Temas;
    this.mf.tema_categoriaId = 0;
    this.mf.tema_subcategoriaId = 0;
    
     /*
    if (this.model.actividadId) {
      this.mf.actividadId = this.model.actividadId;
    }

   
    if (this.model.tema_categoriaId) {
      this.mf.tema_categoriaId = this.model.tema_categoriaId;
    }
    */
    
    this.filtra();
    this.CategoriaLista = Array.from(new Set(this.TemasFiltrados
      .map((item: any) => item.tema_categoriaId)))
      .map(tema_categoriaId => { return this.TemasFiltrados.find(obj => obj.tema_categoriaId === tema_categoriaId) })
    //console.log(this.CategoriaLista);
  }

  SubCategoriaLista;
  selSubCategoria() {
    this.mf.tema_subcategoriaId = 0;
    this.filtra();
    this.SubCategoriaLista = Array.from(new Set(this.TemasFiltrados
      .map((item: any) => item.tema_subcategoriaId)))
      .map(tema_subcategoriaId => { return this.TemasFiltrados.find(obj => obj.tema_subcategoriaId === tema_subcategoriaId) })
    //console.log(this.SubCategoriaLista);
  }
  /*
  selSubcategoria(){
    this.mf.tema_subcategoriaId= 0;
    if(this.model.tema_categoriaId){
      this.mf.tema_categoriaId=this.model.tema_categoriaId;
    }
    this.temaservice.getTemaSubcategoria()
    .toPromise()
      .then(data => {
        this.Subcategoria = data;
        this.Subcategoria = this.Subcategoria.filter(x => x.tema_categoriaId === Number(this.mf.tema_categoriaId));
        this.filtrar();
      })

  }
  */

  TemasFiltradosTemp;
  filtra(){
    //Establecemos los elementos totales de la lista
    this.TemasFiltradosTemp = this.Temas;
    //console.log(this.mf.actividadId + ' - ' + this.mf.tema_categoriaId + ' - ' + this.mf.tema_subcategoriaId);

    if (this.mf.actividadId != 0 && this.mf.actividadId !== undefined) {
      this.TemasFiltradosTemp = this.TemasFiltradosTemp
        .filter(x => x.actividadId === Number(this.mf.actividadId));
    }

    if (this.mf.tema_categoriaId != 0 && this.mf.tema_categoriaId !== undefined) {
      let caid = (this.mf.tema_categoriaId === null) ? null : Number(this.mf.tema_categoriaId);
      this.TemasFiltradosTemp = this.TemasFiltradosTemp
        .filter(x => x.tema_categoriaId === caid);
    }

    if (this.mf.tema_subcategoriaId != 0 && this.mf.tema_subcategoriaId !== undefined
      //&& this.mf.tema_subcategoriaId !== null
    ) {
      let csid = (this.mf.tema_subcategoriaId === null) ? null : Number(this.mf.tema_subcategoriaId);
      this.TemasFiltradosTemp = this.TemasFiltradosTemp
        .filter(x => x.tema_subcategoriaId === csid);
      //.filter(x => x.tema_subcategoriaId === Number(this.mf.tema_subcategoriaId));
    }



    //this.TemaList = this.TemasFiltradosTemp;
    this.TemasFiltrados = this.TemasFiltradosTemp;

  }

  nuevo(content) {
    this.iniciarForm();
    this.model.activo = 'S';
    this.modal.open(content, { size: 'xl' });
    //this.loadCategoria();
  }

  editar(content, modelo) {
    this.model = modelo;
    this.loadSubCategoria();
    this.modal.open(content,{size:'xl'});
  }

  guardar() {
    this.forma.controls["costo"].setValue(Number(this.model.costo));

    //console.log(this.forma.value);
    //return false;

    if(this.forma.controls["temaId"].value == undefined) {
      this.temaservice.addData(this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {console.log(error);});
    }
    else {
      this.temaservice.updData(this.forma.controls["temaId"].value, this.forma.value).subscribe(
        (res) => {this.recargar();},
        (error) => {console.log(error);});
    }
  }

  recargar(){
    this.modal.dismissAll();
    this.addLista(true);
    //this.filtra();
  }

  Categoria;
  loadCategoria() {
    this.Categoria = this.categoriaservice.getData().toPromise()
      .then(data => {
        this.Categoria = data;
      })
    //console.log(this.Categoria);
  }

  SubCategoria;
  loadSubCategoria() {
    this.spinner.show();
    this.subcategoriaservice.getData().toPromise()
      .then(data => {
        this.spinner.hide();
        this.SubCategoria = data;
        //Asignamos el universo de items al listado, mediante una entidad de filtro
        this.SubCategoria = this.SubCategoria
          .filter(x => x.tema_categoriaId === Number(this.model.tema_categoriaId));

        //console.log(this.SubCategoria);
      })

  }

}
