import { Component, OnInit, Input } from '@angular/core';
import { AgendaService } from '../../../core/services/agenda.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioService } from '../../../core/services/usuario.service';

@Component({
  selector: 'app-reporte',
  templateUrl: './reporte.component.html',
  styles: []
})

export class ReporteComponent implements OnInit {
    Reporte;
    Actividades;
    forma: FormGroup;
    model;

    constructor(
        public fb: FormBuilder,
        private router: Router,
        private usuarioService: UsuarioService,
        private http: HttpClient,
        private agendaService: AgendaService,
        private spinner: NgxSpinnerService,
        public modal: NgbModal
    ) {
        if(!this.usuarioService.int()) this.router.navigate(['/portal']);
        this.iniciarForm();
    }

    ngOnInit(): void {
        this.loadOneTime();
    }

    iniciarForm() {
        this.forma = this.fb.group({
            agenda_actividadId: 0,
            tipo_actividad: '',
            fecha_inicio: '',
            fecha_fin: ''
        })
        this.model = this.forma;
    }

    loadMainData(){
        this.spinner.show();
        this.agendaService.getReporte(this.forma.value).subscribe(
            (res) => {
                this.spinner.hide();
                this.Reporte = res;
            },
            (error) => {console.log(error);}
        );
    }

    loadOneTime(){
        this.spinner.show();
        this.agendaService.getData().toPromise()
        .then(data => {
            this.spinner.hide();
            this.Actividades = data;
        })
    }

}
