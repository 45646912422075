<!--Contenedor-->
<div class="container align-items-center flex-column">
    <!--Encabezado-->
    <div class="text-center">
        <h4 class="title-section">Agenda</h4>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="alert alert-success" *ngIf="!(this.authService.user === undefined)">
                    Se encuentra autenticado correctamente. Para cerrar sesión dar click
                    <span class="MGClass_LogOutLink" (click)="this.authService.signOut()">aquí</span>
                </div>
                <div class="alert alert-danger" *ngIf="(this.authService.user === undefined)">
                    Para poder agregar o editar las actividades, es necesario autenticarse. Para hacerlo, da click <span
                        class="MGClass_LogInLink" (click)="MGEvent_AskUserToLogIn()">aquí</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <select class="form-control" [(ngModel)]="participanteSeleccionado">
                    <option value="">Ver Todo</option>
                    <option *ngFor="let participante of this.participantes" value="{{participante}}">
                        {{participante}}</option>
                </select>
            </div>
            <div class="col-4">
                <button class="btn btn-danger spaceHorizontal" (click)="FiltrarPorParticipanteSeleccionado()"
                    ngbTooltip="Buscar">
                    Filtrar
                </button>
            </div>
        </div>
    </div>
    <br>
    <full-calendar #calendar [options]="calendarOptions"></full-calendar>
</div>

<!--Formulario-->

<ng-template #content let-modal>
    <div class="modal-hidden">
        <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card border-dark mb-0">
            <div class="card-header text-white text-center bg-dark">Actividad</div>
            <div class="card-body text-dark">
                <div class=" card-body row">
                    <div class="col-12">
                        <form [formGroup]="forma">
                            <div class="row text-dark">
                                <input class="form-control" type="hidden" formControlName="agenda_actividadId"
                                    [(ngModel)]="model.agenda_actividadId">
                            </div>
                            <div class="row text-dark">
                                <div class="form-group col-lg-8">
                                    <label for="nombre">Actividad:</label>
                                    <input class="form-control" type="text" formControlName="nombre"
                                        [(ngModel)]="model.nombre">
                                </div>
                                <div class="form-group col-lg-4">
                                    <label>Tipo de Actividad</label>
                                    <select class="form-control" formControlName="tipo" [(ngModel)]="model.tipo">
                                        <option value="">--Seleccionar--</option>
                                        <option value="Interna">Interna</option>
                                        <option value="Externa">Externa</option>
                                    </select>
                                </div>
                                <div class="form-group col-lg-12">
                                    <label>Invitados:</label>
                                    <ng-multiselect-dropdown [placeholder]="'--Seleccionar--'"
                                        [settings]="dropdownSettings" [data]="dropdownList" formControlName="invitados"
                                        [(ngModel)]="model.invitados" (onSelect)="onItemSelect($event)"
                                        (onSelectAll)="onSelectAll($event)"></ng-multiselect-dropdown>
                                </div>
                                <div class="form-group col-lg-4">
                                    <label for="fecha_actividad">Fecha:</label>
                                    <div class="input-group">
                                        <input class="form-control" placeholder="yyyy-mm-dd" [readonly]="true"
                                            id="fecha_actividad" [(ngModel)]="model.fecha_actividad"
                                            formControlName="fecha_actividad" ngbDatepicker #d="ngbDatepicker"
                                            [markDisabled]="isDisabled">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                                type="button"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-lg-4">
                                    <label>Hora de Inicio</label>
                                    <input class="form-control" type="time" value="08:00:00"
                                        formControlName="hora_actividad" [(ngModel)]="model.hora_actividad">
                                </div>
                                <div class="form-group col-lg-4">
                                    <label>Hora Fin</label>
                                    <input class="form-control" type="time" value="08:00:00" formControlName="hora_fin"
                                        [(ngModel)]="model.hora_fin">
                                </div>
                            </div>
                            <div class="row text-dark pt-3">
                                <div class="col-lg-12">
                                    <div class="buttonSelect text-center">
                                        <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                                        <button class="btn btn-danger" (click)="guardar()"
                                            [disabled]="forma.invalid">Guardar</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>