<!-- Torneo Section-->
<section class="section" *ngIf="this.cs()">
    <div class="container-fluid align-items-center d-flex flex-column">
      <div class="row sol-titulo ">
        <div class="col-md-12 text-center pt-3">
            <h2 class="titulo-seccion-torneos text-secondary ">
                Torneos
            </h2>
        </div>
    </div>
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-secondary text-left pt-2 text-parrafo texto-torneos" >
            El deporte es una herramienta poderosa para promover la solidaridad, la amistad y el juego limpio, valores que se
            fomentan a través de nuestros Torneos Preuniversitarios. Invita a tus alumnos (as) a esta actividad donde pueden
            desarrollar habilidades de liderazgo y autodisciplina para su formación integral.
        </div>
        </div>
      </div>

     <div class="container pt-2">
      <div class="row ">
        <div class="col-sm-12 col-md-5 col-lg-5 text-left pt-3 link_torneos">
            <p *ngFor="let item of arrBanners">
                <a class="text-danger" href="{{ item.referencia }}" target="_blank">{{ item.descripcion }}</a>
            </p>
        </div>
        <div class="col-sm-12 col-md-7 col-lg-7 pt-5 content-img-torneos">
            <img class="img_jugador_basket seccion-avatar-01 animate__animated animate__backInLeft" src="/assets/img/player_basket.svg" alt="" />
        </div>
     </div>
     </div>
    </div>
    </section>

  <!--section class="pagina-seccion hfull">
      <div class="container-fluid d-flex align-items-center flex-column p-5">
          <div class="row ">
              <div class="col-md-12 text-center">
                  <h1 class="pagina-seccion-titulo text-secondary mb-12 sol-titulo">
                      Torneos
                  </h1>
              </div>
          </div>

          <div class="row pt-5">
              <div class="col-md-12 text-secondary text-left">
                  El deporte es una herramienta poderosa para promover la solidaridad, la amistad y el juego limpio, valores que se
                  fomentan a través de nuestros Torneos Preuniversitarios. Invita a tus alumnos (as) a esta actividad donde pueden
                  desarrollar habilidades de liderazgo y autodisciplina para su formación integral.
              </div>
          </div>

          <div class="row">
            <div class="col-md-4 text-left my-auto">
              <p><a class="text-danger" href="">TORNEO DE FUTBOL</a></p>
              <p><a class="text-danger" href="">TORNEO DE BASQUETBOL</a></p>
              <p><a class="text-danger" href="">TORNEO DE VOLEIBOL DE PLAYA</a></p>
          </div>
              <div class="col-md-4 mb-0">
                  <img class="seccion-avatar-01 animate__animated animate__backInLeft" src="/assets/img/jugador_basket01.svg" alt="" />
              </div>
              <div class="col-md-4 mb-0 text-left my-auto">
                  <img class="card-icon-left animate__animated animate__bounceInUp" src="/assets/img/jugador_copa02.svg" alt="" />
              </div>
          </div>
      </div>
  </section-->
