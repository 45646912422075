<!--Contenedor-->
<div class="container align-items-center flex-column">

  <!--Encabezado-->
  <div class="text-center">
    <h4 class="title-section">Notificaciones</h4>
  </div>

 <!--Filtros-->
 <div class="row">
  <div class="col-md-4 col-sm-12">
    <h5 class="title-subsection">Emisor</h5>
</div>
 </div>
 <div class="row">
  <div class="col-lg-4">
    <label>Tipo de usuario</label>
    <select id="" name="" class="form-control">
      <option value="">--Seleccionar--</option>
      <option *ngFor="let item of  "
      value="{{ item.estatus_actividadId }}" >
      {{ item.descripcion }}
      </option>
    </select>
  </div>
    <div class="col-lg-4">
      <label>Perfil</label>
      <select id="" name="" class="form-control">
        <option value="">--Seleccionar--</option>
        <option *ngFor="let item of "
          value="{{ item.actividadId }}" >
          {{ item.nombre }}
          </option>
      </select>
    </div>
    <div class="col-lg-4">
      <label>Rol</label>
      <select id="" name="" class="form-control">
        <option value="">--Seleccionar--</option>
        <option *ngFor="let item of "
        value="{{ item.id_estatus_actividad }}" >
        {{ item.cve_carrera }}</option>
    </select>
    </div>
</div>

<div class="row">
  <div class="col-lg-4">
    <label>Acción</label>
    <select id="" name="" class="form-control">
      <option value="">--Seleccionar--</option>
      <option *ngFor="let item of "
      value="{{ item.estatus_actividadId }}" >
      {{ item.descripcion }}
      </option>
    </select>
  </div>
    <div class="col-lg-4">
      <label>Estatus</label>
      <select id="" name="" class="form-control">
        <option value="">--Seleccionar--</option>
        <option *ngFor="let item of "
          value="{{ item.actividadId }}" >
          {{ item.nombre }}
          </option>
      </select>
    </div>
    <div class="col-lg-4">
      <label>Actividad</label>
      <select id="" name="" class="form-control">
        <option value="">--Seleccionar--</option>
        <option *ngFor="let item of "
        value="{{ item.id_estatus_actividad }}" >
        {{ item.cve_carrera }}</option>
    </select>
    </div>
</div>


  <!--Listado-->
  <div class="row justify-content-between py-2">
    <div class="col-md-4 col-sm-12">
        <h5 class="title-subsection">Datos de la notificación</h5>
    </div>
    <div class="col-md-4 col-sm-12 text-right">
        <button class="btn btn-primary btn_sac" (click)="nuevo(content)">Agregar</button>
    </div>
  </div>

  <table class="table table-striped text-center table-fixed">
    <thead>
      <tr>
        <th scope="col" class="encabezado">#</th>
        <th scope="col" class="encabezado">Emisor</th>
        <th scope="col" class="encabezado">Asunto</th>
        <th scope="col" class="encabezado">Actividad</th>
        <th scope="col" class="encabezado">Destino</th>
        <th scope="col" class="encabezado">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr #item *ngFor="let item of">
        <td scope="row"></td>
        <td class="w-25">
          {{ item.descripcion }}
        </td>
        <td>
          {{ item.activo=='S'?"Activo":"Inactivo" }}
        </td>
        <td></td>
        <td>
          <button class="btn icon-btn"  ngbTooltip="Editar">
            <fa-icon [icon]="['fas','edit']" class="text-dark"></fa-icon>
          </button>
        </td>
      </tr>
    </tbody>
    <tfoot class="tfoot-ibero text-center">
      <tr>
        <td colspan="6"> Registros</td>
      </tr>
    </tfoot>
  </table>
</div>

<!--Formulario-->

<ng-template #content let-modal>
  <div class="modal-hidden">
    <button class="close" aria-label="close" type="button" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card border-dark mb-0">
      <div class="card-header text-white text-center bg-dark">Datos de la notificación</div>
      <div class="card-body text-dark">

        <div class=" card-body row">
          <div class="col-12">
            <form [formGroup]="forma">
              <div class="row text-dark">
                <input class="form-control" type="hidden" formControlName="gradoId"
                       [(ngModel)]="model.gradoId">
              </div>
              <div class="row">
                <div class="col-md-4 col-sm-12">
                  <h5 class="title-subsection">Emisor</h5>
              </div>
              </div>
              <div class="row text-dark">
                <!--div class="form-group col-lg-6">
                  <label for="descripcion">Descripción:</label>
                  <input class="form-control" type="text" formControlName="descripcion"
                         [(ngModel)]="model.descripcion">
                </div-->
                <div class="form-group col-lg-4">
                  <label for="activo">Tipo de usuario</label>
                  <select class="form-control" formControlName="activo"
                          [(ngModel)]="model.activo">
                    <option value="">--Seleccionar--</option>

                  </select>
                </div>
                <div class="form-group col-lg-4">
                  <label for="activo">Perfil</label>
                  <select class="form-control" formControlName="activo"
                          [(ngModel)]="model.activo">
                    <option value="">--Seleccionar--</option>

                  </select>
                </div>
                <div class="form-group col-lg-4">
                  <label for="activo">Rol</label>
                  <select class="form-control" formControlName="activo"
                          [(ngModel)]="model.activo">
                    <option value="">--Seleccionar--</option>

                  </select>
                </div>
              </div>

              <div class="row text-dark">
                <!--div class="form-group col-lg-6">
                  <label for="descripcion">Descripción:</label>
                  <input class="form-control" type="text" formControlName="descripcion"
                         [(ngModel)]="model.descripcion">
                </div-->
                <div class="form-group col-lg-4">
                  <label for="activo">Acción</label>
                  <select class="form-control" formControlName="activo"
                          [(ngModel)]="model.activo">
                    <option value="">--Seleccionar--</option>

                  </select>
                </div>
                <div class="form-group col-lg-4">
                  <label for="activo">Estatus</label>
                  <select class="form-control" formControlName="activo"
                          [(ngModel)]="model.activo">
                    <option value="">--Seleccionar--</option>

                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 col-sm-12">
                  <h5 class="title-subsection">Destino</h5>
              </div>
              </div>
              <div class="row text-dark">
                <!--div class="form-group col-lg-6">
                  <label for="descripcion">Descripción:</label>
                  <input class="form-control" type="text" formControlName="descripcion"
                         [(ngModel)]="model.descripcion">
                </div-->
                <div class="form-group col-lg-4">
                  <label for="activo">Tipo de usuario</label>
                  <select class="form-control" formControlName="activo"
                          [(ngModel)]="model.activo">
                    <option value="">--Seleccionar--</option>

                  </select>
                </div>
                <div class="form-group col-lg-4">
                  <label for="activo">Perfil</label>
                  <select class="form-control" formControlName="activo"
                          [(ngModel)]="model.activo">
                    <option value="">--Seleccionar--</option>

                  </select>
                </div>
                <div class="form-group col-lg-4">
                  <label for="activo">Rol</label>
                  <select class="form-control" formControlName="activo"
                          [(ngModel)]="model.activo">
                    <option value="">--Seleccionar--</option>

                  </select>
                </div>
              </div>
              <div class="row justify-content-center pt-3">
                <div class="col-md-4 col-sm-12">
                  <h5 class="title-subsection">Datos de la notificación</h5>
              </div>
              </div>
              <div class="row justify-content-center pt-3">
                <div class=" form-group col-md-2 text-right">
                  <label for="descripcion">Asunto:</label>
                </div>
                <div class="form-group col-lg-6">
                  <textarea class="form-control"  rows="5"></textarea>
                </div>
              </div>
              <div class="row justify-content-center pt-3">
                <div class=" form-group col-md-2 text-right">
                  <label for="descripcion">Encabezado:</label>
                </div>
                <div class="form-group col-lg-6">
                  <textarea class="form-control"  rows="3"></textarea>
                </div>
              </div>
              <div class="row justify-content-center pt-3">
                <div class=" form-group col-md-2 text-right">
                  <label for="descripcion">Notificación:</label>
                </div>
                <div class="form-group col-lg-6">
                  <textarea class="form-control"  rows="3"></textarea>
                </div>
              </div>
              <div class="row justify-content-center pt-3">
                <div class=" form-group col-md-2 text-right">
                  <label for="descripcion">Firma:</label>
                </div>
                <div class="form-group col-lg-6">
                  <textarea class="form-control"  rows="2"></textarea>
                </div>
              </div>
              <div class="row offset-md-1 pt-3">
                <div class="col-md-3 text-right">
                  <label class="form-check-label" for="defaultCheck1">
                    Notificar al administrador
                  </label>
                </div>
                <div class="col-md-2">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                    <label class="form-check-label" for="defaultCheck1">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div class="row offset-md-1 pt-3">
                <div class="form-group col-lg-4">
                  <label for="activo">Estatus</label>
                  <select class="form-control" formControlName="activo"
                          [(ngModel)]="model.activo">
                    <option value="">--Seleccionar--</option>
                    <option value="S">Activo</option>
                    <option value="N">Inactivo</option>
                  </select>
                </div>
              </div>

              <div class="row text-dark pt-3">
                <div class="col-lg-12">
                  <div class="buttonSelect text-center">
                    <button class="btn btn-danger" (click)="modal.close()">Cancelar</button>&nbsp;
                    <button class="btn btn-danger" >Guardar</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

