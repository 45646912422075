import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioService } from '../../../core/services/usuario.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styles: [
  ]
})
export class PanelComponent implements OnInit {

  constructor(
    private router: Router,
    private usuarioService: UsuarioService,
  ) { 
    if(!this.usuarioService.int()) this.router.navigate(['/portal']);
  }

  ngOnInit(): void {
  }

}
